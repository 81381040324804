import { connect } from "react-redux"
import SearchByProduct from "components/StoreFinder/components/SearchByProduct"
import { setPatternId } from "components/StoreFinder/actions"

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPatternId: (patternId) => dispatch(setPatternId(patternId))
  }
}

const SearchByProductContainer = connect(mapStateToProps, mapDispatchToProps)(SearchByProduct)

export default SearchByProductContainer
