import { FormattedMessage } from "react-intl"
import newWhisperClothes from "./assets/img/new-whisper-clothes.jpg"
import "./assets/styles/theNewWhisper.scss"
import { NAV_ITEMS } from "modules/whisper/constants"

const TheNewWhisper = () => (
  <div className="bg-light py-5">
    <div className="container bg-light">
      <div className="row">
        <div className="col-12 col-md-6">
          <img className="w-100" src={newWhisperClothes} alt="whisper cloths" />
        </div>
        <div className="col-12 col-md-6">
          <h2 className="new-whisper-container text-capitalize text-secondary my-4 mt-md-0">
            <FormattedMessage
              id="WhisperNeoPlus.theNewWhisper.title"
              description="The New Whisper section title"
              defaultMessage="The New Whisper"
            />
          </h2>
          <p className="text-secondary">
            <FormattedMessage
              id="WhisperNeoPlus.theNewWhisper.description"
              description="The New Whisper section description"
              defaultMessage="Designed to offer an updated color palette with the same luxurious look and feel of soft leather synonymous with the name,Whisper Neo Plus now includes 17 new colors. Spradling is adding an array of warm light neutrals, earth tones, watery blues, and a spectrum of greens.{br}{br}Whisper features Permablok Plus+, our newly developed protective coating, which seals the surface of your upholstery providing a shield against stains, making cleaning even easier and improving the removal of denim-dye transfer.{br}{br}The understated elegance of Whisper has combined the lavish aesthetic of soft leather with the outstanding wear-resistant properties required in commercial applications."
              values={{ br: <br /> }}
            />
          </p>
          <div className="new-whisper-container mt-4">
            <a
              className="btn btn-outline-slate-gray"
              href={`#${NAV_ITEMS[NAV_ITEMS.length - 1]}`}
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="WhisperNeoPlus.Banner.buttonText"
                description="WhisperNeoPlus buttonText banner"
                defaultMessage="Request Sample Card"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default TheNewWhisper
