import { useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, Col, Row } from "reactstrap"
import SelectCountry from "./components/SelectCountry"
import SelectRegion from "./components/SelectRegion"
import { useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import useToggle from "hooks/useToggle"
import ErrorMessage from "components/ErrorMessage"
import ModalPrivacyPolicy from "./components/ModalPrivacyPolicy"
import { getCurrentInstanceId, getCurrentLanguageAndInstanceCode } from "services/instances"
import { VALIDATION_MESSAGES } from "utils/forms"
import {
  REQUEST_SAMPLES_INFO,
  MODAL_TYPES_DOWNLOAD,
  CONTACT_INFO,
  MODAL_TYPES_THANKS_URL,
  MODAL_TYPES
} from "modules/masiala/constants"
import ColorsSelect from "../ColorsSelect"
import { handleURLPdfAccordingToFormType } from "./constants"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "app/store"
import { COUNTRIES_FOR_USA } from "consts/countryCodes"
import { REGIONS_BY_COUNTRY } from "modules/rivulet/components/SamplingRequest/components/RequestForm/components/SelectRegion/constants"
import { MSGS } from "components/select/SelectCity/constants"
import SelectCompanyType from "./components/SelectCompanyType"
import stylesMasialaInput from "./components/styles"
import { createDownloadLog, createAnonimalOrder } from "./mutation"
import "../../styles/samplingrequest.scss"

const COLOR_SCHEMA = Yup.object().shape({
  label: Yup.string().required(),
  value: Yup.string().required(),
  data: Yup.object().shape({
    catalogue: Yup.string(),
    pattern: Yup.string(),
    color: Yup.string(),
    reference: Yup.string()
  })
})

const RequestForm = ({ modalContext, handleCallbackToShowThankYouMessage, patternInfo }) => {
  const intl = useIntl()
  const [lang] = getCurrentLanguageAndInstanceCode()
  const { state, actions } = useStateMachine({ updateAction })
  const [toggle, toggable] = useToggle()
  const [checked, setChecked] = useState(false)
  const [createOrder, { error: errorCreateOrder }] = useMutation(createAnonimalOrder)
  const [logDownload] = useMutation(createDownloadLog)

  const initialValues = {
    colours: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companyType: "",
    country: "",
    region: "",
    address: "",
    zipCode: "",
    city: "",
    email: "",
    phone: "",
    type: modalContext.type,
    privacyPolicy: false
  }

  const validationSchema = Yup.object({
    colours:
      modalContext === REQUEST_SAMPLES_INFO
        ? Yup.array().of(COLOR_SCHEMA).required(intl.formatMessage(VALIDATION_MESSAGES.required))
        : Yup.string(),
    firstName: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    lastName: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    companyName: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    companyType: Yup.object().nullable().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    email: Yup.string().email().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    phone: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    country: Yup.object().nullable().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    region:
      modalContext === REQUEST_SAMPLES_INFO &&
      Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string()
        })
        .nullable()
        .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    address:
      modalContext === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
        : Yup.string(),
    zipCode:
      modalContext === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
        : Yup.string(),
    city:
      modalContext === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
        : Yup.string(),
    privacyPolicy: Yup.bool().oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    reset
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  })

  const countryValue = useWatch({ control, name: "country" })
  const regionValue = useWatch({ control, name: "region" })

  const handlePostSubmit = () => {
    actions.updateAction({
      ...state,
      sampleCount: []
    })
    setChecked(false)
    reset()
  }

  const onSubmit = async (values) => {
    const {
      firstName,
      lastName,
      email,
      privacyPolicy,
      type,
      companyName,
      companyType: { value: companyTypeId },
      country: { value: countryId },
      region: { value: regionId },
      city,
      colours,
      zipCode,
      ...rest
    } = values
    const processedColors = !colours.length ? [] : colours.map(({ value }) => value)
    const processedColorsOrder = processedColors.map((item) => {
      const cleanedItem = item.replace(/\n/g, " ")
      const splitItem = cleanedItem.split(" | ")
      if (splitItem.length === 2) {
        return { name: `${splitItem[0]}, MASIALA`, stock: splitItem[1], quantity: 1 }
      } else {
        return { name: item, stock: "", quantity: 1 }
      }
    })

    try {
      if (MODAL_TYPES_DOWNLOAD.includes(modalContext.type)) {
        const { pdfURL } = handleURLPdfAccordingToFormType({
          lang,
          formType: MODAL_TYPES[modalContext.type]
        })
        const downloadLogRequest = {
          firstName,
          lastName,
          email,
          companyType: companyTypeId,
          instance: getCurrentInstanceId(),
          patternId: patternInfo.id,
          resourceType: MODAL_TYPES_THANKS_URL[modalContext.type],
          resourceUrl:
            modalContext.type === MODAL_TYPES.PRODUCT_DETAIL
              ? patternInfo.productDetailsResourceUrl
              : pdfURL
        }

        const { data } = await logDownload({ variables: { input: downloadLogRequest } })

        if (data) handleCallbackToShowThankYouMessage()
      }

      if (modalContext === REQUEST_SAMPLES_INFO) {
        const inputForAnonimalOrder = {
          instance: getCurrentInstanceId(),
          source: "landing masiala",
          name: firstName,
          surname: lastName,
          email,
          company: companyName,
          companyType: companyTypeId,
          country: countryId,
          region: regionId,
          city: city.toLowerCase(),
          acceptTerms: privacyPolicy,
          postalCode: zipCode,
          anonimalSamples: processedColorsOrder,
          ...rest
        }
        const result = await createOrder({ variables: { input: inputForAnonimalOrder } })
        if (result?.data?.storeAnonimalOrder?.success) {
          handleCallbackToShowThankYouMessage()
        }
      }

      handlePostSubmit()
    } catch (e) {
      console.log("ERROR crete contact on CONTACTFORM: ", e)
      handlePostSubmit()
    }
  }

  return (
    <form
      id={modalContext.type}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="px-lg-5 pb-5 mt-2"
    >
      <Row>
        <Col md={12}>
          <p className="text-center px-5 mb-4">{modalContext.subtitle}</p>
        </Col>
      </Row>
      {modalContext === REQUEST_SAMPLES_INFO && (
        <>
          <Row>
            <Col md={12}>
              <div className="form-group">
                <ColorsSelect id="colours" name="colours" control={control} setValue={setValue} />
                <ErrorMessage weight="normal">{errors?.colours?.message}</ErrorMessage>
              </div>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelName",
                defaultMessage: "Name"
              })}
              id="firstName"
              name="firstName"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("firstName")}
            />
            <ErrorMessage weight="normal">{errors?.firstName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "Form.field.Lastname",
                defaultMessage: "Last Name"
              })}
              id="lastName"
              name="lastName"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("lastName")}
            />
            <ErrorMessage weight="normal">{errors?.lastName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelCompany",
                defaultMessage: "Company"
              })}
              id="companyName"
              name="companyName"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("companyName")}
            />
            <ErrorMessage weight="normal">{errors?.companyName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <SelectCompanyType
              name="companyType"
              control={control}
              customStyles={stylesMasialaInput}
            />
            <ErrorMessage weight="normal">{errors?.companyType?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <SelectCountry
              labelClassName={""}
              name="country"
              control={control}
              options={COUNTRIES_FOR_USA}
              isSearchable
            />
            <ErrorMessage weight="normal">{errors?.country?.message}</ErrorMessage>
          </div>
        </Col>
        {modalContext === REQUEST_SAMPLES_INFO && (
          <>
            <Col md={6}>
              <div className="form-group">
                <SelectRegion
                  labelClassName={""}
                  name="region"
                  control={control}
                  isSearchable={false}
                  defaultOptions={REGIONS_BY_COUNTRY[countryValue?.value]}
                />
                <ErrorMessage weight="normal">{errors?.region?.message}</ErrorMessage>
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <input
                  className="form-control rounded-0 mt-2 text-secondary"
                  placeholder={
                    !regionValue
                      ? intl.formatMessage(MSGS.withoutRegion)
                      : intl.formatMessage(MSGS.city)
                  }
                  id="city"
                  name="city"
                  type="text"
                  disabled={!regionValue}
                  {...register("city")}
                />
                <ErrorMessage weight="normal">{errors?.city?.message}</ErrorMessage>
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <input
                  placeholder={intl.formatMessage({
                    id: "SamplingRequest.labelPostal",
                    defaultMessage: "Postal Code"
                  })}
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  className="form-control rounded-0 mt-2"
                  {...register("zipCode")}
                />
                <ErrorMessage weight="normal">{errors?.zipCode?.message}</ErrorMessage>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <input
                  placeholder={intl.formatMessage({
                    id: "SamplingRequest.labelAddress",
                    defaultMessage: "Address"
                  })}
                  id="address"
                  name="address"
                  type="text"
                  className="form-control rounded-0 mt-2"
                  {...register("address")}
                />
                <ErrorMessage weight="normal">{errors?.address?.message}</ErrorMessage>
              </div>
            </Col>
          </>
        )}
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelEmail",
                defaultMessage: "Email"
              })}
              id="email"
              name="email"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("email")}
            />
            <ErrorMessage weight="normal">{errors?.email?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelPhone",
                defaultMessage: "Phone"
              })}
              id="phone"
              name="phone"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("phone")}
            />
            <ErrorMessage weight="normal">{errors?.phone?.message}</ErrorMessage>
          </div>
        </Col>
      </Row>

      <div className="form-group form-check my-4 footer-form-container text-center">
        <input
          type="checkbox"
          name="privacyPolicy"
          id="privacyPolicy"
          className="form-check-input rounded-0"
          checked={checked}
          onClick={toggable}
          {...register("privacyPolicy")}
        />
        <label htmlFor="privacyPolicy" className="form-check-label fw-light text-secondary">
          <FormattedMessage
            id="SamplingRequest.privacyPolicy"
            defaultMessage="I've read and agree the <strong>Privacy Policy</strong>"
            values={{
              strong: (...chunks) => <strong>{chunks}</strong>
            }}
          />
        </label>
        <ErrorMessage weight="normal">{errors?.privacyPolicy?.message}</ErrorMessage>
        <ModalPrivacyPolicy
          isOpen={toggle}
          toggle={toggable}
          acceptPrivacyPolicy={() => {
            setChecked(true)
            setValue("privacyPolicy", true)
          }}
        />

        <div className="text-center mt-3 py-lg-0">
          <Button
            id={modalContext.id}
            type="submit"
            disabled={isSubmitting}
            className="text-white px-5 button-form-masiala py-2"
          >
            {!isSubmitting ? (
              <p className="m-0 p-0 text-white">
                {modalContext === REQUEST_SAMPLES_INFO && (
                  <FormattedMessage
                    id="SamplingRequestMasiala.btnLabelRequest"
                    defaultMessage="Order Now"
                  />
                )}
                {MODAL_TYPES_DOWNLOAD.includes(modalContext.type) && (
                  <FormattedMessage
                    id="SamplingRequestMasiala.btnLabelRequestDownloadCatalogue"
                    defaultMessage="Download"
                  />
                )}
                {modalContext.type === CONTACT_INFO.type && (
                  <FormattedMessage
                    id="SamplingRequestMasiala.btnLabelRequestContactUs"
                    defaultMessage="Send"
                  />
                )}
              </p>
            ) : (
              <p className="m-0 p-0 text-white">
                <FormattedMessage
                  id="SamplingRequest.btnLabelLoading"
                  defaultMessage="Sending..."
                />
              </p>
            )}
          </Button>
        </div>
        {errorCreateOrder?.networkError?.statusCode === 400 && (
          <span className="form-error-message form-error-message--color-red">
            <small>{errorCreateOrder.networkError.result.errors[0].message}</small>
          </span>
        )}
      </div>
    </form>
  )
}

RequestForm.propTypes = {
  modalContext: PropTypes.object.isRequired,
  handleCallbackToShowThankYouMessage: PropTypes.func.isRequired,
  patternInfo: PropTypes.shape({
    id: PropTypes.string,
    productDetailsResourceUrl: PropTypes.string
  })
}

export default RequestForm
