import RequestsSection from "modules/kizuna/components/RequestsSection"
import {
  DIGITAL_CATALOGUE_INFO,
  CARE_AND_CLEANING_GUIDE_INFO
} from "modules/kizuna/components/RequestsSection/utils"
import ProductInformationDetail from "./components/ProductInformationDetail"
import SpecAccordion from "./components/SpecAccordion"
import { PRODUCT_SPECS, productSpecDetailsInformationToShow } from "./constants/specs"
import { useParams } from "react-router-dom"
import useRequestModal from "modules/kizuna/components/Footer/useRequestModal"

const ProductSpecs = () => {
  const { product: design } = useParams()
  const { modalContext, toggable, toggle, handleClickInRequest } = useRequestModal()

  return (
    <div>
      <SpecAccordion
        title={PRODUCT_SPECS.COMPOSITION}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow(design).COMPOSITION}
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.FEATURES_AND_BENEFITS}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow(design).FEATURES_AND_BENEFITS}
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.APPLICATIONS}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow(design).APPLICATIONS}
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.FINISHES_AND_TREATMENTS}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow(design).FINISHES_AND_TREATMENTS}
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.FLAME_RETARDANCY}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow(design).FLAME_RETARDANCY}
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.ADDITIONAL_TEST}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow(design).ADDITIONAL_TEST}
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.WARRANTY}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow(design).WARRANTY}
          />
        }
      />
      <div onClick={() => handleClickInRequest(DIGITAL_CATALOGUE_INFO)}>
        <SpecAccordion title={PRODUCT_SPECS.DIGITAL_CATALOGUE} downloadIcon />
      </div>
      <div onClick={() => handleClickInRequest(CARE_AND_CLEANING_GUIDE_INFO)}>
        <SpecAccordion title={PRODUCT_SPECS.CARE_AND_CLEANING} downloadIcon />
      </div>

      <RequestsSection toggable={toggable} toggle={toggle} modalContext={modalContext} />
    </div>
  )
}

export default ProductSpecs
