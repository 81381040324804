import Footer from "components/Footer"
import Header from "components/Header"
import Banner from "components/Banner"
import WhyPF from "modules/petfriendly/components/WhyPF-no-video"
import Video from "modules/petfriendly/components/Video"
import References from "modules/petfriendly/components/References"
import WhereToBuy from "modules/petfriendly/components/WhereToBuy"
import { GLOBAL_SITE_TAG, NAV_ITEMS, SLIDE } from "./constants"
import Analytics from "components/Analytics"
import { getCurrentInstanceId } from "services/instances"

const Petfriendly = () => (
  <>
    <Analytics gtag={GLOBAL_SITE_TAG[getCurrentInstanceId()]} />
    <Header items={NAV_ITEMS} isTransparent />
    <div className="content">
      <Banner slides={SLIDE} />
      <Video />
      <WhyPF id={NAV_ITEMS[0]} />
      <References id={NAV_ITEMS[1]} />
      <WhereToBuy />
    </div>
    <Footer />
  </>
)

export default Petfriendly
