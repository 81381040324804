import gql from "graphql-tag"

const allPatternsQuery = gql`
  query AllPatternsQuery($instanceIds: [ID]) {
    patterns(products_Instances: $instanceIds) {
      edges {
        node {
          id
          name
          altName
        }
      }
    }
  }
`

export default allPatternsQuery
