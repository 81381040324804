import { forwardRef } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import "./styles/privacy-policy-checkbox.scss"
import { getCurrentLanguageAndInstanceCode } from "services/instances"
import { PRIVACY_POLICY_LINKS } from "./constants"

const PrivacyPolicyCheckbox = forwardRef(({ onChange, name, value, color = "black" }, ref) => {
  const [lang] = getCurrentLanguageAndInstanceCode()

  return (
    <div className="privacy-policy-checkbox form-check">
      <input
        className="form-check-input"
        type="checkbox"
        ref={ref}
        onChange={onChange}
        name={name}
        value={value}
      />
      <label
        className={`form-check-label form-check-label--${color}`}
        htmlFor="privacy-policy-checkbox"
      >
        <div className="d-sm-flex">
          <p className={`m-0 me-1 text-${color}`}>
            <FormattedMessage
              id="Form.field.PrivacyPolicy.description"
              description="Form field privacy policy description"
              defaultMessage="I have read and agree to the {PrivacyPolicyLink}"
              values={{
                PrivacyPolicyLink: (
                  <a
                    className={`text-${color}`}
                    href={PRIVACY_POLICY_LINKS[lang]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage
                      id="Form.field.PrivacyPolicy"
                      description="Form field privacy policy"
                      defaultMessage="Privacy Policy"
                    />
                  </a>
                )
              }}
            />
          </p>
        </div>
      </label>
    </div>
  )
})

PrivacyPolicyCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.oneOf(["black", "white"]),
  children: PropTypes.element
}

PrivacyPolicyCheckbox.displayName = "PrivacyPolicyCheckbox"

export default PrivacyPolicyCheckbox
