import planetHollywood from "./imgs/1_planet-hollywood.jpg"

export const SLIDER_OPTIONS = (isMobile) => ({
  dots: true,
  infinite: true,
  autoplay: false,
  arrows: false,
  slidesToShow: !isMobile ? 2 : 1,
  slidesToScroll: !isMobile ? 2 : 1
})

export const SLIDES_PROJECTS = [
  {
    id: "hotel_estelar_altamira",
    project: "hotel estelar altamira",
    location: "ibagué colombia",
    patterns: "silvertex basi",
    img: planetHollywood
  },
  {
    id: "hotel_planet_hollywood",
    project: "hotel planet hollywood",
    location: "cancún  méxico",
    patterns: "silvetex y maglia",
    img: planetHollywood
  },
  {
    id: "hotel_royalton",
    project: "hotel royalton",
    location: "cancún méxico",
    patterns: "colección zander y hitch",
    img: null
  },
  {
    id: "hotel_villas_flamingo",
    project: "hotel villas flamingo",
    location: "holbox méxico",
    patterns: "silvertex",
    img: null
  },
  {
    id: "hotel_fiesta_americana",
    project: "hotel fiesta americana",
    patterns: "pranna",
    img: null
  },
  {
    id: "hotel_hilton",
    project: "hotel hilton",
    location: "santa marta",
    patterns: "(falta foto) y ref",
    img: null
  },
  {
    id: "hotel_crowe_plaza",
    project: "hotel crowe plaza",
    location: "santo domingo",
    patterns: "pranna",
    img: null
  }
]
