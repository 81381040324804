import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import Info from "components/Info"
import "modules/whisper/components/Permablok/assets/styles/permablok.scss"
import Video from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"
import poster from "./img/poster.jpg"

const Permacool = ({ id }) => {
  const isMobile = useMediaQuery(SM)
  return (
    <div id={id}>
      <Info
        text={
          <FormattedMessage
            id="Breeze.Permablok.info"
            description="Breeze section title info"
            defaultMessage="Breeze<sup>™</sup> was developed to beat the heat and offer new darker color options for outdoor and marine upholstery. Breeze does this using Permacool<sup>™</sup>, which improves the thermal comfort of your upholstery even on the sunniest of days. Now you can enjoy, relax and feel comfortable in the sun!"
            values={{
              sup: (...chunks) => <sup>{chunks}</sup>
            }}
          />
        }
      />
      <div className="container my-4 py-4">
        <div className="row">
          <div className="col-12 col-md-6">
            <Video className="img-fluid" poster={poster} fromYoutube>
              <iframe
                title="video"
                width="100%"
                height={isMobile ? "250" : "315"}
                src="https://www.youtube.com/embed/yF8qRl9WboE"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Video>
          </div>
          <div className="col-12 col-md-6 align-self-center text-md-end">
            <div className="permablok-description float-md-end">
              <img
                className="my-4 mt-md-0 w-75"
                src="https://d34s9g48jmovrj.cloudfront.net/patterns/img/logos/_permacool.svg"
                alt="permacool"
              />
              <p>
                <FormattedMessage
                  id="Breeze.Permablok.description"
                  description="Breeze section title description"
                  defaultMessage="Permacool<sup>™</sup> is a highly advanced reflective protection designed to reduce the amount of radiant heat absorbed by surfaces, improving the thermal sensation of upholstery during hot sunny days.{br}{br}Permacool technology reduces the temperature of upholstery by up to 26°F when compared to regular vinyl-coated fabrics. These results were obtained by measurements carried out in accordance with ASTM D 4803-10**."
                  values={{
                    br: <br />,
                    sup: (...chunks) => <sup>{chunks}</sup>
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Permacool.propTypes = {
  id: PropTypes.string.isRequired
}

export default Permacool
