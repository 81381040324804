import PropTypes from "prop-types"
import ProductInformationDetail from "./components/ProductInformationDetail"
import SpecAccordion from "./components/SpecAccordion"
import {
  PRODUCT_SPECS,
  productSpecDetailsInformationToShow
} from "./constants/specs"
import "./components/SustainableElements/styles/sustainable-elements.scss"

const ProductSpecs = ({
  nav,
  handleClickInRequest,
  toggableAccordeon,
  handleToggleAccordeon
}) => (
  <section id={nav[2].id}>
    <SpecAccordion
      id={nav[1].id}
      name="collapse1"
      togglable={toggableAccordeon.collapse1}
      handleOnClick={() => handleToggleAccordeon("collapse1")}
      acocordionContainerStyle="container-sustainable-product-profile--masiala"
      title={PRODUCT_SPECS.SUSTAINABLE_PRODUCT_PROFILE}
      contentToShow={
        <ProductInformationDetail
          details={
            productSpecDetailsInformationToShow().SUSTAINABLE_PRODUCT_PROFILE}
          isSustainableElements
        />
      }
    />
    <SpecAccordion
      name="collapse2"
      togglable={toggableAccordeon.collapse2}
      handleOnClick={() => handleToggleAccordeon("collapse2")}
      title={PRODUCT_SPECS.CHARACTERISTICS}
      contentToShow={
        <ProductInformationDetail
          details={productSpecDetailsInformationToShow().CHARACTERISTICS}
        />
      }
    />
    <SpecAccordion
      name="collapse3"
      togglable={toggableAccordeon.collapse3}
      handleOnClick={() => handleToggleAccordeon("collapse3")}
      title={PRODUCT_SPECS.APPLICATIONS}
      contentToShow={
        <ProductInformationDetail
          details={productSpecDetailsInformationToShow().APPLICATIONS}
        />
      }
    />
    <SpecAccordion
      name="collapse4"
      togglable={toggableAccordeon.collapse4}
      handleOnClick={() => handleToggleAccordeon("collapse4")}
      title={PRODUCT_SPECS.PERFORMANCE}
      contentToShow={
        <ProductInformationDetail
          details={
            productSpecDetailsInformationToShow().PERFORMANCE
          }
          isPerformanceInfo
        />
      }
    />
    <SpecAccordion
      name="collapse5"
      togglable={toggableAccordeon.collapse5}
      handleOnClick={() => handleToggleAccordeon("collapse5")}
      title={PRODUCT_SPECS.FLAME_RETARDANCY}
      contentToShow={
        <ProductInformationDetail
          disclaimerContainerStyle="mt-1"
          details={productSpecDetailsInformationToShow().FLAME_RETARDANCY}
        />
      }
    />
  </section>
)

ProductSpecs.propTypes = {
  nav: PropTypes.arrayOf(PropTypes.object),
  handleClickInRequest: PropTypes.func,
  toggableAccordeon: PropTypes.bool,
  handleToggleAccordeon: PropTypes.func
}

export default ProductSpecs
