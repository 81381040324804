import Analytics from "./components/Analytics"
import Header from "./components/Header"
import Banner from "./components/Banner"
import SamplesDetails from "./components/SamplesDetails"
import SectionVideo from "./components/SectionVideo"
import { GTAG_BY_ENV, NAV_0, NAV_1, NAV_2, NAV_3, MASIALA_HOTJAR_CODE } from "./constants"
import SamplingRequest from "./components/SamplingRequest"
import useRequestModal from "./useRequestModal"
import useAccordion from "./useAccordion"

const Masiala = () => {
  const {
    modalContext,
    toggable,
    toggle,
    handleClickInRequest,
    handleOrderCatalogue,
    handleAddSample,
    handleShowSustainabilityModal
  } = useRequestModal()

  const {
    toggable: toggableAccordeon,
    handleToggle: handleToggleAccordeon,
    handleCloseAllAccordion,
    handleToggleSustainabilityAccordeon
  } = useAccordion()

  const NAV_OBJ_ITEMS = [
    {
      id: NAV_0,
      text: NAV_0,
      href: NAV_0
    },
    {
      id: NAV_1,
      text: NAV_1,
      href: NAV_1,
      callback: () => {
        handleToggleSustainabilityAccordeon("collapse1")
        handleShowSustainabilityModal()
      }
    },
    {
      id: NAV_2,
      text: NAV_2,
      href: NAV_2,
      callback: () => handleCloseAllAccordion()
    },
    {
      id: NAV_3,
      text: NAV_3,
      href: NAV_3
    }
  ]

  return (
    <>
      <Analytics
        gtag={GTAG_BY_ENV[process.env.REACT_APP_ENV]}
        hotjarCode={MASIALA_HOTJAR_CODE}
      />
      <Header isTransparent objItems={NAV_OBJ_ITEMS} />
      <Banner handleOrderCatalogue={handleOrderCatalogue} />
      <SamplesDetails
        nav={NAV_OBJ_ITEMS}
        handleClickInRequest={handleClickInRequest}
        handleAddSample={handleAddSample}
        handleOrderCatalogue={handleOrderCatalogue}
        toggableAccordeon={toggableAccordeon}
        handleToggleAccordeon={handleToggleAccordeon}
      />
      <SectionVideo id={NAV_3} />
      <SamplingRequest modalContext={modalContext} isOpen={toggable} toggle={toggle} />
    </>
  )
}

export default Masiala
