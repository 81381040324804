import PropTypes from "prop-types"
import "./styles/HTMLContent.scss"

const HTMLContent = ({ html }) => (
  <div className="html-content">
    <div className="html-content__content" dangerouslySetInnerHTML={{ __html: html }} />
  </div>
)

HTMLContent.propTypes = {
  html: PropTypes.string.isRequired
}

export default HTMLContent
