import { useEffect, useState } from "react"
import useToggle from "hooks/useToggle"

const useRequestModal = () => {
  const [toggable, toggle] = useToggle()
  const [modalContext, setModalContext] = useState({})

  const handleClickInRequest = (modalType) => {
    setModalContext(modalType)
    toggle()
  }

  useEffect(() => {}, [modalContext])

  return {
    modalContext,
    toggable,
    toggle,
    handleClickInRequest
  }
}
export default useRequestModal
