import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import useToggle from "hooks/useToggle"
import Modal from "components/Modal"
import facilLimpieza from "./components/EasyCleaning/img/facil-limpieza.jpg"
import "./styles/benefits.scss"

const Benefits = () => {
  const [showCleaning, toggleCleaning] = useToggle()

  return (
    <>
      <div className="container my-2 py-4">
        <div className="row align-items-center justify-content-center">
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="Pranna.Benefits.title"
              description="Pranna section benefits"
              defaultMessage="Beneficios"
            />
          </TitleUnderlined>
          <div className="col-12 col-md-6">
            <div className="img-container" onClick={() => toggleCleaning()}>
              <img className="img-size" src={facilLimpieza} alt="fácil limpieza" />
              <TitleUnderlined className="benefit-label" hTag={H2_TAG} color="white">
                <FormattedMessage
                  id="Pranna.EasyCleaning.title"
                  description="Pranna section title easy cleaning"
                  defaultMessage="Fácil Limpieza"
                />
              </TitleUnderlined>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showCleaning} handleToggable={toggleCleaning}>
        <div>
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="Pranna.EasyCleaning.title"
              description="Pranna section title easy cleaning"
              defaultMessage="Fácil Limpieza"
            />
          </TitleUnderlined>
          <p className="pb-md-4">
            <FormattedMessage
              id="Linetex.EasyCleaning.description"
              description="Linetex section description easy cleaning"
              defaultMessage="La seguridad e higiene es una prioridad en nuestra referencia Linetex<sup>®</sup>, por su impermeabilidad solo debes realizar estos pasos para que tus muebles o sillas tengan una apariencia única:{br}{br}Utiliza un trapo de color blanco para limpiar toda la superficie con una mezcla de jabón de manos suave pH neutro y agua."
              values={{
                br: <br />,
                sup: (...chunks) => <sup>{chunks}</sup>
              }}
            />
          </p>
          <div className="text-center">
            <a
              className="btn btn-dark"
              href="https://spradling-misc.s3.us-west-2.amazonaws.com/landing-attachments/Pranna-fa%CC%81cil-limpieza.pdf"
              rel="noreferrer"
              target="_blank"
            >
              <FormattedMessage
                id="Pranna.EasyCleaning.btnText"
                description="Pranna text btn section easy cleaning"
                defaultMessage="Ver guía de limpieza"
              />
            </a>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Benefits
