// TODO: remove COLORS and unused functions and files
const COLORS = []

export const handleProcessedColors = () => {
  return COLORS.map(({ node }) => node)
}

export const handleAddedSamplesByTheUser = (samples) =>
  samples?.map(({ id, pattern, color, reference, catalogue, img }) => {
    if (catalogue) {
      return { label: catalogue, value: id, parsedValue: catalogue, data: { id, catalogue } }
    }

    const sampleInfo = `${pattern} ${color}|${reference}`.toUpperCase()
    return {
      label: sampleInfo,
      value: id,
      parsedValue: sampleInfo,
      data: { id, pattern, color, img, reference } // WARNING: used for build email template
    }
  })
