import { useStateMachine } from "little-state-machine"
import { updateAction } from "app/store"
import _ from "lodash"

const useSampleCard = ({ design, handleShowInfoBox }) => {
  const { state, actions } = useStateMachine({ updateAction })

  const handleAddSample = (currentSample, { hasMulti = false } = {}) => {
    if (!state?.sampleCount || state?.sampleCount.length <= 9) {
      let updateSamplesCount
      if (!hasMulti) {
        const proccessedCurrentSamples = { ...currentSample, id: _.random(1, 4, true) }
        const newSample = { pattern: design, ...proccessedCurrentSamples }
        updateSamplesCount = state?.sampleCount ? [...state?.sampleCount, newSample] : [newSample]
      }

      if (hasMulti) {
        const newSamples = currentSample.map((sample) => ({
          pattern: design,
          ...sample,
          id: _.random(1, 4, true)
        }))

        updateSamplesCount = state?.sampleCount
          ? [...state?.sampleCount, ...newSamples]
          : [...newSamples]
      }

      actions.updateAction({
        sampleCount: updateSamplesCount
      })
    }

    handleShowInfoBox()
  }

  return { handleAddSample }
}

export default useSampleCard
