import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H3_TAG } from "components/TitleUnderlined/constants"
import { ITEMS } from "./constants"
import dog from "modules/pranna/components/Benefits/components/PetFriendly/img/pet-friendly.png"
import "./styles/whyPF.scss"

const WhyPF = ({ id }) => (
  <div id={id}>
    <div className="container my-2 py-4">
      <div className="row">
        <div className="col-12 col-md-6 order-lg-1 align-self-center">
          <TitleUnderlined hTag={H3_TAG} className="font-gill-sans">
            <FormattedMessage
              id="Petfriendly.WhyPF.title"
              description="Petfriendly section title WhyPF"
              defaultMessage="¿Por qué son Pet friendly nuestras tapicerías técnicas?"
            />
          </TitleUnderlined>
          <p className="mb-4">
            <FormattedMessage
              id="Petfriendly.WhyPF.description"
              description="Petfriendly section title info"
              defaultMessage="Gracias a nuestros beneficios ya no tendrás que preocuparte porque tus mascotas usen tu mueble favorito o la silla de tu vehículo."
            />
          </p>
          <ul className="mb-4">
            {ITEMS.map(({ id, title }) => (
              <li key={id}>{title}</li>
            ))}
          </ul>
        </div>
        <div className="col-12 col-md-6 order-lg-0 align-self-center">
          <img className="w-100" src={dog} />
        </div>
      </div>
    </div>
  </div>
)

WhyPF.propTypes = {
  id: PropTypes.string.isRequired
}

export default WhyPF
