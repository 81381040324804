import PropTypes from "prop-types"
import { useStateMachine } from "little-state-machine"
import "./styles/SamplesCount.scss"
import { FormattedMessage } from "react-intl"

const SamplesCount = ({ toggle }) => {
  const { state } = useStateMachine()

  return (
    <div className="sample-count-container-masiala" onClick={() => toggle()}>
      <p className="sample-count-text px-4">
        <FormattedMessage
          id="Valencia.SamplesCount.finishYourOrder"
          description="This is the samples count for your order"
          defaultMessage="FINISH YOUR ORDER HERE"
        />
         {" "} ({state?.sampleCount?.length ?? 0})
      </p>
    </div>
  )
}

SamplesCount.propTypes = {
  showBox: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

export default SamplesCount
