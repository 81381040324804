import PropTypes from "prop-types"
import { useStateMachine } from "little-state-machine"
import { FormattedMessage } from "react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { GlassMagnifier } from "react-image-magnifiers"
import { REQUEST_SAMPLES_INFO } from "../../constants"
import ProductSpecs from "./components/ProductSpecs"
import SamplesList from "./components/SamplesList"
import { SAMPLES_TO_SHOW_IN_DESKTOP } from "./constants/samples"
import "./styles/sample-details.scss"
import SamplesCount from "./components/SamplesCount"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"

const SamplesDetails = ({
  nav,
  handleClickInRequest,
  handleAddSample,
  handleOrderCatalogue,
  toggableAccordeon,
  handleToggleAccordeon
}) => {
  const isMobile = useMediaQuery(LG)
  const { state } = useStateMachine()
  const firstSample = SAMPLES_TO_SHOW_IN_DESKTOP[12]

  return (
    <section id={nav[0].id} className="wrapper-samples-details--masiala">
      <h2 className="title-samples-details--masiala text-center text-lg-start">
        <FormattedMessage id="Masiala.ProductDetails.title" defaultMessage="High performance" />
      </h2>
      <div className="row g-4">
        <div className="col-12 col-lg-5 d-xl-flex justify-content-xl-end">
          <div className="container-master-sample">
            <div className="position-relative">
              <div className="container-search-icon p-4">
                <FontAwesomeIcon
                  className="p-2 rounded-circle bg-light"
                  style={{ width: 20, height: 20 }}
                  icon={faSearch}
                  color="gray"
                  fixedWidth
                />
              </div>
              <GlassMagnifier
                className="large-img--masiala"
                imageSrc={
                  state.sampleDetails ? state?.sampleDetails?.imgDetail : firstSample.imgDetail
                }
                imageAlt="sample-master-image"
                magnifierBorderColor="white"
                magnifierBackgroundColor="white"
                magnifierBorderSize={2}
                magnifierOffsetX={0}
                magnifierOffsetY={0}
                magnifierSize={150}
              />
              <p className="sample-detail-selected--masiala m-0">
                {state.sampleDetails !== undefined && `${state?.sampleDetails?.color} | ${state?.sampleDetails?.reference}`}

                {state.sampleDetails === undefined && `${firstSample.color} | ${firstSample.reference}`}
              </p>
            </div>
            <div className="d-block text-center">
              <button
                type="button"
                className="btn btn-request-add-sample-masiala col-9"
                onClick={() =>
                  handleAddSample(!state.sampleDetails ? firstSample : state.sampleDetails)
                }
              >
                <FormattedMessage id="Masiala.Request.sample" defaultMessage="ADD MEMO SAMPLE" />
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 d-xl-flex justify-content-xl-end m-0 mt-lg-4">
          <SamplesList />
        </div>
        {isMobile && <div className="text-center mt-0 mt-md-3 mb-3">
          <button
            className="btn btn-request-catalogue-masiala"
            onClick={() => handleOrderCatalogue(REQUEST_SAMPLES_INFO)}
          >
            <FormattedMessage
              id="Masiala.Request.catalogue"
              defaultMessage="REQUEST CATALOGUE HERE!"
            />
          </button>
        </div>}
      </div>
      <div className="my-2 my-lg-5">
        {!state?.sampleCount?.length ? (
          <></>
        ) : (
          <div className="position-relative">
            <SamplesCount toggle={() => handleClickInRequest(REQUEST_SAMPLES_INFO)} />
          </div>
        )}
        <ProductSpecs
          nav={nav}
          handleClickInRequest={handleClickInRequest}
          toggableAccordeon={toggableAccordeon}
          handleToggleAccordeon={handleToggleAccordeon}
        />
        {!isMobile && <div className="text-center mt-2">
          <button
            className="btn btn-request-catalogue-masiala"
            onClick={() => handleOrderCatalogue(REQUEST_SAMPLES_INFO)}
          >
            <FormattedMessage
              id="Masiala.Request.catalogue"
              defaultMessage="REQUEST CATALOGUE HERE!"
            />
          </button>
        </div>}
      </div>
    </section>
  )
}

SamplesDetails.propTypes = {
  nav: PropTypes.arrayOf([PropTypes.object]),
  handleClickInRequest: PropTypes.func,
  handleAddSample: PropTypes.func,
  handleOrderCatalogue: PropTypes.func,
  toggableAccordeon: PropTypes.object,
  handleToggleAccordeon: PropTypes.func
}

export default SamplesDetails
