import { useRef } from "react"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import ErrorMessage from "components/ErrorMessage"
import TooltipElement from "modules/claims/components/TooltipElement"
import "components/InputMUI/styles/own-mui.scss"
import useAutosizeTextArea from "./useAutosizeTextArea"
import { Controller, useWatch } from "react-hook-form"

const InputTextArea = ({ control, name, label, placeholder, defaultValue, tooltipElem, error }) => {
  const intl = useIntl()
  const watchedValue = useWatch({ control, name })
  const textAreaRef = useRef(null)
  useAutosizeTextArea(textAreaRef?.current, watchedValue)

  return (
    <div className="step-form--group">
      <ErrorMessage weight="normal">{error}</ErrorMessage>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <textarea
            ref={textAreaRef}
            rows={1}
            className="step-form--field"
            placeholder={intl.formatMessage(placeholder)}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      <label className="step-form--label" htmlFor={name}>
        {intl.formatMessage(label)}
        {tooltipElem && <TooltipElement renderElement={tooltipElem} />}
      </label>
    </div>
  )
}

InputTextArea.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  placeholder: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  tooltipElem: PropTypes.func,
  error: PropTypes.string
}

export default InputTextArea
