/* eslint-disable */
import PropTypes from "prop-types";
import useMediaQuery from "hooks/useMediaQuery";
import SamplesListDesktop from "./components/SamplesListDesktop";
import SamplesListMobile from "./components/SamplesListMobile";
import "./style/SamplesList.scss"
import { CustomSpinner } from "../RequestFormQuickShipPage/components/CustomSpinner/CustomSpinner";

const SamplesList = ({
  samplesDestkop = [],
  samplesMobile = [],
  isLoading = false
}) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const handleClick = (event, sample) => {
    console.log(`🚀🚀🚀 ~ handleClick ~ sample:`, sample)
    event.preventDefault();
  };

  return (
    <>
    
    {
      isLoading ? 
      <CustomSpinner />
      :
      <div className="d-lg-flex p-0 flex-wrap col-12" style={{ height: "100%" }}>
        {
          samplesDestkop?.length > 0 && isDesktop ?
          <SamplesListDesktop
            samples={samplesDestkop}
            handleClick={handleClick}
          />
          : samplesDestkop?.length > 0 ?
          <SamplesListMobile samples={samplesMobile} handleClick={handleClick} />
          :
          <div className="samplesList-wtihout-result-text">No results found. Adjust the filters and try again</div>
        }
      </div>
    }
    </>
  );
};

SamplesList.propTypes = {
  samplesDestkop: PropTypes.array,
  samplesMobile: PropTypes.array,
  isLoading: PropTypes.bool
};

export default SamplesList;
