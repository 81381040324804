import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import ErrorMessage from "components/ErrorMessage"
import "components/InputMUI/styles/own-mui.scss"

const RadioButton = ({ register, name, label, options, description, tooltipElem, error }) => {
  const intl = useIntl()

  return (
    <div>
      <div className="step-form--group">
        <ErrorMessage weight="normal">{error}</ErrorMessage>
        <div className="d-flex">
          {options.map(({ id, label, value }) => (
            <div key={`key_${id}`}>
              <input
                {...register(name)}
                id={`${name}_${id}`}
                className="btn-check"
                type="radio"
                autoComplete="off"
                value={value}
              />
              <label className="btn btn-outline-claims--radio" htmlFor={`${name}_${id}`}>
                {intl.formatMessage(label)}
              </label>
            </div>
          ))}
        </div>
        <label className="step-form--label" htmlFor={name}>
          {intl.formatMessage(label)}
        </label>
      </div>
    </div>
  )
}

RadioButton.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  options: PropTypes.array.isRequired,
  description: PropTypes.string,
  tooltipElem: PropTypes.func,
  error: PropTypes.string
}

export default RadioButton
