import { FormattedMessage } from "react-intl"
import ContactForm from "components/ContactForm"
import { COUNTRY_COLOMBIA } from "consts/countryCodes"
import bannerPandora from "../img/bannerPandora.jpg"

export const SLIDE = [
  {
    id: "slide-pandora",
    imageThumb: bannerPandora,
    title: (
      <FormattedMessage
        id="Pandora.Banner.title"
        description="Pandora mx title banner"
        defaultMessage="Pandora"
      />
    ),
    description: (
      <FormattedMessage
        id="Pandora.Banner.description"
        description="Pandora mx description banner"
        defaultMessage="Capellada apta para la elaboración y fabricación de zapatos y bolsos."
      />
    ),
    form: (
      <ContactForm
        theme="light"
        styleContainer="container-fluid upholstery-glassmorphism my-3"
        styleFormContainer="p-4"
        title={
          <FormattedMessage
            id="Pranna.Contact.title"
            description="Floors section title contact"
            defaultMessage="¿ Quieres más información ?"
          />
        }
        countryOptions={COUNTRY_COLOMBIA}
        landingSource="pandora"
        isBasic
      />
    )
  }
]
