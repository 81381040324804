import PropTypes from "prop-types"
import Modal from "components/Modal"

const TooltipModal = ({
  isOpen = false,
  handleToggable,
  title = "",
  description = "",
  img,
  children
}) => {
  return (
    <Modal isOpen={isOpen} handleToggable={handleToggable} size="xl">
      <div className="text-center">
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
        {!children ? <img className="img-fluid" src={img} alt="tooltip" /> : children}
      </div>
    </Modal>
  )
}

TooltipModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleToggable: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

export default TooltipModal
