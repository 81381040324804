import { useNavigate } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import ErrorMessage from "components/ErrorMessage"
import { VALIDATION_MESSAGES } from "utils/forms"
import { REQUEST_ENDPOINT_ZAPPIER_IBEX } from "./constants"
import { BUTTON_MESSAGES } from "utils/buttons"
import PrivacyPolicyCheckbox from "components/PrivacyPolicyCheckbox"

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  message: "",
  privacyPolicy: false
}

const ContactForm = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const schema = yup.object({
    firstName: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    lastName: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    email: yup.string().email().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    company: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    message: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    privacyPolicy: yup.boolean().oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = ({ privacyPolicy, ...values }) => {
    const data = JSON.stringify({
      ...values,
      date: new Date().toLocaleString()
    })

    const promise = axios.post(REQUEST_ENDPOINT_ZAPPIER_IBEX, data)
    promise.then(() => {
      navigate("/en-us/ibex/thank-you")
    })
    promise.catch((error) => {
      console.log(error)
    })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12 col-md-6 form-group">
                <label htmlFor="firstName">
                  <FormattedMessage
                    id="Form.field.Name"
                    description="Form field name"
                    defaultMessage="Name"
                  />
                </label>
                <input className="form-control" id="firstName" {...register("firstName")} />
                <ErrorMessage>{errors.firstName?.message}</ErrorMessage>
              </div>
              <div className="col-12 col-md-6 form-group">
                <label htmlFor="lastName">
                  <FormattedMessage
                    id="Form.field.Lastname"
                    description="Form field Lastname"
                    defaultMessage="Lastname"
                  />
                </label>
                <input className="form-control" id="lastName" {...register("lastName")} />
                <ErrorMessage>{errors.lastName?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label htmlFor="email">
                  <FormattedMessage
                    id="Form.field.Email"
                    description="Form field email"
                    defaultMessage="Email"
                  />
                </label>
                <input className="form-control" id="email" {...register("email")} />
                <ErrorMessage>{errors.email?.message}</ErrorMessage>
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="company">
                  <FormattedMessage
                    id="Form.field.Company"
                    description="Form field company"
                    defaultMessage="Company"
                  />
                </label>
                <input className="form-control" id="company" {...register("company")} />
                <ErrorMessage>{errors.company?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-12 form-group">
                <label htmlFor="message">
                  <FormattedMessage
                    id="Form.field.Message.Ibex"
                    description="Form field message for ibex Let us know what times you have available"
                    defaultMessage="Let us know what times you have available"
                  />
                </label>
                <textarea className="form-control" id="message" {...register("message")} />
                <ErrorMessage>{errors.message?.message}</ErrorMessage>
              </div>
              <div className="col-12 form-group text-center">
                <Controller
                  name="privacyPolicy"
                  control={control}
                  render={({ field }) => <PrivacyPolicyCheckbox {...field} />}
                />
                <ErrorMessage>{errors.privacyPolicy?.message}</ErrorMessage>
                <button className="btn btn-dark" type="submit">
                  {intl.formatMessage(
                    !isSubmitting ? BUTTON_MESSAGES.send : BUTTON_MESSAGES.sending
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
