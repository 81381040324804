import { useStateMachine } from "little-state-machine"
import { useIntl } from "react-intl"
import { updateAction } from "utils/littleStateMachine"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { VALIDATION_MESSAGES } from "utils/forms"
import InputTextArea from "components/InputMUI/TextArea"
import { BASIC_BOOLEAN_OPTIONS, GENERIC_MSGS } from "modules/claims/constants"
import { BUTTON_MESSAGES } from "utils/buttons"
import ProgressBar from "modules/claims/components/StepForm/ProgressBar"
import Select from "components/InputMUI/Select"
import { PROTECT_PRODUCT_CUSHIONS_OPTIONS } from "modules/claims/components/StepForm/Step4/components/ClaimsReasons/ColorFading/contants"
import { STAIN_MSGS } from "./constants"
import RadioButton from "components/InputMUI/RadioButton"
import { useNavigate } from "react-router-dom"
import { redirectToNextStep } from "../../../utils"

const Stain = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { actions, state } = useStateMachine({ updateAction })

  const defaultValues = { ...state }
  const schema = yup.object({
    typeOfStain: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    knowTheInstructions: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    whichProductUse: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    protectTheProduct: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = (data) => {
    actions.updateAction(data)
    redirectToNextStep(navigate)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputTextArea
        name="typeOfStain"
        control={control}
        label={STAIN_MSGS.typeOfStain}
        placeholder={GENERIC_MSGS.typeAnswer_placeholder}
        defaultValue={state.typeOfStain}
        error={errors.typeOfStain?.message}
      />
      <RadioButton
        name="knowTheInstructions"
        register={register}
        label={STAIN_MSGS.knowTheInstructions}
        options={BASIC_BOOLEAN_OPTIONS}
        defaultValue={state.knowTheInstructions}
        error={errors.knowTheInstructions?.message}
      />
      <InputTextArea
        name="whichProductUse"
        control={control}
        label={STAIN_MSGS.whichProductUse}
        placeholder={GENERIC_MSGS.typeAnswer_placeholder}
        defaultValue={state.whichProductUse}
        error={errors.whichProductUse?.message}
      />
      <Select
        name="protectTheProduct"
        control={control}
        label={STAIN_MSGS.protectProductCushions}
        placeholder={GENERIC_MSGS.choose_placeholder}
        options={PROTECT_PRODUCT_CUSHIONS_OPTIONS}
        defaultValue={state.protectTheProduct}
        error={errors.protectTheProduct?.message}
      />
      <div className="btn-container">
        <button className="btn btn-dark" type="submit">
          {intl.formatMessage(BUTTON_MESSAGES.ok)}
        </button>
      </div>
      <ProgressBar
        progress={60}
        handleNext={handleSubmit(onSubmit)}
        RoutePrev={state.previousStep}
      />
    </form>
  )
}

export default Stain
