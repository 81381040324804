import checkerImg from "./img/checker.jpg"
import artisanImg from "./img/artisan.jpg"
import tweedImg from "./img/tweed.jpg"
import checkerBanner from "./img/banner/banner-checker.jpg"
import artisanBanner from "./img/banner/banner-artisan.jpg"
import tweedBanner from "./img/banner/banner-tweed.jpg"

export const KIZUNA_REFERENCES = [
  {
    id: "checker",
    name: "Checker",
    img: checkerImg,
    banner: checkerBanner,
    bannerDescription:
      "Immerse in nature-inspired rustic sensations through three colored fibers, interweaving linear perfection that defies norms, simplicity, and minimalistic excellence. Embrace the versatility of this modular chess design that adapts perfectly to various requirements and upholstery configurations."
  },
  {
    id: "artisan",
    name: "Artisan",
    img: artisanImg,
    banner: artisanBanner,
    bannerDescription:
      "Drawing on the heritage of global artisans this design channels the very soul of age-old techniques, cherished across generations by highly skilled craftsmen. It pays homage to tradition; whilst also embracing modernity. The color palette effortlessly blends vibrant and earthy tones, reminiscent of the natural dyes and pigments widely used by in traditional craftsmanship."
  },
  {
    id: "tweed",
    name: "Tweed",
    img: tweedImg,
    banner: tweedBanner,
    bannerDescription:
      "An intricately designed pattern that unveils an array of colors and contrasts, encapsulating a spirit of diversity and uniqueness. Inspired by a twill woven structure, it forms a robust and resilient fabric. This creation pays homage to the fabric's inbuilt heritage, whilst encapsulating its enduring grace and timeless allure."
  }
]
