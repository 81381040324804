import PropTypes from "prop-types"
import "./styles/product-information-detail.scss"
import classNames from "classnames/bind"

const ProductInformationDetail = ({
  disclaimerContainerStyle = "info",
  details,
  isSustainableElements = false
}) => {
  return <div
    className={classNames({
      "product--information-detail--main-container": !isSustainableElements
    })}
    >
    <table className="col-12 color-letter-rv">
      {details.info.map(({ id, description, value }) => (
        <tr key={`key_${id}`} className="info">
          {description && <td className="col-6 col-md-4 col-lg-2 text-capitalize product--information-detail-description">{description}</td>}
          <td
            className={classNames({
              "product--information-detail-value": true,
              "col-6 col-md-8 ps-4": description && value,
              "col-12": !description
            })}
          >
            <span className="w-100">
              {value}
            </span>
          </td>
        </tr>
      ))}
    </table>
    {details?.disclaimer && details.disclaimer.length > 0 && (
      <div className={disclaimerContainerStyle}>
        {details.disclaimer.map(({ id, value }) => (
          <p key={`key_${id}`} className="disclaimer">
            {value}
          </p>
        ))}
      </div>
    )}
  </div>
}

ProductInformationDetail.propTypes = {
  details: PropTypes.array.isRequired,
  disclaimerContainerStyle: PropTypes.string,
  isSustainableElements: PropTypes.bool
}

export default ProductInformationDetail
