import { defineMessages } from "react-intl"

export const VALIDATION_MESSAGES = defineMessages({
  required: {
    id: "Form.field.required",
    description: "Form required field message",
    defaultMessage: "This field is required"
  },
  batch: {
    id: "Form.field.batch",
    description: "Form batch validation message",
    defaultMessage:
      "Please indicate correct batch number. starting with PT or CR and followed by 6 numeric digits"
  },
  fileSize: {
    id: "Form.field.fileSize",
    description: "Form file size error message",
    defaultMessage: "The file is too large, 200MB max file size"
  },
  fileType: {
    id: "Form.field.fileType",
    description: "Form file type error message",
    defaultMessage: "We only support .zip"
  }
})
