import PropTypes from "prop-types"
import "./styles/info.scss"

const Info = ({ text }) => (
  <div className="info-container">
    <p className="info">{text}</p>
  </div>
)

Info.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

export default Info
