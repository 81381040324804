import { FormattedMessage } from "react-intl"

export const MODAL_TYPES = {
  REQUEST_SAMPLES: "REQUEST_SAMPLES",
  REQUEST_CATALOGUE: "REQUEST_CATALOGUE",
  DIGITAL_CATALOGUE: "DIGITAL_CATALOGUE",
  CARE_AND_CLEANING_GUIDE: "CARE_AND_CLEANING_GUIDE",
  CONTACT: "CONTACT"
}

export const MODAL_TYPES_THANKS_URL = {
  REQUEST_SAMPLES: "request-samples",
  REQUEST_CATALOGUE: "request-catalogue",
  DIGITAL_CATALOGUE: "digital-catalogue",
  CARE_AND_CLEANING_GUIDE: "care-cleaning",
  CONTACT: "contact"
}

export const MODAL_TYPE_REQUEST = [MODAL_TYPES.REQUEST_SAMPLES, MODAL_TYPES.REQUEST_CATALOGUE]

export const MODAL_TYPES_DOWNLOAD = [
  MODAL_TYPES.DIGITAL_CATALOGUE,
  MODAL_TYPES.CARE_AND_CLEANING_GUIDE
]

export const REQUEST_SAMPLES_INFO = {
  id: MODAL_TYPES.REQUEST_SAMPLES,
  type: MODAL_TYPES.REQUEST_SAMPLES,
  thanksUrl: MODAL_TYPES_THANKS_URL.REQUEST_SAMPLES,
  title: <FormattedMessage id="ModalContext.requestTitle" defaultMessage="Request Samples" />,
  subtitle: (
    <FormattedMessage
      id="ModalContext.kizuna.requestSubtitle"
      defaultMessage="Fill out the form below. and request your samples!"
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>
      }}
    />
  ),
  info: (
    <FormattedMessage
      id="ModalContext.kizuna.requestInfo"
      defaultMessage="All fields with (*) are required."
      values={{
        strong: (...chunks) => <strong>{chunks}</strong>
      }}
    />
  )
}

export const REQUEST_CATALOGUE_INFO = {
  id: MODAL_TYPES.REQUEST_CATALOGUE,
  type: MODAL_TYPES.REQUEST_CATALOGUE,
  thanksUrl: MODAL_TYPES_THANKS_URL.REQUEST_CATALOGUE,
  title: (
    <FormattedMessage id="ModalContext.downloadTitle" defaultMessage="Kizuna Catalogue Request" />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.downloadSubtitle"
      defaultMessage="Fill out the form below, and request your Kizuna Catalogue!"
    />
  )
}

export const DIGITAL_CATALOGUE_INFO = {
  id: MODAL_TYPES.DIGITAL_CATALOGUE,
  type: MODAL_TYPES.DIGITAL_CATALOGUE,
  thanksUrl: MODAL_TYPES_THANKS_URL.DIGITAL_CATALOGUE,
  title: (
    <FormattedMessage
      id="ModalContext.downloadDigitalCatalogue"
      defaultMessage="Download Digital Catalogue"
    />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.downloadDigitalSubtitle"
      defaultMessage="Fill out the form below, and download the digital catalogue!"
    />
  )
}

export const CARE_AND_CLEANING_GUIDE_INFO = {
  id: MODAL_TYPES.CARE_AND_CLEANING_GUIDE,
  type: MODAL_TYPES.CARE_AND_CLEANING_GUIDE,
  thanksUrl: MODAL_TYPES_THANKS_URL.CARE_AND_CLEANING_GUIDE,
  title: (
    <FormattedMessage
      id="ModalContext.downloadTitleCare&Cleaning"
      defaultMessage="Download Care & Cleaning Guide"
    />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.downloadSubtitleCare&Cleaning"
      defaultMessage="Fill out the form below, and download the care & cleaning guide!"
    />
  )
}

export const CONTACT_INFO = {
  id: MODAL_TYPES.CONTACT,
  type: MODAL_TYPES.CONTACT,
  thanksUrl: MODAL_TYPES_THANKS_URL.CONTACT,
  title: <FormattedMessage id="ModalContext.contactTitle" defaultMessage="Contact" />,
  subtitle: <FormattedMessage id="ModalContext.contactSubtitle" defaultMessage="contact us" />
}
