import { defineMessages } from "react-intl"

export const MSGS = defineMessages({
  companyName: {
    id: "Claims.ContactDetails.companyName",
    description: "field company name of section constact details",
    defaultMessage: "Company Name"
  },
  customerNumber: {
    id: "Claims.ContactDetails.customerNumber",
    description: "field customer number of section constact details",
    defaultMessage: "Customer Number"
  },
  fullName: {
    id: "Claims.ContactDetails.fullName",
    description: "select full Name of section constact details",
    defaultMessage: "Name and Last Name"
  },
  email: {
    id: "Claims.ContactDetails.email",
    description: "select email of section constact details",
    defaultMessage: "E-mail"
  }
})
