import PropTypes from "prop-types"
import { Row, Col, Container, Modal, ModalHeader, ModalBody } from "reactstrap"
import RequestForm from "./components/RequestForm"
import "./styles/samplingrequest.scss"
import { useState } from "react"
import spradlingLogoToModalHeader from "./img/spradling-logo-gray.svg"
import ThanksYouMessage from "./components/ThanksYouMessage"
import useGetPatternQuery from "./hooks/useGetPatternQuery"
import useGetPublicPatternPDF from "./hooks/useGetPublicPatternPDF"

const SamplingRequest = ({ modalContext, isOpen, toggle }) => {
  const [isThankYouMessage, setIsThankYouMessage] = useState(false)
  const { MASIALA_PATTERN_ID } = useGetPatternQuery()
  const { patternInformation, handleDownloadPublicPDF } = useGetPublicPatternPDF(MASIALA_PATTERN_ID)

  const handleIsThankYouMessage = () => setIsThankYouMessage(!isThankYouMessage)

  const handleToggle = () => {
    setIsThankYouMessage(false)
    toggle()
  }

  return (
    <Modal
      size="xl"
      className="modal-lg sampling-request-modal"
      scrollable
      isOpen={isOpen}
      toggle={handleToggle}
    >
      <ModalHeader className="custom-modal-header border-0 px-4" toggle={handleToggle}>
        {isThankYouMessage && (
          <img src={spradlingLogoToModalHeader} width={130} alt="logo-spradling" />
        )}
      </ModalHeader>
      <ModalBody>
        <Container>
          {isOpen && !isThankYouMessage && (
            <Row>
              <Col md={12}>
                <h1 className="text-center">{modalContext.title}</h1>
              </Col>
            </Row>
          )}
          {isOpen && !isThankYouMessage && (
            <RequestForm
              modalContext={modalContext}
              handleCallbackToShowThankYouMessage={handleIsThankYouMessage}
              patternInfo={patternInformation}
            />
          )}
          {isOpen && isThankYouMessage && (
            <ThanksYouMessage
              modalContext={modalContext}
              handleDownloadPublicPDF={handleDownloadPublicPDF}
              callbackToChangeValueOfIsThankYouState={setIsThankYouMessage}
              toggle={handleToggle}
            />
          )}
        </Container>
      </ModalBody>
    </Modal>
  )
}

SamplingRequest.propTypes = {
  modalContext: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

export default SamplingRequest
