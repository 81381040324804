import PropTypes from "prop-types"
import { Modal as ModalReactStrap, ModalHeader, ModalBody } from "reactstrap"

const Modal = ({ isOpen = false, handleToggable, size, children, contentClassName }) => (
  <ModalReactStrap contentClassName={contentClassName} isOpen={isOpen} centered size={size}>
    <ModalHeader className="border-0" toggle={handleToggable} />
    <ModalBody>
      <div className="m-4 mt-0">{children}</div>
    </ModalBody>
  </ModalReactStrap>
)

Modal.propTypes = {
  contentClassName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleToggable: PropTypes.func.isRequired,
  size: PropTypes.string,
  children: PropTypes.node
}

export default Modal
