import { FormattedMessage } from "react-intl"
import poster from "modules/petfriendly/components/Video/assets/media/poster.png"
import VideoCommon from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"
import { getCurrentInstanceId } from "services/instances"

const VIDEO_BY_INSTANCE = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "https://www.youtube.com/embed/Nd-GuA4MHPo",
  [process.env.REACT_APP_MX_INSTANCE_ID]: "https://www.youtube.com/embed/Bd1m35sImbs"
}

const Video = () => {
  const isMobile = useMediaQuery(SM)
  return (
    <div className="container my-2 py-4">
      <div className="row">
        <div className="col-12 text-center">
          <p className="mb-4">
            <FormattedMessage
              id="Petfriendly.Video.description"
              description="Petfriendly section title info"
              defaultMessage="Día a día desarrollamos telas con beneficios y tecnologías que se adaptan perfectamente a tus espacios y al de tus mascotas{br}Te invitamos a ver este vídeo para que conozcas las ventajas de nuestros productos Proquinal."
              values={{ br: <br /> }}
            />
          </p>
          <VideoCommon className="img-fluid" poster={poster} fromYoutube>
            <iframe
              width="100%"
              height={isMobile ? "250" : "650"}
              src={VIDEO_BY_INSTANCE[getCurrentInstanceId()]}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoCommon>
        </div>
      </div>
    </div>
  )
}

export default Video
