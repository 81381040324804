import { defineMessages } from "react-intl"
import residentialIcon from "components/UsesList/img/residential.svg"
import officesIcon from "components/UsesList/img/offices.svg"
import educationIcon from "components/UsesList/img/education.svg"
import healthIcon from "components/UsesList/img/health.svg"
import hotelsRestaurantsIcon from "components/UsesList/img/hotelsRestaurants.svg"
import publicSpacesIcon from "components/UsesList/img/publicSpaces.svg"
import marineIcon from "components/UsesList/img/marine.svg"
import transportIcon from "components/UsesList/img/transport.svg"

export const NAV_ITEMS = ["Colores", "Usos", "Características", "Dónde comprar"]

export const MSGS = defineMessages({
  marine: {
    id: "FloorDetail.uses.marine",
    description: "FloorDetail section uses item marine",
    defaultMessage: "Marine"
  },
  transport: {
    id: "FloorDetail.uses.transport",
    description: "FloorDetail section uses item transport",
    defaultMessage: "Transport"
  },
  residential: {
    id: "FloorDetail.uses.residential",
    description: "FloorDetail section uses item residential",
    defaultMessage: "Residencial"
  },
  offices: {
    id: "FloorDetail.uses.offices",
    description: "FloorDetail section uses item offices",
    defaultMessage: "Oficinas"
  },
  education: {
    id: "FloorDetail.uses.education",
    description: "FloorDetail section uses item education",
    defaultMessage: "Educación"
  },
  health: {
    id: "FloorDetail.uses.health",
    description: "FloorDetail section uses item health",
    defaultMessage: "Salud"
  },
  hotelsRestaurants: {
    id: "FloorDetail.uses.hotelsRestaurants",
    description: "FloorDetail section uses item hotels and restaurants",
    defaultMessage: "Hoteles y {br} restaurantes"
  },
  publicSpaces: {
    id: "FloorDetail.uses.publicSpaces",
    description: "FloorDetail section uses item public spaces",
    defaultMessage: "Espacios {br} públicos"
  }
})

export const USES_ITEMS = [
  {
    id: "marine",
    text: MSGS.marine,
    icon: marineIcon
  },
  {
    id: "transport",
    text: MSGS.transport,
    icon: transportIcon
  },
  {
    id: "residential",
    text: MSGS.residential,
    icon: residentialIcon
  },
  {
    id: "offices",
    text: MSGS.offices,
    icon: officesIcon
  },
  {
    id: "education",
    text: MSGS.education,
    icon: educationIcon
  },
  {
    id: "health",
    text: MSGS.health,
    icon: healthIcon
  },
  {
    id: "hotelsRestaurants",
    text: MSGS.hotelsRestaurants,
    icon: hotelsRestaurantsIcon
  },
  {
    id: "publicSpaces",
    text: MSGS.publicSpaces,
    icon: publicSpacesIcon
  }
]

export const GLOBAL_SITE_TAG = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "GTM-MBW4NC4",
  [process.env.REACT_APP_MX_INSTANCE_ID]: "GTM-MBW4NC4"
}
