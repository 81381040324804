import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import img from "./img/graphic.png"
import "./styles/how-works.scss"

const HowWorks = ({ id }) => (
  <div id={id} className="container how-works-container pt-4 pb-4 pt-lg-5 mb-lg-5">
    <div className="row">
      <div className="col-12 col-md-8">
        <img className="img-fluid mb-0 mb-lg-5" src={img} alt="preview of virtual room" />
      </div>
      <div className="col-12 col-md-4">
        <div className="blue-box p-5">
          <h2 className="mb-4">
            <FormattedMessage
              id="Permacool.HowWorks.title"
              description="Launch How does it work page title"
              defaultMessage="How it works?"
            />
          </h2>
          <p className="paragraph">
            <FormattedMessage
              id="Permacool.HowWorks.description"
              description="Launch How does it work page description"
              defaultMessage="Permacool technology reduces the temperature of upholstery, by anywhere up to 26°F, when compared to regular vinyl-coated fabrics.{br}{br} Results were obtained by measurements carried out in accordance with ASTM D 4803-10**."
              values={{ br: <br /> }}
            />
          </p>
        </div>
      </div>
    </div>
  </div>
)

HowWorks.propTypes = {
  id: PropTypes.string
}

export default HowWorks
