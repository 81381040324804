import PropTypes from "prop-types"
import { useStateMachine } from "little-state-machine"
import useMediaQuery from "hooks/useMediaQuery"
import { updateAction } from "utils/littleStateMachine"
import { LG } from "consts/breakpoints"
import SamplesListDesktop from "./components/SamplesListDesktop"
import SamplesListMobile from "./components/SamplesListMobile"
import { SAMPLES_SHOW_IN_DESKTOP, SAMPLES_SHOW_IN_MOBILE } from "../../constants"
import { useRef } from "react"

const SamplesList = ({
  samplesDestkop = SAMPLES_SHOW_IN_DESKTOP,
  samplesMobile = SAMPLES_SHOW_IN_MOBILE
}) => {
  const { state, actions } = useStateMachine({ updateAction })
  const isMobile = useMediaQuery(LG)
  const staticSample = useRef()

  const handleClick = (event, sample) => {
    actions.updateAction({
      sampleDetails: sample
    })

    staticSample.current = state.sampleDetails
    event.preventDefault()
  }

  return (
    <div className="d-lg-flex p-0 flex-wrap col-12 h-75">
      {!isMobile ? (
        <SamplesListDesktop
          staticSampleData={staticSample}
          samples={samplesDestkop}
          handleClick={handleClick}
        />
      ) : (
        <SamplesListMobile samples={samplesMobile} handleClick={handleClick} />
      )}
    </div>
  )
}

SamplesList.propTypes = {
  samplesDestkop: PropTypes.array.isRequired,
  samplesMobile: PropTypes.array.isRequired
}

export default SamplesList
