import Header from "./components/Header"
import Footer from "components/Footer"
import { NAV_ITEMS } from "./constants"
import Banner from "./components/Banner"
import TechnicalUpholstery from "./components/TechnicalUpholstery"
import Products from "./components/Products"
import Projects from "./components/Projects"
import "./styles/hotelery.scss"

const SegmentHotel = () => (
  <>
    <Header items={NAV_ITEMS} isTransparent />
    <Banner />
    <TechnicalUpholstery id={NAV_ITEMS[1]} />
    <Products id={NAV_ITEMS[2]} />
    <Projects id={NAV_ITEMS[3]} />
    <Footer className="footer-background--segm-hotelery" />
  </>
)

export default SegmentHotel
