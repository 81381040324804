import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import VideoCommon from "components/Video"
import rpetLogo from "./img/logo-rpet.png"
import rpetBackgroundImage from "./img/rpet-1280.jpg"
import rpetPosterVideo from "./img/poster.jpg"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"
import "./styles/rpet.scss"

const RpetSection = ({ id }) => {
  const isMobile = useMediaQuery(LG)

  return (
    <section
      id={id}
      className="wrapper--rpet"
      style={{
        backgroundImage: `url(${rpetBackgroundImage})`
      }}
    >
      <div className="container g-xl-0">
        <div className="row g-xl-0">
          <div className="col-12 col-lg-6 order-1 order-lg-0">
            <h2 className="title--rpet">
              <FormattedMessage
                id="Rivulet.RpetSection.title"
                defaultMessage="Giving new life to plastics"
              />
            </h2>
            <p className="my-4 me-lg-5 pe-lg-5 text-white">
              <FormattedMessage
                id="Rivulet.RpetSection.description"
                defaultMessage="Around 250 billion PET (Polyethylene terephthalate) bottles end up in landfills globally each year.On average,these bottles take between 450 and 700 years to decompose. Each 30 yard roll of Rivulet backing is made with 330 recycled plastic bottles, reducing landfill waste, and significantly curbing carbon emissions."
              />
            </p>
            <div className="container-rpet-logo">
              <img className="rpet-logo" src={rpetLogo} alt="rpet-logo" />
            </div>
          </div>
          <div className="col-12 col-lg-6 order-0 order-lg-1">
            <VideoCommon className="img-fluid" poster={rpetPosterVideo} fromYoutube>
              <iframe
                className="video--deborah"
                title="video"
                width="100%"
                height={isMobile ? "250" : "400"}
                src="https://www.youtube.com/embed/mcjjp8v6C44?si=OTbU5ePiwFs7lLtY"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </VideoCommon>
          </div>
        </div>
      </div>
    </section>
  )
}

RpetSection.propTypes = {
  id: PropTypes.string.isRequired
}

export default RpetSection
