import { LANDING_CLEANING_GUIDE_MARINE_URL } from "modules/cleaning-guide-marine/constants"
import { defineMessages } from "react-intl"

const languageMessages = defineMessages({
  english: {
    id: "CleaningMarine.home.button.english",
    defaultMessage: "English"
  },
  spanish: {
    id: "CleaningMarine.home.button.spanish",
    defaultMessage: "Spanish"
  }
})

export const AVAILABLE_LANGS = [
  {
    id: 1,
    headerText: "EN",
    presentationPageText: languageMessages.english
  },
  {
    id: 2,
    headerText: "ES",
    presentationPageText: languageMessages.spanish
  }
]

export const NAVIGATE_ACCORDING_LANG = {
  [AVAILABLE_LANGS[0].headerText.toLowerCase()]: `en-us/${LANDING_CLEANING_GUIDE_MARINE_URL}`,
  [AVAILABLE_LANGS[1].headerText.toLowerCase()]: `es-la/${LANDING_CLEANING_GUIDE_MARINE_URL}`
}
