import PropTypes from "prop-types"
import ErrorMessage from "components/ErrorMessage"
import "components/InputMUI/styles/own-mui.scss"
import TooltipElement from "modules/claims/components/TooltipElement"

const InputCheck = ({ register, name, label, description, tooltipElem, error }) => (
  <div>
    <div className="step-form--group-check">
      <input type="checkbox" className="step-form--field-check col-2" {...register(name)} />
      <div className="d-block col-10">
        <div className="d-flex">
          <label className="label-item" htmlFor={name}>
            {label}
            {tooltipElem && <TooltipElement renderElement={tooltipElem} />}
          </label>
        </div>
        {description && <label className="description-item">{description}</label>}
      </div>
    </div>
    <ErrorMessage className="ms-3" weight="normal">
      {error}
    </ErrorMessage>
  </div>
)

InputCheck.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  tooltipElem: PropTypes.func,
  error: PropTypes.string
}

export default InputCheck
