import { FormattedMessage } from "react-intl"

export const NAV_0 = "Characteristics"
export const NAV_1 = "Product Details"
export const NAV_2 = "Request Samples"

export const SAXONY_GTAG = "GTM-P8KM8M4F"

export const FOOTER_ITEMS = [
  { label: <FormattedMessage id="Rivulet.Footer.phone" />, value: "+1 800 333 0955" },
  { label: "Email", value: "sales@spradlingvinyl.com" },
  { label: "Fax", value: "+1 205 985 2354" }
]

// export const RIVULET_GTAG = "GTM-MBHWXRJP"
