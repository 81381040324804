/* eslint-disable */

import box from "../img/box.png"
import clock from "../img/clock.png"
import calendar from "../img/calendar.svg"

export const MockHowitWorksList = [
  {
    img: box ,
    alt: 'Box',
    text: (
      <>
        Aceptamos pedidos de hasta <span>500 yardas</span> por SKU, garantizando el envío en un día.
      </>
    ),
    textEn: (
      <>
        Accepting orders for up to <span>500 yds</span> per SKU guaranteed to ship in one day.
      </>
    ),
  },
  {
    img: clock,
    alt: 'Clock',
    text: 'Todos los pedidos recibidos antes de las 11 a.m., hora central, estarán disponibles para su recogida el mismo día. Si el pedido se retrasa, Spradling pagará el flete.',
    textEn: 'All orders received by 11 AM central time will be available for pick up the same day. If the order is late, Spradling will pay the freight.',
  },
  {
    img: calendar,
    alt: 'Calendar',
    text: (<>
      <span>Tiempo de entrega:</span> 5 semanas para material adicional.
    </>
    ),
    textEn: (<>
      <span>Lead time:</span> 5 weeks for additional material.
    </>
    ),
  },
];
