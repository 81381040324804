import PropTypes from "prop-types"
import RequestsSection from "../RequestsSection"
import useRequestModal from "../../hooks/useRequestModal"
import { REQUEST_SAMPLES_INFO } from "../RequestsSection/utils"
import { BANNER_INFO } from "./constants"
import "./styles/banner.scss"

const Banner = ({ handleOrderCatalogue }) => {
  const { modalContext, toggable, toggle } = useRequestModal()

  const openRequestModal = () => {
    handleOrderCatalogue(REQUEST_SAMPLES_INFO)
  }

  return (
    <section
      id={BANNER_INFO.id}
      className="wrapper-rv"
      style={{
        backgroundImage: `url(${BANNER_INFO.imageThumb})`
      }}
    >
      <div className="container-rv">
        <div className="text-center mt-5 mt-lg-0">
          <h1 className="title-rv">{BANNER_INFO.title}</h1>
          <p className="description-rv">{BANNER_INFO.description}</p>
          <button className="btn btn-sx mt-3" onClick={() => openRequestModal()}>
            {BANNER_INFO.buttonText}
          </button>
        </div>
      </div>

      <RequestsSection modalContext={modalContext} toggable={toggable} toggle={toggle} />
    </section>
  )
}

Banner.propTypes = {
  handleOrderCatalogue: PropTypes.func
}

export default Banner
