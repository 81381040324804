import PropTypes from "prop-types"
import VideoCommon from "../Video"
import footerLogo from "./img/footer-logo.svg"
import poster from "./img/masiala-poster.jpg"
import { useMediaQuery } from "@mui/material"
import { SECTION_VIDEO_DESCRIPTION_DESKTOP, SECTION_VIDEO_DESCRIPTION_MOBILE } from "./constants"
import ReactPlayer from "react-player/youtube"
import "./styles/section-video.scss"

const VIDEO_URL = "https://www.youtube.com/watch?v=TPBhoiXpOt4"

const SectionVideo = ({ id }) => {
  const isMobile = useMediaQuery("(max-width: 1332px)")

  return (
    <>
      <section id={id} className="mt-4 mt-lg-0">
        <VideoCommon poster={poster} fromYoutube>
          <div style={isMobile ? { widht: "100%" } : { width: "590" }}>
            <ReactPlayer url={VIDEO_URL} loop controls />
          </div>
        </VideoCommon>
        <div className="d-flex justify-content-center">
          <p className="col-12 col-lg-9 text-center section-video__description-valencia px-5 px-lg-0">
            {isMobile ? SECTION_VIDEO_DESCRIPTION_MOBILE : SECTION_VIDEO_DESCRIPTION_DESKTOP}
          </p>
        </div>
      </section>
      <footer className="footer--masiala">
        <img className="footer-logo--masiala" src={footerLogo} alt="spradling for the logn run" />
      </footer>
    </>
  )
}

SectionVideo.propTypes = {
  id: PropTypes.string
}

export default SectionVideo
