import Banner from "components/Banner"
import { SLIDE_EXTERNAL } from "modules/floors/components/Banner/constants"
import Video from "../components/Video"
import { GLOBAL_SITE_TAG, MENU, MENU_EXTERNAL_FLOORS } from "../constants"
import Header from "../components/Header"
import References from "../components/References"
import Footer from "components/Footer"
import ProductDetails from "../components/ProductDetails"
import posterHomeVideo from "modules/floors/img/poster-home.jpg"
import WhereToBuy from "modules/floors/modules/WhereToBuy"
import Analytics from "components/Analytics"
import { getCurrentInstanceId } from "services/instances"

const ExternalFloors = () => {
  return (
    <>
      <Analytics gtag={GLOBAL_SITE_TAG[getCurrentInstanceId()]} />
      <Header objItems={MENU_EXTERNAL_FLOORS} noInspirations />
      <Banner slides={SLIDE_EXTERNAL} noDescription />
      <Video video="https://www.youtube.com/embed/NSxP_CYMrFw" poster={posterHomeVideo} />
      <References id={MENU[0].id} />
      <ProductDetails id={MENU[2].id} />
      <WhereToBuy id={MENU[3].id} />
      <Footer />
    </>
  )
}

export default ExternalFloors
