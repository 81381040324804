import PropTypes from "prop-types"
import "./styles/sample-card.scss"

const SampleCard = ({ sample, handleClick }) => (
  <div className="container-card--rv card-color" onClick={() => handleClick(sample)}>
    <div className="d-flex align-items-center justify-content-center">
      <img className="img-card--rv" src={sample.img} alt="sample-image" />
    </div>
    <p className="card-label--rv">{sample.description}</p>
  </div>
)

SampleCard.propTypes = {
  sample: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default SampleCard
