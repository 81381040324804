import PropTypes from "prop-types"
import { useEffect } from "react"
import { Helmet } from "react-helmet"

const Analytics = ({ gtag, conversionTracking, pixel }) => {
  useEffect(() => {
    const noscript = document.createElement("noscript")
    if (gtag) {
      noscript.textContent = `<iframe
          src="https://www.googletagmanager.com/ns.html?id=${gtag}"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        />`

      document.body.appendChild(noscript)
    }
    return () => {
      document.body.removeChild(noscript) // Limpiar el noscript al desmontar el componente
    }
  }, [])

  return (
    <Helmet>
      {gtag && (
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtag}');
        `}
        </script>
      )}
      {conversionTracking && (
        <script src={`https://www.googletagmanager.com/gtag/js?id=${conversionTracking}`} />
      )}
      {conversionTracking && (
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${conversionTracking}');
          `}
        </script>
      )}
      {pixel && (
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${pixel}');
            fbq('track', 'PageView');
          `}
        </script>
      )}
      {pixel && (
        <noscript>
          {`
            <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixel}&ev=PageView&noscript=1" />
          `}
        </noscript>
      )}
    </Helmet>
  )
}

Analytics.propTypes = {
  gtag: PropTypes.string,
  conversionTracking: PropTypes.string,
  pixel: PropTypes.string
}

export default Analytics
