import RequestsSection from "modules/rivulet/components/RequestsSection"
import useRequestModal from "modules/rivulet/components/RequestsSection/hooks/useRequestModal"
import { REQUEST_SAMPLES_INFO } from "modules/rivulet/components/RequestsSection/utils"
import { BANNER_INFO } from "./constants"
import "./styles/banner.scss"

const Banner = () => {
  const { modalContext, toggable, toggle, handleClickInRequest } = useRequestModal()

  const openRequestModal = () => {
    handleClickInRequest(REQUEST_SAMPLES_INFO)
  }

  return (
    <section
      id={BANNER_INFO.id}
      className="wrapper-rv"
      style={{
        backgroundImage: `url(${BANNER_INFO.imageThumb})`
      }}
    >
      <div className="container-rv">
        <div className="text-center mt-5 mt-lg-0">
          <h1 className="title-rv">{BANNER_INFO.title}</h1>
          <p className="description-rv">{BANNER_INFO.description}</p>
          <button className="btn btn-rv" onClick={() => openRequestModal()}>
            {BANNER_INFO.buttonText}
          </button>
        </div>
      </div>

      <RequestsSection modalContext={modalContext} toggable={toggable} toggle={toggle} />
    </section>
  )
}

export default Banner
