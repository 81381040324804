import { useEffect } from "react"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"
import Select from "react-select"
import { FormattedMessage } from "react-intl"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "app/store"
import { handleAddedSamplesByTheUser } from "./utils"
import "./styles/colors-select.scss"

const ColorsSelect = ({ name, id, control, setValue }) => {
  const { state, actions } = useStateMachine({ updateAction })
  const colorsValues = control?._formValues?.colours
  const isDisabled = colorsValues && colorsValues.length >= 10
  const SELECTED_COLORS = handleAddedSamplesByTheUser(state?.sampleCount)

  const handleChange = (selected) => {
    let tempArray = []

    tempArray = selected.map(({ data }) => ({
      pattern: data.pattern,
      color: data.color,
      reference: data.reference,
      catalogue: data?.catalogue
    }))

    actions.updateAction({
      sampleCount: tempArray
    })

    setValue(name, selected)
  }

  useEffect(() => {
    if (SELECTED_COLORS && SELECTED_COLORS.length) {
      handleChange(SELECTED_COLORS)
    }
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <Select
          id={id}
          name={name}
          isMulti
          isDisabled={isDisabled}
          value={field.values}
          defaultValue={SELECTED_COLORS}
          onChange={handleChange}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: (state.isFocused || !state.isFocused) && "2px solid #bdbdbd",
              ":hover": {
                borderColor: "gray"
              },
              boxShadow: "none",
              borderRadius: "none",
              padding: "2px 0px"
            })
          }}
          placeholder={
            <p className="p-0 m-0 fw-light fs-5 select-color">
              <FormattedMessage id="SamplingRequest.labelColour" defaultMessage="Color" />
            </p>
          }
        />
      )}
    />
  )
}

ColorsSelect.propTypes = {
  id: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
}

export default ColorsSelect
