import PropTypes from "prop-types"
import useToggle from "hooks/useToggle"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import "./styles/table-accordion.scss"
import { useEffect } from "react"

const TableAccordion = ({
  children,
  title,
  img,
  openByDefault = false,
  isFullContainer = false,
  id
}) => {
  const [toggable, toggle] = useToggle(openByDefault)

  useEffect(() => {
    if (toggable && id !== title) {
      toggle()
    }
    if (id === title) {
      toggle()
    }
  }, [id])

  return (
    <div className="my-2">
      <div
        className="accordion--cleaning-sm--general"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "100%"
        }}
        onClick={toggle}
      >
        <h2 className="title--segm-hotelery text-white">{title}</h2>
        <FontAwesomeIcon
          className="float-end me-3"
          icon={faAngleDown}
          size="lg"
          flip={toggable ? "vertical" : undefined}
        />
      </div>
      <div
        className={classNames({
          "table-accordion--collapse": !toggable,
          "table-accordion--collapse-show": toggable,
          "full-container": toggable && isFullContainer
        })}
        isOpen={toggable}
      >
        {children}
      </div>
    </div>
  )
}

TableAccordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  img: PropTypes.string,
  disclaimer: PropTypes.node,
  openByDefault: PropTypes.bool,
  isFullContainer: PropTypes.bool,
  children: PropTypes.element,
  id: PropTypes.string
}

export default TableAccordion
