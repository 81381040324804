import { defineMessages } from "react-intl"
import facilLimpieza from "modules/pranna/components/ProductDetails/img/facil-limpieza.svg"
import impermeabilidad from "modules/pranna/components/ProductDetails/img/impermeabilidad.svg"
import abrasion from "modules/pranna/components/ProductDetails/img/abrasion.svg"
import flexonResistency from "../img/resistencia-a-la-flexon.svg"

export const MSGS = defineMessages({
  facilLimpieza: {
    id: "Pranna.ProductDetail.easyCleaning",
    description: "Pranna section product detail item easy cleaning",
    defaultMessage: "Fácil {br} Limpieza"
  },
  impermeabilidad: {
    id: "Pranna.ProductDetail.impermeability",
    description: "Pranna section product detail item impermeability",
    defaultMessage: "Impermeabilidadss"
  },
  abrasion: {
    id: "Pranna.ProductDetail.abrationRetardancy",
    description: "Pranna section product detail item abration retardancy",
    defaultMessage: "Resistencia {br} a la Abrasión"
  },
  resistenciaFlexon: {
    id: "Pandora.ProductDetail.resistencyToFlexon",
    description: "Pandora section product detail item resistency to flexon",
    defaultMessage: "Resistencia {br} a la flexón"
  }
})

export const PRODUCT_DETAILS = [
  {
    id: "facilLimpieza",
    text: MSGS.facilLimpieza,
    icon: facilLimpieza
  },
  {
    id: "impermeabilidad",
    text: MSGS.impermeabilidad,
    icon: impermeabilidad
  },
  {
    id: "abrasion",
    text: MSGS.abrasion,
    icon: abrasion
  },
  {
    id: "flexonResistency",
    text: MSGS.resistenciaFlexon,
    icon: flexonResistency
  }
]
