/* eslint-disable */
import PropTypes from "prop-types";
import "./styles/SamplesCount.scss";

const SamplesCount = ({ toggle, QuantityItems, lang }) => {
  
  return (
    <div className="sample-count-container-quickship" onClick={toggle}>
      <p className="sample-count-text-quickship px-4">
        { lang === 'es' ? 'FINALICE AQUÍ SU PEDIDO' : 'FINISH YOUR ORDER HERE' }
         {" "} ({QuantityItems ?? 0})
      </p>
    </div>
  );
};

SamplesCount.propTypes = {
  QuantityItems: PropTypes.number,
  lang: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

export default SamplesCount;
