import PropTypes from "prop-types"
import { useState } from "react"
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from "reactstrap"
import "./styles/carousel.scss"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"

const Slider = ({ id, slides }) => {
  const isMobile = useMediaQuery(SM)
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const SLIDES = slides.map(({ id, title, img, description }) => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <div
          className="d-flex"
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%"
          }}
        >
          <div className="col-12 d-flex align-items-center">
            <div className="slider-info--container">
              <h1 className="text-white pb-2 mb-0 mb-lg-4">{title}</h1>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </CarouselItem>
    )
  })

  return (
    <div id={id}>
      <Carousel className="custom-tag" activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators items={slides} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {SLIDES}
        {!isMobile && (
          <>
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </>
        )}
      </Carousel>
    </div>
  )
}

Slider.propTypes = {
  id: PropTypes.string,
  slides: PropTypes.array
}

export default Slider
