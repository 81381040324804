import { useStateMachine } from "little-state-machine"
import { useIntl } from "react-intl"
import { updateAction } from "utils/littleStateMachine"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import InputTextArea from "components/InputMUI/TextArea"
import { BASIC_BOOLEAN_OPTIONS, GENERIC_MSGS } from "modules/claims/constants"
import { BUTTON_MESSAGES } from "utils/buttons"
import ProgressBar from "modules/claims/components/StepForm/ProgressBar"
import RadioButton from "components/InputMUI/RadioButton"
import { VALIDATION_MESSAGES } from "utils/forms"
import { SURFACE_DETERIORATION_MSGS } from "./contants"
import { useNavigate } from "react-router-dom"
import { redirectToNextStep } from "../../../utils"

const SurfaceDeterioration = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { actions, state } = useStateMachine({ updateAction })

  const defaultValues = { ...state }
  const schema = yup.object({
    suggestedInstructions: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    describeProcessUsed: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    theMaterialContaminated: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = (data) => {
    actions.updateAction(data)
    redirectToNextStep(navigate)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RadioButton
        name="suggestedInstructions"
        register={register}
        label={SURFACE_DETERIORATION_MSGS.suggestedInstructions}
        options={BASIC_BOOLEAN_OPTIONS}
        defaultValue={state.suggestedInstructions}
        error={errors.suggestedInstructions?.message}
      />
      <InputTextArea
        name="describeProcessUsed"
        control={control}
        label={SURFACE_DETERIORATION_MSGS.describeProcessUsed}
        placeholder={GENERIC_MSGS.typeAnswer_placeholder}
        defaultValue={state.describeProcessUsed}
        error={errors.describeProcessUsed?.message}
      />
      <InputTextArea
        name="theMaterialContaminated"
        control={control}
        label={SURFACE_DETERIORATION_MSGS.theMaterialContaminated}
        placeholder={GENERIC_MSGS.typeAnswer_placeholder}
        defaultValue={state.theMaterialContaminated}
        error={errors.theMaterialContaminated?.message}
      />
      <div className="btn-container">
        <button className="btn btn-dark" type="submit">
          {intl.formatMessage(BUTTON_MESSAGES.ok)}
        </button>
      </div>
      <ProgressBar
        progress={60}
        handleNext={handleSubmit(onSubmit)}
        RoutePrev={state.previousStep}
      />
    </form>
  )
}

export default SurfaceDeterioration
