import { useNavigate } from "react-router-dom"
import mainBanner from "./img/main-banner.jpg"
import "./styles/cleaning-guide-marine.scss"
import { getCurrentLanguageAndInstanceCode } from "services/instances"
import { FormattedMessage, useIntl } from "react-intl"
import { AVAILABLE_LANGS } from "./components/Header/components/Menu/constants"
import { CLEANING_GUIDE_MARINE_CHIL_URL, CLEANING_GUIDE_MARINE_GENERAL_URL, LANDING_CLEANING_GUIDE_MARINE_URL } from "./constants"
import useChangeLanguage from "./hooks/useChangeLanguage"
import Select from "react-select"
import { customStylesSelectMainPage, handleGetCustomStylesSelectMainPage } from "./constants/customStylesSelects"

const CleaningGuideMarine = () => {
  const isPresentationPage = true
  const intl = useIntl()
  const navigate = useNavigate()
  const [lang, instance] = getCurrentLanguageAndInstanceCode()
  const { selectedLang, handleLanguageChange } = useChangeLanguage(isPresentationPage)
  const customSelectStyles = handleGetCustomStylesSelectMainPage(false)

  const handleGuideRedirect = (param) =>
    navigate(`/${lang}-${instance}/${LANDING_CLEANING_GUIDE_MARINE_URL}/${param}`)

  return (
    <div
      id="home-slide"
      className="home-slide main-container--cleaning-sm"
      style={{
        backgroundImage: `url(${mainBanner})`
      }}
    >
      <div className="logo-container--cleaning-sm">
        <img
          className="img-fluid"
          width={240}
          src="https://static.spradling.group/uploads/instances/3/logo-spradling.png"
        />
      </div>
      <div className="home-slide__content home-slide__content--cleaning-sm home-slide__content--center">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h1 className="title--cleaning-sm">
            <FormattedMessage
              id="CleaningMarine.home.welcome"
              defaultMessage="Welcome to Spradling Marine {br} Cleaning Guide"
              values={{
                br: <br />
              }}
            />
            <br />
          </h1>
          <div className="border-bottom mt-3 d-inline text-white w-auto">
            <Select
              value={selectedLang}
              className="cleaning-guide--header_select_lang-main-page"
              options={AVAILABLE_LANGS.map(({ id, headerText, presentationPageText }) => {
                return {
                  label: intl.formatMessage(presentationPageText),
                  value: headerText
                }
              })}
              isSearchable={false}
              styles={customSelectStyles}
              onChange={(e) => handleLanguageChange(e, isPresentationPage)}
            />
          </div>
          <div className="btns-container--cleaning-sm">
            <button
              className="btn btn-light my-2 my-lg-0 mx-3"
              onClick={() => handleGuideRedirect(CLEANING_GUIDE_MARINE_GENERAL_URL)}
            >
              <FormattedMessage
                id="CleaningMarine.home.button.general"
                defaultMessage="General Cleaning Guide"
              />
            </button>
            <button
              className="btn btn-light my-2 my-lg-0 mx-3"
              onClick={() => handleGuideRedirect(CLEANING_GUIDE_MARINE_CHIL_URL)}
            >
              <FormattedMessage
                id="CleaningMarine.home.button.chil"
                defaultMessage="Chil™ Cleaning Guide"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CleaningGuideMarine
