import * as yup from "yup"
import { defineMessages } from "react-intl"
import { VALIDATION_MESSAGES } from "utils/forms"

export const VISUAL_DEFECTS_SCHEMA = (intl) => ({
  typeVisualDifference: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  visualDefectsHowDetectDifference: yup
    .string()
    .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  howCompareMaterial: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
})

export const VISUAL_DEFECTS_MSGS = defineMessages({
  typeVisualDifference: {
    id: "Claims.VisualDefects.typeVisualDefect",
    description: "option type visual defect on visual defects",
    defaultMessage: "Please select the type of visual difference in the following options"
  },
  howDetectsDifference: {
    id: "Claims.VisualDefects.howDetectsDifference",
    description: "option how detects difference on visual defects",
    defaultMessage: "How do you detect the difference?"
  },
  howCompareMaterial: {
    id: "Claims.VisualDefects.howCompareMaterial",
    description: "option how compare material on visual defects",
    defaultMessage: "To which sample/material do you compare?"
  }
})

const TYPE_VISUAL_DEFECT_OPTIONS_MSGS = defineMessages({
  embossing: {
    id: "Claims.VisualDefects.typeVisualDefect.options.embossing",
    description: "option embossing in type visual defect on visual defects",
    defaultMessage: "Embossing"
  },
  gloss: {
    id: "Claims.VisualDefects.typeVisualDefect.options.gloss",
    description: "option gloss in type visual defect on visual defects",
    defaultMessage: "Gloss"
  },
  print: {
    id: "Claims.VisualDefects.typeVisualDefect.options.print",
    description: "option print in type visual defect on visual defects",
    defaultMessage: "Print"
  }
})

export const TYPE_VISUAL_DEFECT_OPTIONS = [
  {
    id: "embossing",
    label: TYPE_VISUAL_DEFECT_OPTIONS_MSGS.embossing,
    value: "embossing"
  },
  {
    id: "gloss",
    label: TYPE_VISUAL_DEFECT_OPTIONS_MSGS.gloss,
    value: "gloss"
  },
  {
    id: "print",
    label: TYPE_VISUAL_DEFECT_OPTIONS_MSGS.print,
    value: "print"
  }
]
