import gql from "graphql-tag"

export const homeSlidersQuery = gql`
  query HomeSliders($instanceIds: [ID]!, $tag: String) {
    homeSliders(instances: $instanceIds, tag: $tag) {
      edges {
        node {
          id
          title
          description
          buttonText
          imageThumb
          link
          tag
        }
      }
    }
  }
`

export const sustainabilityReportsQuery = gql`
  query SustainabilityReportsQuery($orderBy: String) {
    sustainabilityReports(orderBy: $orderBy) {
      edges {
        node {
          id
          imageThumbMedium
          pdf
          date
        }
      }
    }
  }
`
