import axios from "axios"
import { ENDPOINT_ZAPPIER_CLAIMS_BY_CLAIM_REASON } from "./constants"

// WARNING: unused cuz every zap for claims send data to summary Worksheet
// const handleZapSummary = (data) => {
//   const {
//     fileUrl,
//     createClaim: { claim }
//   } = data

//   const promise = axios.post(
//     ENDPOINT_ZAPPIER_CLAIMS_BY_CLAIM_REASON.SUMMARY,
//     JSON.stringify({ fileUrl, ...claim }, null, 2)
//   )
//   promise.then(() => {})
//   promise.catch((error) => {
//     console.log("handleZapSummary error: ", error)
//   })
// }

const handleZapForClaimReason = (data, callback) => {
  const {
    fileUrl,
    createClaim: { claim }
  } = data

  if (claim.product) {
    data.createClaim.claim.product.color = claim?.product.isDiscontinued
      ? `${claim?.product.color} (Discontinued)`
      : claim?.product.color
  }

  const bodyToSendZap = {
    fileUrl,
    ...data.createClaim.claim
  }

  const promise = axios.post(
    ENDPOINT_ZAPPIER_CLAIMS_BY_CLAIM_REASON[data.createClaim.claim.claimReason.reasons],
    JSON.stringify(bodyToSendZap, null, 2)
  )
  promise.then(() => callback(data.createClaim.claim))
  promise.catch((error) => {
    console.log("handleZapForClaimReason error: ", error)
  })
}

const handleZapForSendEmails = (data) => {
  const promise = axios.post(
    ENDPOINT_ZAPPIER_CLAIMS_BY_CLAIM_REASON.SEND_EMAILS,
    JSON.stringify(data, null, 2)
  )
  promise.then(() => {})
  promise.catch((error) => {
    console.log("handleZapSummary error: ", error)
  })
}

const handleZap = (data, callback) => {
  // handleZapSummary(data) // WARNING: unused cuz every zap for claims send data to summary Worksheet
  handleZapForSendEmails(data)
  handleZapForClaimReason(data, callback)
}

export default handleZap
