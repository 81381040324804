import PropTypes from "prop-types"
import { Link } from "react-scroll"
import classNames from "classnames/bind"
import BurgerBtn from "components/Header/components/Menu/components/BurgerBtn"
import useToggle from "hooks/useToggle"
import "./styles/navbar.scss"
import RenderObject from "./components/RenderObject"

export const Menu = ({ logo, items = [], objItems = [] }) => {
  const [toggable, setToggable] = useToggle()

  const handleRenderArray = () =>
    items.map((item) => (
      <li className="main-menu-sections__section" key={`key_${item}`}>
        <div className="text-capitalize">
          <Link
            className="header-menu-button"
            activeClass="header-menu-button--active"
            to={item}
            duration={100}
            offset={-50}
            smooth
            spy
          >
            {item}
          </Link>
        </div>
      </li>
    ))

  return (
    <div className="container">
      <div className="row g-0">
        <div className="col-2 col-md-10 order-md-1">
          <BurgerBtn toggable={toggable} setToggable={setToggable} />
          <div
            className={classNames({
              "header-main-menu": true,
              "header-main-menu--open": toggable
            })}
          >
            <ul
              className={classNames({
                "main-menu-sections": true,
                "main-menu-sections--open": toggable
              })}
            >
              {!items ? <RenderObject items={objItems} /> : handleRenderArray()}
            </ul>
          </div>
        </div>
        <div className="col-8 ps-3 ps-md-0 col-md-2 order-md-0">
          <Link to="home-slide" className="logo cursor-pointer" duration={100} smooth={true}>
            {logo && (
              <img
                className="img-fluid"
                src={logo}
                alt="Spradling Group"
                title="Spradling Group"
                width="150px"
                height="50px"
              />
            )}
          </Link>
        </div>
      </div>
    </div>
  )
}

Menu.propTypes = {
  logo: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  objItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.object,
      externalLink: PropTypes.bool,
      href: PropTypes.string
    })
  )
}

export default Menu
