import PropTypes from "prop-types"
import { Controller, useWatch } from "react-hook-form"
import { useLazyQuery } from "@apollo/client"
import { FormattedMessage, useIntl } from "react-intl"
import AsyncSelect from "react-select/async"
import { citiesQuery, citiesByCountryIdQuery } from "./query"
import _ from "lodash"
import { getCurrentInstanceId } from "services/instances"
import storesByInstanceQuery from "components/StoreFinder/components/SearchOnlyByLocation/query"
import { MSGS as MSGS_REGION } from "components/select/SelectRegion/constants"
import { MSGS } from "./constants"
import styles from "components/select/styles"
import { useEffect, useState } from "react"

const SelectCity = ({ labelClassName, searchByCountryId, name, control }) => {
  const intl = useIntl()
  const { label: nameCountry, value: parentId } = useWatch({
    control,
    name: !searchByCountryId ? "region" : "country"
  })
  const noRegionMsg = !searchByCountryId ? MSGS.withoutRegion : MSGS_REGION.withoutCountry
  const placeholder = intl.formatMessage(!parentId ? noRegionMsg : MSGS.withRegion)
  const [getCities, { data }] = useLazyQuery(
    !searchByCountryId ? citiesQuery : citiesByCountryIdQuery
  )
  const [locationsDefault, setLocationsDefault] = useState([])
  const [getDefaultCities, { loading: loadingDefaultCities }] = useLazyQuery(
    storesByInstanceQuery,
    {
      variables: {
        instanceId: getCurrentInstanceId()
      },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        let locations = data.stores.edges.map(({ node }) => ({
          label: node.city.name,
          value: node.city.id,
          country: node.city.country.name
        }))
        // Remove duplicates
        locations = _.uniqBy(locations, (location) => location.value)
        // Sort alphabetically
        locations = _.sortBy(locations, (location) => location.label)
        // Group by country
        const groupedByCountry = _.groupBy(locations, (location) => location.country)
        // Transform into an array as <Select/> expects
        const groupedOptions = Object.keys(groupedByCountry).map((key) => ({
          label: key,
          options: groupedByCountry[key]
        }))
        const filteredGroupedOptions = groupedOptions.filter(({ label }) => label === nameCountry)
        setLocationsDefault(filteredGroupedOptions)
      }
    }
  )

  useEffect(() => {
    if (searchByCountryId && parentId) {
      getDefaultCities()
    }
  }, [parentId])

  const loadOptions = (_, callback) => {
    const options = data?.cities.edges.map((edge) => ({
      label: edge.node.name,
      value: edge.node.id
    }))

    callback(options)
  }

  const handleOnInputChange = (value) =>
    getCities({
      variables: {
        regionId: parentId,
        query: value
      }
    })

  return (
    <>
      <label htmlFor={name} className={labelClassName}>
        <FormattedMessage
          id="Form.field.City"
          description="Form field city"
          defaultMessage="City"
        />
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange: handleOnChangeForm, ...field } }) => (
          <AsyncSelect
            styles={styles}
            {...field}
            placeholder={
              !loadingDefaultCities ? placeholder : <FormattedMessage id="LoadingMessage.label" />
            }
            defaultOptions={locationsDefault}
            loadOptions={loadOptions}
            isDisabled={!parentId}
            isLoading={loadingDefaultCities}
            isClearable
            onInputChange={handleOnInputChange}
            onChange={handleOnChangeForm}
            noOptionsMessage={() => (
              <FormattedMessage
                id="Form.field.noOptionesMessage"
                description="message when there's no options on a select field"
                defaultMessage="No options"
              />
            )}
          />
        )}
      />
    </>
  )
}

SelectCity.propTypes = {
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  searchByCountryId: PropTypes.bool
}

export default SelectCity
