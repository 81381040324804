import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { scroller } from "react-scroll"
import LogoKizunaWhite from "./img/kizuna-logo-blanco.png"
import { ReactComponent as BagIcon } from "./img/bag.svg"
import { DROP_DOWN_ITEMS } from "./constants"
import { useStateMachine } from "little-state-machine"
import "./styles/toolbar.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import useRequestModal from "../Footer/useRequestModal"
import RequestsSection from "../RequestsSection"
import { REQUEST_SAMPLES_INFO } from "../RequestsSection/utils"

const Header = () => {
  const location = useLocation()
  const { state } = useStateMachine()
  const { modalContext, toggable, toggle, handleClickInRequest } = useRequestModal()

  useEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash, {
        duration: 100,
        delay: 100,
        smooth: true,
        spy: true,
        offset: -50
      })
    }
  }, [location.hash])

  return (
    <header className="header--kizuna Toolbar">
      <div className="w-100 d-flex align-items-center justify-content-between px-2 px-md-5">
        <nav className="w-100 navbar navbar-expand-lg navbar-light py-0">
          <Link className="navbar-brand logo" to="/en-us/kizuna#home">
            <img src={LogoKizunaWhite} alt="Spradling" />
          </Link>
          <div className="navbar-collapse">
            <ul className="navbar-nav ms-0 ms-lg-auto">
              <li className="nav-item dropdown">
                <a
                  className="nav-link kizuna-nav-item"
                  href="#about-kizuna"
                  id="kizunaDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About Kizuna Collection
                  <FontAwesomeIcon icon={faAngleDown} color="white" fixedWidth />
                </a>
                <ul className="dropdown-menu" aria-labelledby="kizunaDropdown">
                  {DROP_DOWN_ITEMS.map(({ id, text, isRedirect }) => (
                    <li key={id} className="kizuna-dropdown-item--container">
                      <Link
                        className="dropdown-item"
                        to={`/en-us/kizuna${!isRedirect ? id : `/${id}`}`}
                      >
                        {text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              {state?.sampleCount?.length > 0 && (
                <li className="nav-item">
                  <button
                    className="btn btn-link nav-link cart-icon"
                    onClick={() => handleClickInRequest(REQUEST_SAMPLES_INFO)}
                  >
                    <BagIcon className="cart-img--bag" />
                    <span className="cart-count">{state?.sampleCount?.length}</span>
                  </button>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
      <RequestsSection modalContext={modalContext} toggable={toggable} toggle={toggle} />
    </header>
  )
}

export default Header
