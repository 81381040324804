// SLIDER
import wasabiInspired from "./imgs/slider/wasabi-inspired.jpg"
import kizunaCollectionSlider from "./imgs/slider/kizuna-three-clothes.jpg"
import designInfo from "./imgs/slider/kizuna-design-info.jpg"

import posterCollection from "./imgs/video/poster.png"

// VIDEO
export const POSTER_KIZUNA_COLLECTION = posterCollection

export const STORY_VIDEO_URL = "https://www.youtube.com/watch?v=dAZeaIFphtg"

export const MAIN_VIDEO_URL = "https://vimeo.com/1003298003"

export const COLLECTION_VIMEO_VIDEO_URL = {
  de: "https://vimeo.com/876403477/80b208d201",
  en: "https://vimeo.com/868295869/6784ecf848",
  es: "https://vimeo.com/876402847/f80f9aaef3",
  fr: "https://vimeo.com/876403156/f5656a9422",
  it: "https://vimeo.com/876403823/116285bd3c",
  pl: "https://vimeo.com/876404257/fb8779cc57"
}

export const COLLECTION_YOUTUBE_VIDEO_URL = {
  de: "https://youtu.be/Rf63x1KiNtQ",
  en: "https://youtu.be/x3jxbJovGOw",
  es: "https://youtu.be/8x0efqVpMT4",
  fr: "https://youtu.be/uIVKqG90z84",
  it: "https://youtu.be/ZSirUUCkUu0",
  pl: "https://youtu.be/zp7PZl222qo"
}

export const CONCEPTS_SLIDER = [
  {
    id: "kizuna_collection",
    title: "About Kizuna Collection",
    description:
      'The Kizuna Collection, in essence is a testament to the power of cooperation and the unyielding spirit of togetherness. Just as the Japanese term "Kizuna" emphasises standing together through challenges, the result of this skilful collaboration between Spradling and Citel showcases how our joint efforts push the boundaries of knowledge and creativity.',
    img: kizunaCollectionSlider
  },
  {
    id: "kizuna_wabi_sabi",
    title: "Inspired by the Wabi-Sabi concept",
    description:
      "This collection celebrates the beauty of imperfection and impermanence, embracing the beauty of the natural world and appreciating the irregularities and asymmetry found in nature.",
    img: wasabiInspired
  }
]

export const DESIGNS_INFO = {
  id: "kizuna_designs",
  title: "Kizuna Designs",
  description:
    'The true spirit of "Kizuna" resonates throughout the Kizuna Collection, bringing forth a celebration of human connections, bonds, and relationships that extend far beyond our own business. It becomes a symbol of unity, trust, and solidarity.\n\nA reminder that we can achieve remarkable feats together and create something extraordinary, reflecting the strength and resilience of working collaboratively toward a common goal.',
  img: designInfo
}

export const KIZUNA_USA_GTM = "GTM-NBT7R725"
