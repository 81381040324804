import { defineMessages } from "react-intl"
import { MATERIAL_CURRENT_STATE } from "./materialCurrentState"

export const REASONS = {
  COLOR_DIFFERENCE: "color difference",
  COLOR_FADING: "color fading",
  STAINING: "staining",
  SURFACE_DETERIORATION: "surface deterioration",
  MATERIAL_RESISTANCE: "material resistance",
  PLEATS: "pleats",
  DIFFERENCES_IN_HAND: "differences in hand",
  FACIAL_DEFFECTS: "facial defects",
  VISUAL_DEFFECTS: "visual defects",
  DIFFERENCES_IN_DIMENSION: "differences in dimensions",
  OTHER: "others"
}

export const REASON_OPTIONS_MSGS = defineMessages({
  colourDifference: {
    id: "Claims.OriginalCondition.reason.option.colourDifference",
    description: "option colour difference for select",
    defaultMessage: "Colour difference"
  },
  colourFading: {
    id: "Claims.OriginalCondition.reason.option.colourFading",
    description: "option colour fading for select",
    defaultMessage: "Colour fading | Colour changes"
  },
  staining: {
    id: "Claims.OriginalCondition.reason.option.staining",
    description: "option staining for select",
    defaultMessage: "Staining"
  },
  SurfaceDeterioration: {
    id: "Claims.OriginalCondition.reason.option.SurfaceDeterioration",
    description: "option Surface Deterioration for select",
    defaultMessage: " Surface deterioration (hardening, cracking)"
  },
  materialResistance: {
    id: "Claims.OriginalCondition.reason.option.materialResistance",
    description: "option material resistance for select",
    defaultMessage: "Material Resistance"
  },
  pleats: {
    id: "Claims.OriginalCondition.reason.option.pressureOn",
    description: "option pleats or pressure marks on material for select",
    defaultMessage: "Pleats | Pressure marks on material"
  },
  differencesInHand: {
    id: "Claims.OriginalCondition.reason.option.differencesInHand",
    description: "option differences in hand dimension for select",
    defaultMessage: "Differences in hand"
  },
  facialDefects: {
    id: "Claims.OriginalCondition.reason.option.facialDefects",
    description: "option facial defects for select",
    defaultMessage: "Facial defects"
  },
  facialDefectsDescription: {
    id: "Claims.OriginalCondition.reason.option.facialDefects.description",
    description: "option description facial defects for select",
    defaultMessage: "(stains, spots, contamination or holes in the material)"
  },
  visualDefects: {
    id: "Claims.OriginalCondition.reason.option.visualDefects",
    description: "option visual defects for select",
    defaultMessage: "Visual defects (embossing, gloss, print)"
  },
  differencesInDimension: {
    id: "Claims.OriginalCondition.reason.option.differencesInDimension",
    description: "option differences in material dimension for select",
    defaultMessage: "Differences in material dimension"
  },
  differencesInDimensionDescription: {
    id: "Claims.OriginalCondition.reason.option.differencesInDimension.description",
    description: "option description differences in material dimension for select",
    defaultMessage: "(lenght | width)"
  },
  other: {
    id: "Claims.OriginalCondition.reason.option.other",
    description: "option other for select",
    defaultMessage: "Other"
  },
  otherDescription: {
    id: "Claims.OriginalCondition.reason.option.other.description",
    description: "option other for select",
    defaultMessage: "Please describe your claim if different to the options given"
  }
})

const REASON_OPTIONS_ALL = {
  [REASONS.COLOR_DIFFERENCE]: {
    value: REASONS.COLOR_DIFFERENCE,
    label: REASON_OPTIONS_MSGS.colourDifference
  },
  [REASONS.COLOR_FADING]: {
    value: REASONS.COLOR_FADING,
    label: REASON_OPTIONS_MSGS.colourFading
  },
  [REASONS.STAINING]: {
    value: REASONS.STAINING,
    label: REASON_OPTIONS_MSGS.staining
  },
  [REASONS.SURFACE_DETERIORATION]: {
    value: REASONS.SURFACE_DETERIORATION,
    label: REASON_OPTIONS_MSGS.SurfaceDeterioration
  },
  [REASONS.MATERIAL_RESISTANCE]: {
    value: REASONS.MATERIAL_RESISTANCE,
    label: REASON_OPTIONS_MSGS.materialResistance
  },
  [REASONS.PLEATS]: {
    value: REASONS.PLEATS,
    label: REASON_OPTIONS_MSGS.pleats
  },
  [REASONS.DIFFERENCES_IN_HAND]: {
    value: REASONS.DIFFERENCES_IN_HAND,
    label: REASON_OPTIONS_MSGS.differencesInHand
  },
  [REASONS.FACIAL_DEFFECTS]: {
    value: REASONS.FACIAL_DEFFECTS,
    label: REASON_OPTIONS_MSGS.facialDefects,
    description: REASON_OPTIONS_MSGS.facialDefectsDescription
  },
  [REASONS.VISUAL_DEFFECTS]: {
    value: REASONS.VISUAL_DEFFECTS,
    label: REASON_OPTIONS_MSGS.visualDefects
  },
  [REASONS.DIFFERENCES_IN_DIMENSION]: {
    value: REASONS.DIFFERENCES_IN_DIMENSION,
    label: REASON_OPTIONS_MSGS.differencesInDimension,
    description: REASON_OPTIONS_MSGS.differencesInDimensionDescription
  },
  [REASONS.OTHER]: {
    value: REASONS.OTHER,
    label: REASON_OPTIONS_MSGS.other
  }
}

const REASON_OPTIONS_ORIGINAL = [
  REASON_OPTIONS_ALL[REASONS.COLOR_DIFFERENCE],
  REASON_OPTIONS_ALL[REASONS.FACIAL_DEFFECTS],
  REASON_OPTIONS_ALL[REASONS.PLEATS],
  REASON_OPTIONS_ALL[REASONS.MATERIAL_RESISTANCE],
  REASON_OPTIONS_ALL[REASONS.DIFFERENCES_IN_HAND],
  REASON_OPTIONS_ALL[REASONS.VISUAL_DEFFECTS],
  REASON_OPTIONS_ALL[REASONS.DIFFERENCES_IN_DIMENSION],
  REASON_OPTIONS_ALL[REASONS.OTHER]
]

const REASON_OPTIONS_TRANSFORMED = [
  REASON_OPTIONS_ALL[REASONS.COLOR_DIFFERENCE],
  REASON_OPTIONS_ALL[REASONS.FACIAL_DEFFECTS],
  REASON_OPTIONS_ALL[REASONS.PLEATS],
  REASON_OPTIONS_ALL[REASONS.MATERIAL_RESISTANCE],
  REASON_OPTIONS_ALL[REASONS.SURFACE_DETERIORATION],
  REASON_OPTIONS_ALL[REASONS.DIFFERENCES_IN_HAND],
  REASON_OPTIONS_ALL[REASONS.VISUAL_DEFFECTS],
  REASON_OPTIONS_ALL[REASONS.OTHER]
]

const REASON_OPTIONS_INSTALLED = [
  REASON_OPTIONS_ALL[REASONS.COLOR_DIFFERENCE],
  REASON_OPTIONS_ALL[REASONS.COLOR_FADING],
  REASON_OPTIONS_ALL[REASONS.STAINING],
  REASON_OPTIONS_ALL[REASONS.SURFACE_DETERIORATION],
  REASON_OPTIONS_ALL[REASONS.MATERIAL_RESISTANCE],
  REASON_OPTIONS_ALL[REASONS.PLEATS],
  REASON_OPTIONS_ALL[REASONS.DIFFERENCES_IN_HAND],
  REASON_OPTIONS_ALL[REASONS.FACIAL_DEFFECTS],
  REASON_OPTIONS_ALL[REASONS.VISUAL_DEFFECTS],
  REASON_OPTIONS_ALL[REASONS.OTHER]
]

export const REASON_OPTIONS = {
  [MATERIAL_CURRENT_STATE.ORIGINAL]: REASON_OPTIONS_ORIGINAL,
  [MATERIAL_CURRENT_STATE.TRANSFORMED]: REASON_OPTIONS_TRANSFORMED,
  [MATERIAL_CURRENT_STATE.INSTALLED]: REASON_OPTIONS_INSTALLED
}
