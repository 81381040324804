import gql from "graphql-tag"

export const productsQuery = gql`
  query productsQuery($pattern: ID) {
    products(pattern: $pattern) {
      edges {
        node {
          id
          color
          isDiscontinued
        }
      }
    }
  }
`
