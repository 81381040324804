import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from "@apollo/client"
import { relayStylePagination } from "@apollo/client/utilities"
import { createUploadLink } from "apollo-upload-client"
import { getCurrentLanguageAndInstanceCode } from "services/instances"

const uri = `${process.env.REACT_APP_SERVER_URL}/graphql/`
const uploadLink = createUploadLink({ uri })
const httpLink = new HttpLink({ uri })

const updateHeadersMiddleware = new ApolloLink((operation, forward) => {
  const [lang] = getCurrentLanguageAndInstanceCode()

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "Accept-Language": lang
    }
  }))

  return forward(operation)
})

const client = new ApolloClient({
  link: concat(updateHeadersMiddleware, uploadLink, httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          countries: relayStylePagination(["query"])
        }
      }
    }
  })
})

export default client
