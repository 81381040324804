import PropTypes from "prop-types"
import { useStateMachine } from "little-state-machine"
import SamplesDetail from "./components/SamplesDetail"
import { SAMPLES } from "../ProductsSamples/constants/samples"
import { updateAction } from "app/store"
import "./styles/ProductsSamplesDetails.scss"
import _ from "lodash"

const KIZUNA_CATALOGUE_ITEM = { catalogue: "Kizuna Catalogue" }

const ProductsSamplesDetails = ({ design, handleShowInformationBox }) => {
  const { state, actions } = useStateMachine({ updateAction })

  const handleClickInAddCatalogueToRequest = () => {
    if (!state?.sampleCount || state?.sampleCount.length <= 9) {
      const updateSamplesCount = state?.sampleCount
        ? [...state?.sampleCount, { ...KIZUNA_CATALOGUE_ITEM, id: _.random(1, 4, true) }]
        : [{ ...KIZUNA_CATALOGUE_ITEM, id: _.random(1, 4, true) }]

      actions.updateAction({
        sampleCount: updateSamplesCount
      })
    }

    handleShowInformationBox()
  }

  const handleSamplesData = (design) => {
    const samplesData = SAMPLES.map(({ designGroup, colors }) => {
      const isSameDesign =
        design === designGroup.toLowerCase() &&
        colors.map(({ color, reference, img }) => {
          const sample = {
            color,
            reference,
            img
          }

          return sample
        })

      return isSameDesign
    }).filter((value) => value)

    return samplesData[0]
  }

  return (
    <div id="designs" className="my-3">
      <SamplesDetail
        state={state}
        design={design}
        handleSamplesData={handleSamplesData}
        handleShowInformationBox={handleShowInformationBox}
        handleClickInAddCatalogueToRequest={handleClickInAddCatalogueToRequest}
      />
    </div>
  )
}

ProductsSamplesDetails.propTypes = {
  design: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleShowInformationBox: PropTypes.func
}

export default ProductsSamplesDetails
