import { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import LocationsListHeaderContainer from "components/StoreFinder/components/LocationsList/components/LocationsListHeader/container"
import classNames from "classnames/bind"
import DetailedStoreBox from "components/StoreFinder/components/LocationsList/components/DetailedStoreBox"
import { useQuery } from "@apollo/client"
import StoreFinderBoxLayout from "components/StoreFinder/components/StoreFinderBoxLayout"
import { FormattedMessage } from "react-intl"
import storesByPatternQuery from "components/StoreFinder/components/LocationsList/query"
import storesByInstanceQuery from "components/StoreFinder/components/SearchOnlyByLocation/query"
import TitleUnderlined from "components/TitleUnderlined"
import { getCurrentInstanceId } from "services/instances"
import "./styles/LocationsList.scss"

const LocationsList = ({
  patternId,
  cityId,
  nearbyStores,
  highlightedStoreId,
  onClickBack,
  onClickPrevious,
  setNearbyStores,
  setHighlightedStoreId
}) => {
  const [detailedStore, setDetailedStore] = useState(null)
  const query = !patternId ? storesByInstanceQuery : storesByPatternQuery
  const variables = !patternId
    ? { instanceId: getCurrentInstanceId() }
    : { patternIds: [patternId], instanceId: getCurrentInstanceId() }

  useQuery(query, {
    variables,
    onCompleted: (data) => {
      const { stores } = data
      let nearbyStores = stores.edges
      if (cityId) nearbyStores = _.filter(stores.edges, ({ node }) => node.city.id === cityId)
      setNearbyStores(nearbyStores)
    }
  })

  const scrollToCard = (storeId) => {
    const card = document.getElementById(storeId)
    const offsetTop = card.offsetTop
    document.getElementById("locations-list-store-cards").scrollTop = offsetTop - 60
  }

  const memoizedScrollToCard = useCallback(scrollToCard, [])

  useEffect(() => {
    if (highlightedStoreId) memoizedScrollToCard(highlightedStoreId)
  }, [highlightedStoreId, memoizedScrollToCard])

  const handleInfoIconClick = (event, store) => {
    event.stopPropagation()
    setDetailedStore(store)
  }

  const handleDetailedBoxCloseClick = () => {
    setDetailedStore(null)
  }

  const handleStoreCardClick = (store) => {
    setHighlightedStoreId(store.id)
  }

  return (
    <StoreFinderBoxLayout
      title={
        !patternId ? (
          <FormattedMessage
            id="SearchByLocation.title"
            defaultMessage="Distribuidores por ubicación"
          />
        ) : (
          <FormattedMessage
            id="LocationsList.title.product"
            defaultMessage="Distribuidores por producto"
          />
        )
      }
      icon={!patternId ? "store" : "fabric"}
      noPadding
      goBack={onClickPrevious}
    >
      <LocationsListHeaderContainer
        patternId={patternId}
        cityId={cityId}
        onClickBack={onClickBack}
      />
      <div className="locations-list-stores">
        <div id="locations-list-store-cards" className="locations-list-stores__cards">
          {nearbyStores && nearbyStores.length === 0 && (
            <div className="no-results d-flex align-items-center">
              <div className="p-3">
                <TitleUnderlined>
                  <FormattedMessage
                    id="LocationsList.noResultsTitle"
                    defaultMessage="Lo sentimos..."
                  />
                </TitleUnderlined>
                <p className="px-5 text-center">
                  <FormattedMessage
                    id="LocationsList.noResultsSubtitle"
                    defaultMessage="No hay distribuidores para la búsqueda actual"
                  />
                </p>
              </div>
            </div>
          )}
          {nearbyStores &&
            nearbyStores.map(({ node }, index) => (
              <div
                key={index}
                className={classNames({
                  "store-card": true,
                  "store-card--highlighted": node.id === highlightedStoreId
                })}
                id={node.id}
                onClick={() => handleStoreCardClick(node)}
              >
                <div className="row">
                  <div className="col-10">
                    <p className="mb-0">
                      <strong>{node.name}</strong>
                    </p>
                    <p className="mb-0 gray">
                      <small>
                        {node.address && (
                          <>
                            <span>
                              {node.address} {node.city.name}, {node.city.country.name}
                            </span>
                            <br />
                          </>
                        )}
                        {node.phoneNumbers && (
                          <>
                            <span>{node.phoneNumbers}</span>
                            <br />
                          </>
                        )}
                        {node.email && (
                          <>
                            <span>
                              <a
                                href={`mailto:${node.email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {node.email}
                              </a>
                            </span>
                            <br />
                          </>
                        )}
                      </small>
                    </p>
                  </div>
                  <div className="col-2 text-right">
                    <i
                      onClick={(event) => handleInfoIconClick(event, node)}
                      className="fa fa-info-circle fa-2x"
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
        {detailedStore && (
          <DetailedStoreBox store={detailedStore} onClose={handleDetailedBoxCloseClick} />
        )}
      </div>
    </StoreFinderBoxLayout>
  )
}

LocationsList.propTypes = {
  patternId: PropTypes.string,
  cityId: PropTypes.string,
  nearbyStores: PropTypes.array,
  highlightedStoreId: PropTypes.string,
  onClickBack: PropTypes.func,
  onClickPrevious: PropTypes.func,
  setNearbyStores: PropTypes.func,
  setHighlightedStoreId: PropTypes.func
}

export default LocationsList
