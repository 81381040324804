import * as yup from "yup"
import { VALIDATION_MESSAGES } from "utils/forms"

const DEFAULT_VALUES_BASIC = {
  firstName: "",
  email: "",
  phone: "",
  country: "",
  city: "",
  privacyPolicy: false
}

const VALIDATION_SCHEMA_BASIC = (intl) => ({
  firstName: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  email: yup.string().email().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  phone: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  country: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string()
    })
    .nullable()
    .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  city: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  privacyPolicy: yup.boolean().oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required))
})

const DEFAULT_VALUES_FULL = {
  lastName: "",
  companyName: "",
  zipCode: "",
  region: "",
  companyType: "",
  ...DEFAULT_VALUES_BASIC
}

const VALIDATION_SCHEMA_FULL = (intl) => ({
  lastName: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  companyName: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  region: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string()
    })
    .nullable()
    .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  address: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  companyType: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  zipCode: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  ...VALIDATION_SCHEMA_BASIC(intl)
})

export const DEFAULT_VALUES = (isBasic) => (!isBasic ? DEFAULT_VALUES_FULL : DEFAULT_VALUES_BASIC)
export const VALIDATION_SCHEMA = (intl, isBasic) =>
  yup.object(!isBasic ? VALIDATION_SCHEMA_FULL(intl) : VALIDATION_SCHEMA_BASIC(intl))

export const THEME = {
  default: {
    title: {},
    input: "",
    error: {}, // By default color prop in component is red
    privacyLabel: {},
    button: "btn-outline-dark"
  },
  light: {
    title: { color: "white" },
    input: "text-white",
    error: { color: "white" },
    privacyLabel: { color: "white" },
    button: "btn-outline-light"
  }
}
