/* eslint-disable */

import { useState, useEffect } from "react";
// import { useQuery } from "react-apollo";
import { useQuery } from "@apollo/client"
import { collectionsQueryByID } from "../queries";

const useGetCollectionByID = (slug) => {
  const { data, loading, error } = useQuery(collectionsQueryByID, {
    variables: { 
      slug: slug || ''
     },
  });

  const [collectionItems, setCollectionItems] = useState([]);

  useEffect(() => {
    if (data) {
      setCollectionItems(data.quickshipCollection || []);
    }
  }, [data]);

  return { collectionItems, loading, error };
};

export default useGetCollectionByID;
