import classNames from "classnames"
import PropTypes from "prop-types"
import { Collapse } from "reactstrap"
import IconAccordion from "./components/IconAccordion"
import "./styles/SpecAccordion.scss"

const SpecAccordion = ({
  id,
  name,
  togglable = false,
  acocordionContainerStyle,
  title,
  contentToShow,
  downloadIcon,
  onlyMobileStyle,
  handleOnClick
}) => (
  <div
    id={id}
    className={classNames({
      "accordion mt-1": true,
      [acocordionContainerStyle]: acocordionContainerStyle
    })}
  >
    <div
      className={classNames({
        "item-accordion": !onlyMobileStyle,
        "item-accordion-mobile": onlyMobileStyle && !togglable,
        "pe-5 pt-2": onlyMobileStyle && togglable
      })}
    >
      <p
        className={classNames({
          "button-accordion w-100 p-0 text-secondary cursor-pointer item-accordion-title": true,
          "fw-light": !togglable,
          "font-bold": togglable
        })}
        onClick={() => handleOnClick()}
      >
        {title}
        {(togglable || !togglable) && (
          <IconAccordion
            id={`icons_download_${id}`}
            isDownloadIcon={downloadIcon}
            isOpen={togglable}
            onlySpecs={onlyMobileStyle}
          />
        )}
      </p>
    </div>
    <Collapse isOpen={togglable}>{contentToShow}</Collapse>
  </div>
)

SpecAccordion.propTypes = {
  isOpenProp: PropTypes.bool,
  name: PropTypes.string,
  acocordionContainerStyle: PropTypes.string,
  title: PropTypes.string,
  contentToShow: PropTypes.element,
  downloadIcon: PropTypes.bool,
  onlyMobileStyle: PropTypes.bool,
  id: PropTypes.string,
  handleOnClick: PropTypes.func,
  togglable: PropTypes.bool
}

export default SpecAccordion
