import { FormattedMessage } from "react-intl"

export const SECTION_VIDEO_DESCRIPTION_MOBILE = (
  <FormattedMessage
    id="Masiala.sectionVideo.description.mobile"
    defaultMessage="For each roll of our collection, we avoid nearly <strong> 20KG of CO<sub>2</sub> to the planet, </strong> which is equivalent to 4 trees planted for carbon sequestration. This reduction is further complemented by the fact that our <strong>manufacturing facilities are certified as carbon neutral.</strong>"
    values={{
      br: <br />,
      strong: (...chunks) => (
        <strong className="section-video__description-relevant-wrd">{chunks}</strong>
      ),
      sub: (...chunks) => <sub>{chunks}</sub>
    }}
  />
)

export const SECTION_VIDEO_DESCRIPTION_DESKTOP = (
  <FormattedMessage
    id="Masiala.sectionVideo.description"
    defaultMessage="For each roll of our collection, we avoid nearly <strong> 20KG of CO<sub>2</sub> to the planet, </strong> which is equivalent to 4 trees planted for carbon sequestration. This reduction is further complemented by the fact that our <strong>manufacturing facilities are certified as carbon neutral.</strong>"
    values={{
      br: <br />,
      strong: (...chunks) => (
        <strong className="section-video__description-relevant-wrd">{chunks}</strong>
      ),
      sub: (...chunks) => <sub>{chunks}</sub>
    }}
  />
)
