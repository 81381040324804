import { defineMessages } from "react-intl"

export const MSGS = defineMessages({
  withoutRegion: {
    id: "Form.field.City.placeholder.withoutRegion",
    description: "placeholder no region selected for SelectCity",
    defaultMessage: "Select first a region"
  },
  withRegion: {
    id: "Form.field.City.placeholder.withRegion",
    description: "placeholder for autocomplete SelectCity",
    defaultMessage: "Type your city"
  },
  city: {
    id: "Form.field.City",
    description: "placeholder for autocomplete SelectCity",
    defaultMessage: "City"
  }
})
