import { FormattedMessage } from "react-intl"
import VideoCommon from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"
import poster from "./img/poster.jpg"
import "./styles/video.scss"

const Video = () => {
  const isMobile = useMediaQuery(SM)

  return (
    <section className="wrapper container g-xl-0 mt-lg-5 mb-lg-4">
      <div className="row g-xl-0 justify-content-center">
        <div className="col-12 col-lg-5 d-flex align-items-center order-1 order-lg-0">
          <h2 className="desc--vid-rv">
            <FormattedMessage
              id="Rivulet.VideoSection.description"
              defaultMessage="“The beauty is in the perfectly imperfect” "
            />
          </h2>
        </div>
        <div className="col-12 col-lg-7 order-0 order-lg-1 mb-4">
          <VideoCommon className="img-fluid" poster={poster} fromYoutube>
            <iframe
              className="video--deborah"
              title="video"
              width="100%"
              height={isMobile ? "200" : "400"}
              src="https://www.youtube.com/embed/f5wztrX7g_E?si=1vvKPaOb-7BlLdo9"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoCommon>
        </div>
      </div>
    </section>
  )
}

export default Video
