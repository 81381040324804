import classNames from "classnames/bind"
import { FEATURES_AND_BENEFITS_ELEMENTS } from "./constants"
import "./styles/features-and-benefits-elements.scss"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"

const FeaturesAndBenefitsElements = () => {
  const isMobile = useMediaQuery(LG)
  return (
    <div
      className={classNames({
        "mt-1": true,
        "d-flex features-benefits-elements": !isMobile,
        row: isMobile
      })}
    >
      {FEATURES_AND_BENEFITS_ELEMENTS.map(({ id, img, description }, i) => (
        <div
          key={id}
          className={classNames({
            "text-center": true,
            "me-1": i === 0 && !isMobile,
            "mx-1": i > 0 && !isMobile,
            "col-3": isMobile
          })}
        >
          <img className="feature-img--kizuna" src={img} alt={description} />
          <p className="description-feature">{description}</p>
        </div>
      ))}
    </div>
  )
}

export default FeaturesAndBenefitsElements
