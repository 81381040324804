import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Controller, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, Col, Row } from "reactstrap"
import { useMutation } from "@apollo/client"
import ErrorMessage from "components/ErrorMessage"
import ModalContext from "modules/rivulet/components/SamplingRequest/context"
import { getCurrentInstanceId, getCurrentLanguageAndInstanceCode } from "services/instances"
import { REQUEST_SAMPLES_INFO } from "modules/rivulet/components/RequestsSection/utils"
import createContactMutation from "components/ContactForm/mutation"
import PrivacyPolicyCheckbox from "components/PrivacyPolicyCheckbox"
import { THEME } from "components/ContactForm/constants"
import { GET_VALIDATION_SCHEMA } from "./constants/validationSchema"
import SelectCountry from "./components/SelectCountry"
import SelectCompanyType from "./components/SelectCompanyType"
import "../../styles/samplingrequest.scss"
import { COUNTRIES_FOR_USA } from "consts/countryCodes"
import { MSGS } from "components/select/SelectCity/constants"
import SelectRegion from "./components/SelectRegion"
import { REGIONS_BY_COUNTRY } from "./components/SelectRegion/constants"

const RequestForm = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const context = useContext(ModalContext)
  const [createContact] = useMutation(createContactMutation)
  const [lang, instance] = getCurrentLanguageAndInstanceCode()

  const initialValues = {
    firstName: "",
    lastName: "",
    companyName: "",
    companyType: "",
    country: "",
    region: "",
    city: "",
    address: "",
    zipCode: "",
    email: "",
    phone: "",
    type: context.type,
    privacyPolicy: false
  }

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(GET_VALIDATION_SCHEMA(intl, context))
  })

  const countryValue = useWatch({ control, name: "country" })
  const regionValue = useWatch({ control, name: "region" })

  const handlePayload = (values) => {
    const source = "landing rivulet"
    const {
      privacyPolicy,
      type,
      companyType: { value: companyTypeId },
      country,
      region: { value: regionId },
      city,
      ...rest
    } = values

    return {
      instanceId: getCurrentInstanceId(),
      regionId,
      cityLabel: city.toLowerCase(),
      companyType: companyTypeId,
      source,
      ...rest
    }
  }

  const onSubmit = (values) => {
    createContact({
      variables: {
        input: handlePayload(values)
      }
    })
      .then(() => {
        navigate(
          `/${lang}-${instance}/rivulet/${intl.formatMessage({
            id: "ContactForm.thankYou"
          })}`
        )
      })
      .catch((e) => {
        console.log("ERROR crete contact on CONTACTFORM: ", e)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="px-lg-5">
      <Row>
        <Col md={12}>
          <p className="text-center px-5 mb-4">{context.subtitle}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelName",
                defaultMessage: "Name"
              })}
              id="firstName"
              name="firstName"
              type="text"
              className="form-control rounded-0 mt-4 text-secondary"
              {...register("firstName")}
            />
            <ErrorMessage>{errors?.firstName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "Form.field.Lastname",
                defaultMessage: "Lastname"
              })}
              id="lastName"
              name="lastName"
              type="text"
              className="form-control rounded-0 mt-4 text-secondary"
              {...register("lastName")}
            />
            <ErrorMessage>{errors?.lastName?.message}</ErrorMessage>
          </div>
        </Col>
        {context === REQUEST_SAMPLES_INFO && (
          <Col md={12}>
            <div className="form-group">
              <input
                placeholder={intl.formatMessage({
                  id: "SamplingRequest.labelAddress",
                  defaultMessage: "Address"
                })}
                id="address"
                name="address"
                type="text"
                className="form-control rounded-0 mt-4 text-secondary"
                {...register("address")}
              />
              <ErrorMessage>{errors?.address?.message}</ErrorMessage>
            </div>
          </Col>
        )}
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelCompany",
                defaultMessage: "Company"
              })}
              id="companyName"
              name="companyName"
              type="text"
              className="form-control rounded-0 mt-4 text-secondary"
              {...register("companyName")}
            />
            <ErrorMessage>{errors?.companyName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group mt-4">
            <SelectCompanyType name="companyType" control={control} />
            <ErrorMessage>{errors?.companyType?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <SelectCountry
              labelClassName={THEME.default.input}
              name="country"
              control={control}
              options={COUNTRIES_FOR_USA}
              isSearchable
            />
            <ErrorMessage>{errors?.country?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <SelectRegion
              labelClassName={THEME.default.input}
              name="region"
              control={control}
              isSearchable={false}
              defaultOptions={REGIONS_BY_COUNTRY[countryValue?.value]}
            />
            <ErrorMessage>{errors?.region?.message}</ErrorMessage>
          </div>
        </Col>
        {context === REQUEST_SAMPLES_INFO && (
          <>
            <Col md={6}>
              <div className="form-group">
                <input
                  className="form-control rounded-0 mt-4 text-secondary"
                  placeholder={
                    !regionValue
                      ? intl.formatMessage(MSGS.withoutRegion)
                      : intl.formatMessage(MSGS.city)
                  }
                  id="city"
                  name="city"
                  type="text"
                  disabled={!regionValue}
                  {...register("city")}
                />
                <ErrorMessage>{errors?.city?.message}</ErrorMessage>
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <input
                  placeholder={intl.formatMessage({
                    id: "SamplingRequest.labelPostal",
                    defaultMessage: "Zip code"
                  })}
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  className="form-control rounded-0 mt-4 text-secondary"
                  {...register("zipCode")}
                />
                <ErrorMessage>{errors?.zipCode?.message}</ErrorMessage>
              </div>
            </Col>
          </>
        )}
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelEmail",
                defaultMessage: "Email"
              })}
              id="email"
              name="email"
              type="text"
              className="form-control rounded-0 mt-4 text-secondary"
              {...register("email")}
            />
            <ErrorMessage>{errors?.email?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelPhone",
                defaultMessage: "Phone"
              })}
              id="phone"
              name="phone"
              type="text"
              className="form-control rounded-0 mt-4 text-secondary"
              {...register("phone")}
            />
            <ErrorMessage>{errors?.phone?.message}</ErrorMessage>
          </div>
        </Col>
      </Row>

      <div className="form-group justify-content-between form-check d-lg-flex text-start mt-4 mb-4 footer-form-container">
        <div>
          <Controller
            name="privacyPolicy"
            control={control}
            render={({ field }) => <PrivacyPolicyCheckbox color="secondary" {...field} />}
          />
          <ErrorMessage>{errors?.privacyPolicy?.message}</ErrorMessage>
        </div>
        <div className="col-lg-6 text-end d-flex align-items-end justify-content-end py-4 py-lg-0">
          <Button
            className="btn btn-rv btn--desc-rv py-1 px-4"
            color="gray"
            type="submit"
            disabled={isSubmitting}
          >
            {!isSubmitting ? (
              <p className="m-0 p-0 text-white">
                <FormattedMessage id="SamplingRequest.btnLabelRequest" defaultMessage="ORDER NOW" />
              </p>
            ) : (
              <p className="m-0 p-0 text-white">
                <FormattedMessage
                  id="SamplingRequest.btnLabelLoading"
                  defaultMessage="Sending..."
                />
              </p>
            )}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default RequestForm
