/* eslint-disable */

import logoFooterSpradlingQuickSip from "./img/QuickshipLogoSpradlingFooter.svg"
import "./style/FooterQuickShip.scss"

export const FooterQuickShip = () => {
  return (
    <div className='footer--quick-ship d-flex justify-content-center align-items-center' >
      <img src={logoFooterSpradlingQuickSip} alt="logo footer Spradling Quickship" />
    </div>
  );
};
