const styles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    border: "2px solid #bdbdbd",
    fontSize: "20px",
    fontWeight: "lighter",
    fontFamily: "Gill Sans, sans-serif",
    marginTop: "1.5rem !important",
    color: "gray !important"
  })
}

export default styles
