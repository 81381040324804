import { MSGS, CHARACTERISTICS_IMGS } from "modules/pranna/components/ProductDetails/constants"

export const PRODUCT_DETAILS = [
  {
    id: "impermeabilidad",
    text: MSGS.impermeabilidad,
    icon: CHARACTERISTICS_IMGS.impermeabilidad
  },
  {
    id: "facilLimpieza",
    text: MSGS.facilLimpieza,
    icon: CHARACTERISTICS_IMGS.facilLimpieza
  },
  {
    id: "retardanciaFuego",
    text: MSGS.retardanciaFuego,
    icon: CHARACTERISTICS_IMGS.retardanciaFuego
  },
  {
    id: "microorganismos",
    text: MSGS.microorganismos,
    icon: CHARACTERISTICS_IMGS.microorganismos
  },
  {
    id: "durabilidadColor",
    text: MSGS.durabilidadColor,
    icon: CHARACTERISTICS_IMGS.durabilidadColor
  },
  {
    id: "abrasion",
    text: MSGS.abrasion,
    icon: CHARACTERISTICS_IMGS.abrasion
  }
]
