import { FormattedMessage } from "react-intl"

export const MODAL_TYPES = {
  REQUEST_SAMPLES: "REQUEST_SAMPLES"
}

const MODAL_TYPES_THANKS_URL = {
  REQUEST_SAMPLES: "request-samples"
}

export const REQUEST_SAMPLES_INFO = {
  id: MODAL_TYPES.REQUEST_SAMPLES,
  type: MODAL_TYPES.REQUEST_SAMPLES,
  thanksUrl: MODAL_TYPES_THANKS_URL.REQUEST_SAMPLES,
  title: <FormattedMessage id="ModalContext.requestTitle" defaultMessage="Request Samples" />,
  subtitle: (
    <FormattedMessage
      id="ModalContext.requestSubtitle"
      defaultMessage="request the samples of Rivulet"
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>
      }}
    />
  )
}

export const REQUEST_CATALOGUE_INFO = {
  id: MODAL_TYPES.REQUEST_CATALOGUE,
  type: MODAL_TYPES.REQUEST_CATALOGUE,
  thanksUrl: MODAL_TYPES_THANKS_URL.REQUEST_CATALOGUE,
  title: (
    <FormattedMessage id="ModalContext.downloadTitle" defaultMessage="Kizuna Catalogue Request" />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.downloadSubtitle"
      defaultMessage="Fill out the form below, and request your Kizuna Catalogue!"
    />
  )
}
