import Footer from "components/Footer"
import Header from "components/Header"
import Banner from "components/Banner"
import { GLOBAL_SITE_TAG, NAV_ITEMS } from "modules/pranna/constants"
import { SLIDE } from "modules/pranna/components/Banner/constants"
import ColorPalette from "modules/pranna/components/ColorPalette"
import Applications from "modules/pranna/components/Applications"
import ProductDetails from "modules/pranna/components/ProductDetails"
import Benefits from "modules/pranna/components/Benefits"
import Analytics from "components/Analytics"
import Video from "modules/pranna/components/Video"
import { getCurrentInstanceId } from "services/instances"

const PrannaLa = () => {
  const titles = NAV_ITEMS.map(item => item.title)
  return (
  <>
    <Analytics gtag={GLOBAL_SITE_TAG[getCurrentInstanceId()]} />
    <Header items={titles} isTransparent />
    <div className="content">
      <Banner slides={SLIDE} />
      <Video />
      <ColorPalette id={NAV_ITEMS[0].title} />
      <Applications id={NAV_ITEMS[1].title} />
      <ProductDetails id={NAV_ITEMS[2].title} />
      <Benefits />
    </div>
    <Footer />
  </>
  )
}

export default PrannaLa
