import gql from "graphql-tag"

export const singlePatternPublicPDFQuery = gql`
  query SinglePatternPublicPDFQuery($id: ID!) {
    pattern(id: $id) {
      id
      pdfUrl(fullVersion: false)
    }
  }
`

export const singlePatternPrivatePDFQuery = gql`
  query SinglePatternPrivatePDFQuery($id: ID!) {
    pattern(id: $id) {
      id
      pdfUrl(fullVersion: true)
    }
  }
`
