import { FormattedMessage } from "react-intl"
import banner from "modules/floors/components/Banner/img/banner-3.jpg"
import ContactForm from "components/ContactForm"
import { COUNTRY_COLOMBIA } from "consts/countryCodes"

export const SLIDE = [
  {
    id: "slide-floors",
    imageThumb: banner,
    title: (
      <FormattedMessage
        id="Floors.Banner.title"
        description="Floors title banner"
        defaultMessage="Lanzamiento pisos alto tráfico"
      />
    )
  }
]

export const SLIDE_EXTERNAL = [
  {
    ...SLIDE[0],
    form: (
      <ContactForm
        theme="light"
        styleContainer="container-fluid upholstery-glassmorphism my-3"
        styleFormContainer="p-4"
        title={
          <FormattedMessage
            id="Pranna.Contact.title"
            description="Floors section title contact"
            defaultMessage="¿ Quieres más información ?"
            values={{
              sup: (...chunks) => <sup>{chunks}</sup>
            }}
          />
        }
        countryOptions={COUNTRY_COLOMBIA}
        landingSource="pisos"
        isBasic
      />
    )
  }
]
