import { FormattedMessage } from "react-intl"
import {
  DO_AND_DO_NOT_ITEM_BOAT_SEATS,
  DO_AND_DO_NOT_ITEM_BIMI_TOPS_COVERS,
  DO_AND_DO_NOT_ITEM_WOVEN_FLOORING
} from "./doAndDoNots"
import {
  CLEAING_PROCEDURE_ITEM_BOAT_SEATS,
  CLEAING_PROCEDURE_ITEM_BIMI_TOPS_COVERS,
  CLEAING_PROCEDURE_ITEM_WOVEN_FLOORING
} from "./cleaningProcedure"
import {
  KEEP_IN_MIND_ITEM_BOAT_SEATS,
  KEEP_IN_MIND_ITEM_BIMI_TOPS_COVERS,
  KEEP_IN_MIND_ITEM_WOVEN_FLOORING
} from "./keepInMind"
import boats from "modules/cleaning-guide-marine/components/Layout/imgs/1-boats.jpg"
import bimi from "modules/cleaning-guide-marine/components/Layout/imgs/2-bimi.jpg"
import woven from "modules/cleaning-guide-marine/components/Layout/imgs/3-woven.jpg"
import banner from "modules/cleaning-guide-marine/components/Banner/img/banner.jpg"

export const BOAT_SEATS_KEY = "Boat Seats"
export const BIMI_TOPS_COVERS_KEY = "Bimni Tops & Covers"
export const WOVEN_FLOORING_KEY = "Woven Flooring"

export const FOOTER_INFO = <FormattedMessage id="CleaningMarine.footer.info" defaultMessage="Learn more about marine products" />

export const BOAT_SEATS = <FormattedMessage id="CleaningMarine.nav.boatSeats" defaultMessage="Boat Seats" />
export const BIMI_TOPS_COVERS = <FormattedMessage id="CleaningMarine.nav.binmiTops" defaultMessage="Bimni Tops & Covers" />
export const WOVEN_FLOORING = <FormattedMessage id="CleaningMarine.nav.woven" defaultMessage="Woven Flooring" />

export const NAV_ITEMS = [
  {
    headerTitle: BOAT_SEATS,
    key: BOAT_SEATS_KEY
  },
  {
    headerTitle: BIMI_TOPS_COVERS,
    key: BIMI_TOPS_COVERS_KEY
  },
  {
    headerTitle: WOVEN_FLOORING,
    key: WOVEN_FLOORING_KEY
  }]

export const BANNER_INFO = {
  id: "home-slide",
  imageThumb: banner,
  title: (
    <FormattedMessage
      id="CleaningMarine.General.Banner.title"
      defaultMessage="General Cleaning Guide"
    />
  ),
  description: (
    <FormattedMessage
      id="CleaningMarine.General.Banner.description"
      defaultMessage="By following the recommendations in this guide, you’ll{br}keep all your coated fabrics looking good, preventing
      premature wear and tear."
      values={{
        br: <br />
      }}
    />
  )
}

const BOAT_SEATS_INFO = {
  id: BOAT_SEATS_KEY,
  titleDesktop: BOAT_SEATS,
  titleMobile: BOAT_SEATS,
  img: boats,
  doAndDoNots: DO_AND_DO_NOT_ITEM_BOAT_SEATS,
  cleaningProcedures: CLEAING_PROCEDURE_ITEM_BOAT_SEATS,
  keepInMind: KEEP_IN_MIND_ITEM_BOAT_SEATS
}
export const BIMI_TOPS_COVERS_INFO = {
  id: BIMI_TOPS_COVERS_KEY,
  titleDesktop: BIMI_TOPS_COVERS,
  titleMobile: BIMI_TOPS_COVERS,
  img: bimi,
  doAndDoNots: DO_AND_DO_NOT_ITEM_BIMI_TOPS_COVERS,
  cleaningProcedures: CLEAING_PROCEDURE_ITEM_BIMI_TOPS_COVERS,
  keepInMind: KEEP_IN_MIND_ITEM_BIMI_TOPS_COVERS
}
export const WOVEN_FLOORING_INFO = {
  id: WOVEN_FLOORING_KEY,
  titleDesktop: WOVEN_FLOORING,
  titleMobile: WOVEN_FLOORING,
  img: woven,
  doAndDoNots: DO_AND_DO_NOT_ITEM_WOVEN_FLOORING,
  cleaningProcedures: CLEAING_PROCEDURE_ITEM_WOVEN_FLOORING,
  keepInMind: KEEP_IN_MIND_ITEM_WOVEN_FLOORING
}

export const GENERAL_INFO_SECTIONS = {
  [BOAT_SEATS_KEY]: BOAT_SEATS_INFO,
  [BIMI_TOPS_COVERS_KEY]: BIMI_TOPS_COVERS_INFO,
  [WOVEN_FLOORING_KEY]: WOVEN_FLOORING_INFO
}
