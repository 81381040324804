import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import Skeleton from "react-loading-skeleton"
import "./styles/card.scss"

const Card = ({ report, loading }) => (
  <div className="col-12 col-md-4 text-center">
    <div className="my-2">
      {!loading ? (
        <img className="img-fluid" src={report.imageThumbMedium} alt="ecosense-report" />
      ) : (
        <Skeleton height={250} />
      )}
    </div>
    <h2 className="label-year mb-2">
      {!loading ? new Date(report.date).getFullYear() : <Skeleton width={100} height={16} />}
    </h2>
    {!loading ? (
      <a className="btn btn-outline-dark" href={report.pdf} target="_blank" rel="noreferrer">
        <FormattedMessage
          id="Button.readMore"
          description="read more button"
          defaultMessage="Leer más"
        />
      </a>
    ) : (
      <Skeleton width={100} height={40} />
    )}
  </div>
)

Card.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string,
    imageThumbMedium: PropTypes.string,
    pdf: PropTypes.string,
    date: PropTypes.string
  }),
  loading: PropTypes.bool
}

export default Card
