/* eslint-disable */
import { useContext, useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, Col, Row } from "reactstrap"
import SelectCountry from "./components/SelectCountry"
import SelectRegion from "./components/SelectRegion"
import { useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import ErrorMessage from "components/ErrorMessage"
import { getCurrentInstanceId } from "services/instances"
import { VALIDATION_MESSAGES } from "utils/forms"
import { REGIONS_BY_COUNTRY } from "./components/SelectRegion/constants"
import ColorsSelect from "./components/ColorsSelect"
import { COUNTRIES_FOR_USA } from "consts/countryCodes"
import SelectCompanyType from "./components/SelectCompanyType"
import stylesMasialaInput from "./components/styles"
import CollectionContext from "../../context/CollectionContext"
import useStoreAnonimalOrder from "modules/quickship/hooks/useMutationRequestFormQuickshipPage"
import "./style/styleRequestFormQuickShipPage.scss"
import ModalPrivacyPolicy from "modules/masiala/components/SamplingRequest/components/RequestForm/components/ModalPrivacyPolicy"
import { handleSamplesOrder } from "modules/kizuna/components/SamplingRequest/components/RequestForm/utils"

const COLOR_SCHEMA = Yup.object().shape({
  label: Yup.string().required(),
  value: Yup.string().required(),
  data: Yup.object().shape({
    name: Yup.string(),
    stock: Yup.string(),
    quantity: Yup.string()
  })
})

const RequestFormQuickShipPage = ({ handleCallbackToShowThankYouMessage, patternInfo }) => {
  const intl = useIntl()
  const [modalPrivacyPolicy, setModalPrivacyPolicy] = useState(false)

  const [checked, setChecked] = useState(false)

  const { itemsInFormRequest, setItemsInFormRequest } = useContext(CollectionContext)

  const { executeStoreAnonimalOrder, data: mutationData, loading, error } = useStoreAnonimalOrder()

  const handleModalPrivacyPolicy = () => {
    setModalPrivacyPolicy(!modalPrivacyPolicy)
  }

  const initialValues = {
    requestedSamples: itemsInFormRequest || [],
    firstName: "",
    lastName: "",
    companyName: "",
    companyType: "",
    country: "",
    region: "",
    address: "",
    zipCode: "",
    city: "",
    email: "",
    phone: "",
    privacyPolicy: false
  }

  const validationSchema = Yup.object({
    requestedSamples: Yup.array()
      .of(COLOR_SCHEMA)
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    firstName: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    lastName: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    companyName: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    companyType: Yup.object().nullable().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    email: Yup.string().email().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    phone: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    country: Yup.object().nullable().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    region: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string()
      })
      .nullable()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    address: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    zipCode: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    city: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    privacyPolicy: Yup.bool().oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    reset
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  })

  const countryValue = useWatch({ control, name: "country" })
  const regionValue = useWatch({ control, name: "region" })

  const handlePostSubmit = () => {
    setChecked(false)
    setItemsInFormRequest([])
    reset()
  }

  const onSubmit = async (values) => {
    const formattedOrder = handleSamplesOrder({
      colours: values?.requestedSamples,
      patternBase: "QUICKSHIP"
    })

    try {
      const objMutation = {
        name: values?.firstName,
        surname: values?.lastName,
        company: values?.companyName,
        companyType: values?.companyType?.value,
        country: values?.country?.value,
        region: values?.region.value,
        address: values?.address,
        postalCode: values?.zipCode,
        city: values?.city?.toLowerCase(),
        email: values?.email,
        phone: values?.phone,
        source: "landing quickship",
        instance: getCurrentInstanceId(),
        acceptTerms: values?.privacyPolicy,
        anonimalSamples: formattedOrder
      }

      try {
        const success = await executeStoreAnonimalOrder(objMutation)

        if (success) {
          handleCallbackToShowThankYouMessage()
        } else {
          alert("There was an issue with placing your order.")
        }
      } catch (error) {
        console.error("Error executing the mutation:", error)
        alert("An error occurred while placing your order.")
      }

      handlePostSubmit()
    } catch (e) {
      handlePostSubmit()
    }
  }

  return (
    <form
      id="request-samples-info"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="px-lg-0"
    >
      <Row>
        {/* <Col md={6}> */}
        <p className="mb-1">
          <FormattedMessage id="SamplingRequest.subtitle" defaultMessage="Requested samples" />
        </p>
        {/* </Col> */}
      </Row>
      <Row>
        <Col md={12}>
          <div className="form-group">
            <ColorsSelect
              id="requestedSamples"
              name="requestedSamples"
              control={control}
              setValue={setValue}
            />
            <ErrorMessage weight="normal">{errors?.requestedSamples?.message}</ErrorMessage>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <label className="label-inpunts-form-quickship" htmlFor="firstName">
            <FormattedMessage id="SamplingRequest.subtitle" defaultMessage="Name*" />
          </label>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelNameQuickship",
                defaultMessage: "Name"
              })}
              id="firstName"
              name="firstName"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("firstName")}
            />
            <ErrorMessage weight="normal">{errors?.firstName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <label className="label-inpunts-form-quickship" htmlFor="lastName">
            <FormattedMessage id="SamplingRequest.label" defaultMessage="Last Name*" />
          </label>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "Form.field.Lastname-quickship",
                defaultMessage: "Last Name"
              })}
              id="lastName"
              name="lastName"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("lastName")}
            />
            <ErrorMessage weight="normal">{errors?.lastName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <label className="label-inpunts-form-quickship" htmlFor="companyName">
            <FormattedMessage id="SamplingRequest.subtitle" defaultMessage="Company*" />
          </label>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelCompany",
                defaultMessage: "Company"
              })}
              id="companyName"
              name="companyName"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("companyName")}
            />
            <ErrorMessage weight="normal">{errors?.companyName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <label className="label-inpunts-form-quickship" htmlFor="companyType">
            <FormattedMessage
              id="SamplingRequest.subtitle"
              defaultMessage="Select a company type*"
            />
          </label>
          <div className="form-group mt-0">
            <SelectCompanyType
              name="companyType"
              control={control}
              customStyles={stylesMasialaInput}
            />
            <ErrorMessage weight="normal">{errors?.companyType?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <label className="label-inpunts-form-quickship" htmlFor="country">
            <FormattedMessage id="SamplingRequest.subtitle" defaultMessage="Select a country*" />
          </label>
          <div className="form-group">
            <SelectCountry
              labelClassName={""}
              name="country"
              control={control}
              options={COUNTRIES_FOR_USA}
              isSearchable
            />
            <ErrorMessage weight="normal">{errors?.country?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <label className="label-inpunts-form-quickship" htmlFor="region">
            <FormattedMessage id="SamplingRequest.subtitle" defaultMessage="Region*" />
          </label>
          <div className="form-group">
            <SelectRegion
              labelClassName={""}
              name="region"
              control={control}
              isSearchable={false}
              defaultValue={REGIONS_BY_COUNTRY[countryValue?.value] || []}
            />
            <ErrorMessage weight="normal">{errors?.region?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={12}>
          <label className="label-inpunts-form-quickship" htmlFor="address">
            <FormattedMessage id="SamplingRequest.subtitle" defaultMessage="Address*" />
          </label>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelStreet",
                defaultMessage: "Address"
              })}
              id="address"
              name="address"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("address")}
            />
            <ErrorMessage weight="normal">{errors?.address?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <label className="label-inpunts-form-quickship" htmlFor="zipCode">
            <FormattedMessage id="SamplingRequest.subtitle" defaultMessage="ZIP code*" />
          </label>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "Form.field.zipCode",
                defaultMessage: "ZIP Code"
              })}
              id="zipCode"
              name="zipCode"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("zipCode")}
            />
            <ErrorMessage weight="normal">{errors?.zipCode?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <label className="label-inpunts-form-quickship" htmlFor="city">
            <FormattedMessage id="SamplingRequest.subtitle" defaultMessage="City*" />
          </label>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "Form.field.City",
                defaultMessage: "City"
              })}
              id="city"
              name="city"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("city")}
            />
            <ErrorMessage weight="normal">{errors?.city?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <label className="label-inpunts-form-quickship" htmlFor="email">
            <FormattedMessage id="SamplingRequest.subtitle" defaultMessage="Email*" />
          </label>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "Form.field.Email",
                defaultMessage: "Email"
              })}
              id="email"
              name="email"
              type="email"
              className="form-control rounded-0 mt-2"
              {...register("email")}
            />
            <ErrorMessage weight="normal">{errors?.email?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <label className="label-inpunts-form-quickship" htmlFor="phone">
              <FormattedMessage id="SamplingRequest.label" defaultMessage="Phone*" />
            </label>
            <input
              placeholder={intl.formatMessage({
                id: "Form.field.Phone",
                defaultMessage: "Phone"
              })}
              id="phone"
              name="phone"
              type="text"
              className="form-control rounded-0 mt-2"
              {...register("phone")}
            />
            <ErrorMessage weight="normal">{errors?.phone?.message}</ErrorMessage>
          </div>
        </Col>
      </Row>

      <div
        className="form-group form-check mb-0 footer-form-container text-center"
        onClick={handleModalPrivacyPolicy}
      >
        <div className="d-flex justify-content-center align-items-baseline">
          <input
            type="checkbox"
            className="form-check-input rounded-0"
            id="privacyPolicy"
            {...register("privacyPolicy")}
            checked={checked}
            // onChange={() => setChecked(!checked)}
          />
          <label className="form-check-label fw-light text-secondary" htmlFor="privacyPolicy">
            <FormattedMessage
              id="SamplingRequest.privacyPolicy"
              defaultMessage="I've read and agree the <strong>Privacy Policy</strong>"
              values={{
                strong: (...chunks) => <strong style={{ cursor: "pointer" }}>{chunks}</strong>
              }}
            />
          </label>
        </div>
        <ErrorMessage weight="normal">{errors?.privacyPolicy?.message}</ErrorMessage>
      </div>

      {/* Renderizar la modal */}
      {modalPrivacyPolicy && (
        <ModalPrivacyPolicy
          isOpen={modalPrivacyPolicy}
          toggle={handleModalPrivacyPolicy} // Alterna la visibilidad de la modal
          acceptPrivacyPolicy={setChecked} // Actualiza el estado de `checked` si es necesario dentro de la modal
        />
      )}

      <div className="text-center mt-3 py-lg-0">
        <Button
          type="submit"
          className="text-white px-5 button-form-masiala pb-2 btn btn-secondary"
          color="primary"
          disabled={isSubmitting}
        >
          {intl.formatMessage({
            id: "SubmitButton.send",
            defaultMessage: "Order Now"
          })}
        </Button>
      </div>
    </form>
  )
}

RequestFormQuickShipPage.propTypes = {
  handleCallbackToShowThankYouMessage: PropTypes.func.isRequired,
  patternInfo: PropTypes.object
}

export default RequestFormQuickShipPage
