/* eslint-disable */
import PropTypes from "prop-types"
import Slider from "react-slick"
import "./styles/sample-list-mobile.scss"
import SampleCard from "../SampleCard"

const SLIDER_OPTIONS = {
  arrows: false,
  dots: true,
  infinite: false,
  lazyLoad: true,
  adaptiveHeight: true,
  rows: 2,
  slidesPerRow: 1,
  slidesToShow: 2,
  slidesToScroll: 2,
}

const SamplesListMobile = ({ samples }) => {
  return (
    <>
      {
      samples.length > 0 &&
      <Slider {...SLIDER_OPTIONS} className="my-4 slider-cards--masiala">
      {
        samples?.map(({ node }) => (
          node.product.id ?
          <SampleCard
            key={`item_${node?.product?.id}`}
            sample={node}
          /> : <></>
        ))
      }
  </Slider>
      }
    </>
  )
}

SamplesListMobile.propTypes = {
  samples: PropTypes.array,
}

export default SamplesListMobile
