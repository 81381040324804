import gql from "graphql-tag"

const createContactMutation = gql`
  mutation createContactHubspot($input: CreateContactHubSpotInput!) {
    createContactHubspot(input: $input) {
      contact {
        id
      }
    }
  }
`

export default createContactMutation
