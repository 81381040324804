import PropTypes from "prop-types"
import { GoogleMap, Marker } from "@react-google-maps/api"
import markerDefaultImage from "assets/img/marker-default.svg"
import markerActiveImage from "assets/img/marker-active.svg"
import _ from "lodash"
import { useRef } from "react"

const Map = ({ center, zoom, nearbyStores, highlightedStoreId, setHighlightedStoreId }) => {
  const mapRef = useRef()

  const handleLoad = (map) => {
    mapRef.current = map
  }

  const getLatLng = (latitude, longitude) => new window.google.maps.LatLng(latitude, longitude)

  /**
   * Fits bounds when locations are updated
   */
  const fitGoogleMapZoomAndCenter = (map) => {
    const bounds = new window.google.maps.LatLngBounds()

    let loc = null
    for (let i = 0; i < nearbyStores.length; i++) {
      loc = getLatLng(nearbyStores[i].node.latitude, nearbyStores[i].node.longitude)
      bounds.extend(loc)
    }

    if (mapRef.current) {
      mapRef.current.fitBounds(bounds)
      mapRef.current.panToBounds(bounds)
    }
  }

  const handleMarkerClick = (storeId) => {
    setHighlightedStoreId(storeId)
  }

  if (nearbyStores !== null && nearbyStores.length > 0) fitGoogleMapZoomAndCenter()

  if (highlightedStoreId) {
    const store = _.find(nearbyStores, { node: { id: highlightedStoreId } })
    if (store) mapRef.current.panTo(getLatLng(store.node.latitude, store.node.longitude))
  }

  return (
    <GoogleMap
      onLoad={handleLoad}
      mapContainerClassName="locations-map"
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false
      }}
      center={center}
      zoom={nearbyStores?.length === 1 ? 18 : zoom}
      defaultZoom={zoom}
    >
      {nearbyStores &&
        nearbyStores.map(({ node }) => (
          <Marker
            key={node.id}
            icon={node.id === highlightedStoreId ? markerActiveImage : markerDefaultImage}
            position={{
              lat: node.latitude,
              lng: node.longitude
            }}
            onClick={() => handleMarkerClick(node.id)}
            zIndex={node.id === highlightedStoreId ? 100 : 0}
          />
        ))}
    </GoogleMap>
  )
}

Map.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }),
  zoom: PropTypes.number,
  nearbyStores: PropTypes.array,
  highlightedStoreId: PropTypes.string,
  setHighlightedStoreId: PropTypes.func
}

export default Map
