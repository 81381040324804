import * as yup from "yup"
import { defineMessages } from "react-intl"
import { VALIDATION_MESSAGES } from "utils/forms"

export const PLEATS_FOR_TRANSFORMED_INSTALLED = (intl) => ({
  materialIsMarked: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  materialIsCoated: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  foldsInMaterial: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
})

export const PLEATS_FOR_ORIGINAL = (intl) => ({
  marksOnDelivery: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  whereMarksAre: yup
    .array(
      yup.object().shape({
        id: yup.string(),
        label: yup.string(),
        value: yup.string()
      })
    )
    .test(
      "test_whereMarksAre_multiSelect",
      intl.formatMessage(VALIDATION_MESSAGES.required),
      (val) => {
        return val?.length
      }
    ),
  describeWhereMarksAre: yup
    .string()
    .when(
      "whereMarksAre",
      (whereMarksAre, schema) =>
        whereMarksAre?.some(
          ({ value }) =>
            value ===
            WHERE_MARKS_ARE_OPTIONS[WHERE_MARKS_ARE_OPTIONS.length - 1].value
        )
          ? schema.required(intl.formatMessage(VALIDATION_MESSAGES.required))
          : schema
    )
})

export const PLEATS_MSGS = defineMessages({
  materialIsMarked: {
    id: "Claims.Pleats.materialIsMarked",
    description: "option material is marked state on pleats",
    defaultMessage: "Is the material on the original roll also marked?"
  },
  materialIsCoated: {
    id: "Claims.Pleats.materialIsCoated",
    description: "option material is coated state on pleats",
    defaultMessage: "Is the material foam-backed / laminated?"
  },
  foldsInMaterial: {
    id: "Claims.Pleats.foldsInMaterial",
    description: "option folds in material on pleats",
    defaultMessage:
      "Please indicate where the pleats / marks are on the transformed / installed material?"
  },
  marksOnDelivery: {
    id: "Claims.Pleats.marksOnDelivery",
    description: "option marks on delivery on pleats",
    defaultMessage: "Does the material already show these marks when delivered?"
  },
  whereMarksAre: {
    id: "Claims.Pleats.whereMarksAre",
    description: "option marks on delivery on pleats",
    defaultMessage: "Where are the marks situated on the roll? Several answers are possible"
  },
  describeWhereMarksAre: {
    id: "Claims.Pleats.describeWhereMarksAre",
    description: "describe where marks are on delivery on pleats",
    defaultMessage: "Please describe"
  }
})

const WHERE_MARKS_ARE_OPTIONS_MSGS = defineMessages({
  lenght: {
    id: "Claims.Pleats.whereMarksAre.options.lenght",
    description: "option lenght on where are marks on pleats",
    defaultMessage: "Lenght (vertical)"
  },
  width: {
    id: "Claims.Pleats.whereMarksAre.options.width",
    description: "option width on where are marks on pleats",
    defaultMessage: "Width (horizontal)"
  },
  beginningOfTheRoll: {
    id: "Claims.Pleats.whereMarksAre.options.beginningOfTheRoll",
    description: "option beginning of the roll on where are marks on pleats",
    defaultMessage: "Beginning of the roll(s)"
  },
  endOfTheRoll: {
    id: "Claims.Pleats.whereMarksAre.options.endOfTheRoll",
    description: "option end of the roll on where are marks on pleats",
    defaultMessage: "End of the roll(s) (against the core tube)"
  },
  allOverTheRoll: {
    id: "Claims.Pleats.whereMarksAre.options.allOverTheRoll",
    description: "option all over the roll on where are marks on pleats",
    defaultMessage: "All over the roll(s)"
  },
  other: {
    id: "Claims.options.other",
    description: "option other on claim",
    defaultMessage: "Other"
  }
})

export const WHERE_MARKS_ARE_OPTIONS = [
  {
    id: "lenght",
    label: WHERE_MARKS_ARE_OPTIONS_MSGS.lenght,
    value: "lenght (vertical)"
  },
  {
    id: "width",
    label: WHERE_MARKS_ARE_OPTIONS_MSGS.width,
    value: "width (horizontal)"
  },
  {
    id: "beginningOfTheRoll",
    label: WHERE_MARKS_ARE_OPTIONS_MSGS.beginningOfTheRoll,
    value: "begginning of the roll(s)"
  },
  {
    id: "endOfTheRoll",
    label: WHERE_MARKS_ARE_OPTIONS_MSGS.endOfTheRoll,
    value: "end of the roll(s) (against the core tube)"
  },
  {
    id: "allOverTheRoll",
    label: WHERE_MARKS_ARE_OPTIONS_MSGS.allOverTheRoll,
    value: "all over the roll(s)"
  },
  {
    id: "other",
    label: WHERE_MARKS_ARE_OPTIONS_MSGS.other,
    value: "other"
  }
]
