import icon1 from "../img/icon_4.svg"
import icon2 from "../img/icon_2.svg"
import icon3 from "../img/icon_3.svg"
import icon4 from "../img/icon_1.svg"
import icon5 from "../img/icon_5.svg"
import icon6 from "../img/icon_6.svg"
import { FormattedMessage } from "react-intl"

export const CHARACTERISTICS_ICONS = [
  {
    key: "icon_1",
    icon: icon1
  },
  {
    key: "icon_2",
    icon: icon2
  },
  {
    key: "icon_3",
    icon: icon3
  },
  {
    key: "icon_4",
    icon: icon4
  },
  {
    key: "icon_5",
    icon: icon5
  },
  {
    key: "icon_6",
    icon: icon6
  }
]

export const CHARACTERISTICS_SECTIONS = (isMobile) => {
  const CHARACTERISTICS_SECTIONS_OBJ = {
    [CHARACTERISTICS_ICONS[0].key]: {
      id: "icon_1",
      title: "Luxurious soft touch",
      description: "Indulge in the comfort of Saxony’s soft touch, enhancing the overall user experience.",
      icon: icon1
    },
    [CHARACTERISTICS_ICONS[1].key]: {
      id: "icon_2",
      title: "Tailored to perfection",
      description: "Enjoy tailorability that makes working with Saxony a breeze, ensuring a seamless fit for any environment.",
      icon: icon2
    },
    [CHARACTERISTICS_ICONS[2].key]: {
      id: "icon_3",
      title: "Versatile color options",
      description: "Saxony offers a practical variety of colors, allowing you to customize your space with ease.",
      icon: icon3
    },
    [CHARACTERISTICS_ICONS[3].key]: {
      id: "icon_4",
      title: "Effortless maintenance",
      description: (
        <FormattedMessage
          id="SaxonyCharacteristics.effortlessMaintenanceDesc"
          defaultMessage="Experience easy cleaning that simplifies the task of keeping spaces impeccable."
        />
      ),
      icon: icon4
    },
    [CHARACTERISTICS_ICONS[4].key]: {
      id: "icon_5",
      title: "High stretch fabric",
      description: "Benefit from the flexibility of high-stretch fabric, providing both comfort and durability.",
      icon: icon5
    },
    [CHARACTERISTICS_ICONS[5].key]: {
      id: "icon_6",
      title: "Permablok Plus Technology",
      description: " Saxony is equipped with Permablok Plus, guaranteeing high durability and superior quality for long-lasting use.",
      icon: icon6
    }
  }

  return CHARACTERISTICS_SECTIONS_OBJ
}
