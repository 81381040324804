import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { useQuery } from "@apollo/client"
import singleStoreQuery from "./query"
import BusinessHours from "components/StoreFinder/components/LocationsList/components/BusinessHours"
import LoadingMessage from "components/LoadingMessage"
import "./styles/DetailedStoreBox.scss"

const DetailedStoreBox = ({ store, onClose }) => {
  const {
    data: { store: storeData },
    loading,
    error
  } = useQuery(singleStoreQuery, {
    variables: {
      id: store.id
    }
  })

  const getSectorsByMarkets = () => {
    const { store } = storeData
    let sectorsByMarkets = []

    if (store) {
      const { sectors } = store
      sectorsByMarkets = sectors.edges
        .map((sector) => ({
          market: sector.node.market.name,
          sector: sector.node
        }))
        .reduce((r, a) => {
          r[a.market] = r[a.market] || []
          r[a.market].push(a)
          return r
        }, Object.create(null))
    }

    return sectorsByMarkets
  }

  return (
    <div className="detailed-store-box">
      <div className="mb-4">
        <button type="button" onClick={onClose} className="btn btn-outline-dark">
          <i className="fa fa-chevron-left" />
          &nbsp;
          <FormattedMessage
            id="DetailedStoreBox.goBack"
            description="go back button for the store details in the store map"
            defaultMessage="Volver"
          />
        </button>
      </div>
      <div className="store-details mb-4">
        <h5>{store.name}</h5>
        <p className="gray">
          {store.address}
          <br />
          {store.phoneNumbers}
          <br />
          {store.website && (
            <>
              <span>
                <a href={store.website} target="_blank" rel="noopener noreferrer">
                  {store.website}
                </a>
              </span>
            </>
          )}
        </p>
      </div>
      {loading && (
        <div className="text-center">
          <FormattedMessage
            id="DetailedStoreBox.loading"
            description="Loading message for DetailedStoreBox component"
            defaultMessage="Cargando información..."
          />
        </div>
      )}
      {storeData && (
        <>
          <BusinessHours
            businessHours={store.businessHours.edges.map((businessHour) => businessHour.node)}
          />
          <div className="store-sectors">
            {Object.keys(
              getSectorsByMarkets().map((key, index) => (
                <div key={index} className="store-sectors--market">
                  <h4>{key}</h4>
                  <hr />
                  <p>
                    {getSectorsByMarkets()[key].map((sector, index) => (
                      <span key={index}>
                        {sector.sector.name}
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
              ))
            )}
          </div>
        </>
      )}
      {error && <LoadingMessage />}
    </div>
  )
}

DetailedStoreBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  store: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    phoneNumbers: PropTypes.string,
    website: PropTypes.string,
    sectors: PropTypes.shape({
      edges: PropTypes.array
    }),
    businessHours: PropTypes.shape({
      edges: PropTypes.array
    })
  })
}

export default DetailedStoreBox
