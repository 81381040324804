import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons"
import "./styles/counter.scss"
import classNames from "classnames"

const Counter = ({ count, increment, decrement }) => (
  <div className="input-counter">
    <div className="d-flex justify-content-center w-100">
      <span
        className={classNames({
          count: true,
          "count-disabled": count === 9
        })}
      >
        {count}
      </span>
    </div>
    <div
      className="d-flex justify-content-end"
      style={{
        position: "relative"
      }}
    >
      <button
        onClick={() => decrement()}
        className="counter-btn"
        style={{ position: "absolute", top: 0 }}
      >
        <FontAwesomeIcon icon={faAngleDown} color="gray" fixedWidth size="md" />
      </button>
      <button
        onClick={() => increment()}
        className="counter-btn"
        style={{ position: "absolute", bottom: 0 }}
        disabled={count === 9}
      >
        <FontAwesomeIcon icon={faAngleUp} color="gray" fixedWidth size="md" />
      </button>
    </div>
  </div>
)

Counter.propTypes = {
  count: PropTypes.number,
  increment: PropTypes.func,
  decrement: PropTypes.func
}

export default Counter
