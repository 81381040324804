import { useState } from "react"

const useOrderBox = () => {
  const [showInformationBox, setShowInformationBox] = useState(false)

  const handleShowInformationBox = () => {
    setShowInformationBox(true)
    setTimeout(() => {
      setShowInformationBox(false)
    }, 2000)
  }

  return { showInformationBox, setShowInformationBox, handleShowInformationBox }
}

export default useOrderBox
