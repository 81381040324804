import { defineMessages } from "react-intl"

export const MSGS = defineMessages({
  withoutCountry: {
    id: "Form.field.Region.placeholder.withoutCountry",
    description: "placeholder no country selected for SelectRegion",
    defaultMessage: "Select first a country"
  },
  withCountry: {
    id: "Form.field.Region.placeholder.withCountry",
    description: "placeholder for autocomplete SelectRegion",
    defaultMessage: "Type your state"
  }
})

const REGIONS_BY_USA = [
  {
    label: "Alabama",
    value: "UmVnaW9uTm9kZTozNzEy"
  },
  {
    label: "Alaska",
    value: "UmVnaW9uTm9kZTozNzQy"
  },
  {
    label: "Arizona",
    value: "UmVnaW9uTm9kZTozNzMz"
  },
  {
    label: "Arkansas",
    value: "UmVnaW9uTm9kZTozNjk2"
  },
  {
    label: "California",
    value: "UmVnaW9uTm9kZTozNzI4"
  },
  {
    label: "Colorado",
    value: "UmVnaW9uTm9kZTozNzI5"
  },
  {
    label: "Connecticut",
    value: "UmVnaW9uTm9kZTozNzEz"
  },
  {
    label: "Delaware",
    value: "UmVnaW9uTm9kZTozNjk4"
  },
  {
    label: "Florida",
    value: "UmVnaW9uTm9kZTozNjk5"
  },
  {
    label: "Georgia",
    value: "UmVnaW9uTm9kZTozNzAw"
  },
  {
    label: "Hawaii",
    value: "UmVnaW9uTm9kZTozNzQx"
  },
  {
    label: "Idaho",
    value: "UmVnaW9uTm9kZTozNzM0"
  },
  {
    label: "Illinois",
    value: "UmVnaW9uTm9kZTozNzE1"
  },
  {
    label: "Indiana",
    value: "UmVnaW9uTm9kZTozNzE2"
  },
  {
    label: "Iowa",
    value: "UmVnaW9uTm9kZTozNzE0"
  },
  {
    label: "Kansas",
    value: "UmVnaW9uTm9kZTozNzAx"
  },
  {
    label: "Kentucky",
    value: "UmVnaW9uTm9kZTozNzQz"
  },
  {
    label: "Louisiana",
    value: "UmVnaW9uTm9kZTozNzAy"
  },
  {
    label: "Maine",
    value: "UmVnaW9uTm9kZTozNzE3"
  },
  {
    label: "Maryland",
    value: "UmVnaW9uTm9kZTozNzAz"
  },
  {
    label: "Massachusetts",
    value: "UmVnaW9uTm9kZTozNzQ0"
  },
  {
    label: "Michigan",
    value: "UmVnaW9uTm9kZTozNzE4"
  },
  {
    label: "Minnesota",
    value: "UmVnaW9uTm9kZTozNzE5"
  },
  {
    label: "Mississippi",
    value: "UmVnaW9uTm9kZTozNzA1"
  },
  {
    label: "Missouri",
    value: "UmVnaW9uTm9kZTozNzA0"
  },
  {
    label: "Montana",
    value: "UmVnaW9uTm9kZTozNzM1"
  },
  {
    label: "Nebraska",
    value: "UmVnaW9uTm9kZTozNzIw"
  },
  {
    label: "Nevada",
    value: "UmVnaW9uTm9kZTozNzMx"
  },
  {
    label: "New England",
    value: "UmVnaW9uTm9kZTozOTcy"
  },
  {
    label: "New Hampshire",
    value: "UmVnaW9uTm9kZTozNzIx"
  },
  {
    label: "New Jersey",
    value: "UmVnaW9uTm9kZTozNzIy"
  },
  {
    label: "New Mexico",
    value: "UmVnaW9uTm9kZTozNzMw"
  },
  {
    label: "New York",
    value: "UmVnaW9uTm9kZTozNzIz"
  },
  {
    label: "North Carolina",
    value: "UmVnaW9uTm9kZTozNzA2"
  },
  {
    label: "North Dakota",
    value: "UmVnaW9uTm9kZTozNzM2"
  },
  {
    label: "Ohio",
    value: "UmVnaW9uTm9kZTozNzI0"
  },
  {
    label: "Oklahoma",
    value: "UmVnaW9uTm9kZTozNzA3"
  },
  {
    label: "Oregon",
    value: "UmVnaW9uTm9kZTozNzM3"
  },
  {
    label: "Pennsylvania",
    value: "UmVnaW9uTm9kZTozNzQ1"
  },
  {
    label: "Rhode Island",
    value: "UmVnaW9uTm9kZTozNzI1"
  },
  {
    label: "South Carolina",
    value: "UmVnaW9uTm9kZTozNzA4"
  },
  {
    label: "South Dakota",
    value: "UmVnaW9uTm9kZTozNzM4"
  },
  {
    label: "Tennessee",
    value: "UmVnaW9uTm9kZTozNzA5"
  },
  {
    label: "Texas",
    value: "UmVnaW9uTm9kZTozNzEw"
  },
  {
    label: "Utah",
    value: "UmVnaW9uTm9kZTozNzMy"
  },
  {
    label: "Vermont",
    value: "UmVnaW9uTm9kZTozNzI2"
  },
  {
    label: "Virginia",
    value: "UmVnaW9uTm9kZTozNzQ2"
  },
  {
    label: "Washington",
    value: "UmVnaW9uTm9kZTozNzM5"
  },
  {
    label: "Washington, D.C.",
    value: "UmVnaW9uTm9kZTozNjk3"
  },
  {
    label: "West Virginia",
    value: "UmVnaW9uTm9kZTozNzEx"
  },
  {
    label: "Wisconsin",
    value: "UmVnaW9uTm9kZTozNzI3"
  },
  {
    label: "Wyoming",
    value: "UmVnaW9uTm9kZTozNzQw"
  }
]

const REGIONS_BY_CANADA = [
  {
    label: "Alberta",
    value: "UmVnaW9uTm9kZTo0Njc="
  },
  {
    label: "British Columbia",
    value: "UmVnaW9uTm9kZTo0Njg="
  },
  {
    label: "Manitoba",
    value: "UmVnaW9uTm9kZTo0Njk="
  },
  {
    label: "New Brunswick",
    value: "UmVnaW9uTm9kZTo0NzA="
  },
  {
    label: "Newfoundland and Labrador",
    value: "UmVnaW9uTm9kZTo0Nzk="
  },
  {
    label: "Northwest Territories",
    value: "UmVnaW9uTm9kZTo0NzE="
  },
  {
    label: "Nova Scotia",
    value: "UmVnaW9uTm9kZTo0NzI="
  },
  {
    label: "Nunavut",
    value: "UmVnaW9uTm9kZTo0NzM="
  },
  {
    label: "Ontario",
    value: "UmVnaW9uTm9kZTo0NzQ="
  },
  {
    label: "Prince Edward Island",
    value: "UmVnaW9uTm9kZTo0NzU="
  },
  {
    label: "Quebec",
    value: "UmVnaW9uTm9kZTo0NzY="
  },
  {
    label: "Saskatchewan",
    value: "UmVnaW9uTm9kZTo0Nzc="
  },
  {
    label: "Yukon",
    value: "UmVnaW9uTm9kZTo0Nzg="
  }
]

const REGIONS_BY_PUERTORICO = [
  {
    label: "Adjuntas",
    value: "UmVnaW9uTm9kZToyNjM2"
  },
  {
    label: "Aguada",
    value: "UmVnaW9uTm9kZToyNjM3"
  },
  {
    label: "Aguadilla",
    value: "UmVnaW9uTm9kZToyNjM4"
  },
  {
    label: "Aguas Buenas",
    value: "UmVnaW9uTm9kZToyNjM5"
  },
  {
    label: "Aibonito",
    value: "UmVnaW9uTm9kZToyNjQw"
  },
  {
    label: "Añasco",
    value: "UmVnaW9uTm9kZToyNjQx"
  },
  {
    label: "Arecibo",
    value: "UmVnaW9uTm9kZToyNjQy"
  },
  {
    label: "Arroyo",
    value: "UmVnaW9uTm9kZToyNjQz"
  },
  {
    label: "Barceloneta",
    value: "UmVnaW9uTm9kZToyNjQ0"
  },
  {
    label: "Barranquitas",
    value: "UmVnaW9uTm9kZToyNjQ1"
  },
  {
    label: "Bayamón",
    value: "UmVnaW9uTm9kZToyNjQ2"
  },
  {
    label: "Cabo Rojo",
    value: "UmVnaW9uTm9kZToyNjQ3"
  },
  {
    label: "Caguas",
    value: "UmVnaW9uTm9kZToyNjQ4"
  },
  {
    label: "Camuy",
    value: "UmVnaW9uTm9kZToyNjQ5"
  },
  {
    label: "Canóvanas",
    value: "UmVnaW9uTm9kZToyNjUw"
  },
  {
    label: "Carolina",
    value: "UmVnaW9uTm9kZToyNjUx"
  },
  {
    label: "Cataño",
    value: "UmVnaW9uTm9kZToyNjUy"
  },
  {
    label: "Cayey",
    value: "UmVnaW9uTm9kZToyNjUz"
  },
  {
    label: "Ceiba",
    value: "UmVnaW9uTm9kZToyNjU0"
  },
  {
    label: "Ciales",
    value: "UmVnaW9uTm9kZToyNjU1"
  },
  {
    label: "Cidra",
    value: "UmVnaW9uTm9kZToyNjU2"
  },
  {
    label: "Coamo",
    value: "UmVnaW9uTm9kZToyNjU3"
  },
  {
    label: "Comerío",
    value: "UmVnaW9uTm9kZToyNjU4"
  },
  {
    label: "Corozal",
    value: "UmVnaW9uTm9kZToyNjU5"
  },
  {
    label: "Culebra",
    value: "UmVnaW9uTm9kZToyNjYw"
  },
  {
    label: "Dorado",
    value: "UmVnaW9uTm9kZToyNjYx"
  },
  {
    label: "Fajardo",
    value: "UmVnaW9uTm9kZToyNjYy"
  },
  {
    label: "Florida",
    value: "UmVnaW9uTm9kZToyNjYz"
  },
  {
    label: "Guánica",
    value: "UmVnaW9uTm9kZToyNjY0"
  },
  {
    label: "Guayama",
    value: "UmVnaW9uTm9kZToyNjY1"
  },
  {
    label: "Guayanilla",
    value: "UmVnaW9uTm9kZToyNjY2"
  },
  {
    label: "Guaynabo",
    value: "UmVnaW9uTm9kZToyNjY3"
  },
  {
    label: "Gurabo",
    value: "UmVnaW9uTm9kZToyNjY4"
  },
  {
    label: "Hatillo",
    value: "UmVnaW9uTm9kZToyNjY5"
  },
  {
    label: "Hormigueros",
    value: "UmVnaW9uTm9kZToyNjcw"
  },
  {
    label: "Humacao",
    value: "UmVnaW9uTm9kZToyNjcx"
  },
  {
    label: "Isabela",
    value: "UmVnaW9uTm9kZToyNjcy"
  },
  {
    label: "Jayuya",
    value: "UmVnaW9uTm9kZToyNjcz"
  },
  {
    label: "Juana Díaz",
    value: "UmVnaW9uTm9kZToyNjc0"
  },
  {
    label: "Juncos",
    value: "UmVnaW9uTm9kZToyNjc1"
  },
  {
    label: "Lajas",
    value: "UmVnaW9uTm9kZToyNjc2"
  },
  {
    label: "Lares",
    value: "UmVnaW9uTm9kZToyNjc3"
  },
  {
    label: "Las Marías",
    value: "UmVnaW9uTm9kZToyNjc4"
  },
  {
    label: "Las Piedras",
    value: "UmVnaW9uTm9kZToyNjc5"
  },
  {
    label: "Loíza",
    value: "UmVnaW9uTm9kZToyNjgw"
  },
  {
    label: "Luquillo",
    value: "UmVnaW9uTm9kZToyNjgx"
  },
  {
    label: "Manatí",
    value: "UmVnaW9uTm9kZToyNjgy"
  },
  {
    label: "Maricao",
    value: "UmVnaW9uTm9kZToyNjgz"
  },
  {
    label: "Maunabo",
    value: "UmVnaW9uTm9kZToyNjg0"
  },
  {
    label: "Mayagüez",
    value: "UmVnaW9uTm9kZToyNjg1"
  },
  {
    label: "Moca",
    value: "UmVnaW9uTm9kZToyNjg2"
  },
  {
    label: "Morovis",
    value: "UmVnaW9uTm9kZToyNjg3"
  },
  {
    label: "Naguabo",
    value: "UmVnaW9uTm9kZToyNjg4"
  },
  {
    label: "Naranjito",
    value: "UmVnaW9uTm9kZToyNjg5"
  },
  {
    label: "Orocovis",
    value: "UmVnaW9uTm9kZToyNjkw"
  },
  {
    label: "Patillas",
    value: "UmVnaW9uTm9kZToyNjkx"
  },
  {
    label: "Peñuelas",
    value: "UmVnaW9uTm9kZToyNjky"
  },
  {
    label: "Ponce",
    value: "UmVnaW9uTm9kZToyNjkz"
  },
  {
    label: "Quebradillas",
    value: "UmVnaW9uTm9kZToyNjk1"
  },
  {
    label: "Rincón",
    value: "UmVnaW9uTm9kZToyNjk0"
  },
  {
    label: "Río Grande",
    value: "UmVnaW9uTm9kZToyNjk2"
  },
  {
    label: "Sabana Grande",
    value: "UmVnaW9uTm9kZToyNjk3"
  },
  {
    label: "Salinas",
    value: "UmVnaW9uTm9kZToyNjk4"
  },
  {
    label: "San Germán",
    value: "UmVnaW9uTm9kZToyNjk5"
  },
  {
    label: "San Juan",
    value: "UmVnaW9uTm9kZToyNzAw"
  },
  {
    label: "San Lorenzo",
    value: "UmVnaW9uTm9kZToyNzAx"
  },
  {
    label: "San Sebastián",
    value: "UmVnaW9uTm9kZToyNzAy"
  },
  {
    label: "Santa Isabel",
    value: "UmVnaW9uTm9kZToyNzAz"
  },
  {
    label: "Toa Alta",
    value: "UmVnaW9uTm9kZToyNzA0"
  },
  {
    label: "Toa Baja",
    value: "UmVnaW9uTm9kZToyNzA1"
  },
  {
    label: "Trujillo Alto",
    value: "UmVnaW9uTm9kZToyNzA2"
  },
  {
    label: "Utuado",
    value: "UmVnaW9uTm9kZToyNzA3"
  },
  {
    label: "Vega Alta",
    value: "UmVnaW9uTm9kZToyNzA4"
  },
  {
    label: "Vega Baja",
    value: "UmVnaW9uTm9kZToyNzA5"
  },
  {
    label: "Vieques",
    value: "UmVnaW9uTm9kZToyNzEz"
  },
  {
    label: "Villalba",
    value: "UmVnaW9uTm9kZToyNzEw"
  },
  {
    label: "Yabucoa",
    value: "UmVnaW9uTm9kZToyNzEx"
  },
  {
    label: "Yauco",
    value: "UmVnaW9uTm9kZToyNzEy"
  }
]

// key = country id
export const REGIONS_BY_COUNTRY = {
  "Q291bnRyeU5vZGU6Mzg=": REGIONS_BY_CANADA,
  Q291bnRyeU5vZGU6MjM0: REGIONS_BY_USA,
  Q291bnRyeU5vZGU6MTgz: REGIONS_BY_PUERTORICO
}
