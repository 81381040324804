/* eslint-disable */

import { useState, useEffect } from "react";
// import { useQuery } from "react-apollo";
import { useQuery } from "@apollo/client"
import { filtersByCollectionID } from "../queries";

const useGetFiltersByCollectionID = (id, lang) => {
  const { data, loading, error } = useQuery(filtersByCollectionID, {
    variables: { 
      quickshipCollection: id || '' ,
      language: lang || 'en',
    },
  });

  const [filtersByCollection, setFiltersByCollection] = useState([]);

  useEffect(() => {
    if (data) {
      setFiltersByCollection(data.filtersByCollection || []);
    }
  }, [data]);

  return { filtersByCollection, loading, error };
};

export default useGetFiltersByCollectionID;
