import { defineMessages } from "react-intl"

export const SURFACE_DETERIORATION_MSGS = defineMessages({
  suggestedInstructions: {
    id: "Claims.SurfaceDeterioration.suggestedInstructions",
    description: "field suggested instructions in surface deterioration on claim",
    defaultMessage:
      "Are cleaning instructions suggested by the manufacturer followed to clean up the material?"
  },
  describeProcessUsed: {
    id: "Claims.SurfaceDeterioration.describeProcessUsed",
    description: "field describe process used in surface deterioration on claim",
    defaultMessage:
      "If not, please describe the material cleaning process, frequency and cleaning products used"
  },
  theMaterialContaminated: {
    id: "Claims.SurfaceDeterioration.theMaterialContaminated",
    description: "field material contaminated in surface deterioration on claim",
    defaultMessage:
      "Has the material been in contact with any other substances (e.g. disinfectants, liquids, food, oil)? Please specify:"
  }
})
