import section from "./img/img-section.jpg"
import texture from "./img/img-texture.jpg"
import "./styles/texture-section.scss"

const TextureSection = () => (
  <div className="container my-4">
    <div className="container-texture">
      <img className="img-fluid img--texture" src={texture} />
      <img className="img-section" src={section} />
    </div>
  </div>
)

export default TextureSection
