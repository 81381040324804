import { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStateMachine } from "little-state-machine"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./styles/order-info.scss"
import { faClose } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import catalogueIcon from "./img/catalogue.svg"

const OrderInfo = ({ showBox, toggleShowBox, toggle }) => {
  const { state } = useStateMachine()
  const containerRef = useRef(null) // Crear una referencia al contenedor

  const processedSamples = Object.values(
    state?.sampleCount.reduce((acc, sample) => {
      // Crear una clave única basada en los atributos que determinan duplicados
      let key
      if (!sample?.catalogue) {
        key = `${sample.pattern}-${sample.color}-${sample.reference}`
      } else {
        key = sample.catalogue
      }

      // Si el objeto con esa clave ya existe, incrementa el contador
      if (acc[key]) {
        acc[key].count += 1
      } else {
        // Si no existe, crea una nueva entrada con count = 1
        acc[key] = { ...sample, count: 1 }
      }

      return acc
    }, {})
  )

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [state?.sampleCount.length])

  return (
    <div
      className={classNames({
        "order-info-container--kizuna": true,
        "slide-in": showBox,
        "slide-out": !showBox
      })}
      ref={containerRef}
    >
      <div className="close-icon-container">
        <FontAwesomeIcon
          className="close-icon"
          icon={faClose}
          size="xl"
          onClick={() => toggleShowBox(false)}
        />
      </div>
      <div className="order-list">
        {processedSamples.map((sample) => (
          <div className="sample-item-container" key={`key_${sample.id}`}>
            <div className="sample-info-wrapper">
              {!sample?.catalogue ? (
                <>
                  <img
                    className="sample-color"
                    src={sample.img}
                    alt={`${sample.id}_${sample.pattern}_${sample.color}`}
                  />
                  <div className="card-container-info--masiala ms-4">
                    <p className="card-label--masiala">
                      {sample.color} {sample.count > 1 && `(${sample.count})`}
                    </p>
                    <p className="card-stock--masiala">
                      {sample.reference.replace(/\s*-\s*/, "-")}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <img className="sample-color" src={catalogueIcon} alt="catalogue" />
                  <div className="card-container-info--masiala ms-4">
                    <p className="card-label--masiala">
                      {sample.catalogue} {sample.count > 1 && `(${sample.count})`}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div id="#last-sample-item-add" className="last-sample-item-add text-center">
        <p>
          {!state?.sampleCount[state?.sampleCount?.length - 1]?.catalogue ? (
            <>
              <span>{`${state?.sampleCount[
                state?.sampleCount?.length - 1
              ]?.pattern.toUpperCase()}`}</span>
              <span className="mx-1">{`${
                state.sampleCount[state?.sampleCount.length - 1]?.color
              }`}</span>
            </>
          ) : (
            <span className="mx-1">{`${state?.sampleCount[
              state?.sampleCount?.length - 1
            ]?.catalogue.toUpperCase()}`}</span>
          )}
          <span>have been added!</span>
        </p>
        {state?.sampleCount?.length >= 9 && (
          <p className="limit-samples-label mb-4">
            <strong>Limit reached:</strong> Maximum of 10 samples.
          </p>
        )}
        <button className="btn btn-sample" onClick={() => toggle()}>
          <p className="btn-label-sample--kizuna">FINISH YOUR ORDER HERE!</p>
        </button>
      </div>
    </div>
  )
}

OrderInfo.propTypes = {
  showBox: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  toggleShowBox: PropTypes.func
}

export default OrderInfo
