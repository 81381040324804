import bgColorDifference from "../assets/img/3.colour-difference.jpg"
import bgColorFading from "../assets/img/9.colour-fading.jpg"
import bgFacialDefects from "../assets/img/7.facial-defects.jpg"
import bgHero from "../assets/img/hero.png"
import bgSurfaceDeterioration from "../assets/img/11.surface-deterioration.jpg"
import bgStaining from "../assets/img/10.staining.jpg"
import bgVisualDefects from "../assets/img/15.visual-defects.jpg"
import bgTransformed from "../assets/img/6.transformed-condition.jpg"
import bgInstalled from "../assets/img/8.installed-condition.jpg"
import { MATERIAL_CURRENT_STATE, REASONS } from "../../StepForm/Step2/constants"

export const STEPS = ["step-1", "step-4", "step-5", "step-6", "thank-you"]

export const GET_BACKGROUND_IMAGE_ACCORD_TO_REASON = {
  [REASONS.COLOR_DIFFERENCE]: {
    backgroundImage: bgColorDifference
  },
  [REASONS.COLOR_FADING]: {
    backgroundImage: bgColorFading
  },
  [REASONS.FACIAL_DEFFECTS]: {
    backgroundImage: bgFacialDefects
  },
  [REASONS.DIFFERENCES_IN_HAND]: {
    backgroundImage: bgSurfaceDeterioration
  },
  [REASONS.MATERIAL_RESISTANCE]: {
    backgroundImage: bgSurfaceDeterioration
  },
  [REASONS.PLEATS]: {
    backgroundImage: bgSurfaceDeterioration
  },
  [REASONS.SURFACE_DETERIORATION]: {
    backgroundImage: bgSurfaceDeterioration
  },
  [REASONS.STAINING]: {
    backgroundImage: bgStaining
  },
  [REASONS.VISUAL_DEFFECTS]: {
    backgroundImage: bgVisualDefects
  },
  [REASONS.DIFFERENCES_IN_DIMENSION]: {
    backgroundImage: bgHero
  },
  [REASONS.OTHER]: {
    backgroundImage: bgHero
  },

  // Render the background accord condition
  [MATERIAL_CURRENT_STATE.TRANSFORMED]: {
    backgroundImage: bgTransformed
  },
  [MATERIAL_CURRENT_STATE.INSTALLED]: {
    backgroundImage: bgInstalled
  }
}
