import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { SCENES } from "./constants"
import TitleUnderlined from "components/TitleUnderlined"
import { H1_TAG } from "components/TitleUnderlined/constants"
import "./styles/breeze.scss"
import { useNavigate } from "react-router-dom"

const Breeze = ({ id }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate("/en-us/breeze")
  }

  return (
    <div id={id} className="text-center px-4 pt-3 pb-3 pt-lg-4 pb-lg-4 bg-breeze">
      <div className="row justify-content-center pt-3">
        <div className="col-12 col-lg-10">
          <TitleUnderlined hTag={H1_TAG}>
            <FormattedMessage
              id="Breeze.title"
              description="Title Breeze"
              defaultMessage="Breeze"
            />
          </TitleUnderlined>
          <p className="mb-5 mb-lg-3">
            <FormattedMessage
              id={"Breeze.description"}
              defaultMessage="Our new coated fabric, cooler to the touch"
            />
          </p>
        </div>
        <div className="row justify-content-center pt-3">
          <div className="d-flex flex-wrap justify-content-center">
            {SCENES.map(({ title, img }, i) => {
              const isPair = i % 2 === 0
              if (isPair) {
                return (
                  <div key={`key_${i}_${title}`} className="mx-3 mb-5">
                    <div className="vertical-space" />
                    <div
                      className="scene--img"
                      style={{
                        backgroundImage: `url(${img})`
                      }}
                    />
                  </div>
                )
              }
              return (
                <div key={`key_${i}_${title}`} className="mx-3">
                  <div
                    className="scene--img"
                    style={{
                      backgroundImage: `url(${img})`
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <button className="btn btn-dark text-center" onClick={handleClick}>
        <FormattedMessage id="Breeze.moreAbout" defaultMessage="Learn more about Breeze" />
      </button>
    </div>
  )
}

Breeze.propTypes = {
  id: PropTypes.string
}

export default Breeze
