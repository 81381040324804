import SustainableInfo from "./components/SustainableInfo"
import "./styles/sustainable-elements.scss"
import { LG } from "consts/breakpoints"
import { FormattedMessage } from "react-intl"
import useMediaQuery from "hooks/useMediaQuery"
import phatalateFree from "../../../../../SaxonyCharacteristics/img/phatalate-free.svg"
import biocideFree from "../../../../../SaxonyCharacteristics/img/biocide.svg"
import aditiveFree from "./imgs/aditive-free.svg"

const SustainableElements = () => {
  const isMobile = useMediaQuery(LG)

  return (
    <div className="product--information-detail--main-container d-flex">
      <div className="d-flex flex-wrap mt-3">
        <div className="col-6 col-md-auto mb-4 mb-md-0 me-md-5">
          <img src={phatalateFree} alt="phatalaFree image" />
        </div>
        <div className="col-6 col-md-auto me-md-5 ps-4 ps-md-0">
          <img src={biocideFree} alt="biocideFree image" />
        </div>
        <div className="col-6 col-md-auto me-md-5">
          <img className="sustainable-elements--aditiveFree-img" src={aditiveFree} alt="aditiveFree image" />
        </div>
        <div className="col-6 col-md-auto ps-4 ps-md-0 sustainable-elements--low-voc-container">
          <p>Low VOC’s</p>
        </div>
      </div>
    </div>
  )
}

export default SustainableElements
