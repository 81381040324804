import { MODAL_TYPES_DOWNLOAD } from "modules/masiala/constants"

export const ENDPOINT_ZAP = "https://hooks.zapier.com/hooks/catch/9615379/3sz7ygh/"

export const handleURLPdfAccordingToFormType = ({ lang, formType, patternFolder = "masiala" }) => {
  const fileNames = {
    [MODAL_TYPES_DOWNLOAD[0]]: "digital-catalogue",
    [MODAL_TYPES_DOWNLOAD[1]]: "care_and_cleaning"
  }

  const urlPDFAccordingToFormType = {
    [MODAL_TYPES_DOWNLOAD[0]]: `https://spradling-misc.s3.us-west-2.amazonaws.com/landing-attachments/${patternFolder}/digital_catalogue.pdf`,
    [MODAL_TYPES_DOWNLOAD[1]]: `https://spradling-misc.s3.us-west-2.amazonaws.com/landing-attachments/${patternFolder}/cleaning_guide.pdf`
  }

  return {
    pdfURL: urlPDFAccordingToFormType[formType],
    fileName: fileNames[formType]
  }
}
