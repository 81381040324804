import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import "./styles/thumbs.scss"

const Thumbs = ({ files, handleDelete }) => (
  <aside className="thumbsContainer">
    {files.map(({ name }, i) => (
      <div className="thumb d-flex bg-white col-12 justify-content-start border-bottom border-2 border-secondary-subtle" key={name}>
        <h6 className="mt-2 mx-2">{i + 1}.</h6>
        <h6 className="mt-2 mx-2">{name}</h6>
        <FontAwesomeIcon
          icon={faRectangleXmark}
          onClick={() => handleDelete(i)}
          size="lg"
          className={classNames({
            "d-block mt-2 cursor-pointer ms-auto mx-2": true,
            "--fa-primary-color": "#3d3846",
            "--fa-secondary-color": "#ffffff"
          })}
        />
      </div>
    ))}
  </aside>
)

Thumbs.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  handleDelete: PropTypes.func
}

export default Thumbs
