import PropTypes from "prop-types"
import useToggle from "hooks/useToggle"
import ErrorMessage from "components/ErrorMessage"
import FlatPageInfo from "modules/claims/components/FlatPageInfo"
import "components/InputMUI/styles/own-mui.scss"
import classNames from "classnames"
import { useWatch } from "react-hook-form"

const ModalCheck = ({ register, control, setValue, slug, name, label, title, error }) => {
  const [toggable, togle] = useToggle()

  const watchedValue = useWatch({ control, name })

  const handleOnClick = () => {
    setValue(name, true, { shouldValidate: true })
    togle()
  }

  return (
    <div>
      <div className="step-form--group-check">
        <input
          ref={register(name).ref}
          name={register(name).name}
          type="checkbox"
          className={classNames({
            "step-form--field-check--controlled": true,
            "step-form--field-check--controlled-checked": watchedValue
          })}
          onClick={togle}
        />
        <label htmlFor={name} className="label-item label-item--tnc" onClick={togle}>
          {label}
        </label>
      </div>
      <ErrorMessage className="ms-3" weight="normal">
        {error}
      </ErrorMessage>
      <FlatPageInfo
        isOpen={toggable}
        slug={slug}
        title={title}
        handleAcceptTNC={handleOnClick}
      />
    </div>
  )
}

ModalCheck.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  slug: PropTypes.string,
  title: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    defaultValue: PropTypes.string
  }),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string
}

export default ModalCheck
