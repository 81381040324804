import { FormattedMessage } from "react-intl"
import neutrals from "./assets/imgs/beiges.jpg"
import greens from "./assets/imgs/greens.jpg"
import blues from "./assets/imgs/blues.jpg"
import warms from "./assets/imgs/warms.jpg"

export const WHISPER_NEW_COLORS = [
  {
    id: "color.lightNeutral",
    title: (
      <FormattedMessage
        id="WhisperNeoPlus.exploreNewColors.color.lightNeutral"
        defaultMessage="Warm light neutrals"
      />
    ),
    description: (
      <FormattedMessage
        id="WhisperNeoPlus.exploreNewColors.color.lightNeutral.description"
        defaultMessage="Are trending as commercial interior spaces are influenced by numerous light wood tones evoking a more residential or hospitality aesthetic. Powder, Sand, Pearl, Pavestone and Farro are all complex neutral colors that pair well together.{br}{br}These soft, nuanced colors envelop you and create a sense of wellbeing and productivity."
        values={{ br: <br /> }}
      />
    ),
    img: neutrals,
    alt: "neutrals"
  },
  {
    id: "color.greens",
    title: (
      <FormattedMessage id="WhisperNeoPlus.exploreNewColors.color.greens" defaultMessage="Greens" />
    ),
    description: (
      <FormattedMessage
        id="WhisperNeoPlus.exploreNewColors.color.greens.description"
        defaultMessage="Are gaining ground as designers utilize these colors to connect nature to interiors.These subdued, natural hues color block well within the color palette as a whole, with Emerald taking the lead as the deep neutral.{br}{br}We added Emerald and the companion colors Herbal and Silver Leaf, which are all earthy greens that harmonize well together."
        values={{ br: <br /> }}
      />
    ),
    img: greens,
    alt: "greens"
  },
  {
    id: "color.blues",
    title: (
      <FormattedMessage id="WhisperNeoPlus.exploreNewColors.color.blues" defaultMessage="Blues" />
    ),
    description: (
      <FormattedMessage
        id="WhisperNeoPlus.exploreNewColors.color.greens.description"
        defaultMessage="The array of water tones, along with the shades that transition between blue and green, are inspired by the many shades of blue found in nature.{br}{br}whole, while effortlessly evoking a sense of calm and tranquility."
        values={{ br: <br /> }}
      />
    ),
    img: blues,
    alt: "blues"
  },
  {
    id: "color.warmTones",
    title: (
      <FormattedMessage
        id="WhisperNeoPlus.exploreNewColors.color.warmTones"
        defaultMessage="Warm tones"
      />
    ),
    description: (
      <FormattedMessage
        id="WhisperNeoPlus.exploreNewColors.color.warmTones.description"
        defaultMessage="Saddle, Terracotta and Auburn are rich, warm midtones inspired by leather. Saddle and Cinnamon are both trending leather colors.{br}{br}Terracotta is a smokey leather-like color that pairs well with Cognac and Saddle.The Cognac color family is extremely popular and works well with Charcoal, Black and White. Many of these colors reflect the trend of natural hues which have been proven to transition the brain into a state of reflection."
        values={{ br: <br /> }}
      />
    ),
    img: warms,
    alt: "warms"
  }
]
