import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { useQuery } from "@apollo/client"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import ColorPaletteGeneric from "components/ColorPalette"
import PatternQuery from "modules/linetex/components/ColorPalette/query"
import { getCurrentInstanceId } from "services/instances"
import { COLORS_BLACKLIST } from "./constants"
import { filterBlacklist } from "./utils"

const ColorPalette = ({ id }) => {
  const { data } = useQuery(PatternQuery, {
    variables: { instanceIds: [getCurrentInstanceId()], slug: "whisper-neo-permablok-plus" }
  })

  const filteredColors = filterBlacklist({
    originalArray: data?.pattern?.products?.edges,
    blacklist: COLORS_BLACKLIST,
    filter: "stock"
  })

  return (
    <div id={id}>
      <div className="container my-2 py-4">
        <div className="row justify-content-md-center">
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="WhisperNeoPlus.ColorPallete.title"
              description="WhisperNeoPlus section color pallete contact"
              defaultMessage="45 Trendy colors to create modern spaces"
            />
          </TitleUnderlined>
          <div className="col-12">
            <ColorPaletteGeneric colors={filteredColors} />
          </div>
        </div>
      </div>
    </div>
  )
}

ColorPalette.propTypes = {
  id: PropTypes.string.isRequired
}

export default ColorPalette
