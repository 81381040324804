import PropTypes from "prop-types"

const BusinessHours = ({ businessHours }) => (
  <div className="business-hours">
    {businessHours.map((businessHour, index) => (
      <div key={index} className="business-hour">
        <i className="fa fa-clock-o" />
        {`${businessHour.startDay}${businessHour.endDay ? `- ${businessHour.endDay}` : ""} `} |{" "}
        {businessHour.startHour} - {businessHour.endHour}
      </div>
    ))}
  </div>
)

BusinessHours.propTypes = {
  businessHours: PropTypes.arrayOf(
    PropTypes.shape({
      startDay: PropTypes.string.isRequired,
      endDay: PropTypes.string,
      startHour: PropTypes.string.isRequired,
      endHour: PropTypes.string.isRequired
    })
  ).isRequired
}

export default BusinessHours
