import { defineMessages } from "react-intl"
import {
  NAME_AND_COLOR_TOOLTIP,
  QUANTITY_TOOLTIP,
  CUSTOMER_NUMBER_TOOLTIP,
  BATCH_TOOLTIP
} from "modules/claims/constants"

const NAME_COLOR_PRODUCT_INFO = "NAME_COLOR_PRODUCT_INFO"
const QUANTITY_PRODUCT_INFO = "QUANTITY_PRODUCT_INFO"
const CUSTOMER_NUMBER_INFO = "CUSTOMER_NUMBER_INFO"
const PRODUCTION_BATCH_NUMBER_INFO = "PRODUCTION_BATCH_NUMBER_INFO"

export const MSGS = defineMessages({
  descriptionForm: {
    id: "Claims.Registration.form.description",
    description: "description section registration",
    defaultMessage:
      "Before continuing please make sure you have the following information on hand to finalize and record your claim so we can process it accordingly:"
  },
  textBtn: {
    id: "Claims.Registration.btn.start",
    description: "text btn section registration",
    defaultMessage: "Start"
  }
})

const REGISTRATION_CHECKS_MSGS = defineMessages({
  nameAndColor: {
    id: "Claims.Registration.check.nameAndColor",
    description: "check colour difference for registration",
    defaultMessage: "Name & colour of the claimed product"
  },
  quantity: {
    id: "Claims.Registration.check.quantity",
    description: "check quantity for registration",
    defaultMessage: "Quantity of the claimed product or affected pieces"
  },
  customerNumber: {
    id: "Claims.Registration.check.customerNumber",
    description: "check customer number for registration",
    defaultMessage: "Your Customer Number"
  },
  customerNumberDescription: {
    id: "Claims.Registration.check.customerNumber.description",
    description: "check description customer number for registration",
    defaultMessage: "(Indicated on delivery papers or Spradling® invoices)"
  },
  productionBatchNumber: {
    id: "Claims.Registration.check.productionBatchNumber",
    description: "check production batch number for registration",
    defaultMessage: "Production Batch No. of the material"
  },
  productionBatchNumberDescription: {
    id: "Claims.Registration.check.productionBatchNumber.description",
    description: "check description production batch number for registration",
    defaultMessage: "(indicated on the roll label or the textile backing)"
  },
  pictures: {
    id: "Claims.Registration.check.pictures",
    description: "check pictures for registration",
    defaultMessage: "Pictures of the claim"
  },
  termsAndConditions: {
    id: "Claims.Registration.check.termsAndConditions",
    description: "check terms and conditions for registration",
    defaultMessage: "Read terms and Conditions"
  },
  privacyPolicy: {
    id: "Form.field.PrivacyPolicy",
    description: "Form field privacy policy",
    defaultMessage: "Privacy Policy"
  }
})

export const REGISTRATION_CHECKS = [
  {
    name: "check_nameAndColor",
    label: REGISTRATION_CHECKS_MSGS.nameAndColor,
    info: NAME_COLOR_PRODUCT_INFO,
    tooltipElem: NAME_AND_COLOR_TOOLTIP
  },
  {
    name: "check_quantity",
    label: REGISTRATION_CHECKS_MSGS.quantity,
    info: QUANTITY_PRODUCT_INFO,
    tooltipElem: QUANTITY_TOOLTIP
  },
  {
    name: "check_customerNumber",
    label: REGISTRATION_CHECKS_MSGS.customerNumber,
    description: REGISTRATION_CHECKS_MSGS.customerNumberDescription,
    info: CUSTOMER_NUMBER_INFO,
    tooltipElem: CUSTOMER_NUMBER_TOOLTIP
  },
  {
    name: "check_productionBatchNumber",
    label: REGISTRATION_CHECKS_MSGS.productionBatchNumber,
    description: REGISTRATION_CHECKS_MSGS.productionBatchNumberDescription,
    info: PRODUCTION_BATCH_NUMBER_INFO,
    tooltipElem: BATCH_TOOLTIP
  },
  {
    name: "check_pictures",
    label: REGISTRATION_CHECKS_MSGS.pictures
  },
  {
    name: "check_termsAndConditions",
    label: REGISTRATION_CHECKS_MSGS.termsAndConditions,
    showModal: true
  },
  {
    name: "check_privacyPolicy",
    label: REGISTRATION_CHECKS_MSGS.privacyPolicy,
    showModal: true
  }
]

export const VALIDATION_MESSAGES = defineMessages({
  required: {
    id: "Claims.Registration.check.required",
    description: "Claims registration required field message",
    defaultMessage: "This information is required to start the claim process"
  }
})

export const MODAL_CKECK_SLUGS = {
  check_termsAndConditions: {
    title: {
      ...REGISTRATION_CHECKS_MSGS.termsAndConditions,
      id: "termsAndConditions.title"
    },
    slug: "terms-and-conditions-claim-europe"
  },
  check_privacyPolicy: {
    title: REGISTRATION_CHECKS_MSGS.privacyPolicy,
    slug: "privacy-policy-europe"
  }
}
