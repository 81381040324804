import { useRef } from "react"
import PropTypes from "prop-types"
import { useQuery } from "@apollo/client"
import { collectionsQuery } from "modules/quickship/queries"
import { Link, useLocation, useParams } from "react-router-dom"
import downArrowWhiteQuickshipPage from "./img/down-arrow-white-quickship-page.svg"
import { HOME_SECTIONS } from "../consts"
import "./styles/home-product-section.scss"
import useArrowVisibility from "./useArrowVisibility"
import classNames from "classnames"

const HomeProductsSection = ({ id }) => {
  const { language } = useParams()
  const location = useLocation()
  const currentPath = location?.pathname
  const { data } = useQuery(collectionsQuery)
  const productsContainerRef = useRef(null)
  const isArrowVisible = useArrowVisibility(productsContainerRef)

  const scrollDown = () => {
    if (productsContainerRef.current) {
      productsContainerRef.current.scrollBy({
        top: window.innerHeight, // Scroll una ventana de altura completa
        behavior: "smooth" // Desplazamiento suave
      })
    }
  }

  return (
    <div id={id} className="main-container-products-home-quickship-page">
      <div
        className="home--products-container d-flex flex-wrap p-0"
        id={HOME_SECTIONS[2]}
        ref={productsContainerRef}
      >
        {data?.quickshipCollections?.edges?.map(({ node }) => {
          const slugNameCollection = node?.name?.replaceAll(" ", "-")

          return (
            <div
              key={node.id}
              id={slugNameCollection}
              className="home--products-container-product p-0"
            >
              <img src={node.imageThumb} alt={node.name} />
              <section className="text-center px-2">
                <h1>{node.name}</h1>
                <Link to={`${currentPath}/${node?.slug}`} className="btn--Products-home-quickship">
                  {language === "es" ? "Ver productos relacionados" : "View related products"}
                </Link>
              </section>
              <div className="home--products-container-arrow-down animationImgArrowDown--mobile">
                <img
                  className="cursor-pointer"
                  src={downArrowWhiteQuickshipPage}
                  alt="icon-down-arrow-white-quickship-page"
                  onClick={scrollDown}
                />
              </div>
            </div>
          )
        })}
      </div>
      <div className="position-relative">
        <img
          className={classNames({
            "downArrowDesktop-quickshippage animationImgArrowDown--desktop": true,
            "d-none": isArrowVisible
          })}
          src={downArrowWhiteQuickshipPage}
          alt="arrow down"
          onClick={scrollDown}
        />
      </div>
    </div>
  )
}

HomeProductsSection.propTypes = {
  id: PropTypes.string
}

export default HomeProductsSection
