import NotFound from "modules/not-found"
import Pranna from "modules/pranna"
import ThankYou from "modules/thank-you"
import Whisper from "modules/whisper"
import FeriaTapiceria from "modules/feria-tapiceria"
import Claims from "modules/claims"

import Petfriendly from "modules/petfriendly"
import Sandbox from "modules/sandbox"
import Pandora from "modules/pandora"

// STEP-FORM
import Step1 from "modules/claims/components/StepForm/Step1"
import Step2 from "modules/claims/components/StepForm/Step2"
import Step3 from "modules/claims/components/StepForm/Step3"
import Step4 from "modules/claims/components/StepForm/Step4"
import Step5 from "modules/claims/components/StepForm/Step5"
import Step6 from "modules/claims/components/StepForm/Step6"
import ThankYouClaims from "modules/claims/components/StepForm/ThankYou"
// END -> STEP-FORM
import Permacool from "modules/permacool"
import SustainabilityReports from "modules/sustainability-reports"
import Floors from "modules/floors/InternalFloors"
import FloorDetail from "modules/floors/modules/FloorDetail"
import Deliverables from "modules/floors/modules/Deliverables"
import InstalationGuide from "modules/floors/modules/InstalationGuide/InstalationGuide"
import Trend from "modules/floors/modules/Trend"
import ExternalFloors from "modules/floors/ExternalFloors"
import Dali from "modules/dali"
import Linetex from "modules/linetex"
import Ibex from "modules/Ibex"
import Impala from "modules/impala"
import VestuarioYProteccion from "modules/vestuario-y-proteccion"
import ReferenceDetail from "modules/vestuario-y-proteccion/components/ReferenceDetail"
import Breeze from "modules/breeze"
import Prannatex from "modules/prannatex"
import Rivulet from "modules/rivulet"
// SEGMENTS
import SegmentHotel from "modules/segments/hotel"
import CleaningGuideMarine from "modules/cleaning-guide-marine"
import CleaningGuideMarineGeneral from "modules/cleaning-guide-marine/modules/General"
import CleaningGuideMarineChil from "modules/cleaning-guide-marine/modules/Chil"
import Masiala from "modules/masiala"
import Saxony from "modules/saxony"
import QuickshipPage from "modules/quickship/Home"
import QuickshipPageParamSlug from "modules/quickship/paramSlugPage"
import KizunaHome from "modules/kizuna/modules/home"
import KizunaDetail from "modules/kizuna/modules/detail"

export const LANDING_WISHPER_NEO_PLUS = "LANDING_WISHPER_NEO_PLUS"
export const THANK_YOU_WISHPER_NEO_PLUS = "THANK_YOU_WISHPER_NEO_PLUS"
export const LANDING_RIVULET = "LANDING_RIVULET"
export const THANK_YOU_RIVULET = "THANK_YOU_RIVULET"
export const LANDING_PRANNA = "LANDING_PRANNA"
export const THANK_YOU_PRANNA = "THANK_YOU_PRANNA"
export const THANK_YOU_PANDORA = "THANK_YOU_PANDORA"
export const FERIA_TAPICERIA = "FERIA_TAPICERIA"
export const CLAIMS = "CLAIMS"
export const THANK_YOU_CLAIMS = "THANK_YOU_CLAIMS"
export const PETFRIENDLY = "PETFRIENDLY"
export const THANK_YOU_PETFRIENDLY = "THANK_YOU_PETFRIENDLY"
export const PERMACOOL = "PERMACOOL"
export const THANK_YOU_PERMACOOL = "THANK_YOU_PERMACOOL"
export const BREEZE = "BREEZE"
export const THANK_YOU_BREEZE = "THANK_YOU_BREEZE"
export const SUSTAINABILITY_REPORTS_ES = "SUSTAINABILITY_REPORTS_ES"
export const SUSTAINABILITY_REPORTS_EN = "SUSTAINABILITY_REPORTS_EN"
export const FLOORS = "FLOORS"
export const THANK_YOU_FLOORS = "THANK_YOU_FLOORS"
export const FLOORS_EXTERNAL = "FLOORS_EXTERNAL"
export const FLOORS_INSPIRATION_DETAIL = "FLOORS_INSPIRATION_DETAIL"
export const FLOORS_GUIDE = "FLOORS_GUIDE"
export const FLOORS_DELIVERABLES = "FLOORS_DELIVERABLES"
export const DETAIL_FLOOR_INTERN = "DETAIL_FLOOR_INTERN"
export const DETAIL_FLOOR = "DETAIL_FLOOR"
export const LANDING_DALI = "LANDING_DALI"
export const THANK_YOU_DALI = "THANK_YOU_DALI"
export const LANDING_LINETEX = "LANDING_LINETEX"
export const THANK_YOU_LINETEX = "THANK_YOU_LINETEX"
export const LANDING_IBEX = "LANDING_IBEX"
export const LANDING_IMPALA = "LANDING_IMPALA"
export const THANK_YOU_IMPALA = "THANK_YOU_IMPALA"
export const LANDING_VESTUARIO_Y_PROTECCION = "LANDING_VESTUARIO_Y_PROTECCION"
export const DETAIL_REFERENCE_VESTUARIO_Y_PROTECCION = "DETAIL_REFERENCE_VESTUARIO_Y_PROTECCION"
export const THANK_YOU_VESTUARIO_Y_PROTECCION = "THANK_YOU_VESTUARIO_Y_PROTECCION"
export const LANDING_PANDORA = "LANDING_PANDORA"
export const LANDING_PRANNATEX = "LANDING_PRANNATEX"
export const LANDING_CLEANING_GUIDE_MARINE = "LANDING_CLEANING_GUIDE_MARINE"
export const LANDING_CLEANING_GUIDE_MARINE_GENERAL = "LANDING_CLEANING_GUIDE_MARINE_GENERAL"
export const LANDING_CLEANING_GUIDE_MARINE_CHIL = "LANDING_CLEANING_GUIDE_MARINE_CHIL"
export const THANK_YOU_PRANNATEX = "THANK_YOU_PRANNATEX"
export const LANDING_SEGMENTS_HOTEL = "LANDING_SEGMENTS_HOTEL"
export const THANK_YOU_SEGMENTS_HOTEL = "THANK_YOU_SEGMENTS_HOTEL"
export const LANDING_MASIALA = "LANDING_MASIALA"
export const LANDING_QUICKSHIP = "LANDING_QUICKSHIP"
export const LANDING_QUICKSHIP_SLUG = "LANDING_QUICKSHIP_SLUG"
export const THANK_YOU_LANDING_MASIALA = "THANK_YOU_LANDING_MASIALA"
export const LANDING_SAXONY = "LANDING_SAXONY"
export const THANK_YOU_SAXONY = "THANK_YOU_SAXONY"
export const LANDING_KIZUNA_HOME = "LANDING_KIZUNA_HOME"
export const LANDING_KIZUNA_DETAIL = "LANDING_KIZUNA_DETAIL"
export const NOT_FOUND = "NOT_FOUND"
export const SANDBOX = "SANDBOX"
// START -> STEP-FORM
export const STEP_1 = "STEP_1"
export const STEP_2 = "STEP_2"
export const STEP_3 = "STEP_3"
export const STEP_4 = "STEP_4"
export const STEP_5 = "STEP_5"
export const STEP_6 = "STEP_6"
export const STEP_RESULT = "STEP_RESULT"
// END -> STEP-FORM

const locationPath = "/:language-:instance"

export const ROUTES = {
  [LANDING_WISHPER_NEO_PLUS]: {
    path: `${locationPath}/whisper-neo-plus`,
    exact: true,
    element: <Whisper />
  },
  [LANDING_RIVULET]: {
    path: `${locationPath}/rivulet`,
    exact: true,
    element: <Rivulet />
  },
  [THANK_YOU_RIVULET]: {
    path: `${locationPath}/rivulet/thank-you`,
    exact: true,
    element: <ThankYou msgId="Rivulet" />
  },
  [LANDING_SAXONY]: {
    path: `${locationPath}/saxony`,
    exact: true,
    element: <Saxony />
  },
  [THANK_YOU_SAXONY]: {
    path: `${locationPath}/saxony/thank-you`,
    exact: true,
    element: <ThankYou msgId="Saxony" />
  },
  [THANK_YOU_WISHPER_NEO_PLUS]: {
    path: `${locationPath}/whisper-neo-plus/thank-you`,
    exact: true,
    element: <ThankYou msgId="WhisperNeoPlus.Banner.title" />
  },
  [LANDING_PRANNA]: {
    path: `${locationPath}/pranna`,
    exact: true,
    element: <Pranna />
  },
  [THANK_YOU_PRANNA]: {
    path: `${locationPath}/pranna/gracias`,
    exact: true,
    element: <ThankYou msgId="Pranna.Banner.title" />
  },
  [LANDING_PANDORA]: {
    path: `${locationPath}/pandora`,
    exact: true,
    element: <Pandora />
  },
  [THANK_YOU_PANDORA]: {
    path: `${locationPath}/pandora/gracias`,
    exact: true,
    element: <ThankYou msgId="Pandora.Banner.title" />
  },
  [FERIA_TAPICERIA]: {
    path: "feria-tapiceria",
    exact: true,
    element: <FeriaTapiceria />
  },
  [CLAIMS]: {
    path: `${locationPath}/claims`,
    exact: true,
    element: <Claims />
  },
  [STEP_1]: {
    path: `${locationPath}/claims/step-1`,
    exact: true,
    element: <Step1 />
  },
  [STEP_2]: {
    path: `${locationPath}/claims/step-2`,
    exact: true,
    element: <Step2 />
  },
  [STEP_3]: {
    path: `${locationPath}/claims/step-3`,
    exact: true,
    element: <Step3 />
  },
  [STEP_4]: {
    path: `${locationPath}/claims/step-4`,
    exact: true,
    element: <Step4 />
  },
  [STEP_5]: {
    path: `${locationPath}/claims/step-5`,
    exact: true,
    element: <Step5 />
  },
  [STEP_6]: {
    path: `${locationPath}/claims/step-6`,
    exact: true,
    element: <Step6 />
  },
  [THANK_YOU_CLAIMS]: {
    path: `${locationPath}/claims/thank-you`,
    exact: true,
    element: <ThankYouClaims />
  },
  [PETFRIENDLY]: {
    path: `${locationPath}/petfriendly`,
    exact: true,
    element: <Petfriendly />
  },
  [THANK_YOU_PETFRIENDLY]: {
    path: `${locationPath}/petfriendly/gracias`,
    exact: true,
    element: <ThankYou msgId="Petfriendly.Banner.title" />
  },
  [PERMACOOL]: {
    path: `${locationPath}/permacool`,
    exact: true,
    element: <Permacool />
  },
  [THANK_YOU_PERMACOOL]: {
    path: `${locationPath}/permacool/thank-you`,
    exact: true,
    element: <ThankYou msgId="Permacool.Banner.title" />
  },
  [BREEZE]: {
    path: `${locationPath}/breeze`,
    exact: true,
    element: <Breeze />
  },
  [THANK_YOU_BREEZE]: {
    path: `${locationPath}/breeze/thank-you`,
    exact: true,
    element: <ThankYou msgId="Breeze.Banner.title" />
  },
  [SUSTAINABILITY_REPORTS_ES]: {
    path: `${locationPath}/reportes-de-sostenibilidad`,
    exact: true,
    element: <SustainabilityReports />
  },
  [SUSTAINABILITY_REPORTS_EN]: {
    path: `${locationPath}/sustainability-reports`,
    exact: true,
    element: <SustainabilityReports />
  },
  [FLOORS_EXTERNAL]: {
    path: `${locationPath}/pisos`,
    element: <ExternalFloors />
  },
  [FLOORS]: {
    path: `${locationPath}/pisos-interno`,
    element: <Floors />
  },
  [THANK_YOU_FLOORS]: {
    path: `${locationPath}/pisos/gracias`,
    exact: true,
    element: <ThankYou msgId="Floors.Banner.title" />
  },
  [FLOORS_INSPIRATION_DETAIL]: {
    path: `${locationPath}/pisos-interno/tendencias/:slug`,
    element: <Trend />
  },
  [FLOORS_DELIVERABLES]: {
    path: `${locationPath}/pisos-interno/entregables`,
    element: <Deliverables />
  },
  [FLOORS_GUIDE]: {
    path: `${locationPath}/pisos/guia-instalacion`,
    element: <InstalationGuide />
  },
  [DETAIL_FLOOR_INTERN]: {
    path: `${locationPath}/pisos-interno/piso/:slug`,
    element: <FloorDetail />
  },
  [DETAIL_FLOOR]: {
    path: `${locationPath}/pisos/piso/:slug`,
    element: <FloorDetail />
  },
  [LANDING_DALI]: {
    path: `${locationPath}/dali`,
    exact: true,
    element: <Dali />
  },
  [THANK_YOU_DALI]: {
    path: `${locationPath}/dali/gracias`,
    exact: true,
    element: <ThankYou msgId="Dali.Banner.title" />
  },
  [LANDING_LINETEX]: {
    path: `${locationPath}/linetex`,
    exact: true,
    element: <Linetex />
  },
  [THANK_YOU_LINETEX]: {
    path: `${locationPath}/linetex/gracias`,
    exact: true,
    element: <ThankYou msgId="Linetex.Banner.title" />
  },
  [LANDING_IBEX]: {
    path: `${locationPath}/ibex`,
    exact: true,
    element: <Ibex />
  },
  [LANDING_IBEX]: {
    path: `${locationPath}/ibex`,
    exact: true,
    element: <Ibex />
  },
  [LANDING_IMPALA]: {
    path: `${locationPath}/impala`,
    exact: true,
    element: <Impala />
  },
  [THANK_YOU_IMPALA]: {
    path: `${locationPath}/impala/gracias`,
    exact: true,
    element: <ThankYou msgId="Impala.Banner.title" />
  },
  [LANDING_VESTUARIO_Y_PROTECCION]: {
    path: `${locationPath}/vestuario-y-proteccion`,
    exact: true,
    element: <VestuarioYProteccion />
  },
  [DETAIL_REFERENCE_VESTUARIO_Y_PROTECCION]: {
    path: `${locationPath}/vestuario-y-proteccion/referencia/:slug`,
    element: <ReferenceDetail />
  },
  [LANDING_PRANNATEX]: {
    path: `${locationPath}/prannatex`,
    element: <Prannatex />
  },
  [THANK_YOU_PRANNATEX]: {
    path: `${locationPath}/prannatex/gracias`,
    exact: true,
    element: <ThankYou msgId="Prannatex.Banner.title" />
  },
  [LANDING_CLEANING_GUIDE_MARINE]: {
    path: `${locationPath}/cleaning-guide-marine`,
    element: <CleaningGuideMarine />
  },
  [LANDING_CLEANING_GUIDE_MARINE_GENERAL]: {
    path: `${locationPath}/cleaning-guide-marine/general`,
    element: <CleaningGuideMarineGeneral />
  },
  [LANDING_CLEANING_GUIDE_MARINE_CHIL]: {
    path: `${locationPath}/cleaning-guide-marine/chil`,
    element: <CleaningGuideMarineChil />
  },
  [LANDING_SEGMENTS_HOTEL]: {
    path: `${locationPath}/segmentos/hoteleria`,
    element: <SegmentHotel />
  },
  [THANK_YOU_SEGMENTS_HOTEL]: {
    path: `${locationPath}/segmentos/hoteleria/gracias`,
    exact: true,
    element: <ThankYou msgId="Rivulet" />
  },
  [LANDING_MASIALA]: {
    path: `${locationPath}/masiala`,
    element: <Masiala />
  },
  [LANDING_QUICKSHIP]: {
    path: `${locationPath}/quickship`,
    element: <QuickshipPage />
  },
  [LANDING_QUICKSHIP_SLUG]: {
    path: `${locationPath}/quickship/:id`,
    element: <QuickshipPageParamSlug />
  },
  [THANK_YOU_LANDING_MASIALA]: {
    path: `${locationPath}/masiala/thank-you`,
    exact: true,
    element: <ThankYou msgId="Masiala" />
  },
  [LANDING_KIZUNA_HOME]: {
    path: "en-us/kizuna",
    element: <KizunaHome />
  },
  [LANDING_KIZUNA_DETAIL]: {
    path: "en-us/kizuna/:product",
    exact: true,
    element: <KizunaDetail />
  },
  [SANDBOX]: {
    path: "sandbox",
    element: <Sandbox />
  },
  [NOT_FOUND]: {
    path: "*",
    element: <NotFound />
  }
}
