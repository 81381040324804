import ReactGA from "react-ga"

export const initializeTracking = ({ gaTrackingID }) => {
  ReactGA.initialize(gaTrackingID)
  ReactGA.pageview(window.location.pathname + window.location.search)
}

export const eventTrack = ({ category, action, label, value }) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  })
}
