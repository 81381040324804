import Footer from "components/Footer"
import Header from "components/Header"
import Banner from "components/Banner"
import Applications from "./components/Applications"
import ColorPalette from "./components/ColorPalette"
import ProductDetails from "modules/breeze/components/ProductDetails"
import { NAV_ITEMS, FOOTER_ITEMS, GLOBAL_SITE_TAG } from "modules/breeze/constants"
import { SLIDE } from "modules/breeze/components/Banner/constants"
import Permacool from "./components/Permacool"
import ContactForm from "components/ContactForm"
import WhereToBuy from "./components/WhereToBuy"
import { FormattedMessage } from "react-intl"
import { COUNTRIES_FOR_USA } from "consts/countryCodes"
import Analytics from "components/Analytics"

const Breeze = () => (
  <>
    <Analytics gtag={GLOBAL_SITE_TAG} />
    <Header items={NAV_ITEMS} isTransparent />
    <div className="content">
      <Banner slides={SLIDE} />
      <Permacool id={NAV_ITEMS[0]} />
      <ColorPalette id={NAV_ITEMS[1]} />
      <Applications id={NAV_ITEMS[2]} />
      <ProductDetails id={NAV_ITEMS[3]} />
      <ContactForm
        id={NAV_ITEMS[4]}
        title={
          <FormattedMessage
            id="ContactForm.title"
            description="section title for ContactForm"
            defaultMessage="Want more information about {pattern} ?"
            values={{
              pattern: "Breeze"
            }}
          />
        }
        countryOptions={COUNTRIES_FOR_USA}
        patternSlug="breeze"
        landingSource="breeze"
      />
      <WhereToBuy id={NAV_ITEMS[5]} />
    </div>
    <Footer hasItems items={FOOTER_ITEMS} />
  </>
)

export default Breeze
