import { defineMessages } from "react-intl"
import facilLimpieza from "modules/pranna/components/ProductDetails/img/facil-limpieza.svg"
import impermeabilidad from "modules/pranna/components/ProductDetails/img/impermeabilidad.svg"
import microorganismos from "modules/pranna/components/ProductDetails/img/microorganismos.svg"
import retardanciaFuego from "modules/pranna/components/ProductDetails/img/retardancia-fuego.svg"
import abrasion from "modules/pranna/components/ProductDetails/img/abrasion.svg"
import durabilidadColor from "modules/pranna/components/ProductDetails/img/durabilidad-color.svg"
import bajasTemperaturas from "modules/pranna/components/ProductDetails/img/bajas-temperaturas.svg"
import petFriendlyIcon from "modules/floors/components/ProductDetails/img/pet-friendly.svg"

export const MSGS = defineMessages({
  facilLimpieza: {
    id: "Pranna.ProductDetail.easyCleaning",
    description: "Pranna section product detail item easy cleaning",
    defaultMessage: "Fácil {br} Limpieza"
  },
  impermeabilidad: {
    id: "Pranna.ProductDetail.impermeability",
    description: "Pranna section product detail item impermeability",
    defaultMessage: "Impermeabilidad"
  },
  microorganismos: {
    id: "Pranna.ProductDetail.resistanceToMicroorganisms",
    description: "Pranna section product detail item microorganismos",
    defaultMessage: "Resistencia A {br} Microorganismos"
  },
  retardanciaFuego: {
    id: "Pranna.ProductDetail.flameRetardancy",
    description: "Pranna section product detail item flame retardancy",
    defaultMessage: "Retardancia {br} Al Fuego"
  },
  abrasion: {
    id: "Pranna.ProductDetail.abrationRetardancy",
    description: "Pranna section product detail item abration retardancy",
    defaultMessage: "Resistencia {br} a la Abrasión"
  },
  durabilidadColor: {
    id: "Pranna.ProductDetail.colorDurability",
    description: "Pranna section product detail item color durability",
    defaultMessage: "Durabilidad {br} Del Color A La Luz"
  },
  bajasTemperaturas: {
    id: "Pranna.ProductDetail.lowTemperatureResistance",
    description: "Pranna section product detail item low temperature resistance",
    defaultMessage: "Resistencia A {br} Bajas Temperaturas"
  }
})

export const PRODUCT_DETAILS = [
  {
    id: "facilLimpieza",
    text: MSGS.facilLimpieza,
    icon: facilLimpieza
  },
  {
    id: "impermeabilidad",
    text: MSGS.impermeabilidad,
    icon: impermeabilidad
  },
  {
    id: "microorganismos",
    text: MSGS.microorganismos,
    icon: microorganismos
  },
  {
    id: "retardanciaFuego",
    text: MSGS.retardanciaFuego,
    icon: retardanciaFuego
  },
  {
    id: "abrasion",
    text: MSGS.abrasion,
    icon: abrasion
  },
  {
    id: "durabilidadColor",
    text: MSGS.durabilidadColor,
    icon: durabilidadColor
  },
  {
    id: "bajasTemperaturas",
    text: MSGS.bajasTemperaturas,
    icon: bajasTemperaturas
  },
  {
    id: "petfrinedly",
    icon: petFriendlyIcon
  }
]
