import gql from "graphql-tag"

export const createPdfHtml = gql`
  mutation createPdfFromHtml($input: CreatePDFHtmlInput!) {
    createPdfHtml(input: $input) {
      pdf
    }
  }
`
export const uploadFiles = gql`
  mutation uploadFile($files: Upload) {
    uploadFile(file: $files) {
      url
    }
  }
`

export const createClaimMutation = gql`
  mutation createClaim($input: CreateClaimInput!) {
    createClaim(input: $input) {
      claim {
        id
        ticket
        instance {
          id
          name
        }
        companyName
        customerNumber
        fullName
        email
        quantity
        uploadedZip
        product {
          id
          color
          isDiscontinued
          pattern {
            id
            altName
          }
        }
        otherProduct
        otherColor
        batch
        date
        currentCondition {
          id
          conditions
          application
          describeApplicationMaterial
          installedIn
          placeWhereIsInstalled
          dateOfPurchase
          dateOfMaterialInstalled
          dateOfMaterialTransformed
          whenDetectDifference
          conditionsOfMaterial
          describeConditionsOfMaterial
        }
        claimReason {
          id
          reasons
          others
        }
        pleats {
          id
          materialIsMarked
          materialIsCoated
          foldsInMaterial
          marksOnDelivery
          whereMarksAre
          describeWhereMarksAre
        }
        materialresistance {
          id
          adequateProductResistance
          ifOtherDescription
          knowTheRecomendations
          howDetectedNonConformity
          howItTransforms
        }
        differencesinhand {
          id
          describeTheDifference
          howDetectDifference
          howAffectsDifference
        }
        differencesinmaterialdimensions {
          id
          howMeasuredMaterial
          indicateDifference
        }
        staining {
          id
          typeOfStain
          knowTheInstructions
          protectTheProduct
          whichProductUse
        }
        visualdefects {
          id
          typeVisualDifference
          howDetectDifference
          howCompareMaterial
        }
        surfacedeterioration {
          id
          suggestedInstructions
          describeProcessUsed
          theMaterialContaminated
        }
        colourfading {
          id
          cleaningAgentsUsed
          protectProductCushions
          surfaceContact
        }
        facialdefects {
          id
          description
          defect
        }
        colordifference {
          id
          colorDifferenceDescription
        }
      }
    }
  }
`
