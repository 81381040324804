export const SLIDE = ({ id, imageThumb, title, description, link, buttonText }) => [
  {
    id: `slide-sustainability-reports-${id}`,
    imageThumb,
    title,
    description,
    link,
    buttonText
  }
]
