import classNames from "classnames/bind"
import PropTypes from "prop-types"
import RelatedBrandCell from "../RelatedBrandCell"

const Specs = ({ pattern }) => (
  <table className="table table-striped product-specs-table my-5">
    <tbody>
      {pattern.specsSheetData.general.map((obj, index) => (
        <tr key={index}>
          <td
            className={classNames({
              "align-middle": obj.value?.slug
            })}
          >
            {obj.message}
          </td>
          <td>
            {obj.value?.slug ? (
              <RelatedBrandCell brand={obj.value} />
            ) : obj.value.indexOf(".svg") > -1 ? (
              <img src={obj.value} alt={obj.message} height={32} />
            ) : (
              obj.value
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

Specs.propTypes = {
  pattern: PropTypes.any
}

export default Specs
