import PropTypes from "prop-types"
import classNames from "classnames/bind"
import MethodIcon from "components/StoreFinder/components/MethodIcon"
import "./styles/StoreFinderBoxLayout.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const StoreFinderBoxLayout = ({ title, icon, children, noPadding = false, goBack }) => {
  return (
    <div className="store-finder-box">
      {title && (
        <header className="store-finder-box--container">
          <div className="d-flex flex-row align-items-center">
            {goBack && (
              <button className="btn store-finder-box--btn m-3" onClick={goBack}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
            )}
            <span className="me-auto">
              <MethodIcon icon={icon} />
              <span className="store-finder-box--title text-size-md--down-small">{title}</span>
            </span>
          </div>
        </header>
      )}
      <div
        className={classNames({
          "store-finder-box__content": true,
          "p-3": !noPadding
        })}
      >
        {children}
      </div>
    </div>
  )
}

StoreFinderBoxLayout.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.oneOf(["fabric", "store"]),
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
  goBack: PropTypes.func
}

export default StoreFinderBoxLayout
