import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import ColorPaletteGeneric from "components/ColorPalette"
import { COLORS } from "./constants"

const ColorPalette = ({ id }) => (
  <div id={id}>
    <div className="container my-2 py-4">
      <div className="row justify-content-md-center">
        <TitleUnderlined hTag={H2_TAG}>
          <FormattedMessage
            id="Pranna.ColorPallete.title"
            description="Pranna section color pallete contact"
            defaultMessage="Paleta de colores"
          />
        </TitleUnderlined>
        <div className="col-12">
          <ColorPaletteGeneric colors={COLORS} />
        </div>
      </div>
    </div>
  </div>
)

ColorPalette.propTypes = {
  id: PropTypes.string.isRequired
}

export default ColorPalette
