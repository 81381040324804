import PropTypes from "prop-types"
import img from "../../../../../Banner/img/banner.jpg"
import "./styles/keep-in-mind.scss"
import classNames from "classnames/bind"

const KeepInMind = ({ title, subtitle, list, description, approvedCleaners }) => (
  <div className="col-12">
    <div className="col-12 pt-4 pb-4 pt-lg-5 mb-lg-5 px-0">
      <div className="col-12 col-lg-8 p-0 keep-in-mind-bg h-auto">
        <div className={classNames({
          "col-7 col-lg-4 keep-in-mind-description": true
        })}>
          <div className="box-hw">
            <h1 className={classNames({
              "mb-4 fw-bold  keep-in-mind-title text-center": true
            })}>{title}</h1>
            <p className="paragraph subtitle-keep-in-mind mb-2">{subtitle}</p>
            <span className="d-block mb-4">{description && <p className="mt-3 do-not-use-alcohol-description">{description}</p>}</span>
            {list && (
              <ul className="p-0 ps-3 keep-in-mind-description-list">
                {list.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            )}
            {approvedCleaners && <p className="mb-2 title-approve-cleaners">{approvedCleaners.title}</p>}
            {approvedCleaners && <p className="mt-3 approve-cleaners-description">{approvedCleaners.description}</p>}
            {approvedCleaners && approvedCleaners.list && (
              <ul>
                {approvedCleaners.list.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)

KeepInMind.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  list: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
  approvedCleaners: PropTypes.object
}

export default KeepInMind
