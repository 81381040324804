import PropTypes from "prop-types"
import Slider from "react-slick"
import "components/Banner/components/HomeSlide/styles/home-slide.scss"
import SampleCard from "../SampleCard"

const SLIDER_OPTIONS = {
  arrows: false,
  dots: true,
  infinite: false,
  lazyLoad: true,
  adaptiveHeight: true,
  rows: 2,
  slidesPerRow: 1,
  slidesToShow: 2,
  slidesToScroll: 2
}

const SampleSliderList = ({ design, samples, handleShowInfoBox }) => (
  <Slider {...SLIDER_OPTIONS} className="my-4 pt-4 slider-cards--masiala">
    {samples.map((sample) => (
      <SampleCard
        key={`key_${sample.reference}_${sample.color}`}
        design={design}
        sample={sample}
        handleShowInfoBox={handleShowInfoBox}
      />
    ))}
  </Slider>
)

SampleSliderList.propTypes = {
  samples: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
      color: PropTypes.string
    })
  ),
  design: PropTypes.string,
  handleShowInfoBox: PropTypes.func
}

export default SampleSliderList
