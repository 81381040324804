import bio from "./imgs/bio.svg"

import hiLoftRPET from "./imgs/hi-loft-rpet.svg"
import globalRecicledStandar from "./imgs/global-recicled-standar.svg"
import indoorAir from "./imgs/indoor-air.svg"
import greenGuard from "./imgs/green-guard.svg"
import greenBuilding from "./imgs/green-building.svg"
import internationalWeelBuilding from "./imgs/intenational-well-building.svg"

import carbonNeutral from "./imgs/carbon-neutral.svg"
import iso14001 from "./imgs/iso-14001.svg"
import iso45001 from "./imgs/iso-45001.svg"
import iso50001 from "./imgs/iso-50001.svg"

import reachCompliant from "./imgs/reach-compliant.svg"
import rohsCompliant from "./imgs/rohs-compliant.svg"
import prop65Compliant from "./imgs/prop-65-compilant.svg"
import abCompilant from "./imgs/ab-compilant-2998.svg"

import useToggle from "hooks/useToggle"
import SustainableInfo from "./components/SustainableInfo"
import "./styles/sustainable-elements.scss"
import SustainableModal from "./components/SustainableModal"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"
import { useStateMachine } from "little-state-machine"
import useRequestModal from "modules/masiala/useRequestModal"

const SustainableElements = () => {
  const isMobile = useMediaQuery(LG)
  const [toggable, toggle] = useToggle()
  const { state } = useStateMachine()
  const { handleShowSustainabilityModal } = useRequestModal()

  return (
    <div className="container g-0">
      <div className="mt-3">
        <div className="container--over-polymer col-12 d-flex col-lg-8 justify-content-center align-items-center px-4 px-lg-0 mb-3 mb-lg-0">
          <img
            style={
              !isMobile
                ? { width: 80, height: 64 }
                : { width: 68, height: 56 }
            }
            src={bio} alt="bio atributed polymer"
          />
          <p className="m-0 mx-2 text-justify">
            <strong className="sustainable-over-relevant-wrd">
              Over 50%
            </strong> of the polymer made from bio-attributed components sourced from
            the forestry industry.
          </p>
        </div>

        <div className="row justify-content-center align-items-center text-center">
          <div className="col-12 col-lg-4 my-2 my-lg-0">
            <div className="container--backing-profile">
              <p className="sustainable-title--masiala">
                Backing profile
              </p>
              <hr className="p-0 m-0 col-8 mx-auto d-block d-lg-none sustainable-divider--masiala" />
              <div className="row justify-content-center align-items-end h-80">
                <div className="col-5 col-md-3 col-lg-6 position-relative align-self-end h-80">
                  <SustainableInfo
                    text="100% Recycled Hi-Loft Yarns"
                    isMobile={isMobile}
                    >
                    <img
                      className="img-sustainable-backing-profile-rpet"
                      style={
                        !isMobile
                          ? { width: 159, height: 60 }
                          : { width: 136, height: 32 }
                      }
                      src={hiLoftRPET}
                      alt="hi loft RPET"
                    />
                  </SustainableInfo>
                </div>
                <div className="col-5 col-md-3 col-lg-6 position-relative align-self-end h-80">
                  <SustainableInfo
                    text="Recognizes products meeting international recycling standards."
                    isMobile={isMobile}
                  >
                    <img
                      className="img-sustainable-backing-profile-global"
                      style={
                        !isMobile
                          ? { width: 110, height: 50 }
                          : { width: 112, height: 40 }
                      }
                      src={globalRecicledStandar}
                      alt="recicled global standar"
                    />
                  </SustainableInfo>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 my-2 my-lg-0">
            <div className="container--backing-profile">
              <p className="sustainable-title--masiala">
                Certifications
              </p>
              <hr className="p-0 m-0 col-8 mx-auto d-block d-lg-none sustainable-divider--masiala" />
              <div className="d-flex justify-content-center">
                <div className="row justify-content-center align-items-end h-80 w-75">
                  <div className="col-5 col-md-2 col-lg-6 col-xl-5 position-relative align-self-end h-80">
                    <SustainableInfo
                      text="Sets high air purity standards, fostering health and wellness in spaces."
                      isMobile={isMobile}
                    >
                      <img
                      className="img-sustainable-test"
                      style={{ width: 80, height: 72 }}
                        src={indoorAir} alt="inddor air quality"
                      />
                    </SustainableInfo>
                  </div>
                  <div className="col-5 col-md-2 col-lg-6 col-xl-5 position-relative align-self-end h-80">
                    <SustainableInfo
                      text="Adheres to strict emission standards, promoting healthier indoor spaces."
                      isMobile={isMobile}
                    >
                      <img
                      className="img-sustainable-test"
                      style={{ width: 72, height: 64 }}
                        src={greenGuard} alt="green guard"
                      />
                    </SustainableInfo>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 my-2 my-lg-0">
            <p className="sustainable-title--masiala">
              Seals we contribute to
            </p>
            <hr className="p-0 m-0 col-8 mx-auto d-block d-lg-none sustainable-divider--masiala" />
            <div className="d-flex justify-content-center">
              <div className="row justify-content-center align-items-end h-80">
                <div className="col-6 position-relative align-self-end h-80">
                  <SustainableInfo
                    text="Symbol of sustainable building practices, assessing environmental design."
                    isMobile={isMobile}
                  >
                    <img
                      className="img-sustainable-test"
                      style={{ width: 80, height: 72 }}
                      src={greenBuilding} alt="green building"
                    />
                  </SustainableInfo>
                </div>
                <div className="col-6 position-relative align-self-end h-80">
                  <SustainableInfo
                    text="Focuses on improving health and well-being through the built environment."
                    isMobile={isMobile}
                  >
                    <img
                      className="img-sustainable-test"
                      style={{ width: 144, height: 72 }}
                      src={internationalWeelBuilding}
                      alt="international well building"
                    />
                  </SustainableInfo>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center align-items-center text-center">
          <div className="col-12 col-lg-6 my-2 my-lg-0">
            <p className="sustainable-title--masiala">
              Manufacturing certifications
            </p>
            <hr className="p-0 m-0 col-8 mx-auto d-block d-lg-none sustainable-divider--masiala" />
            <div className="row justify-content-center align-items-center mt-3 mt-lg-0">
              <div className="col-5 col-md-2 col-lg-3 mb-lg-0 position-relative">
                <SustainableInfo
                  text="Certified for net-zero carbon emissions."
                  isMobile={isMobile}
                  >
                  <img
                      className="img-sustainable-manufacturing--carbon-neutral"
                      style={{ width: 104, height: 56 }}
                    src={carbonNeutral} alt="carbon neutral"
                  />
                </SustainableInfo>
              </div>
              <div className="col-5 col-md-2 col-lg-3 mb-lg-0 position-relative">
                <SustainableInfo
                  text="Adheres to environmental management system standards."
                  isMobile={isMobile}
                  >
                  <img
                      className="img-sustainable-manufacturing--iso14001"
                      style={{ width: 72, height: 56 }}
                    src={iso14001} alt="iso14001"
                  />
                </SustainableInfo>
              </div>
              <div className="col-5 col-md-2 col-lg-3 position-relative">
                <SustainableInfo
                  text="Meets international standards for occupational safety and health management."
                  isMobile={isMobile}
                  >
                  <img
                      className="img-sustainable-manufacturing--iso45001"
                      style={{ width: 72, height: 56 }}
                    src={iso45001} alt="iso45001"
                  />
                </SustainableInfo>
              </div>
              <div className="col-5 col-md-2 col-lg-3 position-relative">
                <SustainableInfo
                  text="Complies with energy management system standards."
                  isMobile={isMobile}
                  >
                  <img
                      className="img-sustainable-manufacturing--iso50001"
                      style={{ width: 72, height: 56 }}
                    src={iso50001} alt="iso50001"
                  />
                </SustainableInfo>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 my-2 my-lg-0">
            <p className="sustainable-title--masiala">
              Compliances
            </p>
            <hr className="p-0 m-0 col-8 mx-auto d-block d-lg-none sustainable-divider--masiala" />
            <div className="row justify-content-center align-items-center mt-3 mt-lg-0">
              <div className="col-5 col-md-2  col-lg-3 position-relative sustainable-compilance-container-img">
                <SustainableInfo
                  text="Compliant with EU chemical safety regulations."
                  isMobile={isMobile}
                  >
                  <img
                      className="img-sustainable-compilances--reach"
                      style={
                      !isMobile
                        ? { width: 104, height: 56 }
                        : { width: 80, height: 56 }
                    }
                    src={reachCompliant}
                    alt="reach compliant"
                  />
                </SustainableInfo>
              </div>
              <div className="col-5 col-md-2  col-lg-3 position-relative sustainable-compilance-container-img ps-3 ps-xl-0">
                <SustainableInfo
                  text="Adheres to the Restriction of Hazardous Substances Directive."
                  isMobile={isMobile}
                  >
                  <img
                      className="img-sustainable-compilances--rohs"
                      style={
                      !isMobile
                        ? { width: 80, height: 56 }
                        : { width: 66, height: 45 }
                    }
                    src={rohsCompliant}
                    alt="rohs compliant"
                  />
                </SustainableInfo>
              </div>
              <div className="col-5 col-md-2  col-lg-3 position-relative sustainable-compilance-container-img">
                <SustainableInfo
                  text="Complies with California warning label compliance law."
                  isMobile={isMobile}
                  >
                  <img
                      className="img-sustainable-compilances--prop"
                      style={{ width: 56, height: 56 }}
                    src={prop65Compliant}
                    alt="prop 65 compliant"
                  />
                </SustainableInfo>
              </div>
              <div className="col-5 col-md-2  col-lg-3 position-relative sustainable-compilance-container-img">
                <SustainableInfo
                  text="AB 2998: Prohibits the sale and distribution of upholstered furniture containing flame retardant chemicals at levels about 1,000 parts per million."
                  isMobile={isMobile}
                  >
                  <img
                      className="img-sustainable-compilances--abCompilant"
                      style={
                      !isMobile
                        ? { width: 106, height: 56 }
                        : { width: 80, height: 56 }
                    }
                    src={abCompilant}
                    alt="ab compilant 2998"
                  />
                </SustainableInfo>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3 mt-lg-5 mb-3">
        <button className="btn sustainable-btn--masiala" onClick={() => handleShowSustainabilityModal()}>
          MORE ABOUT SUSTAINABLE ATTRIBUTES
        </button>
      </div>
      <SustainableModal
        isOpen={state.showSustainabilityModal ?? false}
        handleToggle={handleShowSustainabilityModal}
      />
    </div>
  )
}

export default SustainableElements
