import axios from "axios"
import { COUNTRY_CODE_LATAM, COUNTRY_CODE_MX, COUNTRY_CODE_USA } from "consts/countryCodes"
import { GEOLOCATION_ENDPOIND } from "consts/geolocation"

export const getCurrentInstanceId = () => {
  const { location } = window
  const parts = location.pathname.split("/")
  const instanceCode = parts[1].split("-")[1]

  switch (instanceCode) {
    case "la":
      return process.env.REACT_APP_LATAM_INSTANCE_ID

    case "mx":
      return process.env.REACT_APP_MX_INSTANCE_ID

    case "us":
      return process.env.REACT_APP_USA_INSTANCE_ID

    case "eu":
      return process.env.REACT_APP_EU_INSTANCE_ID

    case "sm":
      return process.env.REACT_APP_SM_INSTANCE_ID

    default:
      return process.env.REACT_APP_LATAM_INSTANCE_ID
  }
}

export const isUSA = () => getCurrentInstanceId() === process.env.REACT_APP_USA_INSTANCE_ID
export const isLATAM = () => getCurrentInstanceId() === process.env.REACT_APP_LATAM_INSTANCE_ID
export const isMEXICO = () => getCurrentInstanceId() === process.env.REACT_APP_MX_INSTANCE_ID
export const isEUROPE = () => getCurrentInstanceId() === process.env.REACT_APP_EU_INSTANCE_ID
export const isMARINE = () => getCurrentInstanceId() === process.env.REACT_APP_SM_INSTANCE_ID

const getBrowserLanguage = () => {
  return navigator.language.split(/[-_]/)[0]
}

const getInstanceCode = () => {
  axios(GEOLOCATION_ENDPOIND)
    .then(({ country_code: countryCode, ...data }) => {
      if (COUNTRY_CODE_LATAM.includes(countryCode)) {
        return "la"
      } else if (COUNTRY_CODE_MX.includes(countryCode)) return "mx"
      else if (COUNTRY_CODE_USA.includes(countryCode)) return "us"
    })
    .catch((error) => console.log(error))
}

export const getCurrentLanguageAndInstanceCode = (language, instanceCode) => {
  const path = window.location.pathname.split("/")
  let lang = language
  let instance = instanceCode
  const regexp = /^([a-z]{2})-([a-z]{2})$/
  if (path.length >= 2) {
    path.shift() // removes the first value which is an empty string
    const firstPart = path.shift() // gets the first part of the path, which is supposed to be "es-la", for example
    if (regexp.test(firstPart)) {
      const matches = firstPart.match(regexp)
      lang = matches[1]
      instance = matches[2]
    }
  }
  return [lang, instance]
}

const getLangAndInstanceCode = () => {
  const language = getBrowserLanguage()
  const instanceCode = getInstanceCode()
  getCurrentLanguageAndInstanceCode(language, instanceCode)
}

getLangAndInstanceCode()
