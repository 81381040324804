import PropTypes from "prop-types"
import Menu from "./components/Menu"
import { instanceQuery } from "components/Footer/query"
import { useQuery } from "@apollo/client"
import { getCurrentInstanceId } from "services/instances"
import "./styles/header.scss"

const Header = ({ items: itemsProp }) => {
  const { data } = useQuery(instanceQuery, {
    variables: {
      instanceId: getCurrentInstanceId()
    }
  })

  return (
    <header className="header-glassmorphism fixed-top">
      <Menu
        className="header-glassmorphism"
        logo={data && data.instance.logo}
        items={itemsProp}
        objItems={null}
      />
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  objItems: PropTypes.arrayOf(PropTypes.object),
  isSticky: PropTypes.bool,
  isTransparent: PropTypes.bool
}

export default Header
