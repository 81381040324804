import gql from "graphql-tag"

export const patternsQuery = gql`
  query PatternsQuery($instanceIds: [ID]!, $saleable: Boolean, $patternClass: String) {
    patterns(products_Instances: $instanceIds, saleable: $saleable, patternClass: $patternClass) {
      edges {
        node {
          id
          name
          altName
          patternClass
          date
          productcategorization {
            warranty
            status
          }
        }
      }
    }
  }
`
