/* eslint-disable */
import "./style/CustomSpinner.scss"

export const CustomSpinner = () => {
  return (
    <div className="container-loader-result-samplesList">
        <div class="spinner-border custom-spinner" role="status">
          <span class="sr-only"></span>
        </div> 
    </div>
  )
}
