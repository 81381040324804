import { defineMessages } from "react-intl"
import { REASONS, REASON_OPTIONS_MSGS } from "../../Step2/constants/reason"

export const CLAIM_REASONS_LEFT_SECTIONS_TITLE_MSGS = defineMessages({
  [REASONS.COLOR_DIFFERENCE]: REASON_OPTIONS_MSGS.colourDifference,
  [REASONS.FACIAL_DEFFECTS]: REASON_OPTIONS_MSGS.facialDefects,
  [REASONS.PLEATS]: REASON_OPTIONS_MSGS.pleats,
  [REASONS.MATERIAL_RESISTANCE]: REASON_OPTIONS_MSGS.materialResistance,
  [REASONS.DIFFERENCES_IN_HAND]: REASON_OPTIONS_MSGS.differencesInHand,
  [REASONS.VISUAL_DEFFECTS]: REASON_OPTIONS_MSGS.visualDefects,
  [REASONS.DIFFERENCES_IN_DIMENSION]: REASON_OPTIONS_MSGS.differencesInDimension,
  [REASONS.COLOR_FADING]: REASON_OPTIONS_MSGS.colourFading,
  [REASONS.STAINING]: REASON_OPTIONS_MSGS.staining,
  [REASONS.SURFACE_DETERIORATION]: REASON_OPTIONS_MSGS.SurfaceDeterioration,
  [REASONS.OTHER]: REASON_OPTIONS_MSGS.other
})
export const CLAIM_REASONS_LEFT_SECTIONS_DESCRIPTION_MSGS = defineMessages({
  [REASONS.OTHER]: REASON_OPTIONS_MSGS.otherDescription
})
