import SamplingRequest from "modules/rivulet/components/SamplingRequest"
import ModalContext from "modules/rivulet/components/SamplingRequest/context"
import PropTypes from "prop-types"

const RequestsSection = ({ modalContext, toggable, toggle }) => {
  return (
    <>
      <ModalContext.Provider value={modalContext ?? ""}>
        <SamplingRequest isOpen={toggable} toggle={toggle} />
      </ModalContext.Provider>
    </>
  )
}

RequestsSection.propTypes = {
  modalContext: PropTypes.object,
  toggable: PropTypes.bool,
  toggle: PropTypes.func
}

export default RequestsSection
