import PropTypes from "prop-types"
import SampleCard from "../../../SampleCard"

const SamplesListDesktop = ({ staticSampleData, samples, handleClick }) =>
  samples.map((item) => (
    <SampleCard
      key={`item_${item.color}`}
      staticSampleData={staticSampleData}
      sample={item}
      handleClick={handleClick}
    />
  ))

SamplesListDesktop.propTypes = {
  staticSampleData: PropTypes.object.isRequired,
  samples: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default SamplesListDesktop
