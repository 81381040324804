import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import Skeleton from "react-loading-skeleton"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import Card from "./components/Card"

const Reports = ({ reports, loading }) => (
  <div className="container my-2 py-4">
    <div className="row justify-content-md-center">
      <TitleUnderlined hTag={H2_TAG}>
        {!loading ? (
          <FormattedMessage
            id="SustainabilityReports.title"
            description="SustainabilityReports section title"
            defaultMessage="Informes"
          />
        ) : (
          <Skeleton width={200} />
        )}
      </TitleUnderlined>
      <div className="col-12">
        <div className="row justify-content-evenly align-items-center">
          {!loading
            ? reports.edges.map(({ node: report }) => (
                <Card key={`key_${report.id}`} report={report} loading={loading} />
                // eslint-disable-next-line indent
              ))
            : [1, 2, 3].map((i) => <Card key={`key_${i}`} loading={loading} />)}
        </div>
      </div>
    </div>
  </div>
)

Reports.propTypes = {
  reports: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string,
          imageThumbMedium: PropTypes.string,
          pdf: PropTypes.string,
          date: PropTypes.string
        })
      })
    )
  }),
  loading: PropTypes.bool
}

export default Reports
