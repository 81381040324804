import { useStateMachine } from "little-state-machine"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import RequestsSection from "../RequestsSection"
import useRequestModal from "../RequestsSection/hooks/useRequestModal"
import { REQUEST_SAMPLES_INFO } from "../RequestsSection/utils"
import ProductSpecs from "./components/ProductSpecs"
import SamplesList from "./components/SamplesList"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"
import { SAMPLES } from "./constants"
import "./styles/sample-details.scss"

const SamplesDetails = ({ id }) => {
  const { state } = useStateMachine()
  const { modalContext, toggable, toggle, handleClickInRequest } = useRequestModal()
  const isMobile = useMediaQuery(LG)

  const firstSample = SAMPLES[0]

  const openRequestModal = () => {
    handleClickInRequest(REQUEST_SAMPLES_INFO)
  }

  return (
    <section id={id} className="my-5">
      <div className="container">
        <div className="row g-0">
          <div className="container-title--sm-rv">
            <h2 className="title--sm-rv">
              <FormattedMessage
                id="Rivulet.ProductDetails.title"
                defaultMessage="Product Details"
              />
            </h2>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-12 col-lg-6 d-xl-flex justify-content-xl-end">
          <img
            className="img-fluid large-img--sm-rv"
            src={state.sampleDetails ? state?.sampleDetails?.imgDetail : firstSample.imgDetail}
            alt="sample-master-image"
          />
        </div>
        <div className="col-12 col-lg-6 container-info--sm-rv">
          <h3 className="subtitle--sm-rv">Rivulet</h3>
          <p className="reference--sm-rv my-0 mb-lg-3">
            {state.sampleDetails ? state?.sampleDetails?.description : firstSample.description}
          </p>
          {!isMobile ? <ProductSpecs /> : <SamplesList />}
        </div>
      </div>
      <div className="my-2 my-lg-5">
        {!isMobile ? (
          <SamplesList />
        ) : (
          <div className="col-12 container-info--sm-rv">
            <ProductSpecs />
          </div>
        )}
        <div className="text-center mt-4 mt-lg-0">
          <button className="btn btn-rv btn--desc-rv" onClick={openRequestModal}>
            <FormattedMessage id="Riuvlet.Banner.buttonText" defaultMessage="Request Sample Card" />
          </button>
        </div>
      </div>
      <RequestsSection modalContext={modalContext} toggable={toggable} toggle={toggle} />
    </section>
  )
}

SamplesDetails.propTypes = {
  id: PropTypes.string.isRequired
}

export default SamplesDetails
