import PropTypes from "prop-types"
import questionMark from "./img/question-mark.svg"
import "./styles/sustainable-info.scss"

const SustainableInfo = ({ text, children, isMobile }) => (
  <>
    {
      !isMobile
        ? <>
        <div className="hover-text cursor-pointer">
          <img
            className="question-marl-icon--masiala hover-text"
            src={questionMark}
            alt="question mark"
          />
          <span className="tooltip-text">
            {text}
          </span>
        </div>
        <div className="hover-text cursor-pointer">
          {children}
          <span className="tooltip-text">
            {text}
          </span>
        </div>
        </> : children
    }
  </>
)

SustainableInfo.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  isMobile: PropTypes.bool
}

export default SustainableInfo
