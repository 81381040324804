import scarlet from "./img/products/plain/1_scarlet.jpg"
import mesa from "./img/products/plain/2_mesa.jpg"
import apricot from "./img/products/plain/3_apricot.jpg"
import grapefruit from "./img/products/plain/4_grapefruit.jpg"
import butterscotch from "./img/products/plain/5_butterscotch.png"
import clover from "./img/products/plain/6_clover.jpg"
import leaf from "./img/products/plain/7_leaf.jpg"
import olive from "./img/products/plain/8_olive.jpg"
import pistachio from "./img/products/plain/9_pistachio.jpg"
import eucalyptus from "./img/products/plain/10_eucalyptus.png"
import navy from "./img/products/plain/11_navy.png"
import pool from "./img/products/plain/12_pool.jpg"
import capri from "./img/products/plain/13_capri.jpg"
import mist from "./img/products/plain/14_mist.jpg"
import river from "./img/products/plain/15_river.jpg"
import darkRoast from "./img/products/plain/16_darkRoast.jpg"
import sepia from "./img/products/plain/17_sepia.jpg"
import chai from "./img/products/plain/18_chai.jpg"
import mushroom from "./img/products/plain/19_mushroom.jpg"
import charcoal from "./img/products/plain/20_charcoal.png"
import stone from "./img/products/plain/21_stone.png"
import pebble from "./img/products/plain/22_pebble.png"
import fog from "./img/products/plain/23_fog.png"
import stormCloud from "./img/products/plain/24_stormCloud.jpg"
// MONTAIN IMAGES
import scarletMontain from "./img/products/mountain compressed/1_scarlet.jpg"
import mesaMontain from "./img/products/mountain compressed/2_mesa.jpg"
import apricotMontain from "./img/products/mountain compressed/3_apricot.jpg"
import grapefruitMontain from "./img/products/mountain compressed/4_grapefruit.jpg"
import butterscotchMontain from "./img/products/mountain compressed/5_butterscotch.jpg"
import cloverMontain from "./img/products/mountain compressed/6_clover.jpg"
import leafMontain from "./img/products/mountain compressed/7_leaf.jpg"
import oliveMontain from "./img/products/mountain compressed/8_olive.jpg"
import pistachioMontain from "./img/products/mountain compressed/9_pistachio.jpg"
import eucalyptusMontain from "./img/products/mountain compressed/10_eucalyptus.jpg"
import navyMontain from "./img/products/mountain compressed/11_navy.jpg"
import poolMontain from "./img/products/mountain compressed/12_pool.jpg"
import capriMontain from "./img/products/mountain compressed/13_capri.jpg"
import mistMontain from "./img/products/mountain compressed/14_mist.jpg"
import riverMontain from "./img/products/mountain compressed/15_river.jpg"
import darkRoastMontain from "./img/products/mountain compressed/16_darkRoast.jpg"
import sepiaMontain from "./img/products/mountain compressed/17_sepia.jpg"
import chaiMontain from "./img/products/mountain compressed/18_chai.jpg"
import mushroomMontain from "./img/products/mountain compressed/19_mushroom.jpg"
import charcoalMontain from "./img/products/mountain compressed/20_charcoal.jpg"
import stoneMontain from "./img/products/mountain compressed/21_stone.jpg"
import pebbleMontain from "./img/products/mountain compressed/22_pebble.jpg"
import fogMontain from "./img/products/mountain compressed/23_fog.jpg"
import stormCloudMontain from "./img/products/mountain compressed/24_stormCloud.jpg"

export const SAMPLES = [
  {
    id: "1_scarlet",
    description: "scarlet",
    imgDetail: scarletMontain,
    img: scarlet
  },
  {
    id: "2_mesa",
    description: "mesa",
    imgDetail: mesaMontain,
    img: mesa
  },
  {
    id: "3_apricot",
    description: "apricot",
    imgDetail: apricotMontain,
    img: apricot
  },
  {
    id: "4_grapefruit",
    description: "grapefruit",
    imgDetail: grapefruitMontain,
    img: grapefruit
  },
  {
    id: "5_butterscotch",
    description: "butterscotch",
    imgDetail: butterscotchMontain,
    img: butterscotch
  },
  {
    id: "6_clover",
    description: "clover",
    imgDetail: cloverMontain,
    img: clover
  },
  {
    id: "7_leaf",
    description: "leaf",
    imgDetail: leafMontain,
    img: leaf
  },
  {
    id: "8_olive",
    description: "olive",
    imgDetail: oliveMontain,
    img: olive
  },
  {
    id: "9_pistachio",
    description: "pistachio",
    imgDetail: pistachioMontain,
    img: pistachio
  },
  {
    id: "10_eucalyptus",
    description: "eucalyptus",
    imgDetail: eucalyptusMontain,
    img: eucalyptus
  },
  {
    id: "11_navy",
    description: "navy",
    imgDetail: navyMontain,
    img: navy
  },
  {
    id: "12_pool",
    description: "pool",
    imgDetail: poolMontain,
    img: pool
  },
  {
    id: "13_capri",
    description: "capri",
    imgDetail: capriMontain,
    img: capri
  },
  {
    id: "14_mist",
    description: "mist",
    imgDetail: mistMontain,
    img: mist
  },
  {
    id: "15_river",
    description: "river",
    imgDetail: riverMontain,
    img: river
  },
  {
    id: "16_darkRoast",
    description: "dark roast",
    imgDetail: darkRoastMontain,
    img: darkRoast
  },
  {
    id: "17_sepia",
    description: "sepia",
    imgDetail: sepiaMontain,
    img: sepia
  },
  {
    id: "18_chai",
    description: "chai",
    imgDetail: chaiMontain,
    img: chai
  },
  {
    id: "19_mushroom",
    description: "mushroom",
    imgDetail: mushroomMontain,
    img: mushroom
  },
  {
    id: "20_charcoal",
    description: "charcoal",
    imgDetail: charcoalMontain,
    img: charcoal
  },
  {
    id: "23_stormCloud",
    description: "storm cloud",
    imgDetail: stormCloudMontain,
    img: stormCloud
  },
  {
    id: "21_stone",
    description: "stone",
    imgDetail: stoneMontain,
    img: stone
  },
  {
    id: "22_pebble",
    description: "pebble",
    imgDetail: pebbleMontain,
    img: pebble
  },
  {
    id: "23_fog",
    description: "fog",
    imgDetail: fogMontain,
    img: fog
  }
]
