import { FormattedMessage } from "react-intl"

import hospitality from "./img/hospitality.svg"
import healthcare from "./img/healthcare.svg"
import residential from "./img/residential.svg"
import automotive from "./img/automotive.svg"
import publicSpaces from "./img/public-spaces.svg"
import corporate from "./img/corporate.svg"

export const APPLICATIONS_ELEMENTS = [
  {
    img: {
      srcImg: hospitality,
      style: { with: 30 }
    },
    description: (
      <FormattedMessage id="WhisperNeoPlus.Applications.hospitality" defaultMessage="Hospitality" />
    )
  },
  {
    img: {
      srcImg: healthcare,
      style: { with: 24 }
    },
    description: (
      <FormattedMessage id="WhisperNeoPlus.Applications.healthcare" defaultMessage="Healthcare" />
    )
  },
  {
    img: {
      srcImg: residential,
      style: { with: 28 }
    },
    description: (
      <FormattedMessage id="WhisperNeoPlus.Applications.residential" defaultMessage="Residential" />
    )
  },
  {
    img: {
      srcImg: automotive,
      style: { with: 24 }
    },
    description: (
      <FormattedMessage id="WhisperNeoPlus.Applications.automotive" defaultMessage="Automotive" />
    )
  },
  {
    img: {
      srcImg: publicSpaces,
      style: { with: 52 }
    },
    description: (
      <FormattedMessage id="Rivulet.specApplicationsPublicSpaces" defaultMessage="Public Spaces" />
    )
  },
  {
    img: {
      srcImg: corporate,
      style: { with: 36 }
    },
    description: (
      <FormattedMessage id="WhisperNeoPlus.Applications.corporate" defaultMessage="Corporate" />
    )
  }
]
