import { FormattedMessage } from "react-intl"
import healthcare from "../img/healthcare.png"
import hospitality from "../img/hospitality.png"
import education from "../img/education.png"
import residential from "../img/residential.png"
import corporate from "../img/corporate.png"

export const ITEMS = [
  {
    id: "Healthcare",
    title: (
      <FormattedMessage
        id="WhisperNeoPlus.Applications.healthcare"
        description="WhisperNeoPlus section applications item healthcare"
        defaultMessage="Healthcare"
      />
    ),
    imageThumb: healthcare
  },
  {
    id: "Hospitality",
    title: (
      <FormattedMessage
        id="WhisperNeoPlus.Applications.hospitality"
        description="WhisperNeoPlus section applications item hospitality"
        defaultMessage="Hospitality"
      />
    ),
    imageThumb: hospitality
  },
  {
    id: "Education",
    title: (
      <FormattedMessage
        id="WhisperNeoPlus.Applications.education"
        description="WhisperNeoPlus section applications item education"
        defaultMessage="Education"
      />
    ),
    imageThumb: education
  },
  {
    id: "Residential",
    title: (
      <FormattedMessage
        id="WhisperNeoPlus.Applications.residential"
        description="WhisperNeoPlus section applications item residential"
        defaultMessage="Residential"
      />
    ),
    imageThumb: residential
  },
  {
    id: "Corporate",
    title: (
      <FormattedMessage
        id="WhisperNeoPlus.Applications.corporate"
        description="WhisperNeoPlus section applications item corporate"
        defaultMessage="Corporate"
      />
    ),
    imageThumb: corporate
  }
]
