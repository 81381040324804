import gql from "graphql-tag"

export const trendsQuery = gql`
  query TrendsQuery {
    trends {
      edges {
        node {
          id
          name
          text
          slug
        }
      }
    }
  }
`
