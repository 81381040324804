import { useEffect, useState } from "react"
import { animateScroll as scroll } from "react-scroll"

const useHeaderSectionId = (defaultId) => {
  const [headerSectionId, setHeaderSectionId] = useState({
    id: defaultId,
    clickInHeader: false
  })

  return { headerSectionId, setHeaderSectionId }
}

export default useHeaderSectionId
