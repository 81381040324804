import PropTypes from "prop-types"
import Characteristics from "./components/Characteristics"
import ProductsLayout from "./components/ProductsLayout"

const Products = ({ id }) => (
  <section id={id} className="py-4 my-0 my-md-2">
    <Characteristics />
    <ProductsLayout />
  </section>
)

Products.propTypes = {
  id: PropTypes.string
}

export default Products
