import Layout from "modules/claims/components/Layout"
import { FormattedMessage } from "react-intl"
import { useLocation } from "react-router-dom"
import "../styles/step-form.scss"
import bgHero from "../../Layout/assets/img/hero.png"

const ThankYou = () => {
  const { state: createdClaim } = useLocation()

  return (
    <Layout
      title={
        <FormattedMessage
          id="Claims.ThankYou.title"
          description="section claim thank you title"
          defaultMessage="Thank you!"
        />
      }
      alignDescription="center"
      backgroundImage={bgHero}
    >
      <div>
        <p className="step-form--label">
          <FormattedMessage
            id="Claims.ThankYou.description"
            description="section claim thank you description"
            defaultMessage="Your claim has been submitted and will be processed. Please allow 5-10 working days for an initial feedback. A Customer Service Representative will contact you.{br}{br}Your claim reference number is: <strong>{ticket}</strong> {br}{br}Please indicate this number in all correspondence related with this claim.{br}{br} A copy of your claim registration will be sent to {email}"
            values={{
              email: createdClaim?.email,
              ticket: createdClaim?.ticket,
              br: <br />,
              strong: (...chunks) => <strong>{chunks}</strong>
            }}
          />
        </p>
      </div>
    </Layout>
  )
}

export default ThankYou
