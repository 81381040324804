import PropTypes from "prop-types"
import { Controller, useWatch } from "react-hook-form"
import { useLazyQuery } from "@apollo/client"
import { FormattedMessage, useIntl } from "react-intl"
import AsyncSelect from "react-select/async"
import regionsQuery from "./query"
import { MSGS } from "./constants"
import styles from "../styles"

const SELECT_OWN_STYLES = {
  ...styles,
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none" // Oculta la flecha
  }),
  indicatorSeparator: () => ({
    display: "none" // Oculta la línea separadora
  })
}

const SelectRegion = ({ name, control, defaultOptions }) => {
  const intl = useIntl()
  const { value: countryId } = useWatch({ control, name: "country" })
  const placeholder = intl.formatMessage(!countryId ? MSGS.withoutCountry : MSGS.withCountry)
  const [getRegions, { data }] = useLazyQuery(regionsQuery)

  const loadOptions = (_, callback) => {
    const options = data?.regions.edges.map((edge) => ({
      label: edge.node.name,
      value: edge.node.id
    }))

    callback(options)
  }

  const handleOnInputChange = (value) =>
    getRegions({
      variables: {
        countryId,
        query: value
      }
    })

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: handleOnChangeForm, ...field } }) => (
        <AsyncSelect
          styles={SELECT_OWN_STYLES}
          {...field}
          defaultOptions={defaultOptions}
          placeholder={placeholder}
          loadOptions={loadOptions}
          isDisabled={!countryId}
          onInputChange={handleOnInputChange}
          onChange={handleOnChangeForm}
          noOptionsMessage={() => (
            <FormattedMessage
              id="Form.field.noOptionesMessage"
              description="message when there's no options on a select field"
              defaultMessage="No options"
            />
          )}
        />
      )}
    />
  )
}

SelectRegion.propTypes = {
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultOptions: PropTypes.array
}

export default SelectRegion
