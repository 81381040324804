import gql from "graphql-tag"

const regionsQuery = gql`
  query RegionsQuery($countryId: ID, $query: String) {
    regions(country: $countryId, name_Icontains: $query) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export default regionsQuery
