import { useState, useContext } from "react"
import PropTypes from "prop-types"
import { Row, Col, Container, Modal, ModalHeader, ModalBody } from "reactstrap"
import RequestForm from "./components/RequestForm"
import ModalContext from "./context"
import "./styles/samplingrequest.scss"
import ThanksYouMessage from "modules/masiala/components/SamplingRequest/components/ThanksYouMessage"

const SamplingRequest = ({ isOpen, toggle }) => {
  const context = useContext(ModalContext)
  const [isThankYouMessage, setIsThankYouMessage] = useState(false)

  const handleIsThankYouMessage = () => setIsThankYouMessage(!isThankYouMessage)

  const handleToggle = () => {
    setIsThankYouMessage(false)
    toggle()
  }

  return (
    <Modal
      size="xl"
      className="modal-lg sampling-request-modal--kizuna"
      scrollable
      isOpen={isOpen}
      toggle={handleToggle}
    >
      <ModalHeader className="custom-modal-header border-0" toggle={handleToggle} />
      <ModalBody>
        <Container>
          {isOpen && !isThankYouMessage && (
            <Row>
              <Col md={12}>
                <h3 className="text-center fw-bold">{context.title}</h3>
              </Col>
            </Row>
          )}
          {isOpen && !isThankYouMessage ? (
            <RequestForm handleCallbackToShowThankYouMessage={handleIsThankYouMessage} />
          ) : (
            <ThanksYouMessage
              btnClassName="btn btn-sample mt-2"
              modalContext={context}
              patternFolderToDownlod="kizuna"
              callbackToChangeValueOfIsThankYouState={setIsThankYouMessage}
              toggle={handleToggle}
            />
          )}
        </Container>
      </ModalBody>
    </Modal>
  )
}

SamplingRequest.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

export default SamplingRequest
