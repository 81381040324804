import PropTypes from "prop-types"
import SampleCard from "../../../SampleCard"

const SamplesListDesktop = ({ samples, handleClick }) =>
  samples.map((item) => (
    <SampleCard sample={item} handleClick={handleClick} key={`item_${item.description}`} />
  ))

SamplesListDesktop.propTypes = {
  samples: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default SamplesListDesktop
