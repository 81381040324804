import Analytics from "components/Analytics"
import TitleUnderlined from "components/TitleUnderlined"
import { H1_TAG } from "components/TitleUnderlined/constants"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import "./styles/thankYou.scss"

const ThankYou = ({ msgId }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const gtagCode = location?.state?.gtagCode

  return (
    <div className="container p-4 m-md-5 p-md-5">
      <Analytics gtag={gtagCode} />
      <div className="row my-5 py-5">
        <div className="col">
          <TitleUnderlined className="my-5" hTag={H1_TAG} align="left">
            <FormattedMessage
              id={msgId}
              values={{
                sup: (...chunks) => <sup>{chunks}</sup>
              }}
            />
          </TitleUnderlined>
          <h3 className="description my-2">
            <FormattedMessage
              id="ThankYou.description"
              description="ThankYou description text"
              defaultMessage="Thank you for filling out our form, we received your information successfully. Our team will be contacting you very soon."
            />
          </h3>
          <button className="btn btn-dark btn-lg mt-4" onClick={() => navigate(-1)}>
            <FormattedMessage
              id="Button.comeBack"
              description="Button come back text"
              defaultMessage="Come Back"
            />
          </button>
        </div>
      </div>
    </div>
  )
}

ThankYou.propTypes = {
  msgId: PropTypes.string.isRequired
}

export default ThankYou
