import { useEffect } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import StoreFinderBoxLayout from "components/StoreFinder/components/StoreFinderBoxLayout"
import MethodIcon from "components/StoreFinder/components/MethodIcon"
import { isMARINE } from "services/instances"

const SelectSearchMethod = ({ onClickProduct, onClickLocation, clearCurrentSearch }) => {
  // Everytime this component renders, clear everything in the store so we make sure we're starting fresh
  useEffect(() => {
    clearCurrentSearch()
  })

  return (
    <StoreFinderBoxLayout>
      <p className="text-center">
        <strong>
          <FormattedMessage
            id="SelectSearchMethod.title"
            defaultMessage="¿Qué tipo de búsqueda quieres hacer?"
          />
        </strong>
      </p>
      <div className="text-center">
        <button
          className={classNames({
            "btn btn-dark m-2": true,
            "btn-prussian-blue": isMARINE()
          })}
          onClick={onClickLocation}
        >
          <MethodIcon icon="store" />
          <br />
          <FormattedMessage
            id="SelectSearchMethod.byLocationLabel"
            defaultMessage="Buscar por ubicación"
          />
        </button>
        <button
          className={classNames({
            "btn btn-dark": true,
            "btn-prussian-blue": isMARINE()
          })}
          onClick={onClickProduct}
        >
          <MethodIcon icon="fabric" />
          <br />
          <FormattedMessage
            id="SelectSearchMethod.byProductLabel"
            defaultMessage="Buscar por producto"
          />
        </button>
      </div>
    </StoreFinderBoxLayout>
  )
}

SelectSearchMethod.propTypes = {
  onClickProduct: PropTypes.func,
  onClickLocation: PropTypes.func,
  clearCurrentSearch: PropTypes.func
}

export default SelectSearchMethod
