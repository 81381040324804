import { defineMessages } from "react-intl"

export const COLOR_DIFFERENCE_MSGS = defineMessages({
  colorDifferenceDescription: {
    id: "Claims.ColorDifference.description",
    description: "field color difference description for step color difference",
    defaultMessage:
      "Please describe how you detect the difference (how it is measured or compared) and under which conditions (light conditions - e.g. indoor/outdoor)."
  }
})
