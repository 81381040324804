const handleIsObject = (val) => val !== null && typeof val === "object" && !Array.isArray(val)

const handleAlphabeticalOrder = (a, b) => {
  if (!handleIsObject(a.label)) {
    return a.label.localeCompare(b.label)
  } else {
    return a.label.props.values.color.localeCompare(b.label)
  }
}

export const handleOrderArray = (options) => {
  if (options && options.length) {
    options.sort((a, b) => {
      if (a.isDiscontinued !== b.isDiscontinued) {
        return a.isDiscontinued ? 1 : -1
      } else {
        return handleAlphabeticalOrder(a, b)
      }
    })
  }
  return options
}
