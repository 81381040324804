import Footer from "components/Footer"
import Header from "components/Header"
import Banner from "components/Banner"
import Info from "components/Info"
import { SLIDE } from "./components/Banner/constants"
import { GLOBAL_SITE_TAG, NAV_ITEMS } from "./constants"
import { FormattedMessage } from "react-intl"
import ColorPalette from "./components/ColorPalette"
import ProductDetails from "./components/ProductDetails"
import WhereToBuy from "./components/WhereToBuy"
import Applications from "./components/Applications"
import { getCurrentInstanceId } from "services/instances"
import Analytics from "components/Analytics"

const Impala = () => (
  <>
    <Analytics gtag={GLOBAL_SITE_TAG[getCurrentInstanceId()]} />
    <Header items={NAV_ITEMS} isTransparent />
    <div className="content">
      <Banner slides={SLIDE} />
      <Info
        text={
          <FormattedMessage
            id="Impala.info"
            description="Impala section title info"
            defaultMessage="Impala cuenta con un diseño tipo piel con colores inspirados en las texturas de los tallos de los árboles y diversos materiales minerales dando así una paleta de tonalidades perfectas para crear diseños únicos."
          />
        }
      />
      <ColorPalette id={NAV_ITEMS[0]} />
      <Applications />
      <ProductDetails id={NAV_ITEMS[1]} />
      <WhereToBuy id={NAV_ITEMS[2]} />
    </div>
    <Footer />
  </>
)

export default Impala
