import { FormattedMessage } from "react-intl"
import residencial from "../img/residencial.png"
import institucional from "../img/institucional.png"
import corporativo from "../img/corporativo.png"
import salud from "../img/salud.png"
import transporte from "../img/transporte.png"

export const ITEMS = [
  {
    id: "Residencial",
    title: (
      <FormattedMessage
        id="Pranna.Applications.residential"
        description="Pranna section applications item residential"
        defaultMessage="Residencial"
      />
    ),
    imageThumb: residencial
  },
  {
    id: "Institucional",
    title: (
      <FormattedMessage
        id="Pranna.Applications.education"
        description="Pranna section applications item institucional"
        defaultMessage="Institucional"
      />
    ),
    imageThumb: institucional
  },
  {
    id: "Corporativo",
    title: (
      <FormattedMessage
        id="Pranna.Applications.corporate"
        description="Pranna section applications item corporate"
        defaultMessage="Corporativo"
      />
    ),
    imageThumb: corporativo
  },
  {
    id: "Salud",
    title: (
      <FormattedMessage
        id="Pranna.Applications.healthcare"
        description="Pranna section applications item healthcare"
        defaultMessage="Salud"
      />
    ),
    imageThumb: salud
  },
  {
    id: "Transporte",
    title: (
      <FormattedMessage
        id="Pranna.Applications.transport"
        description="Pranna section applications item transport"
        defaultMessage="Transporte"
      />
    ),
    imageThumb: transporte
  }
]
