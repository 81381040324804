import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "react-intl"
import { Controller, useController } from "react-hook-form"
import Select from "react-select"
import ErrorMessage from "components/ErrorMessage"
import { handleFormatItems } from "utils/items"
import Option from "./components/Option"
import { THEME, CUSTOM_STYLES } from "./constants"
import "components/InputMUI/styles/own-mui.scss"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "utils/littleStateMachine"

const InputSelect = ({
  customStyles,
  name,
  control,
  label,
  description,
  placeholder,
  options,
  isMulti = false,
  areDrawOptions = false,
  isSearchable = false,
  disabled,
  error
}) => {
  const intl = useIntl()
  const { field } = useController({ control, name })
  const { actions } = useStateMachine({ updateAction })

  const getDefaultValue = () => {
    let defaultValue = null
    if (options && field?.value) {
      const defaultFiltered = options.filter(({ value }) => value === field.value)
      if (!areDrawOptions) {
        defaultValue = handleFormatItems(defaultFiltered, intl)[0]
      } else {
        defaultValue = defaultFiltered
      }
    }
    return defaultValue
  }

  const handleOnChangeSelect = (callback, name, e) => {
    callback(e.value)
    if (name === "patternId") {
      actions.updateAction({
        productName: e.value !== "OTHER" ? e.label : e.value
      })
    }
    if (name === "productId") {
      actions.updateAction({
        productColor: e.label?.props ? e.label?.props.values.color : e.label
      })
    }
  }

  return (
    <div>
      <label htmlFor={name} className="step-form--label">
        {intl.formatMessage(label)}
      </label>
      {description && <span className="description-item">{intl.formatMessage(description)}</span>}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange: handleOnChange, ...field } }) => {
          return (
            <Select
              className="pt-2"
              styles={{ ...CUSTOM_STYLES, ...customStyles }}
              theme={(theme) => THEME(theme)}
              menuPlacement="auto"
              {...field}
              options={!areDrawOptions ? handleFormatItems(options, intl) : options}
              placeholder={intl.formatMessage(placeholder)}
              value={!isMulti ? getDefaultValue() : field.value}
              isSearchable={isSearchable}
              isMulti={isMulti}
              isDisabled={disabled}
              onChange={(e) =>
                !isMulti ? handleOnChangeSelect(handleOnChange, name, e) : handleOnChange(e)
              }
              components={{ Option }}
              noOptionsMessage={() => (
                <FormattedMessage
                  id="Form.field.noOptionesMessage"
                  description="message when there's no options on a select field"
                  defaultMessage="No options"
                />
              )}
            />
          )
        }}
      />
      <ErrorMessage weight="normal">{error}</ErrorMessage>
    </div>
  )
}

InputSelect.propTypes = {
  customStyles: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  description: PropTypes.object,
  placeholder: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
      description: PropTypes.object
    })
  ),
  areDrawOptions: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
        description: PropTypes.object
      })
    )
  ]),
  error: PropTypes.string
}

export default InputSelect
