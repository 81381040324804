import { FormattedMessage } from "react-intl"
import { WHISPER_NEW_COLORS } from "./constants"
import bird from "./assets/imgs/bird.png"
import "./assets/styles/explore-new-colors.scss"

const ExploreNewColors = () => (
  <div className="position-relative container py-5">
    <h2 className="new-whisper-container text-capitalize text-secondary mb-5">
      <FormattedMessage
        id="WhisperNeoPlus.exploreNewColors.title"
        description="Explore New Colors section title"
        defaultMessage="Explore the New Colors"
      />
    </h2>
    <div className="row">
      <div className="col-12 col-md-10">
        <div className="row">
          {WHISPER_NEW_COLORS.map(({ id, title, description, img, alt }) => (
            <div key={`key_${id}`} className="col-12 col-md-6 mb-5">
              <img className="img-fluid mb-4" src={img} alt={alt} />
              <h3 className="my-4">{title}</h3>
              <p className="text-secondary">{description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    <img className="bird-img" src={bird} alt="bird" />
  </div>
)

export default ExploreNewColors
