import { useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import _ from "lodash"
import { defineMessages, useIntl } from "react-intl"
import { useQuery } from "@apollo/client"
import { getCurrentInstanceId } from "services/instances"
import storesByInstanceQuery from "components/StoreFinder/components/SearchOnlyByLocation/query"

const i18nMessages = defineMessages({
  placeholderLoading: {
    id: "LocationSelect.placeholderLoading",
    defaultMessage: "Cargando ubicaciones..."
  },
  placeholderLoaded: {
    id: "LocationSelect.placeholderLoaded",
    defaultMessage: "Selecciona una ubicación"
  }
})

// TODO Fix the first request because is not needed
// I've tried using useLazyQuery but that fucking thing for some reason is causing too many re-renders
// error in the console.
// There are many search results in Google about that
// https://stackoverflow.com/questions/57784602/uselazyquery-causing-too-many-re-renders-apollo-apollo-react-hooks

const LocationSelect = ({ onChange }) => {
  const intl = useIntl()
  const [locations, setLocations] = useState([])
  const { loading } = useQuery(storesByInstanceQuery, {
    variables: {
      instanceId: getCurrentInstanceId()
    },
    onCompleted: (data) => {
      let locations = data.stores.edges.map(({ node }) => ({
        label: node.city.name,
        value: node.city.id,
        country: node.city.country.name
      }))
      // Remove duplicates
      locations = _.uniqBy(locations, (location) => location.value)
      // Sort alphabetically
      locations = _.sortBy(locations, (location) => location.label)
      // Group by country
      const groupedByCountry = _.groupBy(locations, (location) => location.country)
      // Transform into an array as <Select/> expects
      const groupedOptions = Object.keys(groupedByCountry).map((key) => ({
        label: key,
        options: groupedByCountry[key]
      }))
      setLocations(groupedOptions)
    }
  })

  const handleSelectChange = (option) => {
    onChange(option?.value)
  }

  const getPlaceholder = () => {
    let placeholder = i18nMessages.placeholderLoaded
    if (loading) placeholder = i18nMessages.placeholderLoading
    else if (locations.length > 0) placeholder = i18nMessages.placeholderLoaded
    return intl.formatMessage(placeholder)
  }

  return (
    <Select
      isDisabled={loading}
      isLoading={loading}
      isClearable={true}
      isSearchable={true}
      name="location"
      options={locations}
      onChange={handleSelectChange}
      placeholder={getPlaceholder()}
    />
  )
}

LocationSelect.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default LocationSelect
