import PropTypes from "prop-types"
import Slider from "react-slick"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import impermeabilidad from "./img/impermeabilidad.jpg"
import impermeabilidadModel from "./img/impermeabilidad-2.jpg"
import { SLIDER_SETTINGS_FEATURES } from "../../constants"

const FEATURES = [
  {
    id: "feature-impermeabilidad",
    img: impermeabilidad,
    alt: "impermeabilidad"
  },
  {
    id: "feature-impermeabilidad-model",
    img: impermeabilidadModel,
    alt: "impermeabilidad modelo"
  }
]

const Features = ({ id }) => (
  <div id={id}>
    <div className="container my-4 py-4">
      <div className="row">
        <div className="col-12">
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="Floors.ProductDetail.title"
              description="Features section title vestuario y proteccion"
              defaultMessage="Características"
            />
          </TitleUnderlined>
          <div className="text-center">
            <p>
              <FormattedMessage
                id="VestuarioYProteccion.Features.description"
                description="Features section description vestuario y proteccion"
                defaultMessage="Por su impermeabilidad y diseño son aptas contra todo tipo de líquidos y manchas."
              />
            </p>
            <Slider className="slider-img img-application" {...SLIDER_SETTINGS_FEATURES}>
              {FEATURES.map(({ img, alt }) => (
                <img key={`key_${id}`} className="img-application" src={img} alt={alt} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Features.propTypes = {
  id: PropTypes.string
}

export default Features
