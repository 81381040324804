import classNames from "classnames/bind"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import "./styles/product-details.scss"

const ProductDetails = ({ className, items }) => (
  <>
    {items.map(({ id, text, icon, classNameIcon }) => (
      <div
        key={`key_${id}`}
        className={classNames({
          "col-6 col-md-3 align-self-center text-center my-md-2": true,
          [className]: className !== undefined
        })}
      >
        <img
          className={classNames({
            "icon my-2": true,
            "icon-no-text": !text,
            [classNameIcon]: classNameIcon !== undefined
          })}
          src={icon}
        />
        {text && (
          <p>
            <FormattedMessage
              {...text}
              values={{
                br: <br />
              }}
            />
          </p>
        )}
      </div>
    ))}
  </>
)

ProductDetails.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.object,
      icon: PropTypes.string,
      classNameIcon: PropTypes.string
    })
  ).isRequired
}

export default ProductDetails
