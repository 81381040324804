import { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { defineMessages, useIntl } from "react-intl"
import { useQuery } from "@apollo/client"
import allPatternsQuery from "components/StoreFinder/components/SearchByProduct/components/PatternSelect/query"
import { getCurrentInstanceId } from "services/instances"

const i18nMessages = defineMessages({
  placeholderLoading: {
    id: "PatternSelect.placeholderLoading",
    defaultMessage: "Cargando productos..."
  },
  placeholderLoaded: {
    id: "PatternSelect.placeholderLoaded",
    defaultMessage: "Escribe un producto"
  }
})

const PatternSelect = ({ onChange }) => {
  const intl = useIntl()
  const [patterns, setPatterns] = useState([])
  const [selected, setSelected] = useState(null)
  const { loading } = useQuery(allPatternsQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()]
    },
    onCompleted: (data) => {
      const { patterns } = data
      setPatterns(
        patterns.edges.map(({ node }) => ({
          label: node.altName ? node.altName : node.name,
          value: node.id
        }))
      )
    }
  })
  const memoizedOnChange = useCallback(onChange, [onChange])

  useEffect(() => {
    if (selected) memoizedOnChange(selected)
  }, [selected, memoizedOnChange])

  const handleSelectChange = (option) => {
    setSelected(option.value)
  }

  return (
    <Select
      isDisabled={loading}
      isLoading={loading}
      isClearable={true}
      isSearchable={true}
      name="pattern"
      options={patterns}
      onChange={handleSelectChange}
      placeholder={
        loading
          ? intl.formatMessage(i18nMessages.placeholderLoading)
          : intl.formatMessage(i18nMessages.placeholderLoaded)
      }
    />
  )
}

PatternSelect.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default PatternSelect
