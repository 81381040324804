import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import ErrorMessage from "components/ErrorMessage"
import PrivacyPolicyCheckbox from "components/PrivacyPolicyCheckbox"
import { VALIDATION_MESSAGES } from "utils/forms"
import SelectCountry from "components/select/SelectCountry"
import SelectCity from "components/select/SelectCity"
import { REQUEST_ENDPOINT_ZAPPIER, COUNTRY_OPTIONS } from "./constants"
import { BUTTON_MESSAGES } from "utils/buttons"
import { isLATAM } from "services/instances"
import { eventTrack } from "components/Analytics/utils"

const defaultValues = {
  firstName: "",
  email: "",
  phone: "",
  country: "",
  city: "",
  privacyPolicy: false
}

const ContactForm = ({ colorTheme, pattern }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const schema = yup.object({
    firstName: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    email: yup.string().email().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    phone: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    country: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string()
      })
      .nullable()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    city: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string()
      })
      .nullable()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    privacyPolicy: yup.boolean().oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = ({ privacyPolicy, ...values }) => {
    eventTrack({
      category: "Click",
      action: "Click en botón",
      label: "Contáctanos"
    })
    const {
      city: { label: cityLabel }
    } = values

    const data = JSON.stringify({
      ...values,
      pattern: pattern?.altName,
      city: cityLabel,
      date: new Date().toLocaleString()
    })

    const promise = axios.post(REQUEST_ENDPOINT_ZAPPIER, data)
    promise.then(() => {
      if (isLATAM()) {
        navigate("/es-la/pisos/gracias")
      } else {
        navigate("/es-mx/pisos/gracias")
      }
    })
    promise.catch((error) => {
      console.log(error)
    })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12 form-group">
                <label htmlFor="firstName" className={`text-${colorTheme}`}>
                  <FormattedMessage
                    id="Form.field.Name"
                    description="Form field name"
                    defaultMessage="Name"
                  />
                </label>
                <input className="form-control" id="firstName" {...register("firstName")} />
                <ErrorMessage color={colorTheme === "white" ? colorTheme : "red"}>
                  {errors.firstName?.message}
                </ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label htmlFor="email" className={`text-${colorTheme}`}>
                  <FormattedMessage
                    id="Form.field.Email"
                    description="Form field email"
                    defaultMessage="Email"
                  />
                </label>
                <input className="form-control" id="email" {...register("email")} />
                <ErrorMessage color={colorTheme === "white" ? colorTheme : "red"}>
                  {errors.email?.message}
                </ErrorMessage>
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="phone" className={`text-${colorTheme}`}>
                  <FormattedMessage
                    id="Form.field.Phone"
                    description="Form field phone"
                    defaultMessage="Phone"
                  />
                </label>
                <input className="form-control" id="phone" {...register("phone")} />
                <ErrorMessage color={colorTheme === "white" ? colorTheme : "red"}>
                  {errors.phone?.message}
                </ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <SelectCountry
                  labelClassName={`text-${colorTheme}`}
                  name="country"
                  control={control}
                  options={COUNTRY_OPTIONS}
                  isSearchable={false}
                />
                <ErrorMessage color={colorTheme === "white" ? colorTheme : "red"}>
                  {errors.country?.message}
                </ErrorMessage>
              </div>
              <div className="col-md-6 form-group">
                <SelectCity
                  labelClassName={`text-${colorTheme}`}
                  name="city"
                  control={control}
                  searchByCountryId
                />
                <ErrorMessage color={colorTheme === "white" ? colorTheme : "red"}>
                  {errors.city?.message}
                </ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-12 form-group text-center">
                <Controller
                  name="privacyPolicy"
                  control={control}
                  render={({ field }) => <PrivacyPolicyCheckbox color={colorTheme} {...field} />}
                />
                <ErrorMessage color={colorTheme === "white" ? colorTheme : "red"}>
                  {errors.privacyPolicy?.message}
                </ErrorMessage>
                <button
                  className={`btn btn-outline-${colorTheme === "white" ? "light" : "dark"}`}
                  type="submit"
                >
                  {intl.formatMessage(
                    !isSubmitting ? BUTTON_MESSAGES.send : BUTTON_MESSAGES.sending
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

ContactForm.propTypes = {
  colorTheme: PropTypes.oneOf(["black", "white"]),
  pattern: PropTypes.object
}

export default ContactForm
