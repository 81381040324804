import PropTypes from "prop-types"
import { REASONS, MATERIAL_CURRENT_STATE } from "modules/claims/components/StepForm/Step2/constants"
import ColorFading from "../ClaimsReasons/ColorFading"
import ColourDifference from "../ClaimsReasons/ColorDifference"
import DifferencesInHand from "../ClaimsReasons/DifferencesInHand"
import FacialDefects from "../ClaimsReasons/FacialDefects"
import MaterialResistance from "../ClaimsReasons/MaterialResistance"
import Other from "../ClaimsReasons/Other"
import Pleats from "../ClaimsReasons/Pleats"
import Stain from "../ClaimsReasons/Stain"
import SurfaceDeterioration from "../ClaimsReasons/SurfaceDeterioration"
import VisualDefects from "../ClaimsReasons/VisualDefects"
import DifferencesInDimension from "../ClaimsReasons/DifferencesInDimension"

const CLAIM_REASONS_COMPONENT = {
  [MATERIAL_CURRENT_STATE.ORIGINAL]: {
    [REASONS.COLOR_DIFFERENCE]: <ColourDifference />,
    [REASONS.FACIAL_DEFFECTS]: <FacialDefects />,
    [REASONS.PLEATS]: <Pleats />,
    [REASONS.MATERIAL_RESISTANCE]: <MaterialResistance />,
    [REASONS.DIFFERENCES_IN_HAND]: <DifferencesInHand />,
    [REASONS.VISUAL_DEFFECTS]: <VisualDefects />,
    [REASONS.DIFFERENCES_IN_DIMENSION]: <DifferencesInDimension />,
    [REASONS.OTHER]: <Other />
  },
  [MATERIAL_CURRENT_STATE.TRANSFORMED]: {
    [REASONS.COLOR_DIFFERENCE]: <ColourDifference />,
    [REASONS.FACIAL_DEFFECTS]: <FacialDefects />,
    [REASONS.PLEATS]: <Pleats />,
    [REASONS.MATERIAL_RESISTANCE]: <MaterialResistance />,
    [REASONS.SURFACE_DETERIORATION]: <SurfaceDeterioration />,
    [REASONS.DIFFERENCES_IN_HAND]: <DifferencesInHand />,
    [REASONS.VISUAL_DEFFECTS]: <VisualDefects />,
    [REASONS.OTHER]: <Other />
  },
  [MATERIAL_CURRENT_STATE.INSTALLED]: {
    [REASONS.COLOR_DIFFERENCE]: <ColourDifference />,
    [REASONS.COLOR_FADING]: <ColorFading />,
    [REASONS.STAINING]: <Stain />,
    [REASONS.SURFACE_DETERIORATION]: <SurfaceDeterioration />,
    [REASONS.MATERIAL_RESISTANCE]: <MaterialResistance />,
    [REASONS.PLEATS]: <Pleats />,
    [REASONS.DIFFERENCES_IN_HAND]: <DifferencesInHand />,
    [REASONS.FACIAL_DEFFECTS]: <FacialDefects />,
    [REASONS.VISUAL_DEFFECTS]: <VisualDefects />,
    [REASONS.OTHER]: <Other />
  }
}

const Funnel = ({ conditions, reasons }) => CLAIM_REASONS_COMPONENT[conditions][reasons]

Funnel.propTypes = {
  conditions: PropTypes.string.isRequired,
  reasons: PropTypes.string.isRequired
}

export default Funnel
