import { FormattedMessage } from "react-intl"
import banner from "modules/dali/components/Banner/img/banner.png"
import ContactForm from "components/ContactForm"
import { COUNTRY_COLOMBIA } from "consts/countryCodes"

export const SLIDE = [
  {
    id: "slide-dali",
    imageThumb: banner,
    title: (
      <FormattedMessage
        id="Dali.Banner.title"
        description="Dali title banner"
        defaultMessage="Dalí"
      />
    ),
    description: (
      <FormattedMessage
        id="Dali.Banner.description"
        description="Dali description banner"
        defaultMessage="Nuevo diseño tipo textil apta para tapicería interior"
      />
    ),
    form: (
      <ContactForm
        theme="light"
        styleContainer="container-fluid upholstery-glassmorphism my-3"
        styleFormContainer="p-4"
        title={
          <FormattedMessage
            id="Pranna.Contact.title"
            description="Floors section title contact"
            defaultMessage="¿ Quieres más información ?"
          />
        }
        countryOptions={COUNTRY_COLOMBIA}
        landingSource="dali"
        isBasic
      />
    )
  }
]
