import Modal from "components/Modal"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { useLazyQuery } from "@apollo/client"
import { useStateMachine } from "little-state-machine"
import useToggle from "hooks/useToggle"
import { singlePatternQuery } from "./queries"
import { Spinner } from "reactstrap"
import ColorPalette from "components/ColorPalette"
import { getCurrentInstanceId, isLATAM, isMEXICO } from "services/instances"
import { updateAction } from "utils/littleStateMachine"
import Specs from "./components/Specs"
import { INTERNATIONAL_UNITS, ANGLO_SAXON_UNITS } from "./constants"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import Markets from "./components/Markets"

const Card = ({ id, altName, slug, productcategorization, imageThumbSmall }) => {
  const [toggable, toggle] = useToggle()
  const { actions } = useStateMachine({ updateAction })
  const [getPattern, { data, loading }] = useLazyQuery(singlePatternQuery, {
    variables: {
      slug,
      instanceIds: [getCurrentInstanceId()],
      unitSystem: isLATAM() && isMEXICO() ? ANGLO_SAXON_UNITS : INTERNATIONAL_UNITS
    }
  })

  const handleClick = () => {
    getPattern()
    toggle()
  }

  const handleMoreInfo = () => {
    actions.updateAction({ pattern: altName })
    toggle()
  }

  return (
    <div className="container-reference" key={`key_${id}`} onClick={handleClick}>
      {imageThumbSmall && (
        <img className="img-fluid img-reference" src={imageThumbSmall} alt={slug} />
      )}
      <div className="pt-2 px-2">
        <h3 className="text-capitalize m-0">{altName}</h3>
        {productcategorization?.design && (
          <p className="text-capitalize">
            <FormattedMessage
              id="Petfriendly.References.appearance"
              description="Petfriendly section references appearance text on card"
              defaultMessage="Apariencia"
            />
            <ul>
              {productcategorization?.design.map((item) => (
                <li key={item} className="ms-1">
                  {item.toLowerCase()}
                </li>
              ))}
            </ul>
          </p>
        )}
      </div>

      <Modal isOpen={toggable} handleToggable={toggle}>
        {data && (
          <div>
            <TitleUnderlined hTag={H2_TAG} className="text-capitalize">
              {altName}
            </TitleUnderlined>
            <ColorPalette colors={data.pattern.products.edges} align="center" actived hideWarning />
            <Markets pattern={data.pattern} />
            <Specs pattern={data.pattern} />
            <div className="text-center">
              <a className="btn btn-dark" href="#contacto" onClick={handleMoreInfo}>
                <FormattedMessage
                  id="Button.moreInfo"
                  description="Button more info text"
                  defaultMessage="Learn more"
                />
              </a>
            </div>
          </div>
        )}
        {loading && (
          <div className="text-center">
            <Spinner color="dark" />
          </div>
        )}
      </Modal>
    </div>
  )
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  altName: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  productcategorization: PropTypes.object.isRequired,
  imageThumbSmall: PropTypes.string.isRequired
}

export default Card
