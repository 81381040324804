import { formattedDate } from "./formatedDate"

/* eslint-disable no-useless-escape */
const { MATERIAL_CURRENT_STATE } = require("../../Step2/constants")

const CONDITIONS_TEMPLATES = {
  [MATERIAL_CURRENT_STATE.TRANSFORMED]: (state) => {
    const {
      application,
      dateOfPurchase,
      dateOfMaterialTransformed,
      conditionsOfMaterial,
      whenDetectDifference
    } = state

    const purchaseDate = formattedDate(dateOfPurchase)
    const transformedDate = formattedDate(dateOfMaterialTransformed)

    const transformedTemplate = {
      title: "3. Transformed condition",
      application: {
        label: "Final Application of material",
        value: application
      },
      dateOfPurchase: {
        label: "Date of purchase",
        value: purchaseDate
      },
      dateOfMaterialTransformed: {
        label: "Date material transformed",
        value: transformedDate
      },
      conditionsOfMaterial: {
        label: "Used material in customer's process",
        value: conditionsOfMaterial
      },
      whenDetectDifference: {
        label: "Difference Detected",
        value: whenDetectDifference
      }
    }

    return transformedTemplate
  },
  [MATERIAL_CURRENT_STATE.INSTALLED]: (state) => {
    const {
      application,
      dateOfPurchase,
      dateOfMaterialInstalled,
      placeWhereIsInstalled,
      installedIn,
      conditionsOfMaterial,
      whenDetectDifference
    } = state

    const purchaseDate = formattedDate(dateOfPurchase)
    const installedDate = formattedDate(dateOfMaterialInstalled)

    const installedObj = {
      title: "3. Installed condition",
      application: {
        label: "Final Application of material",
        value: application
      },
      dateOfPurchase: {
        label: "Date of purchase",
        value: purchaseDate
      },
      dateOfMaterialInstalled: {
        label: "Date material Installed",
        value: installedDate
      },
      placeWhereIsInstalled: {
        label: "Place where the material is installed",
        value: placeWhereIsInstalled
      },
      installedIn: {
        label: "Where is the material installed?",
        value: installedIn
      },
      conditionsOfMaterial: {
        label: "Used material in customer's process",
        value: conditionsOfMaterial
      },
      whenDetectDifference: {
        label: "Difference Detected",
        value: whenDetectDifference
      }
    }

    return installedObj
  }
}

export const getConditionTemplate = (state) => {
  const { conditions } = state

  if (conditions !== MATERIAL_CURRENT_STATE.ORIGINAL) {
    const conditionObject = CONDITIONS_TEMPLATES[conditions](state)

    const tempArray = []

    for (const [key] of Object.entries(state)) {
      if (conditionObject[key] !== undefined && conditionObject[key] !== "title") {
        tempArray.push({
          key: conditionObject[key].label,
          value: conditionObject[key].value
        })
      }
    }

    return `
    <div class="product-information-container" style="
      background-color: #DDDDDD;
      height: auto;
    ">
      <h2 style="margin: 0; margin-bottom: 20px;">
        ${conditionObject.title}
      </h2>
      <table>
          <tbody>
          ${tempArray.reduce((acc, { key, value }, index) => {
            if (index % 2 === 0) {
              acc += "<tr>"
            }
            acc += `
            <td style="width: 65%;>
              <div style="width: 100%;">
                <p>${key}</p>
                <p class="property">${
                  typeof value === "object" ? value.map(({ label }) => label + " ").join("") : value
                }</p>
              </div>
            </td>
            `
            if (index % 2 !== 0) {
              acc += "</tr>"
            }
            return acc
          }, "")}
        </tbody>
      </table>
    </div>

    <hr style="
      width: 95%;
      margin-left: 0;
      margin-top: 40px;
      margin-bottom: 40px;
    "></hr>
    `
  }

  return ""
}
