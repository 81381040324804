import PropTypes from "prop-types"
import "./styles/color-detail.scss"

const ColorDetail = ({
  color: { color = "", stock = "", imageThumbMedium = null },
  type = "rectangle"
}) => {
  return (
    <div
      className={`color-detail color-detail--${type}`}
      style={{ backgroundImage: `url(${imageThumbMedium})` }}
    >
      <div className="color-detail-text-container">
        <p className="color-detail--label m-1">{color}</p>
        {stock && <p className="color-detail--stock m-1">{stock}</p>}
      </div>
    </div>
  )
}

ColorDetail.propTypes = {
  color: PropTypes.shape({
    color: PropTypes.string,
    stock: PropTypes.string,
    imageThumbMedium: PropTypes.string
  }),
  type: PropTypes.oneOf(["rectangle", "square"])
}

export default ColorDetail
