import PropTypes from "prop-types"
import classNames from "classnames/bind"
import "./styles/home-with-form.scss"

const HomeWithForm = ({ align = "left", slide = null, targetLink = "_self", form }) => (
  <div
    id="home-slide__form"
    className={classNames({
      "home-slide__form": true,
      [slide.slideFormClassName]: slide.slideFormClassName !== undefined
    })}
    style={{
      backgroundImage: `url(${slide && slide.imageThumb})`
    }}
  >
    <div className={`home-slide__form__content home-slide__form__content--${align}`}>
      <div className="container">
        <div
          className={classNames({
            row: true,
            "align-items-center justify-content-center": align === "center"
          })}
        >
          <div className="col-12 col-md-6">
            <div
              className={classNames({
                [`slide-content__form slide-content__form--${align} text-${align} text-light`]: true,
                [`text-${slide.color}`]: !!slide.color
              })}
            >
              {slide?.title && (
                <h1
                  className={classNames({
                    "slide-content__form__title": true,
                    [slide.titleClassName]: slide.titleClassName !== undefined
                  })}
                >
                  {slide?.title}
                </h1>
              )}
              {slide?.description && (
                <p className="slide-content__form__description">{slide.description}</p>
              )}
              {slide.link && (
                <a
                  className={classNames({
                    "slide-content__button btn btn-outline-light": true,
                    "mt-2": align === "center"
                  })}
                  target={targetLink}
                  href={slide.link}
                  rel="noopener noreferrer"
                >
                  {slide.buttonText}
                </a>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">{form}</div>
        </div>
      </div>
    </div>
  </div>
)

HomeWithForm.propTypes = {
  align: PropTypes.string,
  slide: PropTypes.shape({
    imageThumb: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    color: PropTypes.oneOf(["light, dark"])
  }),
  targetLink: PropTypes.string,
  form: PropTypes.element
}

export default HomeWithForm
