import { useRef, useState } from "react"
import PropTypes from "prop-types"
import { BsPlayCircle } from "react-icons/bs"
import "./styles/video.scss"

const Video = ({ id, className, src, poster, fromYoutube = false, children }) => {
  const videoRef = useRef(null)
  const [readyForFirstPlay, setReadyForFirstPlay] = useState(true)

  const handlePlay = () => {
    if (!fromYoutube) videoRef.current.play()
    setReadyForFirstPlay(!readyForFirstPlay)
  }

  return (
    <div id={id} className="container-video-kizuna">
      {readyForFirstPlay && poster && (
        <div className="container-poster">
          <img className="poster" src={poster} />
          <BsPlayCircle className="btn-play-kizuna cursor-pointer" onClick={handlePlay} />
        </div>
      )}
      {!fromYoutube ? <video ref={videoRef} className={className} src={src} controls /> : children}
    </div>
  )
}

Video.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  poster: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fromYoutube: PropTypes.bool,
  children: PropTypes.node
}

export default Video
