/* eslint-disable */
import { useContext, useState, useEffect } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import Select from "react-select"
import { FaSearch } from "react-icons/fa"
import { GlassMagnifier } from "react-image-magnifiers"
import SamplesList from "./Components/SamplesList"
import useGetCollectionByID from "../hooks/useGetCollectionByID"
import "./styles/sample-details.scss"
import {
  customStylesSelctCollectionQuickShipPage,
  customStylesSelctQuickShipPage
} from "./styles/customStylesSelctQuickShipPage"
import { useQuery } from "@apollo/client"
import { collectionsQuery } from "../queries"
import upArrowSelectorQuickship from "./img/up-arrow-selector-quickship.svg"
import downArrowSelectorQuickship from "./img/down-arrow-selector-quickship.svg"
import leftArrowQuickshipPage from "./img/left-arrow-quickship-page.svg"
import mobileLeftArrowQuickshipPage from "./img/mobile-left-arrow-go-back-quickship-page.svg"
import useGetFiltersByCollectionID from "../hooks/useGetFiltersByCollectionID"
import { capitalizeWords } from "../utils/capitalizeWords"
import useGetFilteredProductQuickshipPage from "../hooks/useGetFilteredProductQuickshipPage"
import SamplesCount from "./Components/SamplesCount"
import ScrollTopOnMount from "../utils/ScrollTopOnMount"
import useMediaQuery from "hooks/useMediaQuery"
import { TRANSLATED_ROUTES_QUICKSHIP_PAGE } from "../Home/components/Header/Constants/urlsQuinckshipPage"
import HeaderQuickShip from "../Home/components/Header"
import CollectionContext from "./context/CollectionContext"
import { FooterQuickShip } from "../Home/components/Footer/FooterQuickShip"
import RequestFormQuickShipPage from "./Components/RequestFormQuickShipPage"
import ThanksYouMessageQuickShip from "./Components/RequestFormQuickShipPage/components/ThanksYouMessage"

const QuickshipPageParamSlug = () => {
  const { id, language, instance } = useParams()
  const history = useNavigate()
  const { data } = useQuery(collectionsQuery)
  const isDesktop = useMediaQuery("(min-width: 1024px)")
  const { collectionItems } = useGetCollectionByID(id)

  const [limitReached, setLimitReached] = useState(false)
  const [currentIDbySlug, setCurrentIDbySlug] = useState("UXVpY2tzaGlwQ29sbGVjdGlvbk5vZGU6Mw==")

  const [filters, setFilters] = useState({
    market: null,
    design: null,
    characteristics: null
  })

  const { filtersByCollection } = useGetFiltersByCollectionID(currentIDbySlug, language)
  const { filteredProducts, loading: loadingFilteredProductos } =
    useGetFilteredProductQuickshipPage(
      currentIDbySlug,
      filters.market ? [filters.market] : [],
      filters.design ? [filters.design] : [],
      filters.characteristics ? filters.characteristics : ""
    )

  const {
    selectedItemCollection,
    setSelectedItemCollection,
    itemsInFormRequest,
    setItemsInFormRequest
  } = useContext(CollectionContext) || {}

  if (!CollectionContext) {
    return <div>Error: CollectionContext is not available</div>
  }

  const collectionData = data?.quickshipCollections?.edges

  const currentInstance = instance ? `-${instance}` : ""

  const [selectedCollection, setSelectedCollection] = useState(null)

  const [isOpenModalRequestForm, setIsOpenModalRequestForm] = useState(false)

  const [isThankYouMessage, setIsThankYouMessage] = useState(false)
  const handleIsThankYouMessage = () => setIsThankYouMessage(!isThankYouMessage)

  const [quantity, setQuantity] = useState(1)

  const handleToggleModalFormRequest = () => {
    setIsOpenModalRequestForm(!isOpenModalRequestForm)
    setIsThankYouMessage(false)
  }

  useEffect(() => {
    if (!selectedItemCollection) {
      setSelectedItemCollection(collectionItems?.productonquickshipSet?.edges[0]?.node)
      return
    }

    setCurrentIDbySlug(collectionItems?.id)

    setSelectedItemCollection(collectionItems?.productonquickshipSet?.edges[0]?.node)

    return () => {
      setSelectedItemCollection(null)
    }
  }, [collectionItems])

  useEffect(() => {
    if (collectionData) {
      const initialCollection = collectionData.find((collection) => collection.node.slug === id)
      if (initialCollection) {
        setCurrentIDbySlug(initialCollection?.node?.id)
        setSelectedCollection({
          value: initialCollection.node.id,
          label: initialCollection.node.name
        })
      }
    }
  }, [collectionData, id])

  useEffect(() => {
    setQuantity(1)
  }, [selectedItemCollection])

  const handleFilterChange = (selectedOption, { name }) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: selectedOption ? selectedOption.value : null
    }))
  }

  const handleCollectionChange = (selectedOption) => {
    setSelectedCollection(selectedOption)
    history(`/${language}${instance ? `-${instance}` : ""}/quickship/${selectedOption.value}`)
  }

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value)
  }

  const incrementQuantity = () => {
    if (limitReached) return
    setQuantity((prevQuantity) => prevQuantity + 1)
  }

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1))
  }

  const handleAddSample = () => {
    if (!selectedItemCollection) return

    const newItem = {
      value: selectedItemCollection.product.id,
      label: `${selectedItemCollection.product.color} | ${
        selectedItemCollection.product.stock
      } | ${selectedItemCollection.product.pattern.name.toUpperCase()}`,
      data: {
        name: `${selectedItemCollection.product.pattern.name.toUpperCase()}, ${
          selectedItemCollection.product.color
        }`,
        stock: selectedItemCollection.product.stock,
        quantity: quantity
      }
    }

    setItemsInFormRequest((prevItems) => [...prevItems, newItem])
  }

  const groupedSectors = filtersByCollection?.sectors?.reduce((groups, sector) => {
    const group = groups.find((g) => g.label === sector.marketEn)

    if (group) {
      group.options.push({
        value: sector.id,
        labelEs: sector.name,
        labelEn: sector.nameEn
      })
    } else {
      groups.push({
        label: sector.marketEn,
        options: [
          {
            value: sector.id,
            labelEs: sector?.name?.replaceAll("/", "/ "),
            labelEn: sector?.nameEn?.replaceAll("/", "/ ")
          }
        ]
      })
    }

    return groups
  }, [])

  useEffect(() => {
    if (quantity >= 10 || itemsInFormRequest.length >= 10) {
      setLimitReached(true)
    } else {
      setLimitReached(false)
    }
  }, [quantity, itemsInFormRequest])

  return (
    <div className="main-page-slug-quickship position-relative">
      <ScrollTopOnMount />
      <HeaderQuickShip />

      <div className="main-container-content-page-quickship-collection">
        <div className="container-row-page-quickship-collection">
          <Link
            to={`/${language}${currentInstance}/${TRANSLATED_ROUTES_QUICKSHIP_PAGE[language].route}`}
            className={"btn-go-back-quickship"}
          >
            {isDesktop ? (
              <img src={leftArrowQuickshipPage} alt="left arrow" />
            ) : (
              <img src={mobileLeftArrowQuickshipPage} alt="left arrow" />
            )}
            Go back
          </Link>
          <div className="Container-selects-quickship-page">
            <Select
              name="market"
              options={groupedSectors?.map((group) => ({
                ...group,
                options: group?.options?.map((option) => ({
                  value: option?.value,
                  label: option?.labelEn
                }))
              }))}
              onChange={handleFilterChange}
              placeholder={"Markets"}
              styles={customStylesSelctQuickShipPage}
              isClearable={true}
              menuPortalTarget={document.body}
            />

            <Select
              name="design"
              options={filtersByCollection?.designs?.map((filter) => ({
                value: filter.value,
                label: capitalizeWords(filter.name)
              }))}
              onChange={handleFilterChange}
              placeholder={"Design"}
              styles={customStylesSelctQuickShipPage}
              isClearable={true}
              menuPortalTarget={document.body}
            />

            <Select
              name="characteristics"
              options={filtersByCollection?.characteristics?.map((filter) => ({
                value: filter?.value,
                label: filter?.nameEn
              }))}
              onChange={handleFilterChange}
              placeholder={"Characteristics"}
              styles={customStylesSelctQuickShipPage}
              isClearable={true}
              // isSearchable={false}
              menuPortalTarget={document.body}
            />
          </div>
        </div>

        <div className="container-row-selector-collection-quiickship-page">
          <p className="text-selector-color-quickship-page">Colors</p>
          <Select
            name="selectCollection"
            value={selectedCollection}
            options={collectionData?.map((filter) => ({
              value: filter?.node?.slug,
              label: filter?.node?.name
            }))}
            onChange={handleCollectionChange}
            placeholder={"Collection"}
            isSearchable={false}
            styles={customStylesSelctCollectionQuickShipPage}
          />
        </div>

        <div className="container-row-quickhip-selector row g-4 mt-4">
          <div className="col-12 col-lg-5 d-xl-flex justify-content-xl-center mt-0">
            <div className="container-master-sample-quickship">
              <div className="position-relative container-sample-quickship-param">
                {isDesktop && (
                  <div className="container-search-icon p-4">
                    <div className="bg-light rounded-circle">
                      <FaSearch className="p-2" color="gray" />
                    </div>
                  </div>
                )}
                {isDesktop ? (
                  <GlassMagnifier
                    className="large-img--quickship"
                    imageSrc={
                      selectedItemCollection?.product?.imageForProduction ||
                      "https://valenciabiosense.spradling.eu/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FVALENCIA%20PURE-WHITE_107-9607.3d59c194.jpg&w=828&q=75"
                    }
                    imageAlt="sample-master-image"
                    magnifierBorderColor="white"
                    magnifierBackgroundColor="white"
                    magnifierBorderSize={2}
                    magnifierOffsetX={0}
                    magnifierOffsetY={0}
                    magnifierSize={150}
                  />
                ) : (
                  <img
                    className="large-img--quickship"
                    src={
                      selectedItemCollection?.product?.imageForProduction ||
                      "https://valenciabiosense.spradling.eu/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FVALENCIA%20PURE-WHITE_107-9607.3d59c194.jpg&w=828&q=75"
                    }
                    alt="sample-master-image"
                  />
                )}
                <p className="sample-detail-selected--quickship m-0">
                  {selectedItemCollection !== undefined &&
                    `${selectedItemCollection?.product?.color} | ${selectedItemCollection?.product?.stock}`}
                </p>
              </div>
            </div>
          </div>
          <div className="container-sample-list-and-quantity col-12 col-lg-7 d-xl-flex justify-content-xl-start m-0">
            <SamplesList
              samplesDestkop={filteredProducts}
              samplesMobile={filteredProducts}
              isLoading={loadingFilteredProductos}
            />
            {filteredProducts?.length > 0 && (
              <div className="quantity-selector-quickship-page">
                <div className="container-quantity">
                  {
                    <p
                      className="limit-reached-message-quickship"
                      style={{
                        opacity: limitReached ? "1" : "0",
                        zIndex: limitReached ? 1 : -1
                      }}
                    >
                      <span>Limit reached:</span> Maximum of 10 samples.
                    </p>
                  }
                  <button onClick={decrementQuantity} className="quantity-btn-decrement">
                    <img src={downArrowSelectorQuickship} alt="less" />
                  </button>
                  <input
                    type="number"
                    value={quantity}
                    onChange={handleQuantityChange}
                    min="1"
                    className="quantity-input"
                  />
                  <button onClick={incrementQuantity} className="quantity-btn-increment">
                    <img src={upArrowSelectorQuickship} alt="plus" />
                  </button>
                </div>
                <button
                  className="btn-add-sample"
                  onClick={handleAddSample}
                  style={{
                    backgroundColor: limitReached ? "#98989A" : "#00575F"
                  }}
                  disabled={limitReached}
                >
                  Add MEMO Sample
                </button>
              </div>
            )}

            {itemsInFormRequest?.length === 0 ? (
              <></>
            ) : (
              <div className="position-relative">
                <SamplesCount
                  QuantityItems={itemsInFormRequest?.length}
                  lang={language}
                  toggle={handleToggleModalFormRequest}
                />
              </div>
            )}
          </div>

          {isOpenModalRequestForm && (
            <div
              className="modal-request-form-quickship-page"
              onClick={handleToggleModalFormRequest}
            >
              <div
                className="content-request-form-quickship-page"
                onClick={(e) => e.stopPropagation()}
              >
                <span
                  className={"close-modal-form-request-quickship"}
                  onClick={handleToggleModalFormRequest}
                >
                  &times;
                </span>
                {!isThankYouMessage && (
                  <>
                    <h3>Sample Request</h3>
                    <p>Fill out the form below. and request your samples!</p>
                    <p className="text-header-request-form-quickship">
                      All fields with (*) are required.
                    </p>
                  </>
                )}
                <div className="form-request-quickshippage">
                  {!isThankYouMessage ? (
                    <RequestFormQuickShipPage
                      handleCallbackToShowThankYouMessage={handleIsThankYouMessage}
                    />
                  ) : (
                    <ThanksYouMessageQuickShip
                      handleIsThankYouMessage={handleIsThankYouMessage}
                      toggle={handleToggleModalFormRequest}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="fill-remaining-space"></div>

      <FooterQuickShip />
    </div>
  )
}

export default QuickshipPageParamSlug
