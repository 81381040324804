import { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import StoreFinderBoxLayout from "components/StoreFinder/components/StoreFinderBoxLayout"
import { FormattedMessage } from "react-intl"
import PatternSelect from "components/StoreFinder/components/SearchByProduct/components/PatternSelect"
import LocationSelect from "components/StoreFinder/components/SearchByLocation/components/LocationSelect"
import { isMARINE } from "services/instances"

const SearchByLocation = ({ patternId, onClickNext, onClickPrevious, setPatternId, setCityId }) => {
  const [btnIsDisabled, setBtnIsDisabled] = useState(true)

  const handlePatternSelectChange = (patternId) => {
    setPatternId(patternId)
  }

  const handleLocationSelectChange = (cityId) => {
    setCityId(cityId)
    setBtnIsDisabled(false)
  }

  const handleBtnClick = () => {
    if (btnIsDisabled) return
    onClickNext()
  }

  return (
    <StoreFinderBoxLayout
      title={
        <FormattedMessage
          id="SearchByLocation.title"
          defaultMessage="Distribuidores por ubicación"
        />
      }
      icon="store"
      goBack={onClickPrevious}
    >
      <p className="mb-1">
        <FormattedMessage
          id="SearchByLocation.inputLabel1"
          defaultMessage="¿Qué producto estás buscando?"
        />
      </p>
      <div className="row g-0 mb-4">
        <div className="col-12 col-md-9">
          <PatternSelect onChange={handlePatternSelectChange} />
        </div>
      </div>
      <p className="mb-1">
        <FormattedMessage
          id="SearchByLocation.inputLabel2"
          defaultMessage="Ubicaciones disponibles:"
        />
      </p>
      <div className="row g-0 justify-content-between">
        <div className="col-12 col-md-9 mb-3 mb-md-0">
          <LocationSelect patternId={patternId} onChange={handleLocationSelectChange} />
        </div>
        <div className="col-auto">
          <button
            type="button"
            className={classNames({
              "btn btn-dark": true,
              "btn-prussian-blue": isMARINE()
            })}
            onClick={handleBtnClick}
            disabled={btnIsDisabled}
          >
            <FormattedMessage id="SearchByLocation.submitBtnLabel" defaultMessage="Buscar" />
          </button>
        </div>
      </div>
    </StoreFinderBoxLayout>
  )
}

SearchByLocation.propTypes = {
  patternId: PropTypes.string.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  setPatternId: PropTypes.func.isRequired,
  setCityId: PropTypes.func.isRequired
}

export default SearchByLocation
