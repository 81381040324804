/* eslint-disable */
import HeaderQuickShip from "./components/Header"
import QuickshipBanner from "./components/QuickshipBanner"
import HomeProductsSection from "./components/HomeProductsSection"
import ScrollTopOnMount from "../utils/ScrollTopOnMount"
import { FooterQuickShip } from "./components/Footer/FooterQuickShip"
import { HOME_SECTIONS } from "./components/consts"

const QuickshipPage = () => {
  return (
    <div className="position-relative">
      <ScrollTopOnMount />
      <HeaderQuickShip />
      <QuickshipBanner id={HOME_SECTIONS[0]} />
      <HomeProductsSection id={HOME_SECTIONS[1]} />
      <FooterQuickShip />
    </div>
  )
}

export default QuickshipPage
