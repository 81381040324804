import PropTypes from "prop-types"
import { FaAngleDown, FaAngleUp, FaFileArrowDown, FaMinus, FaPlus } from "react-icons/fa6"

const IconAccordion = ({ isDownloadIcon, isOpen, onlySpecs = false }) => {
  return (
    <>
      {isDownloadIcon && <FaFileArrowDown className="float-end mt-1 me-1 size-icon" />}

      {isOpen && !isDownloadIcon && !onlySpecs && (
        <FaMinus className="float-end mt-1 me-1 size-icon" />
      )}

      {!isOpen && !isDownloadIcon && !onlySpecs && (
        <FaPlus className="float-end mt-1 me-1 size-icon" />
      )}

      {!isOpen && onlySpecs && !isDownloadIcon && (
        <FaAngleDown className="float-end mt-2 me-1 size-icon" />
      )}

      {isOpen && onlySpecs && !isDownloadIcon && (
        <FaAngleUp className="float-end mt-2 me-1 size-icon" />
      )}
    </>
  )
}

IconAccordion.propTypes = {
  isDownloadIcon: PropTypes.bool,
  isOpen: PropTypes.bool,
  onlySpecs: PropTypes.bool
}

export default IconAccordion
