import PropTypes from "prop-types"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "app/store"
import "./styles/SampleCard.scss"
import useSampleCard from "./useSampleCard"
import classNames from "classnames"
import { ReactComponent as BagIcon } from "modules/kizuna/components/Header/img/bag.svg"

const SampleCard = ({ design, sample, handleShowInfoBox }) => {
  const { state, actions } = useStateMachine({ updateAction })
  const { handleAddSample } = useSampleCard({ design, handleShowInfoBox })

  const handleClickInAnySample = (e, sample) => {
    e.preventDefault()
    const sampleDetails = {
      color: sample.color,
      reference: sample.reference,
      img: sample.img,
      imgDetail: sample.imgDetail
    }

    actions.updateAction({
      productDetails: {
        title: design,
        sampleDetails
      }
    })
  }

  return (
    <div
      className={classNames({
        "card-color container-card--masiala": true,
        "actived-card--kizuna": state?.productDetails?.sampleDetails.color === sample.color
      })}
      onClick={(event) => handleClickInAnySample(event, sample)}
    >
      <div className="d-flex align-items-center justify-content-center position-relative">
        <img className="img-card--masiala" src={sample.img} alt="sample-image" />
        <button
          className="bag-icon hover-icon position-absolute"
          onClick={() => handleAddSample(sample)}
        >
          <BagIcon className="bag-img--bag" />
          <span className="bag-count">+</span>
        </button>
      </div>
      <div className="card-container-info--masiala">
        <p className="card-label--masiala">{sample.color}</p>
        <p className="card-stock--masiala">{sample.reference.replace(/\s*-\s*/, "-")}</p>
      </div>
    </div>
  )
}

SampleCard.propTypes = {
  design: PropTypes.string.isRequired,
  handleShowInfoBox: PropTypes.func.isRequired,
  sample: PropTypes.object.isRequired
}

export default SampleCard
