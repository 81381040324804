// SAXONY SAMPLES TO MASTER SAMPLE
import artic from "./img/saxony-master-samples/Saxony_Artic.jpg"
import azure from "./img/saxony-master-samples/Saxony_Azure.jpg"
import black from "./img/saxony-master-samples/Saxony_Black.jpg"
import clever from "./img/saxony-master-samples/Saxony_Clever.jpg"
import cobalt from "./img/saxony-master-samples/Saxony_Cobalt.jpg"
import esmerald from "./img/saxony-master-samples/Saxony_Emerald.jpg"
import flare from "./img/saxony-master-samples/Saxony_Flare.jpg"
import fog from "./img/saxony-master-samples/Saxony_Fog.jpg"
import grass from "./img/saxony-master-samples/Saxony_Grass.jpg"
import iron from "./img/saxony-master-samples/Saxony_Iron.jpg"
import lagoon from "./img/saxony-master-samples/Saxony_Lagoon.jpg"
import lipstick from "./img/saxony-master-samples/Saxony_Lipstick.jpg"
import mandarin from "./img/saxony-master-samples/Saxony_Mandarin.jpg"
import navy from "./img/saxony-master-samples/Saxony_Navy.jpg"
import sand from "./img/saxony-master-samples/Saxony_Sand.jpg"
import sea from "./img/saxony-master-samples/Saxony_Sea.jpg"
import shell from "./img/saxony-master-samples/Saxony_Shell.jpg"
import sky from "./img/saxony-master-samples/Saxony_Sky.jpg"
import storm from "./img/saxony-master-samples/Saxony_Storm.jpg"
import taupe from "./img/saxony-master-samples/Saxony_Taupe.jpg"
import zest from "./img/saxony-master-samples/Saxony_Zest.jpg"

// SAXONY SAMPLES TO CARDS
import articCard from "./img/saxony-card-samples/SAO-0201-Arctic.jpg"
import azureCard from "./img/saxony-card-samples/SAO-0212-Azure.jpg"
import blackCard from "./img/saxony-card-samples/SAO-0207-Black.jpg"
import cleverCard from "./img/saxony-card-samples/SAO-0215-Clover.jpg"
import cobaltCard from "./img/saxony-card-samples/SAO-0209-Cobalt.jpg"
import esmeraldCard from "./img/saxony-card-samples/SAO-0214-Emerald.jpg"
import flareCard from "./img/saxony-card-samples/SAO-0219-Flare.jpg"
import fogCard from "./img/saxony-card-samples/SAO-0205-Fog.jpg"
import grassCard from "./img/saxony-card-samples/SAO-0216-Grass.jpg"
import ironCard from "./img/saxony-card-samples/SAO-0200-Iron.jpg"
import lagoonCard from "./img/saxony-card-samples/SAO-0213-Lagoon.jpg"
import lipstickCard from "./img/saxony-card-samples/SAO-0220-Lipstick.jpg"
import mandarinCard from "./img/saxony-card-samples/SAO-0218-Mandarin.jpg"
import navyCard from "./img/saxony-card-samples/SAO-0208-Navy.jpg"
import sandCard from "./img/saxony-card-samples/SAO-0203-Sand.jpg"
import seaCard from "./img/saxony-card-samples/SAO-0211-Sea.jpg"
import shellCard from "./img/saxony-card-samples/SAO-0202-Shell.jpg"
import skyCard from "./img/saxony-card-samples/SAO-0210-Sky.jpg"
import stormCard from "./img/saxony-card-samples/SAO-0206-Storm-Cloud.jpg"
import taupeCard from "./img/saxony-card-samples/SAO-0204-Taupe.jpg"
import zestCard from "./img/saxony-card-samples/SAO-0217-Zest.jpg"

export const SAMPLES_SHOW_IN_DESKTOP = [
  {
    id: crypto.randomUUID(),
    color: "sea",
    reference: "SAO-0211",
    imgDetail: sea,
    img: seaCard
  },
  {
    id: crypto.randomUUID(),
    color: "sky",
    reference: "SAO-0210",
    imgDetail: sky,
    img: skyCard
  },
  {
    id: crypto.randomUUID(),
    color: "azure",
    reference: "SAO-0212",
    imgDetail: azure,
    img: azureCard
  },
  {
    id: crypto.randomUUID(),
    color: "cobalt",
    reference: "SAO-0209",
    imgDetail: cobalt,
    img: cobaltCard
  },
  {
    id: crypto.randomUUID(),
    color: "navy",
    reference: "SAO-0208",
    imgDetail: navy,
    img: navyCard
  },
  {
    id: crypto.randomUUID(),
    color: "artic",
    reference: "SAO-0201",
    imgDetail: artic,
    img: articCard
  },
  {
    id: crypto.randomUUID(),
    color: "shell",
    reference: "SAO-0202",
    imgDetail: shell,
    img: shellCard
  },
  {
    id: crypto.randomUUID(),
    color: "sand",
    reference: "SAO-0203",
    imgDetail: sand,
    img: sandCard
  },
  {
    id: crypto.randomUUID(),
    color: "taupe",
    reference: "SAO-0204",
    imgDetail: taupe,
    img: taupeCard
  },
  {
    id: crypto.randomUUID(),
    color: "fog",
    reference: "SAO-0205",
    imgDetail: fog,
    img: fogCard
  },
  {
    id: crypto.randomUUID(),
    color: "storm cloud",
    reference: "SAO-0206",
    imgDetail: storm,
    img: stormCard
  },
  {
    id: crypto.randomUUID(),
    color: "iron",
    reference: "SAO-0200",
    imgDetail: iron,
    img: ironCard
  },
  {
    id: crypto.randomUUID(),
    color: "black",
    reference: "SAO-0207",
    imgDetail: black,
    img: blackCard
  },
  {
    id: crypto.randomUUID(),
    color: "zest",
    reference: "SAO-0217",
    imgDetail: zest,
    img: zestCard
  },
  {
    id: crypto.randomUUID(),
    color: "mandarin",
    reference: "SAO-0218",
    imgDetail: mandarin,
    img: mandarinCard
  },
  {
    id: crypto.randomUUID(),
    color: "flare",
    reference: "SAO-0219",
    imgDetail: flare,
    img: flareCard
  },
  {
    id: crypto.randomUUID(),
    color: "lipstick",
    reference: "SAO-0220",
    imgDetail: lipstick,
    img: lipstickCard
  },
  {
    id: crypto.randomUUID(),
    color: "grass",
    reference: "SAO-0216",
    imgDetail: grass,
    img: grassCard
  },
  {
    id: crypto.randomUUID(),
    color: "clover",
    reference: "SAO-0215",
    imgDetail: clever,
    img: cleverCard
  },
  {
    id: crypto.randomUUID(),
    color: "lagoon",
    reference: "SAO-0213",
    imgDetail: lagoon,
    img: lagoonCard
  },
  {
    id: crypto.randomUUID(),
    color: "emerald",
    reference: "SAO-0214",
    imgDetail: esmerald,
    img: esmeraldCard
  }
]

export const SAMPLES_SHOW_IN_MOBILE = [
  {
    id: crypto.randomUUID(),
    color: "sea",
    reference: "SAO-0211",
    imgDetail: sea,
    img: seaCard
  },
  {
    id: crypto.randomUUID(),
    color: "sky",
    reference: "SAO-0210",
    imgDetail: sky,
    img: skyCard
  },
  {
    id: crypto.randomUUID(),
    color: "azure",
    reference: "SAO-0212",
    imgDetail: azure,
    img: azureCard
  },
  {
    id: crypto.randomUUID(),
    color: "cobalt",
    reference: "SAO-0209",
    imgDetail: cobalt,
    img: cobaltCard
  },
  {
    id: crypto.randomUUID(),
    color: "navy",
    reference: "SAO-0208",
    imgDetail: navy,
    img: navyCard
  },
  {
    id: crypto.randomUUID(),
    color: "shell",
    reference: "SAO-0202",
    imgDetail: shell,
    img: shellCard
  },
  {
    id: crypto.randomUUID(),
    color: "artic",
    reference: "SAO-0201",
    imgDetail: artic,
    img: articCard
  },
  {
    id: crypto.randomUUID(),
    color: "sand",
    reference: "SAO-0203",
    imgDetail: sand,
    img: sandCard
  },
  {
    id: crypto.randomUUID(),
    color: "taupe",
    reference: "SAO-0204",
    imgDetail: taupe,
    img: taupeCard
  },
  {
    id: crypto.randomUUID(),
    color: "storm cloud",
    reference: "SAO-0206",
    imgDetail: storm,
    img: stormCard
  },
  {
    id: crypto.randomUUID(),
    color: "fog",
    reference: "SAO-0205",
    imgDetail: fog,
    img: fogCard
  },
  {
    id: crypto.randomUUID(),
    color: "iron",
    reference: "SAO-0200",
    imgDetail: iron,
    img: ironCard
  },
  {
    id: crypto.randomUUID(),
    color: "black",
    reference: "SAO-0207",
    imgDetail: black,
    img: blackCard
  },
  {
    id: crypto.randomUUID(),
    color: "mandarin",
    reference: "SAO-0218",
    imgDetail: mandarin,
    img: mandarinCard
  },
  {
    id: crypto.randomUUID(),
    color: "zest",
    reference: "SAO-0217",
    imgDetail: zest,
    img: zestCard
  },
  {
    id: crypto.randomUUID(),
    color: "flare",
    reference: "SAO-0219",
    imgDetail: flare,
    img: flareCard
  },
  {
    id: crypto.randomUUID(),
    color: "lipstick",
    reference: "SAO-0220",
    imgDetail: lipstick,
    img: lipstickCard
  },
  {
    id: crypto.randomUUID(),
    color: "clover",
    reference: "SAO-0215",
    imgDetail: clever,
    img: cleverCard
  },
  {
    id: crypto.randomUUID(),
    color: "grass",
    reference: "SAO-0216",
    imgDetail: grass,
    img: grassCard
  },
  {
    id: crypto.randomUUID(),
    color: "lagoon",
    reference: "SAO-0213",
    imgDetail: lagoon,
    img: lagoonCard
  },
  {
    id: crypto.randomUUID(),
    color: "emerald",
    reference: "SAO-0214",
    imgDetail: esmerald,
    img: esmeraldCard
  }
]
