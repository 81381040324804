import classNames from "classnames/bind"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import useToggle from "hooks/useToggle"
import "./assets/styles/color-palette.scss"
import dropper from "./assets/img/dropper.svg"
import ColorDetail from "./components/ColorDetail"
import { useState, useEffect } from "react"
import { isEUROPE, isUSA } from "services/instances"

const NO_OP = () => {}

const ColorPalette = ({
  colors = [],
  align,
  actived,
  hideWarning = false,
  handleOnClick = NO_OP,
  hideDetailColor = false,
  mainTypeColorDetail = "rectangle"
}) => {
  const hideStock = isUSA() && isEUROPE()
  const [toggable, Toggle] = useToggle()
  const [color, setColor] = useState(actived ? colors[0]?.node : null)

  useEffect(() => {
    if (handleOnClick) {
      handleOnClick(color)
    }
  }, [color])

  return (
    <div>
      {!hideDetailColor && color && <ColorDetail color={color} type={mainTypeColorDetail} />}
      <div className="color-palette-container">
        <div
          className={classNames({
            collapse: !toggable,
            "collapse-show": toggable,
            "collapse-center": align === "center"
          })}
        >
          {colors.map(({ node: { color, stock, imageThumbMedium, imageThumbSmall } }) => (
            <div
              className={classNames({
                "card-color": true,
                "card-color-active": color?.color === color
              })}
              key={`key_${color}`}
              onClick={() => setColor({ color, stock, imageThumbMedium })}
            >
              <img className="card-color--color" src={imageThumbSmall} />
              <div className="card-color--identifier">
                <p>{color}</p>
                {hideStock && <p>{stock}</p>}
              </div>
            </div>
          ))}
        </div>
        {!hideWarning && (
          <div className="d-flex px-4 px-md-3">
            <img className="dropper mx-3 mx-md-2" src={dropper} alt="dropper" />
            <p className="my-3">
              <FormattedMessage
                id="ColorPalette.warning"
                description="Color Palette section warning info"
                defaultMessage="The color samples are indicative and not intended as an exact replica of the actual color of the product. Due to variations in monitor settings and color balances, colors are representative only and should not be construed as an exact color match of the listed product. We advise you to request actual material samples before ordering."
              />
            </p>
          </div>
        )}
      </div>
      <div
        className={classNames({
          "text-center": true,
          "bounce mt-4": !toggable
        })}
      >
        <FontAwesomeIcon
          icon={faAngleDown}
          border
          size="2x"
          flip={toggable ? "vertical" : undefined}
          className="btn btn-lg btn-outline-dark"
          onClick={() => Toggle()}
        />
      </div>
    </div>
  )
}

ColorPalette.propTypes = {
  align: PropTypes.string,
  actived: PropTypes.bool,
  hideWarning: PropTypes.bool,
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      stock: PropTypes.string,
      imageThumbLarge: PropTypes.string,
      imageThumbMedium: PropTypes.string,
      imageThumbSmall: PropTypes.string
    })
  ),
  handleOnClick: PropTypes.func,
  hideDetailColor: PropTypes.bool,
  mainTypeColorDetail: PropTypes.oneOf(["rectangle", "square"])
}

export default ColorPalette
