import { FormattedMessage } from "react-intl"
import hospitality from "../img/1.hospitality.jpg"
import marine from "../img/2.marine.jpg"
import residential from "../img/3.residential.jpg"
import automotive from "../img/4.automotive.jpg"

export const ITEMS = [
  {
    id: "Hospitality",
    title: (
      <FormattedMessage
        id="Breeze.Applications.hospitality"
        description="Breeze section applications item hospitality"
        defaultMessage="Hospitality"
      />
    ),
    imageThumb: hospitality
  },
  {
    id: "Marine",
    title: (
      <FormattedMessage
        id="Breeze.Applications.marine"
        description="Breeze section applications item marine"
        defaultMessage="Marine"
      />
    ),
    imageThumb: marine
  },
  {
    id: "Residential",
    title: (
      <FormattedMessage
        id="Breeze.Applications.residential"
        description="Breeze section applications item residential"
        defaultMessage="Residential"
      />
    ),
    imageThumb: residential
  },
  {
    id: "Automotive",
    title: (
      <FormattedMessage
        id="Breeze.Applications.automotive"
        description="Breeze section applications item automotive"
        defaultMessage="Automotive"
      />
    ),
    imageThumb: automotive
  }
]
