import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import ContactForm from "./components/ContactForm"

const Contact = ({ id }) => (
  <div id={id} className="container my-3 py-4 upholstery-glassmorphism">
    <div className="row justify-content-md-center">
      <TitleUnderlined hTag={H2_TAG}>
        <FormattedMessage
          id="Ibex.Contact.title"
          description="Ibex section title contact"
          defaultMessage="Reserve your appointment now!"
        />
      </TitleUnderlined>
      <div className="col-12">
        <ContactForm />
      </div>
    </div>
  </div>
)

Contact.propTypes = {
  id: PropTypes.string.isRequired
}

export default Contact
