import { FormattedMessage } from "react-intl"

// Google Analytics
export const GTAG_BY_ENV = {
  production: "GTM-5CM5GR9W",
  stage: "GTM-M5RFDF2C",
  develop: "GTM-M5RFDF2C"
}

export const MASIALA_HOTJAR_CODE = { hjid: 4966956, hjsv: 6 }

// Navigations
export const NAV_0 = "Samples Request"
export const NAV_1 = "Sustainable Attributes"
export const NAV_2 = "Technical Information"
export const NAV_3 = "Watch Video"

// Modal types

export const MODAL_TYPES = {
  REQUEST_SAMPLES: "REQUEST_SAMPLES",
  REQUEST_CATALOGUE: "REQUEST_CATALOGUE",
  DIGITAL_CATALOGUE: "DIGITAL_CATALOGUE",
  CARE_AND_CLEANING_GUIDE: "CARE_AND_CLEANING_GUIDE",
  PRODUCT_DETAIL: "PRODUCT_DETAIL",
  CONTACT: "CONTACT"
}

export const MODAL_TYPES_THANKS_URL = {
  REQUEST_SAMPLES: "request-samples",
  REQUEST_CATALOGUE: "request-catalogue",
  DIGITAL_CATALOGUE: "digital-catalogue",
  CARE_AND_CLEANING_GUIDE: "care-cleaning",
  CONTACT: "contact",
  PRODUCT_DETAIL: "product-detail"
}

export const MODAL_TYPE_REQUEST = [MODAL_TYPES.REQUEST_SAMPLES, MODAL_TYPES.REQUEST_CATALOGUE]

export const MODAL_TYPES_DOWNLOAD = [
  MODAL_TYPES.DIGITAL_CATALOGUE,
  MODAL_TYPES.CARE_AND_CLEANING_GUIDE,
  MODAL_TYPES.PRODUCT_DETAIL
]

export const REQUEST_SAMPLES_INFO = {
  id: MODAL_TYPES.REQUEST_SAMPLES,
  type: MODAL_TYPES.REQUEST_SAMPLES,
  thanksUrl: MODAL_TYPES_THANKS_URL.REQUEST_SAMPLES,
  downloadFileName: null,
  title: (
    <FormattedMessage id="Masiala.ModalContext.requestTitle" defaultMessage="Samples Request" />
  ),
  subtitle: (
    <FormattedMessage
      id="Masiala.ModalContext.requestSubtitle"
      defaultMessage="Fill out the form below. and request your samples!"
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>
      }}
    />
  )
}

export const REQUEST_CATALOGUE_INFO = {
  id: MODAL_TYPES.REQUEST_CATALOGUE,
  type: MODAL_TYPES.REQUEST_CATALOGUE,
  thanksUrl: MODAL_TYPES_THANKS_URL.REQUEST_CATALOGUE,
  downloadFileName: null,
  title: (
    <FormattedMessage id="ModalContext.downloadTitle" defaultMessage="Masiala Catalogue Request" />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.downloadSubtitle"
      defaultMessage="Fill out the form below, and request your Masiala Catalogue!"
    />
  )
}

export const DIGITAL_CATALOGUE_INFO = {
  id: MODAL_TYPES.DIGITAL_CATALOGUE,
  type: MODAL_TYPES.DIGITAL_CATALOGUE,
  thanksUrl: MODAL_TYPES_THANKS_URL.DIGITAL_CATALOGUE,
  downloadFileName: "digital catalogue",
  title: (
    <FormattedMessage
      id="ModalContext.downloadDigitalCatalogue"
      defaultMessage="Download Digital Catalogue"
    />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.downloadDigitalSubtitle"
      defaultMessage="Fill out the form below, and download the digital catalogue!"
    />
  )
}

export const CARE_AND_CLEANING_GUIDE_INFO = {
  id: MODAL_TYPES.CARE_AND_CLEANING_GUIDE,
  type: MODAL_TYPES.CARE_AND_CLEANING_GUIDE,
  thanksUrl: MODAL_TYPES_THANKS_URL.CARE_AND_CLEANING_GUIDE,
  downloadFileName: "care and cleaning guide",
  title: (
    <FormattedMessage
      id="ModalContext.downloadTitleCare&Cleaning"
      defaultMessage="Download Care & Cleaning Guide"
    />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.downloadSubtitleCare&Cleaning"
      defaultMessage="Fill out the form below, and download the care & cleaning guide!"
    />
  )
}

export const PRODUCT_DETAIL_INFO = {
  id: MODAL_TYPES.PRODUCT_DETAIL,
  type: MODAL_TYPES.PRODUCT_DETAIL,
  thanksUrl: MODAL_TYPES_THANKS_URL.PRODUCT_DETAIL,
  downloadFileName: "specification sheet",
  title: (
    <FormattedMessage
      id="ModalContext.downloadProductDetail"
      defaultMessage="Download Product Details"
    />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.downloadSubtitleProductDetail&Cleaning"
      defaultMessage="Fill out the form below, and download the care & Product details!"
    />
  )
}

export const CONTACT_INFO = {
  id: MODAL_TYPES.CONTACT,
  type: MODAL_TYPES.CONTACT,
  thanksUrl: MODAL_TYPES_THANKS_URL.CONTACT,
  downloadFileName: null,
  title: <FormattedMessage id="ModalContext.contactTitle" defaultMessage="Contact" />,
  subtitle: <FormattedMessage id="ModalContext.contactSubtitle" defaultMessage="contact us" />
}
