import PropTypes from "prop-types"
import rivuletChair from "./img/silla-960.jpg"
import RequestsSection from "../RequestsSection"
import useRequestModal from "../RequestsSection/hooks/useRequestModal"
import { REQUEST_SAMPLES_INFO } from "../RequestsSection/utils"
import { FormattedMessage } from "react-intl"
import "./styles/rivulet-description.scss"

const RivuletDescription = ({ id }) => {
  const { modalContext, toggable, toggle, handleClickInRequest } = useRequestModal()

  const openRequestModal = () => {
    handleClickInRequest(REQUEST_SAMPLES_INFO)
  }

  return (
    <>
      <section id={id} className="container--desc-rv">
        <div className="row g-0">
          <div className="col-12 col-lg-7 d-flex justify-content-center align-items-center">
            <img className="img-fluid" src={rivuletChair} alt="chair-image" />
          </div>
          <div className="col-12 col-lg-5 d-flex justify-content-start align-items-center">
            <div className="info--desc-rv">
              <h2 className="title--desc-rv">
                <FormattedMessage
                  id="Rivulet.Description.title"
                  defaultMessage="Inspired By Nature:"
                />
              </h2>
              <p className="fw-light">
                <FormattedMessage
                  id="Rivulet.Descriptions.descriptionBody"
                  defaultMessage="Our new pattern, Rivulet, draws its inspiration directly from the mesmerizing beauty of nature. It is a visual ode to the intricate network of streams and brooks that meander through lush forests and serene landscapes. The gentle curves and delicate lines in Rivulet mimic the graceful flow of water as it winds its way through pebbles, rocks, and foliage."
                />
              </p>
              <button className="btn btn-rv btn--desc-rv" onClick={openRequestModal}>
                <FormattedMessage
                  id="Riuvlet.Banner.buttonText"
                  defaultMessage="Request Sample Card"
                />
              </button>
            </div>
          </div>
        </div>
      </section>
      <hr className="separator-rv" />
      <RequestsSection modalContext={modalContext} toggable={toggable} toggle={toggle} />
    </>
  )
}

RivuletDescription.propTypes = {
  id: PropTypes.string.isRequired
}

export default RivuletDescription
