import leftArrow from "modules/rivulet/img/left-chevron.png"
import rightArrow from "modules/rivulet/img/right-arrow.png"

export const STATIC_SLIDER = {
  arrows: false,
  dots: false,
  autoplay: false,
  swipe: false
}

export const SLIDER_OPTIONS = {
  ...STATIC_SLIDER,
  slidesToShow: 1,
  fade: true,
  swipe: false
}

export const SLIDER_OPTIONS_IMGS = {
  infinite: true,
  autoplay: false,
  arrows: true,
  slidesToShow: 1,
  nextArrow: <img width={32} src={rightArrow} alt="next-arrow" />,
  prevArrow: <img width={32} src={leftArrow} alt="prev-arrow" />
}

export const SLIDER_OPTIONS_IMGS_MOBILE = {
  dots: true,
  infinite: true,
  autoplay: false,
  arrows: false,
  slidesToShow: 1
}
