import gql from "graphql-tag"

const PatternsPetFriendlyQuery = gql`
  query PatternsPetFriendlyQuery($instanceIds: [ID], $petFriendly: Boolean) {
    patterns(products_Instances: $instanceIds, otherattributes_PetFriendly: $petFriendly) {
      edges {
        node {
          id
          name
          altName
          slug
          isNew
          imageThumbSmall
          productcategorization {
            design
          }
          products(instances: $instanceIds) {
            totalCount
            edges {
              node {
                id
                code
                color
                predominantColors
                imageThumbSmall
                isNew
              }
            }
          }
        }
      }
    }
  }
`

export default PatternsPetFriendlyQuery
