import PropTypes from "prop-types"
import { useStateMachine } from "little-state-machine"
import { useMediaQuery } from "@mui/material"
import { updateAction } from "utils/littleStateMachine"
import { LG } from "consts/breakpoints"
import SamplesListDesktop from "./components/SamplesListDesktop"
import SamplesListMobile from "./components/SamplesListMobile"
import { SAMPLES } from "../../constants"

const SamplesList = ({ samples = SAMPLES }) => {
  const { actions } = useStateMachine({ updateAction })
  const isMobile = useMediaQuery(LG)

  const handleClick = (sample) => {
    actions.updateAction({
      sampleDetails: { ...sample }
    })
  }

  return (
    <div className="container g-lg-0">
      <div className="row g-lg-0 d-flex flex-wrap justify-content-center">
        {!isMobile ? (
          <SamplesListDesktop samples={samples} handleClick={handleClick} />
        ) : (
          <SamplesListMobile samples={samples} handleClick={handleClick} />
        )}
      </div>
    </div>
  )
}

SamplesList.propTypes = {
  samples: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default SamplesList
