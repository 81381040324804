import mostaza from "../img/1-mostaza.jpg"
import mandarina from "../img/2-mandarina.jpg"
import naranja from "../img/3-naranja.jpg"
import cereza from "../img/4-cereza.jpg"
import escarlata from "../img/5-escarlata.jpg"
import rojo from "../img/6-rojo.jpg"
import capuchino from "../img/7-capuchino.jpg"
import cobre from "../img/8-cobre.jpg"
import camel from "../img/9-camel.jpg"
import tabaco from "../img/10-tabaco.jpg"
import marron from "../img/11-marron.jpg"
import vinotinto from "../img/12-vinotinto.jpg"
import mocca from "../img/13-mocca.jpg"
import chocolate from "../img/14-chocolate.jpg"
import verdeManzana from "../img/15-verde-manzana.jpg"
import verdeClaro from "../img/16-verde-claro.jpg"
import verdeOscuro from "../img/17-verde-oscuro.jpg"
import azulVioleta from "../img/18-azul-violeta.jpg"
import azul from "../img/19-azul.jpg"
import azulOscuro from "../img/20-azul-oscuro.jpg"
import almendra from "../img/21-almendra.jpg"
import grisMedio from "../img/22-gris-medio.jpg"
import plata from "../img/23-plata.jpg"
import negro from "../img/24-negro.jpg"

export const COLORS = [
  { node: { color: "Mostaza", imageThumbSmall: mostaza, imageThumbMedium: mostaza } },
  { node: { color: "Mandarina", imageThumbSmall: mandarina, imageThumbMedium: mandarina } },
  { node: { color: "Naranja", imageThumbSmall: naranja, imageThumbMedium: naranja } },
  { node: { color: "Cereza", imageThumbSmall: cereza, imageThumbMedium: cereza } },
  { node: { color: "escarlata", imageThumbSmall: escarlata, imageThumbMedium: escarlata } },
  { node: { color: "Rojo", imageThumbSmall: rojo, imageThumbMedium: rojo } },
  { node: { color: "Capuchino", imageThumbSmall: capuchino, imageThumbMedium: capuchino } },
  { node: { color: "Cobre", imageThumbSmall: cobre, imageThumbMedium: cobre } },
  { node: { color: "Camel", imageThumbSmall: camel, imageThumbMedium: camel } },
  { node: { color: "Tabaco", imageThumbSmall: tabaco, imageThumbMedium: tabaco } },
  { node: { color: "Marron", imageThumbSmall: marron, imageThumbMedium: marron } },
  { node: { color: "Vinotinto", imageThumbSmall: vinotinto, imageThumbMedium: vinotinto } },
  { node: { color: "Mocca", imageThumbSmall: mocca, imageThumbMedium: mocca } },
  { node: { color: "Chocolate", imageThumbSmall: chocolate, imageThumbMedium: chocolate } },
  {
    node: { color: "Verde Manzana", imageThumbSmall: verdeManzana, imageThumbMedium: verdeManzana }
  },
  { node: { color: "Verde Claro", imageThumbSmall: verdeClaro, imageThumbMedium: verdeClaro } },
  { node: { color: "Verde Oscuro", imageThumbSmall: verdeOscuro, imageThumbMedium: verdeOscuro } },
  { node: { color: "Azul Violeta", imageThumbSmall: azulVioleta, imageThumbMedium: azulVioleta } },
  { node: { color: "Azul", imageThumbSmall: azul, imageThumbMedium: azul } },
  { node: { color: "Azul Oscuro", imageThumbSmall: azulOscuro, imageThumbMedium: azulOscuro } },
  { node: { color: "Almendra", imageThumbSmall: almendra, imageThumbMedium: almendra } },
  { node: { color: "Gris Medio", imageThumbSmall: grisMedio, imageThumbMedium: grisMedio } },
  { node: { color: "Plata", imageThumbSmall: plata, imageThumbMedium: plata } },
  { node: { color: "Negro", imageThumbSmall: negro, imageThumbMedium: negro } }
]
