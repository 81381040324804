import Footer from "components/Footer"
import Header from "components/Header"
import Banner from "./components/Banner"
import Contact from "./components/Contact"
import { NAV_ITEMS } from "./constants"
import Invitation from "./components/Invitation"
import Inspiration from "./components/Inspiration"

const Ibex = () => (
  <>
    <Header items={NAV_ITEMS} isTransparent />
    <div>
      <Banner />
      <Inspiration />
      <Invitation />
      <Contact id={NAV_ITEMS[0]} />
    </div>
    <Footer onlyLogo />
  </>
)

export default Ibex
