import PropTypes from "prop-types"
import { Link as LinkScroll } from "react-scroll"
import { Link } from "react-router-dom"
import "components/Header/components/Menu/styles/navbar.scss"
import ItemDropdown from "./components/ItemDropdown"

const NO_OP = () => {}

const RenderObject = ({ items = [] }) => {
  return items.map(({ id, text, href, externalLink, dropDown, callback }) => (
    <li className="main-menu-sections__section" key={`key_${id}`}>
      <div className="text-capitalize">
        {!externalLink ? (
          <LinkScroll
            className="header-menu-button fw-normal"
            activeClass="header-menu-button--active"
            to={href}
            duration={100}
            offset={-50}
            smooth
            spy
            onClick={callback ? () => callback() : NO_OP}
          >
            {text}
          </LinkScroll>
        ) : !dropDown ? (
          <Link className="header-menu-button" to={href}>
            {text}
          </Link>
        ) : (
          <ItemDropdown text={text} dropDown={dropDown} />
        )}
      </div>
    </li>
  ))
}

RenderObject.propTypes = {
  id: PropTypes.string,
  text: PropTypes.object,
  dropDown: PropTypes.arrayOf(PropTypes.shape({})),
  externalLink: PropTypes.bool,
  href: PropTypes.string
}

export default RenderObject
