import PropTypes from "prop-types"
import Slider from "react-slick"
import TableAccordion from "./components/TableAccordion"
import { SLIDER_OPTIONS_IMGS_MOBILE } from "../ProductsForDesktop/constants"

const ProductsForMobile = ({ items }) => (
  <div className="container">
    <div className="row">
      <div className="col">
        {items.map(({ id, title, info, imgs }) => (
          <TableAccordion key={`key_${id}`} title={title} isFullContainer>
            <div className="container g-0">
              <p className="info--segm-hotelery my-4">{info}</p>
              <Slider {...SLIDER_OPTIONS_IMGS_MOBILE}>
                {imgs.map(({ name, img }) => (
                  <div
                    key={`key_${name}`}
                    className="position-relative d-flex flex-column justify-content-center"
                  >
                    <img id={name} className="individual-img" src={img} alt="application" />
                    <p className="position-absolute bottom-0 start-0 text-white fs-4 fw-normal m-3">
                      {name}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          </TableAccordion>
        ))}
      </div>
    </div>
  </div>
)

ProductsForMobile.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      info: PropTypes.element,
      imgs: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
          img: PropTypes.string
        })
      )
    })
  ).isRequired
}

export default ProductsForMobile
