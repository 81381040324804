import PropTypes from "prop-types"
import DoAndDoNotList from "../../../DoAndDoNotList"
import TableAccordion from "./components/TableAccordion"
import CleaningProcedure from "../../../CleaningProcedure"
import KeepInMind from "./components/KeepInMindMobile"

const LayoutForMobile = ({ sections, sectionIdToScroll }) =>
  sections.map((item, index) => (
    <section key={`key_${item.id}${index}`}>
      <TableAccordion id={sectionIdToScroll} title={item.titleMobile} img={item.img}>
        <DoAndDoNotList id={sectionIdToScroll} section={item.doAndDoNots} />
        {item?.cleaningProcedures && <CleaningProcedure section={item?.cleaningProcedures} />}
        {item?.keepInMind && <KeepInMind {...item?.keepInMind} />}
      </TableAccordion>
    </section>
  ))

LayoutForMobile.propTypes = {
  sections: PropTypes.array.isRequired,
  sectionIdToScroll: PropTypes.string.isRequired
}

export default LayoutForMobile
