import { useEffect, useState } from "react"
import { useStateMachine } from "little-state-machine"
import useToggle from "hooks/useToggle"
import { updateAction } from "app/store"
import _ from "lodash"

const KIZUNA_CATALOGUE_ITEM = { catalogue: "Kizuna Catalogue" }

const useRequestModal = () => {
  const [toggable, toggle] = useToggle()
  const { state, actions } = useStateMachine({ updateAction })
  const [modalContext, setModalContext] = useState({})

  const handleAddCatalogueToRequest = () => {
    if (!state?.sampleCount || state?.sampleCount.length <= 9) {
      const updateSamplesCount = state?.sampleCount
        ? [...state?.sampleCount, { ...KIZUNA_CATALOGUE_ITEM, id: _.random(1, 4, true) }]
        : [{ ...KIZUNA_CATALOGUE_ITEM, id: _.random(1, 4, true) }]

      actions.updateAction({
        sampleCount: updateSamplesCount
      })
    }
  }

  const handleOrderCatalogue = (modalType) => {
    handleAddCatalogueToRequest()
    // handleClickInRequest(modalType)
  }

  const handleClickInRequest = (modalType) => {
    setModalContext(modalType)
    toggle()
  }

  useEffect(() => {}, [modalContext])

  return {
    modalContext,
    toggable,
    toggle,
    handleClickInRequest,
    handleOrderCatalogue
  }
}
export default useRequestModal
