const FLOOR_ROOTS = {
  PISOS: "pisos",
  PISOS_INTERNO: "pisos-interno"
}

export const handleManageForContact = (pathname, actions) => {
  const [, , root] = pathname.split("/")
  if (root === FLOOR_ROOTS.PISOS) {
    actions.updateAction({ floorDetail: { getContact: true } })
  }
}
