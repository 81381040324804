/* eslint-disable indent */
import PropTypes from "prop-types"
import { useQuery } from "@apollo/client"
import Select from "components/InputMUI/Select"
import { getCurrentInstanceId } from "services/instances"
import { patternsQuery } from "./query"
import { FormattedMessage } from "react-intl"

const PatternField = ({ name, control, label, description, placeholder, defaultValue, error }) => {
  const { data, loading } = useQuery(patternsQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()],
      saleable: true,
      patternClass: "PL"
    }
  })

  const filteredPatterns = data?.patterns.edges.filter(
    ({
      node: {
        productcategorization: { status }
      }
    }) => {
      // /**
      //  * atributo seleable (modelo Pattern) en true
      //  * valor de garantia (modelo ProductCategorization) asignado y
      //     menor a 5 años con relación a la fecha de creación de la referencia (modelo Pattern)
      //  * valor de garantia diferente/menor a 10 años
      //  * atributo status (modelo ProductCategorization) en activo
      //  */
      // const currentDate = dayjs()
      // const patternDate = dayjs(date)
      // const hasWaranty = warranty && currentDate.diff(patternDate, "y", true) < 5
      // const hasWarantyMinor10Years = !/10 años|10 years/.test(warranty)
      // const isActive = /Active|Activo/.test(status)
      // return hasWaranty && hasWarantyMinor10Years && isActive

      const isActive = /Active|Activo/.test(status)
      return isActive
    }
  )

  const formattedOptions = filteredPatterns?.length
    ? filteredPatterns?.map(({ node: { altName: label, id: value } }) => ({
        label,
        value
      }))
    : []

  const OPTIONS = [
    ...formattedOptions,
    {
      label: (
        <FormattedMessage
          id="Claims.options.other"
          description="option other on claim"
          defaultMessage="Other"
        />
      ),
      value: "OTHER"
    }
  ]

  return (
    <Select
      name={name}
      control={control}
      label={label}
      description={description}
      placeholder={placeholder}
      options={OPTIONS}
      areDrawOptions
      isSearchable
      defaultValue={defaultValue}
      disabled={loading}
      error={error}
    />
  )
}

PatternField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  description: PropTypes.object,
  placeholder: PropTypes.object,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  error: PropTypes.string
}

export default PatternField
