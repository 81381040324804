/* eslint-disable react/jsx-key */
import { FormattedMessage } from "react-intl"
import ApplicationsElements from "../components/ApplicationsElements"
import { PERFORMANCE_DATA } from "./performanceData"
import SusntainableElements from "../components/SustainableElements"
import { CERTIFICATIONS, PROCESS_CERTIFICATIONS, SUSTAINABLE_PRODUCT_SPECS_ELEMENTS } from "../components/SustainableElements/constants"

const COMPOSITION = "COMPOSITION"
const CHARACTERISTICS = "CHARACTERISTICS"
const PERFORMANCE = "PERFORMANCE"
const FLAME_RETARDANCY = "FLAME_RETARDANCY"
const APPLICATIONS = "APPLICATIONS"
const SUSTAINALE_ATTRIBUTES = "SUSTAINALE_ATTRIBUTES"
const WARRANTY = "WARRANTY"

export const PRODUCT_SPECS = {
  [COMPOSITION]: (
    <FormattedMessage
      id="Rivulet.specComposition"
      defaultMessage="Composition"
    />
  ),
  [CHARACTERISTICS]: (
    <FormattedMessage
      id="Rivulet.specCharacteristics"
      defaultMessage="Characteristics"
    />
  ),
  [PERFORMANCE]: (
    <FormattedMessage
      id="Rivulet.specPerformance"
      defaultMessage="Performance"
    />
  ),
  [FLAME_RETARDANCY]: (
    <FormattedMessage
      id="Rivulet.specFlameRetardancy"
      defaultMessage="Flame Retardancy"
    />
  ),
  [APPLICATIONS]: (
    <FormattedMessage id="Rivulet.specApplications" defaultMessage="Applications" />
  ),
  [SUSTAINALE_ATTRIBUTES]: (
    <FormattedMessage id="Rivulet.specSustainableAttributes" defaultMessage="Sustainable Attributes" />
  ),
  [WARRANTY]: (
    <FormattedMessage id="Rivulet.specWarranty" defaultMessage="Warranty" />
  )
}

export const productSpecDetailsInformationToShow = () => {
  const PRODUCT_SPECS_DETAIL_INFORMATION = {
    [COMPOSITION]: {
      info: [
        {
          id: "Rivulet.specCompositionDetail.Content",
          description: (
            <FormattedMessage
              id="Rivulet.specCompositionDetail.Content"
              defaultMessage="Content"
            />
          ),
          value: (
            <FormattedMessage
              id="Rivulet.specCompositionDetail.Vinyl"
              defaultMessage="Vinyl"
            />
          )
        },
        {
          id: "Rivulet.specCompositionDetail.Specifics",
          description: (
            <FormattedMessage
              id="Rivulet.specCompositionDetail.Specifics"
              defaultMessage="Specifics"
            />
          ),
          value: ""
        },
        {
          id: "Rivulet.specCompositionDetail.Weight",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specCompositionDetail.Weight"
                defaultMessage="Weight"
              />
            </p>
          ),
          value: "26 oz"
        },
        {
          id: "Rivulet.specCompositionDetail.Width",
          description: (
            <p className="ps-3 ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specCompositionDetail.Width"
                defaultMessage="Width"
              />
            </p>
          ),
          value: "54 in"
        },
        {
          id: "Rivulet.specCompositionDetail.RollSize",
          description: (
            <p className="ps-3 ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specCompositionDetail.RollSize"
                defaultMessage="Roll Size"
              />
            </p>
          ),
          value: "30 yd"
        }
      ]
    },
    [CHARACTERISTICS]: {
      info: [
        {
          id: "Rivulet.specCharacteristics.BleachCleanable",
          description: (
            <FormattedMessage
              id="Rivulet.specCharacteristics.BleachCleanable"
              defaultMessage="Bleach Cleanable (1:9)"
            />
          ),
          value: ""
        },
        {
          id: "Rivulet.specCharacteristics.Cleanable",
          description: (
            <FormattedMessage
              id="Rivulet.specCharacteristics.Cleanable"
              defaultMessage="Cleanable"
            />
          ),
          value: ""
        },
        {
          id: "Rivulet.specCharacteristics.FormaldehydeFree",
          description: (
            <FormattedMessage
              id="Rivulet.specCharacteristics.FormaldehydeFree"
              defaultMessage="Formaldehyde Free"
            />
          ),
          value: ""
        },
        {
          id: "Rivulet.specCharacteristics.NoHeavyMetals",
          description: (
            <FormattedMessage
              id="Rivulet.specCharacteristics.NoHeavyMetals"
              defaultMessage="No Heavy Metals"
            />
          ),
          value: ""
        },
        {
          id: "Rivulet.specCharacteristics.WaterResistant",
          description: (
            <FormattedMessage
              id="Rivulet.specCharacteristics.WaterResistant"
              defaultMessage="Water Resistant"
            />
          ),
          value: ""
        }
      ]
    },
    [PERFORMANCE]: {
      info: [
        {
          id: "Rivulet.specPerformance.ASTMD257",
          description: PERFORMANCE_DATA([
            <FormattedMessage
              id="Rivulet.specPerformance.Anti"
              defaultMessage="Anti-Static"
            />,
            <FormattedMessage
              id="Rivulet.specPerformance.ASTMD257"
              defaultMessage="(ASTM D-257)"
            />
          ])
        },
        {
          id: "Rivulet.specPerformance.CFFAHeathcare201B",
          description: (
            <p className="fw-normal p-0 m-0 rivulet__description-title">
              <FormattedMessage
                id="Rivulet.specPerformance.CFFAHeathcare201B"
                defaultMessage="CFFA-Heathcare 201B"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specPerformance.ColdCrackCFFA6a",
          description: PERFORMANCE_DATA([
            <FormattedMessage
              id="Rivulet.specPerformance.ColdCrackCFFA6a"
              defaultMessage="Cold Crack -10°F"
            />,
            <FormattedMessage
              id="Rivulet.specPerformance.ColdCrackCFFA6aDescription"
              defaultMessage="CFFA-6a"
            />
          ])
        },
        {
          id: "Rivulet.specPerformance.StainResistantCFFA142",
          description: PERFORMANCE_DATA([
            <FormattedMessage
              id="Rivulet.specPerformance.StainResistantCFFA142"
              defaultMessage="Stain Resistant"
            />,
            <FormattedMessage
              id="Rivulet.specPerformance.StainResistantCFFA142Description"
              defaultMessage="CFFA-142"
            />
          ])
        },
        {
          id: "Rivulet.specPerformance.SulfideStainResistant",
          description: PERFORMANCE_DATA([
            <FormattedMessage
              id="Rivulet.specPerformance.SulfideStainResistant"
              defaultMessage="Sulfide Stain Resistant"
            />,
            <FormattedMessage
              id="Rivulet.specPerformance.SulfideStainResistantDescription"
              defaultMessage="Fisher Body TM 31-12"
            />
          ])
        },
        {
          id: "Rivulet.specPerformance.SuperiorAbrasion",
          description: PERFORMANCE_DATA([
            <FormattedMessage
              id="Rivulet.specPerformance.SuperiorAbrasion"
              defaultMessage="Superior Abrasion"
            />,
            <FormattedMessage
              id="Rivulet.specPerformance.SuperiorAbrasionDescription"
              defaultMessage="CFFA-1a / ASTM D-4157"
            />,
            <FormattedMessage
              id="Rivulet.specPerformance.SuperiorAbrasionCycles"
              defaultMessage="100,000 Cycles, Wyzenbeek #10 Cotton Duck"
            />
          ])
        },
        {
          id: "Rivulet.specPerformance.UVStability",
          description: PERFORMANCE_DATA([
            <FormattedMessage
              id="Rivulet.specPerformance.UVStability"
              defaultMessage="UV Stability"
            />,
            <FormattedMessage
              id="Rivulet.specPerformance.UVStabilityDescription"
              defaultMessage="Xenotest: AATCC TM 16-3 / NTC 1479 / CFFA-2a - Blue Wool 1,000 Hours"
            />,
            <FormattedMessage
              id="Rivulet.specPerformance.UVStabilityCycles"
              defaultMessage="100,000 Cycles, Wyzenbeek #10 Cotton Duck"
            />
          ])
        }
      ],
      disclaimer: [
        {
          id: "Rivulet.specPerformance.SlingProperties.disclaimer",
          value: (
            <p className="color-letter-rv">
              <FormattedMessage
                id="Rivulet.specPerformance.SlingPropertiesDisclaimer"
                defaultMessage="** The performance of the Rivulet collection in sling applications relies on the proper furniture manufacturing process and its structure design. Please refer to the furniture manufacturer's instructions, warnings, and guidelines. Prior testing is recommended."
              />
            </p>
          )
        }
      ]
    },
    [FLAME_RETARDANCY]: {
      info: [
        {
          id: "Rivulet.specFlameRetardancyCAL",
          description: (
            <FormattedMessage
              id="Rivulet.specFlameRetardancyCAL"
              defaultMessage="California T.B. 117 - 2013"
            />
          ),
          value: (
            <FormattedMessage
              id="Rivulet.specFlameRetardancyCALDescription"
              defaultMessage="Pass"
            />
          )
        },
        {
          id: "Rivulet.specFlameRetardancyNFPA260",
          description: (
            <FormattedMessage
              id="Rivulet.specFlameRetardancyNFPA260"
              defaultMessage="NFPA 260 - Cover Fabric"
            />
          ),
          value: (
            <FormattedMessage
              id="Rivulet.specFlameRetardancyNFPA260Description"
              defaultMessage="Class 1"
            />
          )
        },
        {
          id: "Rivulet.specFlameRetardancyUFAC",
          description: (
            <FormattedMessage
              id="Rivulet.specFlameRetardancyUFAC"
              defaultMessage="UFAC Fabric"
            />
          ),
          value: (
            <FormattedMessage
              id="Rivulet.specFlameRetardancyNFPA260Description"
              defaultMessage="Class 1"
            />
          )
        }
      ],
      disclaimer: [
        {
          id: "Rivulet.specFlameRetardancyDisclaimer",
          value: (
            <p className="color-letter-rv">
              <FormattedMessage
                id="Rivulet.specFlameRetardancyDisclaimer"
                defaultMessage="*This term and any corresponding data refer to the typical performance in the specific tests indicated and should not be construed to imply the behavior of this or any other material under actual fire conditions."
              />
            </p>
          )
        }
      ]
    },
    [APPLICATIONS]: {
      info: [
        {
          id: "Rivulet.specApplicationsEducation",
          description: (
            <FormattedMessage
              id="Rivulet.specApplicationsEducation"
              defaultMessage="Education"
            />
          )
        },
        {
          id: "Rivulet.specApplicationsHealthcare",
          description: (
            <FormattedMessage
              id="Rivulet.specApplicationsHealthcare"
              defaultMessage="Healthcare"
            />
          )
        },
        {
          id: "Rivulet.specApplicationsOffice",
          description: (
            <FormattedMessage
              id="Rivulet.specApplicationsOffice"
              defaultMessage="Office"
            />
          )
        },
        {
          id: "Rivulet.specApplicationsHospitality",
          description: (
            <FormattedMessage
              id="Rivulet.specApplicationsHospitality"
              defaultMessage="Hospitality"
            />
          )
        },
        {
          id: "Rivulet.specApplicationsHospitalityHotel",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specApplicationsHospitalityHotel"
                defaultMessage="Hotel"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specApplicationsHospitalityMotel",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specApplicationsHospitalityMotel"
                defaultMessage="Motel"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specApplicationsHospitalityCasinoRestaurant",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specApplicationsHospitalityCasinoRestaurant"
                defaultMessage="Casino Restaurant"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specApplicationsPublicSpaces",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specApplicationsPublicSpaces"
                defaultMessage="Public Spaces"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specApplicationsResidential",
          description: (
            <FormattedMessage
              id="Rivulet.specApplicationsResidential"
              defaultMessage="Residential"
            />
          )
        },
        {
          id: "Rivulet.specApplicationsResidentialInterior",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specApplicationsResidentialInterior"
                defaultMessage="Interior"
              />
            </p>
          )
        }
      ]
    },
    [SUSTAINALE_ATTRIBUTES]: {
      info: [
        {
          id: "Rivulet.specSustainable.ProductSpecs",
          description: (
            <strong>
              <FormattedMessage
                id="Rivulet.specSustainable.ProductSpecs"
                defaultMessage="Sustainable Product Specs"
              />
            </strong>
          )
        },
        {
          id: "Rivulet.specSustainable.Phthalate",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.Phthalate"
                defaultMessage="Phthalate Free"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.LowVOCs",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.LowVOCs"
                defaultMessage="Low VOC’s No"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.Biocides",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.Biocides"
                defaultMessage="Biocides"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.Hi-Loft",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.Hi-Loft"
                defaultMessage="Hi-Loft RPET"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.NoFlameRetardants",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.NoFlameRetardants"
                defaultMessage="No Flame Retardants"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.Certifications",
          description: (
            <strong>
              <FormattedMessage
                id="Rivulet.specSustainable.Certifications"
                defaultMessage="Certifications"
              />
            </strong>
          )
        },
        {
          id: "Rivulet.specSustainable.Indoor",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.Indoor"
                defaultMessage="Indoor air quiality"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.CFFAHealthcare201",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.CFFAHealthcare201"
                defaultMessage="CFFA-Healthcare-201"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.Greenguard",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.Greenguard"
                defaultMessage="Greenguard Gold "
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.GRS",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.GRS"
                defaultMessage="GRS"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.ProcessCertifications",
          description: (
            <strong>
              <FormattedMessage
                id="Rivulet.specSustainable.ProcessCertifications"
                defaultMessage="Process Certifications And Compliances"
              />
            </strong>
          )
        },
        {
          id: "Rivulet.specSustainable.CarbonNeutral",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.CarbonNeutral"
                defaultMessage="Carbon Neutral"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.Prop65Compliant",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.Prop65Compliant"
                defaultMessage="Prop 65 Compliant"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.ISO14001",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.ISO14001"
                defaultMessage="ISO 14001"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.AB2998Compliant",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.AB2998Compliant"
                defaultMessage="AB 2998 Compliant"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.ISO50001",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.ISO50001"
                defaultMessage="ISO 50001"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.ISO45001",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.ISO45001"
                defaultMessage="ISO 45001"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.Contribute",
          description: (
            <strong>
              <FormattedMessage
                id="Rivulet.specSustainable.Contribute"
                defaultMessage="Seals We Contribute To"
              />
            </strong>
          )
        },
        {
          id: "Rivulet.specSustainable.Leed",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.Leed"
                defaultMessage="Leed"
              />
            </p>
          )
        },
        {
          id: "Rivulet.specSustainable.Well",
          description: (
            <p className="ps-3 pt-0 pb-0 m-0">
              <FormattedMessage
                id="Rivulet.specSustainable.Well"
                defaultMessage="Well"
              />
            </p>
          )
        }
      ]
    }
  }

  return PRODUCT_SPECS_DETAIL_INFORMATION
}
