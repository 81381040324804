import { defineMessages } from "react-intl"

export const MSGS = defineMessages({
  loading: {
    id: "Form.field.Country.loading",
    description: "Form field country loading countries",
    defaultMessage: "Loading countries..."
  },
  placeholder: {
    id: "Form.field.Country.placeholder",
    description: "Form field country placeholder",
    defaultMessage: "Select a country"
  }
})
