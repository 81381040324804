import { useEffect, useState } from "react"
import useToggle from "hooks/useToggle"
import { REQUEST_SAMPLES_INFO } from "modules/saxony/components/RequestsSection/utils"
import { updateAction } from "app/store"
import { useStateMachine } from "little-state-machine"

const SAXONY_CATALOGUE_ITEM = { catalogue: "CATALOGUE, SAXONY" }

const useRequestModal = () => {
  const [toggable, toggle] = useToggle()
  const { state, actions } = useStateMachine({ updateAction })
  const [modalContext, setModalContext] = useState(REQUEST_SAMPLES_INFO)

  const handleClickInRequest = (modalType) => {
    setModalContext(modalType)
    toggle()
  }

  const handleAddSample = (newSample) => {
    const hasSample =
      state?.sampleCount?.length &&
      state?.sampleCount?.some(({ color }) => color === newSample.color)

    if (hasSample) return

    const updateSamplesCount = state?.sampleCount ? [...state?.sampleCount, newSample] : [newSample]

    actions.updateAction({
      sampleCount: updateSamplesCount
    })
  }

  const handleAddCatalogueToRequest = () => {
    const hasCatalogue =
      state?.sampleCount?.length &&
      // eslint-disable-next-line no-prototype-builtins
      state?.sampleCount?.some((obj) => obj.hasOwnProperty("catalogue") && obj.catalogue)

    if (hasCatalogue) return

    const updateSamplesCount = state?.sampleCount
      ? [...state?.sampleCount, SAXONY_CATALOGUE_ITEM]
      : [SAXONY_CATALOGUE_ITEM]

    actions.updateAction({
      sampleCount: updateSamplesCount
    })
  }

  const handleOrderCatalogue = (modalType) => {
    handleAddCatalogueToRequest()
    handleClickInRequest(modalType)
  }

  useEffect(() => { }, [modalContext])

  return {
    modalContext,
    toggable,
    toggle,
    handleClickInRequest,
    handleAddSample,
    handleAddCatalogueToRequest,
    handleOrderCatalogue
  }
}

export default useRequestModal
