import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import chairs from "./imgs/chairs.jpg"
import upholteries from "./imgs/upholteries.jpg"
import "./styles/technical-upholstery.scss"

const TechnicalUpholstery = ({ id }) => (
  <section id={id} className="container g-xl-0 py-4 my-0 my-md-2">
    <div className="row g-xl-0 justify-content-center">
      <div className="col-12">
        <h2 className="title--segm-hotelery">
          <FormattedMessage
            id="Segments.Hotel.TechnicalUpholstery.title"
            defaultMessage="Tapicerías técnicas para{br}experiencias memorables"
            values={{
              br: <br />
            }}
          />
        </h2>
        <div className="container-imgs--segm-hotelery">
          <img className="main-img" src={chairs} />
          <img className="secondary-img" src={upholteries} />
        </div>
        <p className="info--segm-hotelery info--segm-hotelery--technical-upholstery">
          <FormattedMessage
            id="Segments.Hotel.TechnicalUpholstery.info"
            defaultMessage="A través de los años, nuestros clientes han confiado en los productos de <strong>Proquinal<sup>®</sup></strong> por su confort, estilo, versatilidad y rendimiento.{br}{br}Hemos desarrollado una serie de tecnologías que hacen a nuestras tapicerías en exteriores, aptas para alto tráfico, resistentes a los aceites bronceadores, al cloro y al agua salada."
            values={{
              strong: (...chunks) => <strong>{chunks}</strong>,
              sup: (...chunks) => <sup>{chunks}</sup>,
              br: <br />
            }}
          />
        </p>
        <button className="btn btn-rv btn--desc-rv mt-3">
          <FormattedMessage
            id="Segments.Hotel.TechnicalUpholstery.btnText"
            defaultMessage="Descubre aquí nuestro portafolio"
          />
        </button>
      </div>
    </div>
  </section>
)

TechnicalUpholstery.propTypes = {
  id: PropTypes.string
}

export default TechnicalUpholstery
