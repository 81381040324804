import PropTypes from "prop-types"
import "./styles/banner.scss"

const Banner = ({ id, imageThumb, title, description }) => (
  <section
    id={id}
    className="wrapper--cleaning-sm--general py-5"
    style={{
      backgroundImage: `url(${imageThumb})`
    }}
  >
    <div className="container py-lg-5">
      <h1 className="title--cleaning-sm--general">{title}</h1>
      <p className="description--cleaning-sm--general">{description}</p>
    </div>
  </section>
)

Banner.propTypes = {
  id: PropTypes.string,
  imageThumb: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

export default Banner
