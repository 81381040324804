export const ENDPOINT_ZAPPIER_CLAIMS_BY_CLAIM_REASON = {
  COLOR_DIFFERENCE: "https://hooks.zapier.com/hooks/catch/9615379/3z9sxyr/",
  FACIAL_DEFECTS: "https://hooks.zapier.com/hooks/catch/9615379/3z9wedx/",
  COLOR_FADING: "https://hooks.zapier.com/hooks/catch/9615379/3z9aekp/",
  SURFACE_DETERIORATION: "https://hooks.zapier.com/hooks/catch/9615379/3z9g75h/",
  VISUAL_DEFECTS: "https://hooks.zapier.com/hooks/catch/9615379/3z9qgjg/",
  STAINING: "https://hooks.zapier.com/hooks/catch/9615379/3z9l1um/",
  DIFFERENCES_IN_DIMENSIONS: "https://hooks.zapier.com/hooks/catch/9615379/3z9l7a5/",
  DIFFERENCES_IN_HAND: "https://hooks.zapier.com/hooks/catch/9615379/3z9e9sl/",
  MATERIAL_RESISTANCE: "https://hooks.zapier.com/hooks/catch/9615379/3z9ev96/",
  PLEATS: "https://hooks.zapier.com/hooks/catch/9615379/3z9cw33/",
  OTHERS: "https://hooks.zapier.com/hooks/catch/9615379/3z9x6m6/",
  // SUMMARY: "https://hooks.zapier.com/hooks/catch/9615379/3z9xw14/", // WARNING: unused
  SEND_EMAILS: "https://hooks.zapier.com/hooks/catch/9615379/3zrf6me/"
}
