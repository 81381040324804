import { useEffect, useState } from "react"

const useGeneralInfo = (generalInfo) => {
  const GENERAL_INFO_SECTIONS_ARRAY = Object.values(generalInfo)
  const [sectionId, setSectionId] = useState(GENERAL_INFO_SECTIONS_ARRAY[0].id)

  const handleChange = (id) => {
    setSectionId(id)
  }

  const GENERAL_SECTION_INFO = {
    info: generalInfo,
    sections: GENERAL_INFO_SECTIONS_ARRAY,
    sectionId
  }

  return { GENERAL_SECTION_INFO, handleChange }
}

export default useGeneralInfo
