import cloths from "../img/1.cloths.jpg"
import blueCloth from "../img/2.blue-cloth.jpg"
import boat from "../img/3.boat.jpg"
import coolCloths from "../img/4.cool-cloths.jpg"

export const SCENES = [
  {
    title: "cloths",
    img: cloths
  },
  {
    title: "blueCloth",
    img: blueCloth
  },
  {
    title: "boat",
    img: boat
  },
  {
    title: "coolCloths",
    img: coolCloths
  }
]
