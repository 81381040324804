// TODO: remove COLORS and unused functions and files
const COLORS = []

export const handleProcessedColors = () => {
  return COLORS.map(({ node }) => node)
}

export const handleAddedSamplesByTheUser = (samples) =>
  samples?.map(({ color, reference, catalogue }) => {
    if (catalogue) return { label: catalogue, value: catalogue, data: { catalogue } }

    const sampleInfo = `${color} | ${reference}`.toUpperCase()
    return {
      label: sampleInfo,
      value: sampleInfo,
      data: { color, reference } // WARNING: used for build email template
    }
  })
