import PropTypes from "prop-types"
import VideoCommon from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"

const Video = ({ video, poster }) => {
  const isMobile = useMediaQuery(SM)
  return (
    <div className="container my-2 py-4">
      <div className="row">
        <div className="col-12 align-self-center">
          <VideoCommon className="img-fluid" poster={poster} fromYoutube>
            <iframe
              title="video"
              width="100%"
              height={isMobile ? "250" : "650"}
              src={video}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoCommon>
        </div>
      </div>
    </div>
  )
}

Video.propTypes = {
  video: PropTypes.string,
  poster: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

export default Video
