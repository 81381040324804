export const NAV_ITEMS = [
  "permacool",
  "colors",
  "applications",
  "product details",
  "contact",
  "where to buy"
]

export const FOOTER_ITEMS = [
  {
    label: "Phone",
    value: "+1 800 333 0955"
  },
  {
    label: "E-mail",
    value: "sales@spradlingvinyl.com"
  },
  {
    label: "Fax",
    value: "+1 205 985 2354"
  }
]

export const GLOBAL_SITE_TAG = "GTM-55TC5JJ"
