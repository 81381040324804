import PropTypes from "prop-types"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import poster from "./img/usos-tela.jpg"
import { FormattedMessage } from "react-intl"
import VideoCommon from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"

const VIDEO_URL = "https://www.youtube.com/embed/ruBtkjOl0RA"

const Uses = ({ id }) => {
  const isMobile = useMediaQuery(SM)
  return (
    <div id={id}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <TitleUnderlined hTag={H2_TAG}>
              <FormattedMessage
                id="FloorDetail.uses"
                description="uses title at vestuario y protección page"
                defaultMessage="Usos"
              />
            </TitleUnderlined>
            <div className="text-center">
              <p>
                <FormattedMessage
                  id="VestuarioYProteccion.Uses.description"
                  description="Features section description vestuario y proteccion"
                  defaultMessage="Diseños inspirados en el mundo digital y en las composiciones asimétricas de la arquitectura moderna, logran llevar estos patrones al mundo textil con beneficios para la elaboración de todo tipo de prendas de protección: rain coats, capotas, vestimotos e impermeables."
                />
              </p>
              <VideoCommon className="img-fluid" poster={poster} fromYoutube>
                <iframe
                  title="video"
                  width="100%"
                  height={isMobile ? "250" : "650"}
                  src={VIDEO_URL}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </VideoCommon>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Uses.propTypes = {
  id: PropTypes.string
}

export default Uses
