import PropTypes from "prop-types"
import saxonySampleImage from "./img/saxony-section-2.jpeg"
import RequestsSection from "../RequestsSection"
import useRequestModal from "../../hooks/useRequestModal"
import { FormattedMessage } from "react-intl"
import "./styles/rivulet-description.scss"
import { Link } from "react-scroll"
import { NAV_1 } from "modules/saxony/constants"

const SaxonyDescription = ({ id }) => {
  const { modalContext, toggable, toggle } = useRequestModal()

  return (
    <>
      <section id={id} className="container--desc-sx">
        <div className="row g-0">
          <div className="col-12 col-lg-7 saxony--container-chair-img d-flex justify-content-center justify-content-xxl-start align-items-center">
            <img className="saxony--chair-img img-fluid" src={saxonySampleImage} alt="chair-image" />
          </div>
          <div className="col-12 col-lg-5 d-flex justify-content-start align-items-center">
            <div className="info--desc-sx">
              <h2 className="title--desc-rv">
                <FormattedMessage
                  id="Saxony.Description.title"
                  defaultMessage="Elevate your space with bright and inspirational colors"
                />
              </h2>
              <p className="sx-description fw-light font-gray-color">
                <FormattedMessage
                  id="Saxony.Descriptions.descriptionBody"
                  defaultMessage="Explore Saxony, our newest release that seamlessly blends a classic aesthetic with a modern twist, specially designed for the educational and hospitality markets. Experience the ease of tailorability, even with intricate furniture, ensuring flawless integration into any environment. "
                />
              </p>
              <Link to={NAV_1}>
                <button className="btn btn--desc-sx">
                  <FormattedMessage
                    id="Saxony.Banner.buttonText"
                    defaultMessage="Request Samples"
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <hr className="separator-rv" />
      <RequestsSection modalContext={modalContext} toggable={toggable} toggle={toggle} />
    </>
  )
}

SaxonyDescription.propTypes = {
  id: PropTypes.string.isRequired
}

export default SaxonyDescription
