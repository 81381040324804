import { CUSTOM_STYLES } from "components/InputMUI/Select/constants"

export const DISCONTINUED_STYLES = {
  option: (provided, state) => {
    const { options, getOptionValue } = state.selectProps
    const { isDiscontinued } = options.find((opt) => getOptionValue(opt) === state.value)

    return {
      ...CUSTOM_STYLES.option(provided, state),
      color: !isDiscontinued ? "#000" : "#f05d5e"
    }
  }
}
