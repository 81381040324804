import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import "./styles/progress-bar.scss"
import { FormattedMessage } from "react-intl"
import { LG } from "consts/breakpoints"
import useMediaQuery from "hooks/useMediaQuery"

const ProgressBar = ({ progress, handleNext, RoutePrev }) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery(LG)

  const handlePrev = () => navigate(RoutePrev)

  return (
    <div className="progress-container">
      <div className="progress-bar-container">
        <div className="progress-bar">
          <span className="progress-bar-text">
            <span className="me-1">{progress}%</span>
            <FormattedMessage
              id="ProgressBar.completed"
              description="label completed percentage on progress bar"
              defaultMessage="completed"
            />
          </span>
          <span className="progress-bar__inner" style={{ width: `${progress}%` }}>
            <span className="progress-bar__inner-text">{progress}%</span>
          </span>
        </div>
        <button className="btn btn-dark text-capitalize progress-btn-up" onClick={handlePrev}>
          {!isMobile && (
            <span className="me-2">
              <FormattedMessage
                id="ProgressBar.previous"
                description="label previous on btn progress bar"
                defaultMessage="previous"
              />
            </span>
          )}
          <FontAwesomeIcon icon={faAngleUp} fixedWidth />
        </button>
        {handleNext && (
          <button className="btn btn-dark text-capitalize progress-btn-down" onClick={handleNext}>
            {!isMobile && (
              <span className="me-2">
                <FormattedMessage
                  id="ProgressBar.next"
                  description="label next on btn progress bar"
                  defaultMessage="next"
                />
              </span>
            )}
            <FontAwesomeIcon icon={faAngleDown} fixedWidth />
          </button>
        )}
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
  handleNext: PropTypes.func,
  RoutePrev: PropTypes.string
}

export default ProgressBar
