import PropTypes from "prop-types"
import { useState } from "react"
import { CHARACTERISTICS_ICONS, CHARACTERISTICS_SECTIONS } from "./constants"
import phatalateFree from "./img/phatalate-free.svg"
import biocideFree from "./img/biocide.svg"
import saxonyImageSection from "./img/saxony_image.jpg"
import CharacteristicCardIcon from "./components/CharacteristicCard"
import "./styles/SaxonyCharacteristics.scss"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"
import classNames from "classnames"

const SaxonyCharacteristics = ({ id }) => {
  const isMobile = useMediaQuery(LG)
  const isDesktop = useMediaQuery("(min-width: 1240px)")
  const isSmallMobile = useMediaQuery("(max-width: 330px)")

  const [currentCharacteristic, setCurrentCharacteristic] = useState(CHARACTERISTICS_SECTIONS(isMobile)[CHARACTERISTICS_ICONS[0].key])

  const handleUpdateCurrentCharacteristic = (itemKey) => {
    setCurrentCharacteristic(CHARACTERISTICS_SECTIONS(isMobile)[itemKey])
  }

  return (
    <section id={id} className={classNames({
      "font-gray-color wrapper-samples-details--masiala mt-5": true
    })}>
      <p className="saxony--section-paragraph mb-5 font-gray-color fw-light">
        Saxony isn’t just about eye-catching colors; it’s a practical and innovative choice with features that set it apart:
      </p>
      <div className="saxony--section-container d-flex flex-column flex-md-row align-items-lg-center">
        <div className="col-12 col-md-12 col-lg-5 col-xl-6 order-1 order-md-0 mt-4 mt-lg-0">
          <div className="col-3 saxony--container-main-icon mb-3 mb-md-0 mt-md-5 mt-lg-0">
            <img className="saxony--characteristic-main-icon" src={currentCharacteristic.icon} alt="icon current characterisctic" />
          </div>
          <section>
            <p className="saxony--characteristic-title fs-4 mt-0 mt-lg-0">
              {currentCharacteristic.title}
            </p>
            <p className="saxony--characteristic-description mb-5 pe-lg-4 fw-light">
              {currentCharacteristic.description}
            </p>
            <div className="d-flex saxony--container-icons">
              {
                CHARACTERISTICS_ICONS.map((item) => {
                  return <CharacteristicCardIcon
                    key={item.key}
                    item={item}
                    currentCharacteristic={currentCharacteristic}
                    handleUpdateCurrentCharacteristic={handleUpdateCurrentCharacteristic}
                  />
                })
              }
            </div>
          </section>
        </div>
        <div className="saxony--container-img d-flex justify-content-end col-12 col-md-6 col-lg-7 col-xl-6 order-0 order-md-1">
          <img className="img-fluid" src={saxonyImageSection} alt="saxony image aside" />
        </div>
      </div>
      <div className="saxony--environment font-gray-color text-md-center mt-2 mt-md-5 mb-5 mb-md-4 mb-lg-0 fw-light">
        <p>
          And that&apos;s not all-Saxony is committed to your well-being and the environment:
        </p>
      </div>
      <section className="saxony--section-adicional-info d-md-flex mt-md-5 mt-lg-0 mb-5 mb-lg-0">
        <div className={classNames({
          "d-flex col-12 col-md-6 col-lg-6 align-items-center mb-4 mb-md-0 mb-lg-0": true,
          "d-flex flex-column justify-content-center text-center gap-3": isSmallMobile
        })}>
          <div className="col-5 col-md-3 col-lg-4 col-xl-6 p-0 m-0 d-flex justify-content-lg-end">
            <img className="saxony--phatalateFree-img me-lg-4" src={phatalateFree} alt="phatalate free image" height="30" />
          </div>
          <p className="saxony--phalateFree-descr ms-md-4 ms-lg-0 col-md-9 col-lg-6 font-gray-color p-0 m-0 fw-light">
            Free from harmful phthalates, <br /> ensuring a safer choice for users.
          </p>
        </div>
        <div className={classNames({
          "col-12 col-md-6 col-lg-6 d-flex align-items-center": true,
          "d-flex flex-column justify-content-center text-center mt-5 gap-3": isSmallMobile
        })}>
          <div className="col-5 col-md-3 d-flex justify-content-lg-end">
            <img className="saxony--biocideFree-img ms-md-4 me-lg-4" src={biocideFree} alt="biocide free image" height="30" />
          </div>
          <p className="saxony--biocideFree-descr ms-md-4 ms-lg-0 col-md-9 col-lg-8 font-gray-color p-0 m-0 fw-light">
            Saxony does not contain biocides, contributing to {isDesktop && <br />} a healthier indoor environment.
          </p>
        </div>
      </section>
    </section>
  )
}

SaxonyCharacteristics.propTypes = {
  id: PropTypes.string.isRequired
}

export default SaxonyCharacteristics
