import { useStateMachine } from "little-state-machine"
import { clearAction, updateAction } from "utils/littleStateMachine"
import { useIntl } from "react-intl"
import { useMutation } from "@apollo/client"
import { createPdfHtml, uploadFiles } from "./mutation"
import { getEmailBody } from "./constants/email"
import { createPDF } from "./constants/createPDF"
import { uploadFilesToGenerateUrl } from "./constants/uploadFiles"
import emailTemplateClient from "./constants/emailTemplates/client"
import emailTemplateAdmin from "./constants/emailTemplates/admin"
import handleZap from "./zap"
import { CLAIMS_EXCEL_URL } from "./utils"

const useRequestsClaim = ({ lang, instance, navigate }) => {
  const intl = useIntl()
  const { state } = useStateMachine({ clearAction, updateAction })
  const [createPdfFromHtml, { loading: loadingPDF }] = useMutation(createPdfHtml)
  const [uploadFile, { loading: loadingUploadFiles }] = useMutation(uploadFiles)

  const handlePdfSummary = async ({ ticket, companyName, uploadedZip }) => {
    const { templateAdmin, templateClient } = getEmailBody({ ticket, uploadedZip, ...state }, intl)
    const { urlAdmin: pdfSummaryAdmin, urlClient: pdfSummaryClient } = await createPDF({
      ticket,
      companyName,
      templateAdmin,
      templateClient,
      createPDFMutation: createPdfFromHtml
    })

    return { pdfSummaryAdmin, pdfSummaryClient }
  }

  const handleFileUrl = async ({ zip }) => {
    const fileUrl = await uploadFilesToGenerateUrl(zip, uploadFile)
    return fileUrl
  }

  const handleTemplateBodyEmails = (params) => {
    const emailTemplateClientZap = emailTemplateClient(params)
    const emailTemplateAdminZap = emailTemplateAdmin(params)

    return { emailTemplateAdminZap, emailTemplateClientZap }
  }

  const handleGoToThankYouPage = (state) =>
    navigate(`../${lang}-${instance}/claims/thank-you`, { state, replace: true })

  const handlePostCompleteClaim = async (data) => {
    try {
      const {
        createClaim: { claim }
      } = data

      const { pdfSummaryAdmin, pdfSummaryClient } = await handlePdfSummary(claim)
      const { emailTemplateAdminZap, emailTemplateClientZap } = handleTemplateBodyEmails({
        fullName: claim.fullName,
        fileUrl: claim.uploadedZip,
        excelUrl: CLAIMS_EXCEL_URL
      })

      const zapData = {
        pdfSummaryAdmin,
        pdfSummaryClient,
        fileUrl: claim.uploadedZip,
        emailTemplateClientZap,
        emailTemplateAdminZap,
        ...data
      }

      handleZap(zapData, handleGoToThankYouPage)
    } catch (e) {
      console.log("[useRequestsClaim] -> handlePostCompleteClaim | ERROR: ", e)
    }
  }

  return { loadingPDF, loadingUploadFiles, handleFileUrl, handlePostCompleteClaim }
}

export default useRequestsClaim
