/* eslint-disable camelcase */
import { useStateMachine } from "little-state-machine"
import { clearAction, updateAction } from "utils/littleStateMachine"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import Layout from "modules/claims/components/Layout"
import ProgressBar from "../ProgressBar"
import JSZip from "jszip"
import { getCurrentLanguageAndInstanceCode } from "services/instances"
import { createClaimMutation } from "./mutation"
import "../styles/step-form.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import bgStep6 from "./img/5.step-6.jpg"
import { handleFormatToBoolean, handleFormatToArrayOfString } from "./utils"
import useRequestsClaim from "./useRequestsClaim"
dayjs.extend(customParseFormat)

const Step6 = () => {
  const navigate = useNavigate()
  const [lang, instance] = getCurrentLanguageAndInstanceCode()
  const { actions, state } = useStateMachine({ clearAction, updateAction })
  const { loadingPDF, loadingUploadFiles, handleFileUrl, handlePostCompleteClaim } =
    useRequestsClaim({
      lang,
      instance,
      navigate
    })
  const [createClaim, { loading }] = useMutation(createClaimMutation, {
    onCompleted: async (data) => {
      await handlePostCompleteClaim(data)
      actions.clearAction()
    }
  })

  const handleGenerateZip = async (files) => {
    const zip = new JSZip()
    const folderName = zip.folder(`${state.fullName}-${state.date}`)
    for (let file = 0; file < files.length; file++) {
      folderName.file(files[file].name, files[file])
    }
    return await zip.generateAsync({ type: "blob" }).then((content) => content)
  }

  const handleSendClaim = async () => {
    const {
      // WARNING: excluing checkboxes values from step0 to no send to mutation
      check_nameAndColor,
      check_quantity,
      check_customerNumber,
      check_productionBatchNumber,
      check_pictures,
      check_termsAndConditions,
      check_privacyPolicy,
      // WARNING: excluing patternId to no send to mutation
      patternId,
      // WARNING: to format Dates
      date: oldDate,
      dateOfPurchase: dateOfPurchaseOld,
      dateOfMaterialTransformed: dateOfMaterialTransformedOld,
      dateOfMaterialInstalled: dateOfMaterialInstalledOld,
      // WARNING: to format options yes/no to boolean
      knowTheRecomendations: knowTheRecomendationsOld,
      knowTheInstructions: knowTheInstructionsOld,
      suggestedInstructions: suggestedInstructionsOld,
      materialIsMarked: materialIsMarkedOld,
      materialIsCoated: materialIsCoatedOld,
      marksOnDelivery: marksOnDeliveryOld,
      // WARNING: to format multi select,
      application: applicationOld,
      whereMarksAre: whereMarksAreOld,
      // WARNING: excluing files to no send to mutation else zip
      files,
      // WARNING: excluing previousStep to no send to mutation
      previousStep,
      // WARNING: excluding productName and productColor to no send to mutation
      productName,
      productColor,
      ...payload
    } = state

    const processedDates = {
      date: oldDate && dayjs(oldDate).format("YYYY-MM-DD"),
      dateOfPurchase: dateOfPurchaseOld && dayjs(dateOfPurchaseOld).format("YYYY-MM-DD"),
      dateOfMaterialTransformed:
        dateOfMaterialTransformedOld && dayjs(dateOfMaterialTransformedOld).format("YYYY-MM-DD"),
      dateOfMaterialInstalled:
        dateOfMaterialInstalledOld && dayjs(dateOfMaterialInstalledOld).format("YYYY-MM-DD")
    }
    const processedBooleans = {
      knowTheRecomendations: handleFormatToBoolean(knowTheRecomendationsOld),
      knowTheInstructions: handleFormatToBoolean(knowTheInstructionsOld),
      suggestedInstructions: handleFormatToBoolean(suggestedInstructionsOld),
      materialIsMarked: handleFormatToBoolean(materialIsMarkedOld),
      materialIsCoated: handleFormatToBoolean(materialIsCoatedOld),
      marksOnDelivery: handleFormatToBoolean(marksOnDeliveryOld)
    }
    const processedMultiSelect = {
      application: handleFormatToArrayOfString(applicationOld),
      whereMarksAre: handleFormatToArrayOfString(whereMarksAreOld)
    }

    const zip = await handleGenerateZip(state.files)
    const uploadedZip = await handleFileUrl({ zip })

    const input = {
      ...processedDates,
      ...processedBooleans,
      ...processedMultiSelect,
      uploadedZip,
      ...payload
    }

    createClaim({ variables: { input } })
  }

  return (
    <Layout
      title={
        <FormattedMessage
          id="Claims.claimedMaterialSamples"
          description="section original condition title"
          defaultMessage="Claimed Material Samples"
        />
      }
      alignDescription="center"
      backgroundImage={bgStep6}
    >
      <>
        <div>
          <p>Please send material samples to</p>
          <p>EURO SPRADLING S.L.U.</p>
          <p>After Sales Service</p>
          <p>Carrer Garbi 3</p>
          <p>08213 Polinya</p>
          <p>Spain</p>
          <p>Tel. +34 938 655 719</p>
          <p>
            <FormattedMessage
              id="Claims.NotificationAboutData"
              description="Notification about data"
              defaultMessage="When you click the 'Finalize' button, your previous answers will be recorded and you will not be able to make any changes to them. Be sure to carefully review all of your answers before proceeding."
            />
          </p>
        </div>
        <div className="btn-container">
          <button className="btn btn-dark w-50" onClick={handleSendClaim}>
            {!loading && !loadingPDF && !loadingUploadFiles ? (
              <FormattedMessage
                id="Claims.Finally.btn"
                description="btn finally from funnel of claim form"
                defaultMessage="Finalize"
              />
            ) : (
              <FontAwesomeIcon icon={faSpinner} size="lg" spin />
            )}
          </button>
        </div>
        <ProgressBar progress={100} RoutePrev={`../${lang}-${instance}/claims/step-5`} />
      </>
    </Layout>
  )
}

export default Step6
