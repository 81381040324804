import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { graphql } from "@apollo/client/react/hoc"
import { flowRight as compose } from "lodash"
import { singlePatternPublicPDFQuery } from "modules/floors/modules/FloorDetail/components/DownloadPatternPDFButton/queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf, faSpinner } from "@fortawesome/free-solid-svg-icons"

const DownloadPatternPDFButton = ({ singlePatternPublicPDFQuery }) => {
  if (singlePatternPublicPDFQuery.error) return null

  return (
    <>
      {singlePatternPublicPDFQuery.loading ? (
        <button disabled className="btn btn-outline-dark my-2 btn-block">
          <FormattedMessage
            id="DownloadPatternPDFButton.label"
            description="Product details download pdf button label"
            defaultMessage="Download PDF"
          />
          <FontAwesomeIcon className="ms-2" icon={faSpinner} spin />
        </button>
      ) : (
        <a
          className="btn btn-outline-dark my-2"
          href={singlePatternPublicPDFQuery.pattern.pdfUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            id="DownloadPatternPDFButton.label"
            description="Product details download pdf button label"
            defaultMessage="Download PDF"
          />
          <FontAwesomeIcon className="ms-2" icon={faFilePdf} />
        </a>
      )}
    </>
  )
}

DownloadPatternPDFButton.propTypes = {
  patternId: PropTypes.string.isRequired,
  singlePatternPublicPDFQuery: PropTypes.object,
  registerSpecSheetDownloadMutation: PropTypes.object
}

export default compose(
  graphql(singlePatternPublicPDFQuery, {
    name: "singlePatternPublicPDFQuery",
    options: (props) => ({
      variables: {
        id: props.patternId
      }
    })
  })
)(DownloadPatternPDFButton)
