import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import ContactForm from "./components/ContactForm"

const Contact = ({ colorTheme = "white", pattern }) => (
  <div className="container my-3 py-4 upholstery-glassmorphism">
    <div className="row justify-content-md-center">
      <TitleUnderlined hTag={H2_TAG} color={colorTheme}>
        <FormattedMessage
          id="Pranna.Contact.title"
          description="Floors section title contact"
          defaultMessage="¿ Quieres más información ?"
          values={{
            sup: (...chunks) => <sup>{chunks}</sup>
          }}
        />
      </TitleUnderlined>
      <div className="col-12">
        <ContactForm colorTheme={colorTheme} pattern={pattern} />
      </div>
    </div>
  </div>
)

Contact.propTypes = {
  colorTheme: PropTypes.oneOf(["black", "white"]),
  pattern: PropTypes.object
}

export default Contact
