import { FormattedMessage } from "react-intl"

export const CLEAING_PROCEDURE_ITEM_CLEANING_CARE = [
  {
    id: "CleaningMarine.Chill.cleaningCare.Procedures.One",
    text: (
      <FormattedMessage
        id="CleaningMarine.Chill.cleaningCare.Procedures.One"
        defaultMessage="Mix a solution of 30:1, (30 parts warm water and 1 part mild NON-BLEACH liquid dish soap)."
      />
    )
  },
  {
    id: "CleaningMarine.Chill.cleaningCare.Procedures.Two",
    text: (
      <FormattedMessage
        id="CleaningMarine.Chill.cleaningCare.Procedures.Two"
        defaultMessage="Apply and rub with a soft damp cloth."
        values={{
          sup: (...chunks) => <sup>{chunks}</sup>
        }}
      />
    )
  },
  {
    id: "CleaningMarine.Chill.cleaningCare.Procedures.Three",
    text: (
      <FormattedMessage
        id="CleaningMarine.Chill.cleaningCare.Procedures.Three"
        defaultMessage="Rinse with clean warm water and wipe dry."
      />
    )
  }
]
