import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import useToggle from "hooks/useToggle"
import Modal from "components/Modal"
import facilLimpieza from "./components/EasyCleaning/img/facil-limpieza.png"
import petFriendly from "./components/PetFriendly/img/pet-friendly.png"
import petFriendlyIcon from "./components/PetFriendly/img/pet-friendly-icon.svg"
import "./styles/benefits.scss"

const Benefits = () => {
  const [showPetFriendly, togglePetFriendly] = useToggle()
  const [showCleaning, toggleCleaning] = useToggle()

  return (
    <>
      <div className="container my-2 py-4">
        <div className="row align-items-center">
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="Pranna.Benefits.title"
              description="Pranna section benefits"
              defaultMessage="Beneficios"
            />
          </TitleUnderlined>
          <div className="col-12 col-md-6">
            <div className="img-container" onClick={() => toggleCleaning()}>
              <img className="img-size" src={facilLimpieza} alt="fácil limpieza" />
              <TitleUnderlined className="benefit-label" hTag={H2_TAG} color="white">
                <FormattedMessage
                  id="Pranna.EasyCleaning.title"
                  description="Pranna section title easy cleaning"
                  defaultMessage="Fácil Limpieza"
                />
              </TitleUnderlined>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="img-container" onClick={() => togglePetFriendly()}>
              <img className="img-size" src={petFriendly} alt="pet friendly" />
              <img
                className="position-absolute top-0 start-0 w-25 p-2"
                src={petFriendlyIcon}
                alt="pet friendly"
              />
              <TitleUnderlined className="benefit-label" hTag={H2_TAG} color="white">
                <FormattedMessage
                  id="Pranna.PetFriendly.title"
                  description="Pranna section title pet friendly"
                  defaultMessage="Pet Friendly"
                />
              </TitleUnderlined>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showCleaning} handleToggable={toggleCleaning}>
        <div>
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="Pranna.EasyCleaning.title"
              description="Pranna section title easy cleaning"
              defaultMessage="Fácil Limpieza"
            />
          </TitleUnderlined>
          <p className="pb-md-4">
            <FormattedMessage
              id="Pranna.EasyCleaning.description"
              description="Pranna section description easy cleaning"
              defaultMessage="La seguridad e higiene es una prioridad en nuestra referencia Pranna, por su impermeabilidad solo debes realizar estos pasos para que tus muebles o sillas tengan una apariencia única: {br}{br} Utiliza un trapo de color blanco para limpiar toda la superficie con una mezcla de jabón de manos suave pH neutro y agua."
              values={{
                br: <br />
              }}
            />
          </p>
          <div className="text-center">
            <a
              className="btn btn-dark"
              href="https://spradling-misc.s3.us-west-2.amazonaws.com/landing-attachments/Pranna-fa%CC%81cil-limpieza.pdf"
              rel="noreferrer"
              target="_blank"
            >
              <FormattedMessage
                id="Pranna.EasyCleaning.btnText"
                description="Pranna text btn section easy cleaning"
                defaultMessage="Ver guía de limpieza"
              />
            </a>
          </div>
        </div>
      </Modal>
      <Modal isOpen={showPetFriendly} handleToggable={togglePetFriendly}>
        <div>
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="Pranna.PetFriendly.title"
              description="Pranna section title pet friendly"
              defaultMessage="Pet Friendly"
            />
          </TitleUnderlined>
          <p className="pb-md-4">
            <FormattedMessage
              id="Pranna.PetFriendly.description"
              description="Pranna section description pet friendly"
              defaultMessage="Cuidamos tus huellas a través de nuestra cultura Pet Friendly, la cual nos incentiva a desarrollar características únicas en nuestras tapicerías técnicas para disminuir el olor y manchas, minimizar la adherencia al pelo y brindar resistencia ante perros y gatos."
            />
          </p>
          <ul>
            <li>
              <FormattedMessage
                id="Pranna.PetFriendly.item.waterproof"
                description="Pranna section pet friendly item list waterproof"
                defaultMessage="100% impermeables para la tranquilidad de manchas."
              />
            </li>
            <li>
              <FormattedMessage
                id="Pranna.PetFriendly.item.noPetHair"
                description="Pranna section pet friendly item list no pet hair"
                defaultMessage="El pelo de tu mascota, un problema del pasado."
              />
            </li>
            <li>
              <FormattedMessage
                id="Pranna.PetFriendly.item.scratches"
                description="Pranna section pet friendly item list scratches"
                defaultMessage="Amigable con las uñas de perros y gatos."
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  )
}

export default Benefits
