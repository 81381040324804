export const THEME = (theme) => ({
  ...theme,
  borderRadius: 0
})

export const CUSTOM_STYLES = {
  menuList: (provided) => {
    return {
      ...provided,
      padding: 0
    }
  },
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgb(0 0 0 / 15%)" : "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "rgb(0 0 0 / 5%)"
    }
  }),
  control: (_, { isDisabled }) => ({
    alignItems: "center",
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: "0.16rem",
    borderColor: "#495057",
    backgroundColor: isDisabled && "#495057",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: "2rem",
    outline: "none",
    position: "relative",
    transition: "all 100ms",
    boxSizing: "border-box"
  }),
  indicatorsContainer: (provided, { isDisabled }) => ({
    ...provided,
    backgroundColor: "#495057",
    color: !isDisabled ? "#fff" : "red",
    display: "flex",
    padding: ".2rem",
    transition: "color 150ms",
    boxSizing: "border-box"
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      height: "1.5rem",
      width: "1.5rem",
      fill: "#fff"
    }
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  }
}
