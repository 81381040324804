import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import "./styles/cleaning-procedure.scss"

const CleaningProcedure = ({ section }) => (
  <section className="wrapper-cleaning-produre pt-5 pb-2 px-4 px-md-5 px-lg-0">
    <div className="container container-cleaning-produre pt-3 px-4 px-md-0">
      <h2 className="fw-bold">
        <FormattedMessage
          id="CleaningMarine.General.Procedures.title"
          defaultMessage="Cleaning Procedure"
        />
      </h2>
      <div className="row my-4 my-lg-5 p-0">
        {section.map(({ id, text }, i) => (
          <div key={`key_${id}`} className="col-12 col-lg-4 info-cleaning-produre">
            <span className="number-cleaning-produre">{i + 1}</span>
            <p className="process-cleaning-produre">{text}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
)

CleaningProcedure.propTypes = {
  section: PropTypes.object.isRequired
}

export default CleaningProcedure
