import { defineMessages } from "react-intl"
import facilLimpieza from "../img/facil-limpieza.svg"
import impermeabilidad from "../img/impermeabilidad.svg"
import abrasion from "modules/pranna/components/ProductDetails/img/abrasion.svg"
import petFriendlyIcon from "modules/floors/components/ProductDetails/img/pet-friendly.svg"

export const MSGS = defineMessages({
  facilLimpieza: {
    id: "Pranna.ProductDetail.easyCleaning",
    description: "Pranna section product detail item easy cleaning",
    defaultMessage: "Fácil {br} Limpieza"
  },
  impermeabilidad: {
    id: "Pranna.ProductDetail.impermeability",
    description: "Pranna section product detail item impermeability",
    defaultMessage: "Impermeabilidad"
  },
  abrasion: {
    id: "Pranna.ProductDetail.abrationRetardancy",
    description: "Pranna section product detail item abration retardancy",
    defaultMessage: "Resistencia {br} a la Abrasión"
  }
})

export const PRODUCT_DETAILS = [
  {
    id: "facilLimpieza",
    text: MSGS.facilLimpieza,
    icon: facilLimpieza
  },
  {
    id: "impermeabilidad",
    text: MSGS.impermeabilidad,
    icon: impermeabilidad
  },
  {
    id: "abrasion",
    text: MSGS.abrasion,
    icon: abrasion
  },
  {
    id: "petfrinedly",
    icon: petFriendlyIcon
  }
]
