import "./styles/invitation.scss"

const Invitation = () => (
  <div className="py-4 bg-spradling-green">
    <div className="row justify-content-center align-items-center">
      <div className="col text-center">
        <p className="text-white">Join us for a colorful experience</p>
        <h2 className="text-white">Annual IBEX Open House</h2>
        <div className="text-white">
          <span>WEDNESDAY, September 28</span>
          <br />
          <span>5:30p to 8:00p</span>
        </div>
        <div className="text-white my-4">
          <span className="fw-bold">Tampa Marriott Water Street</span>
          <br />
          <span>3rd floor, Room 9</span>
          <br />
          <span>505 Water Street, Tampa, FL 33602</span>
        </div>
      </div>
    </div>
  </div>
)

export default Invitation
