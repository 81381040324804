import { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import StoreFinderBoxLayout from "components/StoreFinder/components/StoreFinderBoxLayout"
import { FormattedMessage } from "react-intl"
import PatternSelect from "components/StoreFinder/components/SearchByProduct/components/PatternSelect"
import { isMARINE } from "services/instances"

const SearchByProduct = ({ onClickNext, onClickPrevious, setPatternId }) => {
  const [btnIsDisabled, setBtnIsDisabled] = useState(true)

  const handleSelectChange = (patternId) => {
    setBtnIsDisabled(false)
    setPatternId(patternId)
  }

  const handleBtnClick = () => {
    if (btnIsDisabled) return
    onClickNext()
  }

  return (
    <StoreFinderBoxLayout
      title={
        <FormattedMessage id="SearchByProduct.title" defaultMessage="Distribuidores por producto" />
      }
      icon="fabric"
      goBack={onClickPrevious}
    >
      <p className="mb-1">
        <FormattedMessage
          id="SearchByProduct.inputLabel"
          defaultMessage="¿Qué producto estás buscando?"
        />
      </p>
      <div className="row g-0 justify-content-between">
        <div className="col-12 col-md-9 mb-3 mb-md-0">
          <PatternSelect onChange={handleSelectChange} />
        </div>
        <div className="col-auto">
          <button
            type="button"
            className={classNames({
              "btn btn-dark": true,
              "btn-prussian-blue": isMARINE()
            })}
            onClick={handleBtnClick}
            disabled={btnIsDisabled}
          >
            <FormattedMessage id="SearchByProduct.submitBtnLabel" defaultMessage="Buscar" />
          </button>
        </div>
      </div>
    </StoreFinderBoxLayout>
  )
}

SearchByProduct.propTypes = {
  onClickNext: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func,
  setPatternId: PropTypes.func
}

export default SearchByProduct
