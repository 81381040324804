import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H3_TAG } from "components/TitleUnderlined/constants"
import StoreFinder from "components/StoreFinder"
import "./styles/whereToBuy.scss"

const WhereToBuy = ({ id }) => (
  <div id={id}>
    <div className="container mt-2 pt-4">
      <div className="row">
        <div className="col-12 container-text--where-to-buy">
          <TitleUnderlined hTag={H3_TAG} className="font-gill-sans">
            <FormattedMessage
              id="VestuarioYProteccion.WhereToBuy.title"
              description="Petfriendly section title WhereToBuy"
              defaultMessage="¿Quieres conocer dónde adquirir estas referencias para tus espacios o proyectos?"
            />
          </TitleUnderlined>
          <p>
            <FormattedMessage
              id="VestuarioYProteccion.WhereToBuy.description"
              description="Petfriendly section title info"
              defaultMessage="Contamos con varios distribuidores en todo el país."
            />
          </p>
        </div>
      </div>
    </div>
    <StoreFinder />
  </div>
)

WhereToBuy.propTypes = {
  id: PropTypes.string
}

export default WhereToBuy
