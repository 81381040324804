import { FormattedMessage } from "react-intl"
import VideoCommon from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"
import "./styles/video.scss"
import ReactPlayer from "react-player"

const Video = () => {
  const isMobile = useMediaQuery(SM)

  return (
    <section className="container-fluid g-xl-0 mt-lg-5 mb-lg-4 p-0">
      <VideoCommon className="w-100 p-0" fromYoutube>
        <div className="video-saxony p-0" style={{ width: "100%" }}>
          <ReactPlayer
            url="https://youtu.be/zlb-_E76vko"
            loop
            controls
          />
        </div>
      </VideoCommon>
    </section>
  )
}

export default Video
