import PropTypes from "prop-types"
import Slider from "react-slick"
import HomeSlide from "./components/HomeSlide"
import HomeWithForm from "./components/HomeWithForm"
import { SLIDER_OPTIONS } from "./constants"

const Banner = ({ slides = [{}], align = "center", targetLink, noDescription = false }) => (
  <Slider {...SLIDER_OPTIONS}>
    {slides.map(({ id, ...slide }) =>
      !slide.form ? (
        <HomeSlide
          key={`key_${id}`}
          align={align}
          slide={slide}
          titleClassName={slide.titleClassName}
          btnClassName={slide.btnClassName}
          targetLink={targetLink}
          noDescription={noDescription}
        />
      ) : (
        <HomeWithForm key={`key_${id}`} align="center" slide={slide} form={slide.form} />
      )
    )}
  </Slider>
)

Banner.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageThumb: PropTypes.string,
      title: PropTypes.object,
      description: PropTypes.object,
      link: PropTypes.string,
      buttonText: PropTypes.object,
      btnClassName: PropTypes.string
    })
  ),
  align: PropTypes.string,
  targetLink: PropTypes.string,
  noDescription: PropTypes.bool
}

export default Banner
