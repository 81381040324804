import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { USES_ITEMS } from "./constants"

const UsesList = ({ uses = USES_ITEMS }) => (
  <div className="container">
    <div className="d-flex flex-row flex-wrap justify-content-around">
      {uses.map(({ id, text, icon }) => (
        <div key={`key_${id}`} className="align-self-center text-center m-2 m-md-4 px-md-2">
          <img className="icon my-2" src={icon} />
          {text && (
            <p>
              <FormattedMessage
                {...text}
                values={{
                  br: <br />
                }}
              />
            </p>
          )}
        </div>
      ))}
    </div>
  </div>
)

UsesList.propTypes = {
  uses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.object,
      icon: PropTypes.string
    })
  )
}
export default UsesList
