import { useEffect, useState } from "react"
import { useStateMachine } from "little-state-machine"
import useToggle from "hooks/useToggle"
import { updateAction } from "app/store"

const MASIALA_CATALOGUE_ITEM = { catalogue: "CATALOGUE, MASIALA" }

const useRequestModal = () => {
  const [toggable, toggle] = useToggle()
  const { state, actions } = useStateMachine({ updateAction })
  const [modalContext, setModalContext] = useState({})

  const handleAddSample = (newSample) => {
    const hasSample =
      state?.sampleCount?.length &&
      state?.sampleCount?.some(({ color }) => color === newSample.color)

    if (hasSample) return

    const updateSamplesCount = state?.sampleCount ? [...state?.sampleCount, newSample] : [newSample]

    actions.updateAction({
      sampleCount: updateSamplesCount
    })
  }

  const handleAddCatalogueToRequest = () => {
    const hasCatalogue =
      state?.sampleCount?.length &&
      // eslint-disable-next-line no-prototype-builtins
      state?.sampleCount?.some((obj) => obj.hasOwnProperty("catalogue") && obj.catalogue)

    if (hasCatalogue) return

    const updateSamplesCount = state?.sampleCount
      ? [...state?.sampleCount, MASIALA_CATALOGUE_ITEM]
      : [MASIALA_CATALOGUE_ITEM]

    actions.updateAction({
      sampleCount: updateSamplesCount
    })
  }

  const handleOrderCatalogue = (modalType) => {
    handleAddCatalogueToRequest()
    handleClickInRequest(modalType)
  }

  const handleClickInRequest = (modalType) => {
    setModalContext(modalType)
    toggle()
  }

  const handleShowSustainabilityModal = () => {
    const updateModalState = (value) => {
      actions.updateAction({
        showSustainabilityModal: value
      })
    }

    state.showSustainabilityModal
      ? updateModalState(!state.showSustainabilityModal)
      : updateModalState(true)
  }

  useEffect(() => {}, [modalContext])

  return {
    modalContext,
    toggable,
    toggle,
    handleClickInRequest,
    handleOrderCatalogue,
    handleAddSample,
    handleShowSustainabilityModal
  }
}
export default useRequestModal
