import { FormattedMessage } from "react-intl"
import banner from "./img/banner.jpg"

export const BANNER_INFO = {
  id: "home-slide",
  imageThumb: banner,
  title: (
    <FormattedMessage
      id="Segments.Hotel.Banner.title"
      defaultMessage="Recubriendo el mundo{br}de la hotelería"
      values={{
        br: <br />
      }}
    />
  ),
  description: (
    <FormattedMessage
      id="Segments.Hotel.Banner.description"
      defaultMessage="La magia de las tapicerías técnicas{br}en hoteles de lujo"
      values={{
        br: <br />
      }}
    />
  ),
  buttonText: (
    <FormattedMessage
      id="Segments.Hotel.Banner.buttonText"
      defaultMessage="Conoce aquí nuestro portafolio"
    />
  )
}
