import { FormattedMessage } from "react-intl"

export const ITEMS = [
  {
    id: "waterproof",
    title: (
      <FormattedMessage
        id="Petfriendly.WhyPF.waterproof"
        description="Petfriendly section why pet friendly item waterproof"
        defaultMessage="100% impermeables ante fluidos o manchas."
      />
    )
  },
  {
    id: "smellReduction",
    title: (
      <FormattedMessage
        id="Petfriendly.WhyPF.smellReduction"
        description="Petfriendly section why pet friendly item smell reduction"
        defaultMessage="Disminución del olor."
      />
    )
  },
  {
    id: "hairless",
    title: (
      <FormattedMessage
        id="Petfriendly.WhyPF.hairless"
        description="Petfriendly section why pet friendly item hairless"
        defaultMessage="Minimiza la adherencia al pelo."
      />
    )
  },
  {
    id: "resistance",
    title: (
      <FormattedMessage
        id="Petfriendly.WhyPF.resistance"
        description="Petfriendly section why pet friendly item resistance"
        defaultMessage="Brindan resistencia ante perros y gatos."
      />
    )
  }
]
